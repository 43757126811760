import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { Info, Logo, User } from "../../icons";
import TrialPeriod from "../TrialPeriod/container";
import Cookies from "universal-cookie";
import "./styles.scss";
import { get, isEmpty } from "lodash";
const cookies = new Cookies();

export default class TopNavigationComponent extends PureComponent {
  constructor() {
    super();
  }

  logout() {
    if (
      localStorage.getItem("AdminAuthorization") &&
      localStorage.getItem("Authorization")
    ) {
      localStorage.removeItem("userId");
      this.props.history.push("/admin/active");
      localStorage.removeItem("Authorization");
      this.props.handleSignOut();
      cookies.remove("Authorization", { path: "/", domain: "yardpost.com" });
    } else if (localStorage.getItem("AdminAuthorization")) {
      this.props.history.push("/login");
      localStorage.removeItem("AdminAuthorization");
      localStorage.removeItem("AdminEmail");
      cookies.remove("AdminAuthorization", {
        path: "/",
        domain: "yardpost.com",
      });
    } else if (localStorage.getItem("Authorization")) {
      this.props.history.push("/login");
      localStorage.removeItem("userId");
      localStorage.removeItem("AdminEmail");
      localStorage.removeItem("Authorization");
      cookies.remove("Authorization", { path: "/", domain: "yardpost.com" });
      this.props.handleSignOut();
    }
  }

  componentDidUpdate(prev) {
    document.getElementById("favicon").href = get(
      this.props.user,
      "user.favicon",
      "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png"
    );
  }

  render() {
    return (
      <>
        <header className="top__navigation top__navigation--fixed">
          <div className="top__navigation--container">
            <div className="top__navigation--row">
              <div className="top__navigation--left">
                <div className="top__navigation--logo">
                  <Link to="/">
                    <Logo />
                  </Link>
                </div>
                {localStorage.getItem("AdminAuthorization") &&
                localStorage.getItem("Authorization") &&
                !isEmpty(get(this.props.user, "user", {})) ? (
                  <>
                    <TrialPeriod
                      userData={get(this.props.user, "user", {})}
                      {...this.props}
                    />
                  </>
                ) : localStorage.getItem("Authorization") &&
                  !isEmpty(get(this.props.user, "user", {})) ? (
                  <>
                    <TrialPeriod
                      userData={get(this.props.user, "user", {})}
                      {...this.props}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="top__navigation--right">
                <div className="top__navigation--nav">
                  {localStorage.getItem("AdminAuthorization") &&
                  localStorage.getItem("Authorization") ? (
                    <>
                      <a
                        href="mailto:support@yardpost.com"
                        className="nav__link"
                      >
                        <Info />
                      </a>

                      <Dropdown
                        trigger={["click"]}
                        placement="bottomRight"
                        overlayClassName="top__navigation--dropdown"
                        overlay={
                          <Menu>
                            <div className="profile__wrapper">
                              <div className="profile__name">
                                {get(this.props.user, "user.firstName", "")}{" "}
                                {get(this.props.user, "user.lastName", "")}
                              </div>
                              <div className="profile__role">
                                {get(this.props.user, "user.email", "")}
                              </div>
                            </div>
                            <Menu.Item key="1">
                              <Link
                                to="/account/profile"
                                className="top__navigation--dropdown-link"
                              >
                                Profile
                              </Link>
                            </Menu.Item>
                            <Menu.Item
                              key="2"
                              onClick={this.logout.bind(this)}
                              className="top__navigation--dropdown-btn"
                            >
                              Sign out
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <a
                          className="ant-dropdown-link nav__link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <User />
                        </a>
                      </Dropdown>
                    </>
                  ) : localStorage.getItem("Authorization") ? (
                    <>
                      <a
                        href="mailto:support@yardpost.com"
                        className="nav__link"
                      >
                        <Info />
                      </a>
                      <Dropdown
                        trigger={["click"]}
                        placement="topRight"
                        overlayClassName="top__navigation--dropdown"
                        overlay={
                          <Menu>
                            <div className="profile__wrapper">
                              <div className="profile__name">
                                {get(this.props.user, "user.firstName", "")}{" "}
                                {get(this.props.user, "user.lastName", "")}
                              </div>
                              <div className="profile__role">
                                {get(this.props.user, "user.email", "")}
                              </div>
                            </div>
                            <Menu.Item key="1">
                              <Link
                                to="/account/profile"
                                className="top__navigation--dropdown-link"
                              >
                                Profile
                              </Link>
                            </Menu.Item>
                            <Menu.Item
                              key="2"
                              onClick={this.logout.bind(this)}
                              className="top__navigation--dropdown-btn"
                            >
                              Sign out
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <a
                          className="ant-dropdown-link nav__link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <User />
                        </a>
                      </Dropdown>
                    </>
                  ) : localStorage.getItem("AdminAuthorization") ? (
                    <>
                      <a
                        href="mailto:support@yardpost.com"
                        className="nav__link"
                      >
                        <Info />
                      </a>
                      <Dropdown
                        trigger={["click"]}
                        placement="bottomRight"
                        overlayClassName="top__navigation--dropdown"
                        overlay={
                          <Menu>
                            <div className="profile__wrapper">
                              <div className="profile__name">Super Admin</div>
                              <div className="profile__role">
                                {localStorage.getItem("AdminEmail")}
                              </div>
                            </div>
                            <Menu.Item
                              key="2"
                              onClick={this.logout.bind(this)}
                              className="top__navigation--dropdown-btn"
                            >
                              Sign out
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <a
                          className="ant-dropdown-link nav__link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <User />
                        </a>
                      </Dropdown>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
