import fetch from "isomorphic-fetch";
// import Cookies from 'universal-cookie'

// import Config from '../config'

// const cookies = new Cookies()

const handleHTTPErrors = (res) => {
  if (res.ok) return res;
  return res.json().then((err) => {
    throw err;
  });
};

export default (url, options) => {
  // const jwtToken = cookies.get('Authorization');
    return fetch(url, options).then(handleHTTPErrors);
};
