import React, { PureComponent } from "react";
// import PropTypes from 'prop-types'
import "./styles.scss";

export default class DemoHeaderComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const { campaignType } = this.props;
    return (
      <>
        <div className="preview__header">
          <div className="preview__header--row">
            <div className="preview__header--left">
              <div className="preview__header--title">
                  <> Demo Social Link Page Template </>
              </div>
                <div className="preview__header--subtitle">
                  Once Click the button Start Editing this Social Links, you’ll
                  be able to edit your social link screen.
                </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
