import { connect } from "react-redux";

import StarterPageComponent from "./component";

const StarterPageContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(StarterPageComponent);

export default StarterPageContainer;
