import * as React from "react";

const Realtor = ({ ...props }) => {
  return (
    <svg
      width={30}
      height={36}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.473 0v27.054H28.12V0H1.473Zm14.249 3.421h2.45a4.96 4.96 0 0 1 3.53 1.462 4.96 4.96 0 0 1 1.462 3.53 4.997 4.997 0 0 1-4.992 4.99l-2.45.003V3.421Zm-2.995 20.006H5.305V3.423h7.42v20.004Zm2.995.02V14.04l9.593 9.409h-9.593Zm-10.62 10.31h1.965v-.704H5.103v-.934H7.23v-.703H4.259v4.102h3.066v-.703H5.103v-1.058Zm8.226-2.34h-.844v4.1h2.807v-.703h-1.963v-3.398Zm2.424.702h1.243v3.399h.844v-3.399h1.199v-.703h-3.286v.703Zm13.468-.552a.781.781 0 1 0-.004 1.563.781.781 0 0 0 .004-1.563Zm0 1.47a.688.688 0 1 1-.001-1.376.688.688 0 0 1 .001 1.375ZM3.202 35.027c-.006-.208-.017-.748-.04-.917-.045-.371-.315-.472-.455-.568.275-.163.455-.292.557-.647.1-.354.016-.647-.04-.827-.118-.445-.535-.636-.945-.653H0v4.102h.844v-1.62h.99c.293 0 .496.135.507.41-.011.298-.011.625 0 .827a.937.937 0 0 0 .112.383h.906v-.152c-.09-.056-.157-.107-.157-.338Zm-1.204-1.834H.844v-1.075h1.142c.377 0 .512.17.512.462a.543.543 0 0 1-.5.613Zm8.32-1.778h-.973l-1.43 4.102h.862l.287-.822h1.52l.28.822h.862l-1.408-4.102Zm-1.024 2.577.54-1.592.546 1.592H9.294ZM22.88 31.86c-.343-.355-.844-.513-1.367-.513s-1.024.158-1.367.513c-.383.4-.608 1.018-.608 1.637s.226 1.238.608 1.637c.343.355.844.513 1.367.513s1.024-.158 1.367-.513c.383-.4.608-1.018.608-1.637s-.225-1.238-.607-1.637Zm-.613 2.773a1.09 1.09 0 0 1-.754.276c-.27 0-.54-.079-.753-.276-.27-.247-.406-.692-.411-1.136.005-.445.14-.89.41-1.137.214-.197.484-.276.754-.276s.54.08.754.276c.27.248.406.692.411 1.137-.005.444-.14.889-.41 1.136Zm5.277.394c-.006-.208-.017-.748-.04-.917-.044-.371-.315-.472-.455-.568.275-.163.456-.292.557-.647.1-.354.017-.647-.04-.827-.118-.445-.534-.636-.945-.653h-2.279v4.102h.844v-1.62h.99c.293 0 .496.135.507.41-.011.298-.011.625 0 .827a.938.938 0 0 0 .112.383h.906v-.152c-.09-.056-.157-.107-.157-.338Zm-1.204-1.834h-1.154v-1.075h1.143c.377 0 .512.17.512.462a.544.544 0 0 1-.501.613Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.621 32.142c0-.074-.033-.151-.098-.188a.401.401 0 0 0-.213-.043h-.38v.874h.106v-.403h.197l.25.403h.126l-.264-.403c.155-.004.276-.068.276-.24Zm-.421.156h-.164v-.31h.25c.108 0 .227.017.227.152 0 .178-.19.158-.313.158Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Realtor;
