import React, { PureComponent } from "react";
import { get, isEmpty } from "lodash";
import ReactModal from "react-modal";
import RadioButtonGroup from "../../../components/ui/RadioButtonGroup/component";
import { Formik } from "formik";
import InputMask from "react-text-mask";
import load from "load-stripe";
import { PoweredByStripe } from "../../../components/icons";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./styles.scss";
const stripeKey = process.env.REACT_APP_STRIPE_CLIENT_TEST_KEY;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

const cardNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const cardDate = [/\d/, /\d/, "/", /\d/, /\d/];

const securityCode = [/\d/, /\d/, /\d/, /\d/];

export default class TrialPeriodComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      remainingDays: 0,
      stripeError: {},
      subscriptions: [],
      plan: 0,
      selectedPlan: "year",
      modalIsOpen: false,
      promoData: {},
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = get(this.props.userData, "trial_end_date", "")
      ? new Date(get(this.props.userData, "trial_end_date", ""))
      : new Date();
    const secondDate = new Date();
    this.props.getSubscriptions();
    const diffDays = Math.round((firstDate - secondDate) / oneDay);
    if (diffDays < 0) {
      this.setState({ remainingDays: 0 });
    } else {
      this.setState({ remainingDays: diffDays });
    }
  }

  componentDidUpdate() {
    if (this.props.promoPhase === "success") {
      this.setState({ promoData: this.props.promoData });
    }
    if (this.props.promoPhase === "error") {
      this.setState({ promoData: this.props.promoData });
    }
    if (this.props.subscriptionPhase === "success") {
      this.props.resetLoginUser();
      this.setState({ subscriptions: this.props.subscriptions });
    }
    if (this.props.addCardPhase === "success") {
      this.props.resetLoginUser();
      this.props.getUser();
      this.setState({ paymentLogin: false, modalIsOpen: false });
      this.openNotificationWithIcon(
        "success",
        "Subcription added successfully."
      );
    }
    if (this.props.addCardPhase === "error") {
      this.props.resetLoginUser();
      this.setState({ paymentLogin: false, modalIsOpen: false });
      this.openNotificationWithIcon("error", "User already Subscribed");
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    return { promoData: props.promoData };
  };

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: message,
    });
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  applyCoupon(value) {
    this.props.applyPromoCode({ code: value });
  }

  removeCoupon(value, setFieldValue) {
    setFieldValue("CouponCode", "");
    this.props.initPromoPhase();
  }

  async submitCard(value) {
    const { subscriptions, plan, promoData } = this.state;
    this.setState({ isLoading: true, paymentLogin: true });
    let self = this;
    this.setState({ stripeError: {} });
    let cardDate = value.cardDate.split("/");
    await load(stripeKey).then((stripe) => {
      stripe.card
        .createToken({
          number: value.cardNumber,
          cvc: value.securityCode,
          exp_month: cardDate[0],
          exp_year: cardDate[1],
        })
        .then(async (token) => {
          if (token) {
            let obj = {};
            obj.token = token.id;
            obj.planId = subscriptions[0].plan[plan].id;
            obj.productId = subscriptions[0].id;
            obj.coupon = !isEmpty(promoData)
              ? get(promoData, "data.id", "")
              : "";
            self.setState({ isLoading: true, paymentLogin: true });
            this.props.addCard(obj);
          }
        })
        .catch((err) => {
          let stripeError = {};
          switch (err.type) {
            case "card_error":
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "rate_limit_error":
              // Too many requests made to the API too quickly
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "incorrect_number":
              // Too many requests made to the API too quickly
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(
                err,
                "message",
                "Your card number is incorrect."
              );
              break;
            case "invalid_request_error":
              // Invalid parameters were supplied to Stripe's API
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "api_error":
              // An error occurred internally with Stripe's API
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "api_connection_error":
              // Some kind of error occurred during the HTTPS communication
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "authentication_error":
              // You probably used an incorrect API key
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            default:
              // Handle any other types of unexpected errors
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
          }
          this.setState({ stripeError, isLoading: false, paymentLogin: false });
        });
    });
  }

  onSelectedPlan(e) {
    if (e.target.value === "month") {
      this.setState({ plan: 1, selectedPlan: "month" });
    } else if (e.target.value === "year") {
      this.setState({ plan: 0, selectedPlan: "year" });
    }
  }

  render() {
    const { stripeError, modalIsOpen, promoData, subscriptions, plan } =
      this.state;
    return (
      <>
        {get(this.props.userData, "isSumoApp", "") ? (
          <div className="trial__period">
            <button type="button" className="trial__period--btn">
              Thanks for activating your AppSumo discount plan!
            </button>
          </div>
        ) : (
          <>
            {this.state.remainingDays !== 0 && (
              <div className="trial__period">
                <button
                  type="button"
                  className="trial__period--btn"
                  onClick={
                    get(this.props.userData, "plan", "") === ""
                      ? this.openModal.bind()
                      : ""
                  }
                  disabled={
                    get(this.props.userData, "plan", "") === "" ? false : true
                  }
                >
                  You have {this.state.remainingDays} days left in your trial
                  period
                </button>
              </div>
            )}
          </>
        )}
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Otp"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <Formik
            initialValues={{
              cardNumber: "",
              nameOnCard: "",
              cardDate: "",
              securityCode: "",
              CouponCode: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.cardNumber) {
                errors.cardNumber = "Please enter card number";
              } else if (values.cardNumber.length <= 16) {
                errors.cardNumber = "Please enter valid card number";
              }
              if (!values.nameOnCard) {
                errors.nameOnCard = "Please enter name";
              }
              if (!values.cardDate) {
                errors.cardDate = "Please enter expiration date";
              } else if (values.cardDate.includes("_")) {
                errors.cardDate = "Please enter valid expiration date";
              }
              if (!values.securityCode) {
                errors.securityCode = "Please enter security code";
              } else if (
                values.securityCode.length < 3 ||
                values.securityCode.length > 4
              ) {
                errors.securityCode = "Please enter valid security code";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                this.submitCard(values);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="modal-content">
                  <div className="react-modal-header">
                    <h5 className="react-modal-title">Subscribe to Yardpost</h5>
                  </div>

                  <div className="react-modal-body">
                    <div className="pricing__module">
                      <div className="pricing__module--inner ">
                        {get(this.props, "subscriptions", "") && (
                          <RadioButtonGroup
                            className="tesss"
                            onSelectedPlan={(e) =>
                              this.onSelectedPlan.bind(this)
                            }
                            selectedPlan={this.state.selectedPlan}
                            attribute="select-plan"
                            subscriptions={get(this.props, "subscriptions", {})}
                            {...this.props}
                          />
                        )}
                        <div className="pricing__module--save-money">
                          Save 25%
                        </div>
                      </div>
                      <div className="pricing__module--text">
                        We use Stripe to securely store and process your payment
                        info. All transactions are secure and encrypted
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="label-primary">Name on card</label>
                      <input
                        type="text"
                        name="nameOnCard"
                        className="form-control material-textfield-input"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nameOnCard}
                        required
                      />
                      {errors.nameOnCard && touched.nameOnCard && (
                        <div className="invalid-feedback">
                          {errors.nameOnCard}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="label-primary">Card Number</label>
                      <InputMask
                        guide={false}
                        mask={cardNumberMask}
                        name="cardNumber"
                        className="form-control material-textfield-input"
                        onChange={(e) => {
                          handleChange(e);
                          this.setState({ stripeError: {} });
                        }}
                        onBlur={handleBlur}
                        value={values.cardNumber}
                        required
                      />
                      {errors.cardNumber && touched.cardNumber && (
                        <div className="invalid-feedback">
                          {errors.cardNumber}
                        </div>
                      )}
                      {!isEmpty(stripeError) &&
                        stripeError.code === "incorrect_number" && (
                          <div className="invalid-feedback">
                            {stripeError.message}
                          </div>
                        )}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">
                            Expiration Date (MM/YY)
                          </label>
                          <InputMask
                            guide={false}
                            mask={cardDate}
                            name="cardDate"
                            className="form-control material-textfield-input"
                            onChange={(e) => {
                              handleChange(e);
                              this.setState({ stripeError: {} });
                            }}
                            onBlur={handleBlur}
                            value={values.cardDate}
                            required
                          />
                          {errors.cardDate && touched.cardDate && (
                            <div className="invalid-feedback">
                              {errors.cardDate}
                            </div>
                          )}
                          {!isEmpty(stripeError) &&
                            (stripeError.code === "invalid_expiry_year" ||
                              stripeError.code === "invalid_expiry_month") && (
                              <div className="invalid-feedback">
                                {stripeError.message}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">
                            Security Code (CVV)
                          </label>
                          <InputMask
                            guide={false}
                            mask={securityCode}
                            type="text"
                            name="securityCode"
                            className="form-control material-textfield-input"
                            onChange={(e) => {
                              handleChange(e);
                              this.setState({ stripeError: {} });
                            }}
                            onBlur={handleBlur}
                            value={values.securityCode}
                            required
                          />
                          {errors.securityCode && touched.securityCode && (
                            <div className="invalid-feedback">
                              {errors.securityCode}
                            </div>
                          )}
                          {!isEmpty(stripeError) &&
                            stripeError.code === "invalid_cvc" && (
                              <div className="invalid-feedback">
                                {stripeError.message}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <label className="label-primary">Coupon Code</label>
                      <div className="coupon__code">
                        <input
                          type="text"
                          name="CouponCode"
                          className="form-control material-textfield-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.CouponCode}
                          required
                        />
                        {!isEmpty(promoData) ? (
                          <button
                            type="button"
                            onClick={this.removeCoupon.bind(
                              this,
                              values.CouponCode,
                              setFieldValue
                            )}
                            className="btn btn__purple coupon__code--btn"
                          >
                            Remove
                          </button>
                        ) : (
                          <button
                            disabled={values.CouponCode.length === 0}
                            type="button"
                            onClick={this.applyCoupon.bind(
                              this,
                              values.CouponCode
                            )}
                            className="btn btn__purple coupon__code--btn"
                          >
                            Apply
                          </button>
                        )}
                      </div>
                      {get(promoData, "message", false) && (
                        <div
                          className={
                            get(promoData, "success", false)
                              ? ""
                              : "invalid-feedback"
                          }
                        >
                          {get(promoData, "message", "")}
                        </div>
                      )}
                      <div className="powered__by__stripe">
                        <PoweredByStripe />
                      </div>
                    </div>
                  </div>
                  <div className="react-modal-footer flex-column">
                    <button
                      type="submit"
                      className="btn btn-lg btn__purple w-100"
                      disabled={this.state.paymentLogin || isSubmitting}
                    >
                      {this.state.paymentLogin ? (
                        <Spin indicator={antIcon} />
                      ) : !isEmpty(promoData) &&
                        get(promoData, "success", false) ? (
                        get(promoData, "data.amount_off", null) !== null ? (
                          plan === 1 ? (
                            "Pay & Start Using Yardpost | $" +
                            (!isEmpty(subscriptions) &&
                              subscriptions[0].plan[1].amount / 100 -
                                promoData.data.amount_off / 100) +
                            "/mo"
                          ) : (
                            "Pay & Start Using Yardpost | $" +
                            (!isEmpty(subscriptions) &&
                              subscriptions[0].plan[0].amount / 100 -
                                promoData.data.amount_off / 100) +
                            "/yr"
                          )
                        ) : plan === 1 ? (
                          "Pay & Start Using Yardpost | $" +
                          (
                            !isEmpty(subscriptions[0]) &&
                            subscriptions[0].plan[1].amount / 100 -
                              ((subscriptions[0].plan[1].amount / 100) *
                                promoData.data.percent_off) /
                                100
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) +
                          "/mo"
                        ) : (
                          "Pay & Start Using Yardpost | $" +
                          (!isEmpty(subscriptions[0]) &&
                            (
                              subscriptions[0].plan[0].amount / 100 -
                              ((subscriptions[0].plan[0].amount / 100) *
                                promoData.data.percent_off) /
                                100
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })) +
                          "/yr"
                        )
                      ) : plan === 1 ? (
                        "Pay & Start Using Yardpost | $" +
                        (
                          !isEmpty(subscriptions[0]) &&
                          subscriptions[0].plan[1].amount / 100
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) +
                        "/mo"
                      ) : (
                        "Pay & Start Using Yardpost | $" +
                        (!isEmpty(subscriptions[0]) &&
                          (
                            subscriptions[0].plan[0].amount / 100
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })) +
                        "/yr"
                      )}
                    </button>
                    <button
                      onClick={this.closeModal.bind(this)}
                      className="btn btn-lg btn__link--deafult"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </ReactModal>
      </>
    );
  }
}
