import * as React from "react";

const CheckMarkIcon = ({ ...props }) => {
  return (
    <svg
      width={13}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.852.044c.406 0 .735.33.735.735v13.28c0 .406-.329.735-.735.735h-.44a.735.735 0 0 1-.735-.735V.779c0-.405.329-.735.735-.735h.44Z"
        fill="#fff"
      />
      <path
        d="M12.045 9.093 6.5 15.045.955 9.093"
        stroke="#fff"
        strokeWidth={1.47}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CheckMarkIcon;
