import { connect } from "react-redux";
import {
  getProperty,
  addProperty,
  initPropertyPhase,
  getAllPropertyAddress,
} from "../../../../../store/mylisting/duck";
import {uploadImages} from "../../../../../store/user/duck";
import ListingDetailsComponent from "./component";

const ListingDetailsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    getAllPropertyAddressData: state.mylisting.getAllPropertyAddressData,
    getAllPropertyAddressPhase: state.mylisting.getAllPropertyAddressPhase,
    addPropertyPhase: state.mylisting.addPropertyPhase,
    getCountryAndStatePhase: state.mylisting.getCountryAndStatePhase,
    getCountryAndStateData: state.mylisting.getCountryAndStateData,
    addPropertyMessage: state.mylisting.addPropertyMessage,
  }),
  // Map actions to dispatch and props
  {
    // getCountryAndState,
    initPropertyPhase,
    addProperty,
    getAllPropertyAddress,
    uploadImages
  }
)(ListingDetailsComponent);

export default ListingDetailsContainer;
