import React, { PureComponent } from "react";
import Slider from "react-slick";
import Card from "../../../../components/common/Card/component";

import "./styles.scss";
const settings = {
  dots: false,
  infinite: false,
  lazyLoad: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default class ListingSliderComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const { allLeadPage, filterLeadPage, paramId, activePropertyTab } =
      this.props;
    return (
      <>
        <div className="section__listing--slider">
          <div className="tabs__underline">
            {this.props.isSell && (
              <button
                type="button"
                onClick={() => this.props.propertyTabChange("sale")}
                className={
                  activePropertyTab === "sale"
                    ? "btn btn__tab active"
                    : "btn btn__tab"
                }
              >
                For Sale
              </button>
            )}
            {this.props.isRent && (
              <button
                type="button"
                onClick={() => this.props.propertyTabChange("rent")}
                className={
                  activePropertyTab === "rent"
                    ? "btn btn__tab active"
                    : "btn btn__tab"
                }
              >
                For Rent
              </button>
            )}
          </div>
          <Slider className="listing__slider" {...settings}>
            {allLeadPage &&
              allLeadPage.map((data, i) => {
                return (
                  <div key={i} className="listing__slider--item">
                    <Card data={data} {...this.props} />
                  </div>
                );
              })}
            {allLeadPage.length === 0 && <div> Not available. </div>}
          </Slider>
        </div>
      </>
    );
  }
}
