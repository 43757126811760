import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Plus } from "../../../../components/icons";
import SocialLink from "../../../../images/social-link.png";
import GifSell from "../../../../images/preview.png";
import ListingWebsite from "../../../../images/listing-website.png";
import { get } from "lodash";
import "./styles.scss";

export default class StarterPageComponent extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="page__header">
          <div className="page__header--container">
            <div className="page__header--row">
              <div className="page__header--left">
                <h1 className="page__header--title">
                  Welcome, {get(this.props.user, "user.firstName", "")}!
                </h1>
                <p className="page__header--subtitle">
                  Your minutes away from getting qualified leads. {""}
                  <Link to="/create-social-link">
                    Create
                  </Link>{" "}
                  your first Yardpost Link
                </p>
              </div>
              <div className="page__header--right">
                <button
                  onClick={() =>
                    this.props.history.push(
                      "/create-social-link"
                    )
                  }
                  type="button"
                  className="btn btn__purple"
                >
                  <Plus className="btn__icon" /> Create New Link
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="leads__starter">
        <div className="page__header--container">
          <div className="custom__container">
            <h2 className="leads__starter--title">Get started with Yardpost</h2>
            <div className="leads__starter--row">
              <div className="leads__starter--col">
                <div
                  className="leads__starter--card"
                  onClick={() =>
                    this.props.history.push(
                      "/create-social-link"
                    )
                  }
                >
                  <div className="label__budge label__budge--primary ">
                    Social Links
                  </div>
                  <div className="leads__starter--info">
                    <h4>Create a Social Link</h4>
                    <p>
                      Use this template to create a Social Link with all your
                      links that you can post on social media platforms.
                    </p>
                    <Link
                      to="/create-social-link"
                      className="btn btn__link"
                    >
                      Get Started
                    </Link>
                  </div>
                  <div className="leads__starter--thumb">
                    <div className="mac__window">
                      <div
                        id="mac__window--header"
                        className="mac__window--header"
                      >
                        <div className="mac__window--dot dot__1"></div>
                        <div className="mac__window--dot dot__2"></div>
                        <div className="mac__window--dot dot__3"></div>
                      </div>
                      <div class="mac__window--body">
                        <img src={SocialLink} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="leads__starter--col">
                <div
                  className="leads__starter--card"
                  onClick={() =>
                    this.props.history.push("/create-new-landing-page/sell")
                  }
                >
                  <div className="label__budge label__budge--primary ">
                    Sell pages
                  </div>
                  <div className="leads__starter--info">
                    <h4>Create a targeted Sell Page</h4>
                    <p>
                      Use this template if you're trying to target people
                      looking to sell their home.
                    </p>
                    <Link
                      to="/create-new-landing-page/sell"
                      className="btn btn__link"
                    >
                      Get Started
                    </Link>
                  </div>
                  <div className="leads__starter--thumb">
                    <div className="mac__window">
                      <div
                        id="mac__window--header"
                        className="mac__window--header"
                      >
                        <div className="mac__window--dot dot__1"></div>
                        <div className="mac__window--dot dot__2"></div>
                        <div className="mac__window--dot dot__3"></div>
                      </div>
                      <div class="mac__window--body">
                        <img src={GifSell} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="leads__starter--col">
                <div
                  className="leads__starter--card"
                  onClick={() =>
                    this.props.history.push(
                      "/create-new-landing-page/listing-website"
                    )
                  }
                >
                  <div className="label__budge label__budge--primary ">
                    Listing Websites
                  </div>
                  <div className="leads__starter--info">
                    <h4>Create a Listing Website for each listing</h4>
                    <p>
                      Use this template if you’re trying to get people to buy
                      your exclusive listings
                    </p>
                    <Link
                      to="/create-new-landing-page/listing-website"
                      className="btn btn__link"
                    >
                      Get Started
                    </Link>
                  </div>
                  <div className="leads__starter--thumb">
                    <div className="mac__window">
                      <div
                        id="mac__window--header"
                        className="mac__window--header"
                      >
                        <div className="mac__window--dot dot__1"></div>
                        <div className="mac__window--dot dot__2"></div>
                        <div className="mac__window--dot dot__3"></div>
                      </div>
                      <div class="mac__window--body">
                        <img src={ListingWebsite} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}
