import { connect } from "react-redux";
import {
  getProperty,
  addProperty,
  initPropertyPhase,
} from "../../../../store/mylisting/duck";
import CreateListingComponent from "./component";

const CreateListingContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    addPropertyPhase: state.mylisting.addPropertyPhase,
    addPropertyMessage: state.mylisting.addPropertyMessage,
  }),
  // Map actions to dispatch and props
  {
    initPropertyPhase,
    addProperty,
  }
)(CreateListingComponent);

export default CreateListingContainer;
