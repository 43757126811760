import React, { PureComponent } from "react";
import ReadMoreAndLess from "react-read-more-less";
import { get } from "lodash";
import "./styles.scss";
import { mapStyle } from "../../../../constants/config";
const google = window.google;

// const shareLinks = (
export default class PropertyInfoComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  handleMap() {
    const { leadPageProperty } = this.props;
    const zoom = 18;
    if (document.getElementById("map-canvas")) {
      let map = new google.maps.Map(document.getElementById("map-canvas"), {
        zoom: zoom,
        center: new google.maps.LatLng(
          parseFloat(leadPageProperty.lat),
          parseFloat(leadPageProperty.lng)
        ),
        fullscreenControl: false,
        // zoomControl: false,
        styles: mapStyle,
        // streetViewControl: false,
        draggable: true,
        scrollwheel: false,
        // scaleControl: false,
        // panControl: false,
        // clickableIcons: false,
        // mapTypeControl: false
      });
      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          parseFloat(leadPageProperty.lat),
          parseFloat(leadPageProperty.lng)
        ),
        icon: "https://yardpost-dev.s3.amazonaws.com/property_80501646920765595",
      });
      // To add the marker to the map, call setMap();
      marker.setMap(map);

      this.setState({
        map,
      });

      let contentString = `
            <div class="card-map-view">
              <div class="card-map-info">
                <div class="card-map-price">${get(
                  leadPageProperty,
                  "address",
                  ""
                )}</div>
                <div class="card-map-address">
                  ${get(leadPageProperty, "city", "")} ${" "} ${get(
        leadPageProperty,
        "state",
        ""
      )} ${" "} ${get(leadPageProperty, "zipcode", "")}
                </div>
              </div>
            </div>
          `;
      var infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      marker.addListener("click", () => {
        if (marker.getAnimation() !== null) {
          marker.setAnimation(null);
        } else {
          // marker.setAnimation(google.maps.Animation.BOUNCE);
          if (get(leadPageProperty, "address", "") !== "") {
            infowindow.open(map, marker);
          }
        }
      });

      map.addListener("click", () => {
        infowindow.close();
      });
    }
  }
  // window.open(`https://www.google.com/maps/search/?api=1&query=${get(getDetailsData, 'Latitude','')},${get(getDetailsData, 'Longitude','')}`)
  handleDirection() {
    const { leadPageProperty } = this.props;
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${get(
        leadPageProperty,
        "lat",
        ""
      )},${get(leadPageProperty, "lng", "")}`
    );
  }

  componentDidMount(prev) {
    setTimeout(() => {
      this.handleMap();
    }, 7000);
  }

  render() {
    const { leadPageProperty } = this.props;
    return (
      <>
        <section className="section__property__info">
          <div className="property__info">
            <div className="property__info--left">
              <div className="property__info--content">
                <h4 className="property__info--content-title">
                  All about {`${get(leadPageProperty, "address", "")}`}
                  {get(leadPageProperty, "units", "") !== ""
                    ? `, ${get(leadPageProperty, "units", "")}`
                    : ""}
                </h4>
                <p className="property__info--content-subtitle">
                  {" "}
                  {get(leadPageProperty, "type", "") !== "rental" ? (
                    <>
                      {`$${parseInt(
                        get(leadPageProperty, "askingPrice", 0)
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`}
                      {parseInt(get(leadPageProperty, "interiorSqFeet", 0)) !==
                        0 &&
                        get(leadPageProperty, "interiorSqFeet", "") !== "" &&
                        ` | $${parseInt(
                          get(leadPageProperty, "interiorSqFeet", "")
                        ).toLocaleString()} SqFt`}
                    </>
                  ) : (
                    <>
                      {get(leadPageProperty, "monthlyRent", 0) && (
                        <>
                          $
                          {parseInt(
                            get(leadPageProperty, "monthlyRent", 0)
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }) + "/mo"}
                        </>
                      )}
                      {get(leadPageProperty, "squareFootage", 0) !== "" &&
                        parseInt(get(leadPageProperty, "squareFootage", 0)) !==
                          0 &&
                        parseInt(get(leadPageProperty, "squareFootage", "")) !==
                          "" &&
                        ` | ${parseInt(
                          get(leadPageProperty, "squareFootage", "")
                        ).toLocaleString()} Sq.Ft`}
                    </>
                  )}
                </p>
                <div className="property__info--desc">
                  <pre>
                    <ReadMoreAndLess
                      ref={this.ReadMore}
                      className="read-more-content"
                      charLimit={250}
                      expanded={false}
                      readMoreText="Read More"
                      readLessText="Read Less"
                    >
                      {get(leadPageProperty, "description", "")}
                    </ReadMoreAndLess>
                  </pre>
                </div>
                <ul className="property__info--list">
                  {get(leadPageProperty, "propertyFeature", []).length > 0 ? (
                    <li>
                      <h4
                        className={
                          this.props.propertyFeature
                            ? "title accordian-toggle"
                            : "title"
                        }
                        onClick={(e) =>
                          this.props.showPropertyFeature(
                            !this.props.propertyFeature
                          )
                        }
                      >
                        {" "}
                        Property Features
                      </h4>
                      <div className="accordian__feature--desc">
                        <ul className="feature__list">
                          {this.props.propertyFeature &&
                          get(leadPageProperty, "propertyFeature", []).length >
                            0 ? (
                            get(leadPageProperty, "propertyFeature", []).map(
                              (p) => {
                                return <li>{p}</li>;
                              }
                            )
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                  {get(leadPageProperty, "buildingFeature", []).length > 0 && (
                    <li>
                      <h4
                        className={
                          this.props.buildingFeature
                            ? "title accordian-toggle"
                            : "title"
                        }
                        onClick={(e) =>
                          this.props.showBuildingFeature(
                            !this.props.buildingFeature
                          )
                        }
                      >
                        Building Features
                      </h4>
                      <div className="accordian__feature--desc">
                        <ul className="feature__list">
                          {this.props.buildingFeature &&
                          get(leadPageProperty, "buildingFeature", []).length >
                            0 ? (
                            get(leadPageProperty, "buildingFeature", []).map(
                              (p) => {
                                return <li>{p}</li>;
                              }
                            )
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="property__info--right">
              <div className="property__info--map">
                <div id="map-canvas" className="property__map" />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
