import * as React from "react";

const Logo = ({ ...props }) => {
  return (
    <svg
      width={140}
      height={27}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="text"
        d="M47.799 14.418v6.874h-2.845v-6.875L38.92 3.982h3.24l4.228 7.666 4.204-7.666h3.24l-6.033 10.436ZM65.503 8.927v12.365h-2.67V19.51c-.94 1.31-2.4 2.101-4.328 2.101-3.362 0-6.157-2.844-6.157-6.503 0-3.685 2.795-6.504 6.157-6.504 1.93 0 3.388.791 4.328 2.078V8.927h2.67Zm-2.67 6.182c0-2.3-1.682-3.957-3.908-3.957s-3.908 1.656-3.908 3.957c0 2.3 1.682 3.957 3.908 3.957s3.908-1.657 3.908-3.957ZM75.096 8.705V11.6c-1.755-.124-3.807.692-3.807 3.461v6.231h-2.67V8.927h2.67v2.077c.74-1.68 2.25-2.299 3.807-2.299ZM88.966 3.982v17.31h-2.671v-1.78c-.94 1.31-2.4 2.1-4.327 2.1-3.363 0-6.157-2.843-6.157-6.502 0-3.685 2.794-6.504 6.157-6.504 1.929 0 3.388.791 4.327 2.078V3.982h2.67ZM86.295 15.11c0-2.3-1.682-3.958-3.908-3.958-2.225 0-3.907 1.657-3.907 3.958 0 2.3 1.682 3.957 3.907 3.957 2.226 0 3.908-1.657 3.908-3.957ZM105.236 15.107c0 3.685-2.794 6.504-6.157 6.504-1.93 0-3.388-.791-4.327-2.078v6.701h-2.67V8.927h2.67v1.78c.939-1.31 2.398-2.1 4.327-2.1 3.364-.002 6.157 2.842 6.157 6.502Zm-2.67 0c0-2.299-1.682-3.957-3.908-3.957-2.225 0-3.906 1.657-3.906 3.957 0 2.301 1.68 3.958 3.906 3.958 2.227 0 3.908-1.657 3.908-3.958ZM106.967 15.109c0-3.685 2.917-6.504 6.528-6.504 3.635 0 6.529 2.82 6.529 6.504 0 3.66-2.894 6.503-6.529 6.503-3.611.002-6.528-2.843-6.528-6.503Zm10.385 0c0-2.25-1.682-3.908-3.858-3.908s-3.858 1.657-3.858 3.908c0 2.25 1.682 3.908 3.858 3.908s3.858-1.657 3.858-3.908ZM131.248 17.805c0 2.473-2.151 3.808-4.822 3.808-2.473 0-4.254-1.112-5.044-2.893l2.299-1.336c.395 1.113 1.384 1.78 2.745 1.78 1.186 0 2.102-.395 2.102-1.385 0-2.2-6.652-.964-6.652-5.39 0-2.325 2.002-3.784 4.525-3.784 2.028 0 3.708.94 4.574 2.572l-2.25 1.261c-.445-.964-1.31-1.41-2.324-1.41-.965 0-1.805.422-1.805 1.311 0 2.252 6.652.891 6.652 5.466ZM136.934 11.497v5.935c0 1.583 1.038 1.557 3.066 1.458v2.398c-4.105.495-5.736-.642-5.736-3.858v-5.934h-2.275V8.924h2.275v-2.67l2.67-.791v3.461H140v2.572h-3.066v.001Z"
        fill="currentColor"
      />
      <path
        d="M34.71 0 20.122 25.27H0l7.545-13.072 5.03 8.713L24.648 0H34.71Z"
        fill="#00E1E1"
      />
      <path
        d="M24.649 0 12.576 20.912l-5.03-8.713L14.586 0h10.063Z"
        fill="#C800FF"
      />
      <path d="m14.586 0-7.04 12.198L.505 0h14.082Z" fill="#FF64FF" />
    </svg>
  );
};

export default Logo;
