import React, { PureComponent } from "react";
import { get } from "lodash";

import "./styles.scss";

export default class TabsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
    };
  }

  onTabChange(e) {
    this.setState({ activeTab: e });
    if (e === "lead") {
      this.props.history.push("/leads");
    } else if (e === "myListing") {
      this.props.history.push("/listings/active");
    } else if (e === "leadPages") {
      this.props.history.push("/social-links");
    }
    // else if (e === "socialLink") {
    //   this.props.history.push("/social-link-in-bio");
    // }
  }

  componentDidMount() {
    this.setState({ activeTab: this.props.attr });
  }

  render() {
    const { activeTab } = this.state;
    return (
      <>
        {/* <button
          className={
            activeTab === "socialLink" ? "tabs__link active" : "tabs__link"
          }
          onClick={this.onTabChange.bind(this, "socialLink")}
        >
          Social Link in Bio
        </button> */}
        <button
          className={
            activeTab === "leadPages" ? "tabs__link active" : "tabs__link"
          }
          onClick={this.onTabChange.bind(this, "leadPages")}
        >
          Social Links
        </button>
        <button
          className={activeTab === "lead" ? "tabs__link active" : "tabs__link"}
          onClick={this.onTabChange.bind(this, "lead")}
        >
          Leads
        </button>
        {/* <button
          className={
            activeTab === "myListing" ? "tabs__link active" : "tabs__link"
          }
          onClick={this.onTabChange.bind(this, "myListing")}
        >
          My Listings
        </button> */}
      </>
    );
  }
}
