import React, { PureComponent } from "react";
import { Close } from "../../../../components/icons";
import RadioComponent from "../../../../components/ui/Radio/component";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { get } from "lodash";
import "./styles.scss";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
export default class CreateComponent extends PureComponent {
  constructor() {
    super();
  }

  render() {
    const { listingData } = this.props;

    return (
      <>
        {/* <div className="form-group">
          <label className="label-primary">
            Campaign Type <span className="required">*</span>
          </label>
          <ul className="campaign__type--list">
            {this.props.campaignTypeArray.map((data, i) => {
              return (
                <li key={i}>
                  <RadioComponent
                    label={data.value}
                    value={data.key}
                    type="campaign-type"
                    checked={this.props.campaignType}
                    setter={this.props.changeCampaignType}
                    isTrue={
                      data.key == "social-link"
                        ? this.props.isCampaignType === "social-link"
                          ? true
                          : false
                        : false
                    }
                  />
                </li>
              );
            })}
          </ul>
        </div> */}
        {/* {this.props.campaignType === "listing-website" &&
          get(this.props.user, "user.isListing", "") && (
            <div className="form-group">
              <label className="label-primary">
                Select from your active listings{" "}
                <span className="required">*</span>
              </label>
              <select
                value={this.props.selectValue}
                onChange={this.props.handleChangeListing}
                className="form-control custom-select"
                required
              >
                <option value="0">Select</option>
                {listingData.map((listing, i) => {
                  if (listing.propertyStatus === "active") {
                    return (
                      <option key={i} value={listing.slugUrl}>
                        {listing.address}
                        {listing.units !== "" &&
                        listing.units !== undefined &&
                        listing.units !== null
                          ? ", " + listing.units
                          : ""}
                      </option>
                    );
                  }
                })}
              </select>
              {this.props.selectValueError !== "" && (
                <div className="invalid-feedback">
                  {this.props.selectValueError}
                </div>
              )}
            </div>
          )} */}
        {/* {this.props.campaignType === "listing-website" && (
          get(this.props.user, "user.isListing", "") && ( */}
          <div className="form-group">
            <label className="label-primary">
              Link Name <span className="required">*</span>
            </label>
            <input
              type="text"
              name="campaignName"
              className="form-control"
              onChange={(e) => this.props.handleChange(e)}
              placeholder="Harsh Linkedin Link in Bio"
              required
            />
            <p className="form-control-note">
              This is only visible to you, no one else will see this
            </p>
            {this.props.campaignNameError !== "" && (
              <div className="invalid-feedback">
                {this.props.campaignNameError}
              </div>
            )}
          </div>
        {/* )} */}
        {this.props.campaignType === "sell" && (
          <div className="form-group">
            <label className="label-primary">
              Campaign Name <span className="required">*</span>
            </label>
            <input
              type="text"
              name="campaignName"
              className="form-control"
              onChange={(e) => this.props.handleChange(e)}
              placeholder="People looking to sell their in XYZ city "
              required
            />
            <p className="form-control-note">
              This is only visible to you, no one else will see this
            </p>
            {this.props.campaignNameError !== "" && (
              <div className="invalid-feedback">
                {this.props.campaignNameError}
              </div>
            )}
          </div>
        )}
        {this.props.campaignType === "sell" && (
          <div className="">
            <button
              onClick={() => this.props.handleSubmitCampaign()}
              className="btn btn-lg btn__purple w-100"
            >
              {this.props.isLoading ? (
                <Spin indicator={antIcon} />
              ) : (
                "Start Editing this Landing Page"
              )}
            </button>
          </div>
        )}
        {this.props.campaignType === "listing-website" && (
          // get(this.props.user, "user.isListing", "") && (
          <div className="">
            <button
              onClick={() => this.props.handleSubmitCampaign()}
              className="btn btn-lg btn__purple w-100"
            >
              {this.props.isLoading ? (
                <Spin indicator={antIcon} />
              ) : (
                "Start Editing this Landing Page"
              )}
            </button>
          </div>
        )}
        {this.props.campaignType === "social-link" && (
          <div className="">
            <button
              disabled={this.props.isLoading}
              onClick={() => this.props.handleSubmitCampaign()}
              className="btn btn-lg btn__purple w-100"
            >
              {this.props.isLoading ? (
                <Spin indicator={antIcon} />
              ) : (
                "Start Editing this Link"
              )}
            </button>
          </div>
        )}
        <br></br>
        {/* <div className="col-md-6">  */}
        <div className="text-center">
          <div className="form-group">
            <label className="label-primary PrimayLabel">
              <input
                type="checkbox"
                className="custom__checkbox--only-input"
                checked={this.props.isQrCode}
                onChange={(e) => this.props.onCheckHandle(e)}
              />
              Create a QR Code for this property
            </label>
          </div>
        </div>
        {/* </div> */}
      </>
    );
  }
}
