import React, { PureComponent } from "react";
import { get } from "lodash";

import "./styles.scss";

export default class RadioButtonGroupComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    return (
      <>
        {this.props.attribute === "select-plan" && (
          <>
            <div className={`custom__radio__group ${this.props.className}`}>
              <input
                className="custom__radio__group--button"
                type="radio"
                value="month"
                checked={this.props.selectedPlan === "month"}
                onChange={this.props.onSelectedPlan(this, "month")}
                name="selectedPlan"
                id="Monthly"
              />
              <label className="custom__radio__group--label" htmlFor="Monthly">
                Monthly
              </label>
              <input
                className="custom__radio__group--button"
                type="radio"
                value="year"
                checked={this.props.selectedPlan === "year"}
                onChange={this.props.onSelectedPlan(this, "year")}
                name="selectedPlan"
                id="Annual"
              />
              <label className="custom__radio__group--label" htmlFor="Annual">
                Annual
              </label>
            </div>
          </>
        )}
      </>
    );
  }
}
