import React, { PureComponent } from "react";
import {
  EqualHO,
  Facebook,
  Instagram,
  Realtor,
  Twitter,
  Youtube,
  Whatsapp,
} from "../../../components/icons";
import { get } from "lodash";
import "./styles.scss";
import moment from "moment";

export default class FooterComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const { className, leadPageUserData, leadPageSetting } = this.props;
    return (
      <footer className={`footer ${className} `}>
        <div className="footer__left">
          <div className="footer__copyrights">
            © {moment().year()} {get(leadPageUserData, "companyName", "")}
          </div>
          <div className="footer__mls">
            <ul className="footer__mls--list">
              {get(leadPageSetting, "showHousingLogo", false) && (
                <li>
                  <EqualHO />
                </li>
              )}
              {get(leadPageSetting, "showMlsLogo", false) && (
                <li>
                  <Realtor />
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="footer__right">
          <div className="social__links--list">
            <ul className="social__links--list">
              {get(leadPageSetting, "youtubeUrl", "") && (
                <li>
                  <a href={get(leadPageSetting, "youtubeUrl", "")}>
                    <Youtube />
                  </a>
                </li>
              )}
              {get(leadPageSetting, "facebookUrl", "") && (
                <li>
                  <a href={get(leadPageSetting, "facebookUrl", "")}>
                    <Facebook />
                  </a>
                </li>
              )}
              {get(leadPageSetting, "instagramUrl", "") && (
                <li>
                  <a href={get(leadPageSetting, "instagramUrl", "")}>
                    <Instagram />
                  </a>
                </li>
              )}
              {get(leadPageSetting, "twitterUrl", "") && (
                <li>
                  <a href={get(leadPageSetting, "twitterUrl", "")}>
                    <Twitter />
                  </a>
                </li>
              )}
              {get(leadPageSetting, "whatsupUrl", "") && (
                <li>
                  <a href={get(leadPageSetting, "whatsupUrl", "")}>
                    <Whatsapp />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}
