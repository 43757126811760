// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from "immutable";
import { assign } from "lodash";
import Cookies from "universal-cookie";
import { INIT, LOADING, SUCCESS, ERROR } from "../../constants/phase";
import { get } from "lodash";

import { fromPromise } from "rxjs/observable/fromPromise";
import { of } from "rxjs";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";

import * as api from "./api";
/***********************************
 * Action Types
 ***********/
const cookies = new Cookies();

export const LOGIN_USER = "yardpost/user/LOGIN_USER";
export const LOGIN_USER_SUCCESS = "yardpost/user/LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "yardpost/user/LOGIN_USER_ERROR";

export const LOGIN_USER_TEMPLATE = "yardpost/user/LOGIN_USER_TEMPLATE";
export const LOGIN_USER_TEMPLATE_SUCCESS =
  "yardpost/user/LOGIN_USER_TEMPLATE_SUCCESS";
export const LOGIN_USER_TEMPLATE_ERROR =
  "yardpost/user/LOGIN_USER_TEMPLATE_ERROR";

export const VERIFY_OTP_USER = "yardpost/user/VERIFY_OTP_USER";
export const VERIFY_OTP_USER_SUCCESS = "yardpost/user/VERIFY_OTP_USER_SUCCESS";
export const VERIFY_OTP_USER_ERROR = "yardpost/user/VERIFY_OTP_USER_ERROR";

export const RESEND_OTP_USER = "yardpost/user/RESEND_OTP_USER";
export const RESEND_OTP_USER_SUCCESS = "yardpost/user/RESEND_OTP_USER_SUCCESS";
export const RESEND_OTP_USER_ERROR = "yardpost/user/RESEND_OTP_USER_ERROR";

export const SIGNUP_USER = "yardpost/user/SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "yardpost/user/SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_ERROR = "yardpost/user/SIGNUP_USER_ERROR";

export const PROPERTY_LOG = "yardpost/user/PROPERTY_LOG";
export const PROPERTY_LOG_SUCCESS = "yardpost/user/PROPERTY_LOG_SUCCESS";
export const PROPERTY_LOG_ERROR = "yardpost/user/PROPERTY_LOG_ERROR";
export const PROPERTY_LOG_RESET = "yardpost/user/PROPERTY_LOG_RESET";

export const RESET_LOGIN = "yardpost/user/RESET_LOGIN";

export const GET_USER = "yardpost/user/GET_USER";
export const GET_USER_SUCCESS = "yardpost/user/GET_USER_SUCCESS";
export const GET_USER_ERROR = "yardpost/user/GET_USER_ERROR";

export const EDIT_USER = "yardpost/user/EDIT_USER";
export const EDIT_USER_SUCCESS = "yardpost/user/EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "yardpost/user/EDIT_USER_ERROR";

export const ADD_MEMBER = "yardpost/user/ADD_MEMBER";
export const ADD_MEMBER_SUCCESS = "yardpost/user/ADD_MEMBER_SUCCESS";
export const ADD_MEMBER_ERROR = "yardpost/user/ADD_MEMBER_ERROR";

export const GET_MEMBER = "yardpost/user/GET_MEMBER";
export const GET_MEMBER_SUCCESS = "yardpost/user/GET_MEMBER_SUCCESS";
export const GET_MEMBER_ERROR = "yardpost/user/GET_MEMBER_ERROR";

export const GET_TEAM = "yardpost/user/GET_TEAM";
export const GET_TEAM_SUCCESS = "yardpost/user/GET_TEAM_SUCCESS";
export const GET_TEAM_ERROR = "yardpost/user/GET_TEAM_ERROR";

export const GET_ADMIN_AGENT = "yardpost/user/GET_ADMIN_AGENT";
export const GET_ADMIN_AGENT_SUCCESS = "yardpost/user/GET_ADMIN_AGENT_SUCCESS";
export const GET_ADMIN_AGENT_ERROR = "yardpost/user/GET_ADMIN_AGENT_ERROR";

export const GET_LEADS = "yardpost/user/GET_LEADS";
export const GET_LEADS_SUCCESS = "yardpost/user/GET_LEADS_SUCCESS";
export const GET_LEADS_ERROR = "yardpost/user/GET_LEADS_ERROR";

export const UPLOAD_LEADS = "yardpost/user/UPLOAD_LEADS";
export const UPLOAD_LEADS_SUCCESS = "yardpost/user/UPLOAD_LEADS_SUCCESS";
export const UPLOAD_LEADS_ERROR = "yardpost/user/UPLOAD_LEADS_ERROR";

export const GET_SEARCH_LEADS = "yardpost/user/GET_SEARCH_LEADS";
export const GET_SEARCH_LEADS_SUCCESS =
  "yardpost/user/GET_SEARCH_LEADS_SUCCESS";
export const GET_SEARCH_LEADS_ERROR = "yardpost/user/GET_SEARCH_LEADS_ERROR";

export const DELETE_MEMBER = "yardpost/user/DELETE_MEMBER";
export const DELETE_MEMBER_SUCCESS = "yardpost/user/DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_ERROR = "yardpost/user/DELETE_MEMBER_ERROR";

export const EDIT_MEMBER = "yardpost/user/EDIT_MEMBER";
export const EDIT_MEMBER_SUCCESS = "yardpost/user/EDIT_MEMBER_SUCCESS";
export const EDIT_MEMBER_ERROR = "yardpost/user/EDIT_MEMBER_ERROR";

export const LOGOUT_USER = "yardpost/user/LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "yardpost/user/LOGOUT_USER_SUCCESS";

export const GET_SUBSCRIPTIONS = "yardpost/user/GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_SUCCESS =
  "yardpost/user/GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_ERROR = "yardpost/user/GET_SUBSCRIPTIONS_ERROR";

export const CANCEL_SUBSCRIPTIONS = "yardpost/user/CANCEL_SUBSCRIPTIONS";
export const CANCEL_SUBSCRIPTIONS_SUCCESS =
  "yardpost/user/CANCEL_SUBSCRIPTIONS_SUCCESS";
export const CANCEL_SUBSCRIPTIONS_ERROR =
  "yardpost/user/CANCEL_SUBSCRIPTIONS_ERROR";

export const GET_PROMO = "yardpost/user/GET_PROMO";
export const GET_PROMO_SUCCESS = "yardpost/user/GET_PROMO_SUCCESS";
export const GET_PROMO_ERROR = "yardpost/user/GET_PROMO_ERROR";

export const ADD_CARD = "yardpost/user/ADD_CARD";
export const ADD_CARD_SUCCESS = "yardpost/user/ADD_CARD_SUCCESS";
export const ADD_CARD_ERROR = "yardpost/user/ADD_CARD_ERROR";

export const GET_PAYMENT_HISTORY = "yardpost/user/GET_PAYMENT_HISTORY";
export const GET_PAYMENT_HISTORY_SUCCESS =
  "yardpost/user/GET_PAYMENT_HISTORY_SUCCESS";
export const GET_PAYMENT_HISTORY_ERROR =
  "yardpost/user/GET_PAYMENT_HISTORY_ERROR";

export const UPDATE_CARD = "yardpost/user/UPDATE_CARD";
export const UPDATE_CARD_SUCCESS = "yardpost/user/UPDATE_CARD_SUCCESS";
export const UPDATE_CARD_ERROR = "yardpost/user/UPDATE_CARD_ERROR";

export const SEARCH_DASH = "yardpost/user/SEARCH_DASH_LISTING";
export const SEARCH_DASH_SUCCESS = "yardpost/user/SEARCH_DASH_SUCCESS";
export const SEARCH_DASH_ERROR = "yardpost/user/SEARCH_DASH_ERROR";

export const SEARCH_MY_LIST = "yardpost/user/SEARCH_MY_LIST";
export const SEARCH_MY_LIST_SUCCESS = "yardpost/user/SEARCH_MY_LIST_SUCCESS";
export const SEARCH_MY_LIST_ERROR = "yardpost/user/SEARCH_MY_LIST_ERROR";

export const GET_AGENT = "yardpost/user/GET_AGENT";
export const GET_AGENT_SUCCESS = "yardpost/user/GET_AGENT_SUCCESS";
export const GET_AGENT_ERROR = "yardpost/user/GET_AGENT_ERROR";

export const UPDATE_DOMAIN = "yardpost/user/UPDATE_DOMAIN";
export const UPDATE_DOMAIN_SUCCESS = "yardpost/user/UPDATE_DOMAIN_SUCCESS";
export const UPDATE_DOMAIN_ERROR = "yardpost/user/UPDATE_DOMAIN_ERROR";

export const POST_GOOGLE_ANALYTIC = "yardpost/user/POST_GOOGLE_ANALYTIC";
export const POST_GOOGLE_ANALYTIC_SUCCESS =
  "yardpost/user/POST_GOOGLE_ANALYTIC_SUCCESS";
export const POST_GOOGLE_ANALYTIC_ERROR =
  "yardpost/user/POST_GOOGLE_ANALYTIC_ERROR";

export const GET_GOOGLE_ANALYTIC = "yardpost/user/GET_GOOGLE_ANALYTIC";
export const GET_GOOGLE_ANALYTIC_SUCCESS =
  "yardpost/user/GET_GOOGLE_ANALYTIC_SUCCESS";
export const GET_GOOGLE_ANALYTIC_ERROR =
  "yardpost/user/GET_GOOGLE_ANALYTIC_ERROR";

export const GET_GOOGLE_CONTACT = "yardpost/user/GET_GOOGLE_CONTACT";
export const GET_GOOGLE_CONTACT_SUCCESS =
  "yardpost/user/GET_GOOGLE_CONTACT_SUCCESS";
export const GET_GOOGLE_CONTACT_ERROR =
  "yardpost/user/GET_GOOGLE_CONTACT_ERROR";

export const UPDATE_SUBSCRIPTION_PLAN =
  "yardpost/user/UPDATE_SUBSCRIPTION_PLAN";
export const UPDATE_SUBSCRIPTION_PLAN_SUCCESS =
  "yardpost/user/UPDATE_SUBSCRIPTION_PLAN_SUCCESS";
export const UPDATE_SUBSCRIPTION_PLAN_ERROR =
  "yardpost/user/UPDATE_SUBSCRIPTION_PLAN_ERROR";

export const ADD_SUBSCRIPTION = "yardpost/user/ADD_SUBSCRIPTION";
export const ADD_SUBSCRIPTION_SUCCESS =
  "yardpost/user/ADD_SUBSCRIPTION_SUCCESS";
export const ADD_SUBSCRIPTION_ERROR = "yardpost/user/ADD_SUBSCRIPTION_ERROR";

export const REACTIVE_SUBSCRIPTION = "yardpost/user/REACTIVE_SUBSCRIPTION";
export const REACTIVE_SUBSCRIPTION_SUCCESS =
  "yardpost/user/REACTIVE_SUBSCRIPTION_SUCCESS";
export const REACTIVE_SUBSCRIPTION_ERROR =
  "yardpost/user/REACTIVE_SUBSCRIPTION_ERROR";

export const UPDATE_TEAM_VISIBLE_STATUS =
  "yardpost/user/UPDATE_TEAM_VISIBLE_STATUS";
export const UPDATE_TEAM_VISIBLE_STATUS_SUCCESS =
  "yardpost/user/UPDATE_TEAM_VISIBLE_STATUS_SUCCESS";
export const UPDATE_TEAM_VISIBLE_STATUS_ERROR =
  "yardpost/user/UPDATE_TEAM_VISIBLE_STATUS_ERROR";

export const UPDATE_USER_STATUS = "yardpost/user/UPDATE_USER_STATUS";
export const UPDATE_USER_STATUS_SUCCESS =
  "yardpost/user/UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_ERROR =
  "yardpost/user/UPDATE_USER_STATUS_ERROR";

export const INIT_PROMO_PHASE = "yardpost/user/INIT_PROMO_PHASE";
export const INIT_CARD_PHASE = "yardpost/user/INIT_CARD_PHASE";
export const RESET_MEMBER = "yardpost/user/RESET_MEMBER";
export const RESET_SEARCH = "yardpost/user/RESET_SEARCH";
export const RESET_ADMIN = "yardpost/user/RESET_ADMIN";
export const RESET_USER = "yardpost/user/RESET_USER";
export const SUBCRIPTION_RESET = "yardpost/user/SUBCRIPTION_RESET";
export const RESET_IMPERSONATED_USER = "yardpost/user/RESET_IMPERSONATED_USER";

export const GET_LEAD_PAGE_BY_ID = "yardpost/leadPage/GET_LEAD_PAGE_BY_ID";
export const GET_LEAD_PAGE_BY_ID_SUCCESS =
  "yardpost/leadPage/GET_LEAD_PAGE_BY_ID_SUCCESS";
export const GET_LEAD_PAGE_BY_ID_ERROR =
  "yardpost/leadPage/GET_LEAD_PAGE_BY_ID_ERROR";

export const CREATE_LEAD_PAGE = "yardpost/leadPage/CREATE_LEAD_PAGE";
export const CREATE_LEAD_PAGE_SUCCESS =
  "yardpost/leadPage/CREATE_LEAD_PAGE_SUCCESS";
export const CREATE_LEAD_PAGE_ERROR =
  "yardpost/leadPage/CREATE_LEAD_PAGE_ERROR";

export const ADD_LEAD_PAGE_SETTING = "yardpost/user/ADD_LEAD_PAGE_SETTING";
export const ADD_LEAD_PAGE_SETTING_SUCCESS =
  "yardpost/user/ADD_LEAD_PAGE_SETTING_SUCCESS";
export const ADD_LEAD_PAGE_SETTING_ERROR =
  "yardpost/user/ADD_LEAD_PAGE_SETTING_ERROR";

export const GET_LEAD_PAGE_SETTING_ID =
  "yardpost/user/GET_LEAD_PAGE_SETTING_ID";
export const GET_LEAD_PAGE_SETTING_ID_SUCCESS =
  "yardpost/user/GET_LEAD_PAGE_SETTING_ID_SUCCESS";
export const GET_LEAD_PAGE_SETTING_ID_ERROR =
  "yardpost/user/GET_LEAD_PAGE_SETTING_ID_ERROR";
export const RESET_LEAD_PAGE_SETTING = "yardpost/user/RESET_LEAD_PAGE_SETTING";

export const INIT_LEAD_PAGE_PHASE = "yardpost/leadPage/INIT_LEAD_PAGE_PHASE";
export const UPLOAD_IMAGE = "yardpost/user/UPLOAD_IMAGE";

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  updateDomainPhase: INIT,
  updateDomainData: {},
  updateDomainMessage: "",
  userPhase: INIT,
  userPhase1: INIT,
  user: {},
  error: null,
  message: null,
  otpMessage: "",
  resendMessage: "",
  isSubmitting: false,
  signupPhase: INIT,
  otpPhase: INIT,
  addCardPhase: INIT,
  addCardMessage: "",
  resendPhase: INIT,
  signupUser: {},
  subscriptionPhase: INIT,
  subscriptions: [],
  cancelSubscriptionPhase: INIT,
  cancelSubscriptionsMessage: "",
  loginMessage: "",
  editUserPhase: INIT,
  editUserData: {},
  agentData: {},
  agentPhase: INIT,
  adminagentPhase: INIT,
  adminAgentData: [],
  updateUserStatusPhase: INIT,
  updateCardPhase: INIT,
  updateCardData: {},
  getPaymentHistoryPhase: INIT,
  getPaymentHistoryData: {},
  updateSubscriptionPlanPhase: INIT,
  updateSubscriptionPlanData: {},
  leadPageByIdPhase: INIT,
  leadPageByIdData: {},
  leadPageByIdMessage: "",
  createLeadPagePhase: INIT,
  createLeadPageMessage: "",
  createLeadPageData: {},
  addLeadPageSettingPhase: INIT,
  addLeadPageSettingMessage: "",
  getLeadPageSettingIdPhase: INIT,
  getLeadPageSettingIdMessage: "",
  getLeadPageSettingIdData: {},
  promoPhase: INIT,
  promoMessage: "",
  promoData: {},
  leadPageByIdproperty: {},
  reactiveSubscriptionPhase: INIT,
  reactiveSubscriptionMessage: "",
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case LOGIN_USER: {
      return state
        .set("phase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case LOGIN_USER_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state.set("phase", SUCCESS).set("message", payload.message);
      } else {
        return state.set("phase", ERROR).set("message", payload.message);
      }
      break;
    }

    case LOGIN_USER_ERROR: {
      const { payload } = action;
      return state
        .set("phase", ERROR)
        .set("error", payload.message)
        .set("isSubmitting", true);
    }

    case UPDATE_CARD: {
      const { payload } = action;
      return state.set("updateCardPhase", INIT).set("isSubmitting", true);
    }

    case UPDATE_CARD_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateCardPhase", SUCCESS)
          .set("updateCardData", payload.data)
          .set("error", null)
          .set("isSubmitting", true);
      }
    }

    case UPDATE_CARD_ERROR: {
      const { payload } = action;
      return state
        .set("updateCardPhase", ERROR)
        .set("updateCardData", {})
        .set("error", payload.message)
        .set("isSubmitting", true);
    }

    case ADD_CARD: {
      return state
        .set("addCardPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case ADD_CARD_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("addCardPhase", SUCCESS)
          .set("addCardMessage", payload.message);
      }
    }

    case ADD_CARD_ERROR: {
      const { payload } = action;
      return state.set("addCardPhase", ERROR).set("error", payload.message);
    }

    case LOGIN_USER_TEMPLATE: {
      return state
        .set("phase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case LOGIN_USER_TEMPLATE_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state.set("phase", SUCCESS).set("loginMessage", payload.message);
      } else {
        return state.set("phase", ERROR).set("loginMessage", payload.message);
      }
      break;
    }

    case UPDATE_DOMAIN: {
      const { payload } = action;
      return state.set("updateDomainPhase", INIT).set("isSubmitting", true);
    }

    case UPDATE_DOMAIN_SUCCESS: {
      const { payload } = action;
      return state
        .set("updateDomainPhase", payload.success)
        .set("updateDomainMessage", payload.message)
        .set("updateDomainData", payload.data)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case UPDATE_DOMAIN_ERROR: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateDomainPhase", ERROR)
          .set("updateDomainData", {})
          .set("error", payload.message)
          .set("isSubmitting", true);
      }
    }

    case UPDATE_USER_STATUS: {
      return state.set("updateUserStatusPhase", LOADING).set("error", null);
    }

    case UPDATE_USER_STATUS_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state.set("updateUserStatusPhase", SUCCESS);
      }
    }

    case UPDATE_USER_STATUS_ERROR: {
      const { payload } = action;
      return state.set("updateUserStatusPhase", ERROR);
    }

    case GET_SUBSCRIPTIONS: {
      return state
        .set("subscriptionPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_SUBSCRIPTIONS_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("subscriptionPhase", SUCCESS)
          .set("subscriptions", payload.data)
          .set("message", payload.message);
      }
    }

    case GET_SUBSCRIPTIONS_ERROR: {
      const { payload } = action;
      return state
        .set("subscriptionPhase", ERROR)
        .set("error", payload.message);
    }

    case LOGIN_USER_TEMPLATE_ERROR: {
      const { payload } = action;
      return state
        .set("phase", ERROR)
        .set("error", payload.message)
        .set("isSubmitting", true);
    }

    case VERIFY_OTP_USER: {
      return state
        .set("otpPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case VERIFY_OTP_USER_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        const role = get(payload, "data.user.role", "");
        if (role === "User") {
          localStorage.setItem("UserAuthorization", payload.data.token);
          cookies.set("UserAuthorization", payload.data.token, {
            path: "/",
            domain: "yardpost.com",
          });
        } else if (role === "Superadmin") {
          localStorage.setItem("AdminAuthorization", payload.data.token);
          localStorage.setItem("AdminEmail", payload.data.user.email);
          cookies.set("AdminAuthorization", payload.data.token, {
            path: "/",
            domain: "yardpost.com",
          });
        } else {
          localStorage.setItem("Authorization", payload.data.token);
          cookies.set("Authorization", payload.data.token, {
            path: "/",
            domain: "yardpost.com",
          });
        }
        return state
          .set("otpPhase", SUCCESS)
          .set("otpMessage", payload.message);
      } else {
        return state.set("otpPhase", ERROR).set("otpMessage", payload.message);
      }
      break;
    }

    case EDIT_USER: {
      return state
        .set("editUserPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case EDIT_USER_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("editUserPhase", SUCCESS)
          .set("editUserData", payload.data)
          .set("message", payload.message)
          .set("isSubmitting", true);
      } else {
        // console.log(ERROR,'ss')
        return state
          .set("editUserPhase", ERROR)
          .set("editUserData", payload.data);
      }
    }

    case EDIT_USER_ERROR: {
      const { payload } = action;
      return state
        .set("editUserPhase", ERROR)
        .set("editUserData", {})
        .set("message", payload.message)
        .set("isSubmitting", true);
    }

    case VERIFY_OTP_USER_ERROR: {
      const { payload } = action;
      return state
        .set("otpPhase", ERROR)
        .set("otpMessage", payload.message)
        .set("isSubmitting", true);
    }

    case RESEND_OTP_USER: {
      return state
        .set("otpPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_USER: {
      return state
        .set("userPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_PAYMENT_HISTORY: {
      return state
        .set("getPaymentHistoryPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_PAYMENT_HISTORY_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("getPaymentHistoryPhase", SUCCESS)
          .set("getPaymentHistoryData", payload.data)
          .set("error", payload.message)
          .set("isSubmitting", true);
      }
    }

    case GET_PAYMENT_HISTORY_ERROR: {
      const { payload } = action;
      return state
        .set("getPaymentHistoryPhase", ERROR)
        .set("getPaymentHistoryData", {})
        .set("error", payload.message)
        .set("isSubmitting", true);
    }

    case REACTIVE_SUBSCRIPTION: {
      return state
        .set("reactiveSubscriptionPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case REACTIVE_SUBSCRIPTION_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("reactiveSubscriptionPhase", SUCCESS)
          .set("reactiveSubscriptionMessage", payload.message);
      }
    }

    case REACTIVE_SUBSCRIPTION_ERROR: {
      const { payload } = action;
      return state
        .set("reactiveSubscriptionPhase", SUCCESS)
        .set("reactiveSubscriptionMessage", payload.message);
    }

    case GET_USER_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("userPhase", SUCCESS)
          .set("userPhase1", SUCCESS)
          .set("user", payload.data)
          .set("error", payload.message)
          .set("isSubmitting", true);
      }
    }

    case GET_USER_ERROR: {
      const { payload } = action;
      return state
        .set("userPhase", ERROR)
        .set("user", {})
        .set("error", payload.message)
        .set("isSubmitting", true);
    }
    case RESEND_OTP_USER_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("resendPhase", SUCCESS)
          .set("resendMessage", payload.message);
      } else {
        return state
          .set("resendPhase", ERROR)
          .set("resendMessage", payload.message);
      }
      break;
    }

    case RESEND_OTP_USER_ERROR: {
      const { payload } = action;
      return state
        .set("resendPhase", ERROR)
        .set("resendMessage", payload.message)
        .set("isSubmitting", true);
    }

    case SIGNUP_USER: {
      return state
        .set("signupPhase", LOADING)
        .set("otpMessage", null)
        .set("isSubmitting", true);
    }

    case SIGNUP_USER_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        const role = get(payload, "data.user.role", "");
        if (role == "User") {
          localStorage.setItem("UserAuthorization", payload.data.token);
        } else if (role === "Admin") {
          localStorage.setItem("Authorization", payload.data.token);
        }
        return state
          .set("signupPhase", SUCCESS)
          .set("user", payload.data)
          .set("message", payload.message);
      } else {
        return state
          .set("signupPhase", ERROR)
          .set("user", payload.data)
          .set("message", payload.message);
      }
    }

    case SIGNUP_USER_ERROR: {
      const { payload } = action;
      return state.set("signupPhase", ERROR).set("message", payload.message);
    }

    case LOGOUT_USER: {
      cookies.remove("Authorization", { path: "/", domain: "yardpost.com" });
      localStorage.removeItem("Authorization"); //remove only user
      return state
        .set("phase", INIT)
        .set("user", {})
        .set("error", null)
        .set("isSubmitting", true);
    }

    case CANCEL_SUBSCRIPTIONS: {
      return state
        .set("cancelSubscriptionPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case CANCEL_SUBSCRIPTIONS_SUCCESS: {
      const { payload } = action;
      return state
        .set("cancelSubscriptionPhase", SUCCESS)
        .set("cancelSubscriptionsMessage", payload.message);
    }

    case CANCEL_SUBSCRIPTIONS_ERROR: {
      const { payload } = action;
      return state
        .set("cancelSubscriptionPhase", ERROR)
        .set("cancelSubscriptionsMessage", payload.message);
    }

    case GET_AGENT: {
      return state
        .set("agentPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_AGENT_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("agentPhase", SUCCESS)
          .set("agentData", payload.data)
          .set("error", payload.message)
          .set("isSubmitting", true);
      }
    }

    case GET_AGENT_ERROR: {
      const { payload } = action;
      return state
        .set("agentPhase", ERROR)
        .set("agentData", {})
        .set("error", payload.message)
        .set("isSubmitting", true);
    }

    case GET_ADMIN_AGENT: {
      return state
        .set("adminagentPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_ADMIN_AGENT_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("adminagentPhase", SUCCESS)
          .set("adminAgentData", payload.data)
          .set("error", payload.message)
          .set("isSubmitting", true);
      }
    }

    // case GET_LEAD_PAGE_BY_ID: {
    //   return state
    //     .set("leadPageByIdPhase", LOADING)
    //     .set("error", null)
    //     .set("isSubmitting", true);
    // }

    // case GET_LEAD_PAGE_BY_ID_SUCCESS: {
    //   const { payload } = action;
    //   if (payload.success) {
    //     return state
    //       .set("leadPageByIdPhase", SUCCESS)
    //       .set("leadPageByIdData", payload.data)
    //       .set("leadPageByIdproperty", payload.property)
    //       .set("leadPageByIdMessage", payload.message);
    //   } else {
    //     return state
    //       .set("leadPageByIdPhase", ERROR)
    //       .set("leadPageByIdMessage", payload.message);
    //   }
    //   break;
    // }

    // case GET_LEAD_PAGE_BY_ID_ERROR: {
    //   const { payload } = action;
    //   return state
    //     .set("leadPageByIdPhase", ERROR)
    //     .set("leadPageByIdMessage", payload.message)
    //     .set("isSubmitting", true);
    // }

    case UPDATE_SUBSCRIPTION_PLAN: {
      const { payload } = action;
      return state.set("updateCardPhase", INIT).set("isSubmitting", true);
    }

    case UPDATE_SUBSCRIPTION_PLAN_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateSubscriptionPlanPhase", SUCCESS)
          .set("updateSubscriptionPlanData", payload.data)
          .set("error", null)
          .set("isSubmitting", true);
      }
    }

    case UPDATE_SUBSCRIPTION_PLAN_ERROR: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateSubscriptionPlanPhase", ERROR)
          .set("updateSubscriptionPlanData", {})
          .set("error", payload.message)
          .set("isSubmitting", true);
      }
    }

    case RESET_LOGIN: {
      return state
        .set("phase", INIT)
        .set("message", "")
        .set("otpPhase", INIT)
        .set("otpMessage", "")
        .set("resendPhase", INIT)
        .set("resendMessage", "")
        .set("signupPhase", INIT)
        .set("addCardPhase", INIT)
        .set("editUserPhase", INIT)
        .set("updateUserStatusPhase", INIT)
        .set("loginMessage", "");
    }

    case RESET_ADMIN: {
      return state.set("adminagentPhase", false).set("adminAgentData", {});
    }

    case RESET_USER: {
      return state
        .set("updateCardPhase", INIT)
        .set("userPhase", INIT)
        .set("getPaymentHistoryPhase", INIT)
        .set("cancelSubscriptionPhase", INIT)
        .set("updateSubscriptionPlanPhase", INIT)
        .set("addCardPhase", INIT)
        .set("subscriptionPhase", INIT)
        .set("addSubscriptionPhase", INIT)
        .set("reactiveSubscriptionPhase", INIT);
    }

    case RESET_MEMBER: {
      return state
        .set("getMemberPhase", false)
        .set("addMemberPhase", LOADING)
        .set("userPhase", INIT)
        .set("editUserPhase", LOADING)
        .set("editUserData", {})
        .set("deleteMemberPhase", LOADING)
        .set("editMemberPhase", LOADING)
        .set("getPaymentHistoryPhase", INIT)
        .set("updateCardPhase", INIT)
        .set("updateDomainPhase", INIT)
        .set("updateTeamStatusPhase", INIT)
        .set("subscriptionPhase", INIT);
    }

    case RESET_LEAD_PAGE_SETTING: {
      return state
        .set("addLeadPageSettingPhase", INIT)
        .set("getLeadPageSettingIdPhase", INIT)
        .set("getLeadPageSettingIdData", {});
    }

    case CREATE_LEAD_PAGE: {
      return state.set("createLeadPagePhase", LOADING);
    }

    case CREATE_LEAD_PAGE_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("createLeadPagePhase", SUCCESS)
          .set("createLeadPageData", payload.data)
          .set("createLeadPageMessage", payload.message);
      } else {
        return state
          .set("createLeadPagePhase", ERROR)
          .set("createLeadPageData", {})
          .set("createLeadPageMessage", payload.message);
      }
      break;
    }

    case CREATE_LEAD_PAGE_ERROR: {
      const { payload } = action;
      return state
        .set("createLeadPagePhase", ERROR)
        .set("createLeadPageMessage", payload.message);
    }

    case INIT_LEAD_PAGE_PHASE: {
      return state
        .set("leadPageByIdMessage", "")
        .set("leadPageByIdPhase", INIT)
        .set("createLeadPagePhase", INIT);
    }

    case RESET_IMPERSONATED_USER: {
      return state
        .set("phase", INIT)
        .set("user", {})
        .set("error", null)
        .set("isSubmitting", true);
    }

    case ADD_LEAD_PAGE_SETTING: {
      return state
        .set("addLeadPageSettingPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case ADD_LEAD_PAGE_SETTING_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("addLeadPageSettingPhase", SUCCESS)
          .set("addLeadPageSettingMessage", payload.message);
      }
    }

    case ADD_LEAD_PAGE_SETTING_ERROR: {
      const { payload } = action;
      return state
        .set("addLeadPageSettingPhase", ERROR)
        .set("error", payload.message);
    }

    case GET_LEAD_PAGE_SETTING_ID: {
      return state
        .set("getLeadPageSettingIdPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_LEAD_PAGE_SETTING_ID_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("getLeadPageSettingIdPhase", SUCCESS)
          .set("getLeadPageSettingIdData", payload.data)
          .set("getLeadPageSettingIdMessage", payload.message);
      }
    }

    case GET_LEAD_PAGE_SETTING_ID_ERROR: {
      const { payload } = action;
      return state
        .set("getLeadPageSettingIdPhase", ERROR)
        .set("getLeadPageSettingIdData", {})
        .set("error", payload.message);
    }

    case GET_PROMO: {
      return state
        .set("promoPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_PROMO_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return (
          state
            // .set('promoData', payload)
            .set("promoPhase", SUCCESS)
            .set("promoMessage", payload.message)
            .set("promoData", payload)
        );
      } else {
        return state
          .set("promoPhase", ERROR)
          .set("error", payload.message)
          .set("promoData", payload);
      }
    }

    case GET_PROMO_ERROR: {
      const { payload } = action;
      return state.set("promoPhase", ERROR).set("error", payload.message);
    }

    case INIT_PROMO_PHASE: {
      return state.set("promoPhase", INIT).set("promoData", {});
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

// User Auth
export const loginUser = (credentials) => {
  return {
    type: LOGIN_USER,
    payload: credentials,
  };
};

export const resetLoginUser = (credentials) => {
  return {
    type: RESET_USER,
    payload: credentials,
  };
};

// export const getLeadPageById = (credentials) => {
//   return {
//     type: GET_LEAD_PAGE_BY_ID,
//     payload: credentials,
//   };
// };

export const updateDomain = (credentials) => {
  return {
    type: UPDATE_DOMAIN,
    payload: credentials,
  };
};

export const resetMember = (credentials) => {
  return {
    type: RESET_MEMBER,
  };
};

export const loginUserTemplate = (credentials) => {
  return {
    type: LOGIN_USER_TEMPLATE,
    payload: credentials,
  };
};

export const verifyOtp = (credentials) => {
  return {
    type: VERIFY_OTP_USER,
    payload: credentials,
  };
};

export const getPaymentHistory = (credentials) => {
  return {
    type: GET_PAYMENT_HISTORY,
    payload: credentials,
  };
};

export const getSubscriptions = (credentials) => {
  return {
    type: GET_SUBSCRIPTIONS,
    payload: credentials,
  };
};

export const resendOtp = (credentials) => {
  return {
    type: RESEND_OTP_USER,
    payload: credentials,
  };
};

export const resetLogin = (credentials) => {
  return {
    type: RESET_LOGIN,
  };
};

export const updateCard = (credentials) => {
  return {
    type: UPDATE_CARD,
    payload: credentials,
  };
};

export const uploadImages = (credentials) => {
  return {
    type: UPLOAD_IMAGE,
    payload: api.uploadImages(credentials),
  };
};

export const updateUserStatus = (credentials) => {
  return {
    type: UPDATE_USER_STATUS,
    payload: credentials,
  };
};

export const reactiveSubscription = (credentials) => {
  return {
    type: REACTIVE_SUBSCRIPTION,
    payload: credentials,
  };
};

export const signupUser = (credentials) => {
  return {
    type: SIGNUP_USER,
    payload: credentials,
  };
};

export const getUser = (credentials) => {
  return {
    type: GET_USER,
    payload: credentials,
  };
};

export const handleSignOut = () => ({
  type: LOGOUT_USER,
});

export const cancelSubscriptions = (credentials) => {
  return {
    type: CANCEL_SUBSCRIPTIONS,
    payload: credentials,
  };
};

export const editUser = (credentials) => {
  return {
    type: EDIT_USER,
    payload: credentials,
  };
};

export const addCard = (credentials) => {
  return {
    type: ADD_CARD,
    payload: credentials,
  };
};

export const resetAdmin = (credentials) => {
  return {
    type: RESET_ADMIN,
  };
};

// export const createLeadPage = credentials => {
//   return {
//     type: CREATE_LEAD_PAGE,
//     payload: credentials
//   }
// }

export const initLeadPage = (credentials) => {
  return {
    type: INIT_LEAD_PAGE_PHASE,
  };
};

// Agent
export const getAgent = (credentials) => {
  return {
    type: GET_AGENT,
    payload: credentials,
  };
};

export const getAdminAgent = (credentials) => {
  return {
    type: GET_ADMIN_AGENT,
    payload: credentials,
  };
};

export const updateSubscriptionPlan = (credentials) => {
  return {
    type: UPDATE_SUBSCRIPTION_PLAN,
    payload: credentials,
  };
};

export const resetImpersonatedUser = (credentials) => {
  return {
    type: RESET_IMPERSONATED_USER,
  };
};

export const addLeadPageSetting = (credentials) => {
  return {
    type: ADD_LEAD_PAGE_SETTING,
    payload: credentials,
  };
};

export const getLeadPageSettingId = (credentials) => {
  return {
    type: GET_LEAD_PAGE_SETTING_ID,
    payload: credentials,
  };
};

export const resetLeadPageSetting = (credentials) => {
  return {
    type: RESET_LEAD_PAGE_SETTING,
  };
};

export const applyPromoCode = (credentials) => {
  return {
    type: GET_PROMO,
    payload: credentials,
  };
};

export const initPromoPhase = () => ({
  type: INIT_PROMO_PHASE,
});

/***********************************
 * Epics
 ***********************************/

const reactiveSubscriptionEpic = (action$) =>
  action$.pipe(
    ofType(REACTIVE_SUBSCRIPTION),
    mergeMap((action) => {
      return fromPromise(api.reactiveSubscription(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: REACTIVE_SUBSCRIPTION_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: REACTIVE_SUBSCRIPTION_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const updateSubscriptionPlanEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_SUBSCRIPTION_PLAN),
    mergeMap((action) => {
      return fromPromise(api.updateSubscriptionPlan(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_SUBSCRIPTION_PLAN_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const updateUserStatusEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_USER_STATUS),
    mergeMap((action) => {
      return fromPromise(api.updateUserStatus(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_USER_STATUS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_USER_STATUS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const loginUserEpic = (action$) =>
  action$.pipe(
    ofType(LOGIN_USER),
    mergeMap((action) => {
      return fromPromise(api.loginUser(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: LOGIN_USER_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: LOGIN_USER_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const loginUserTemplateEpic = (action$) =>
  action$.pipe(
    ofType(LOGIN_USER_TEMPLATE),
    mergeMap((action) => {
      return fromPromise(api.loginUserTemplate(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: LOGIN_USER_TEMPLATE_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: LOGIN_USER_TEMPLATE_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const verifyOtpEpic = (action$) =>
  action$.pipe(
    ofType(VERIFY_OTP_USER),
    mergeMap((action) => {
      return fromPromise(api.verifyOtp(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: VERIFY_OTP_USER_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: VERIFY_OTP_USER_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const resendOtpEpic = (action$) =>
  action$.pipe(
    ofType(RESEND_OTP_USER),
    mergeMap((action) => {
      return fromPromise(api.resendOtp(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: RESEND_OTP_USER_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: RESEND_OTP_USER_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const editUserEpic = (action$) =>
  action$.pipe(
    ofType(EDIT_USER),
    mergeMap((action) => {
      return fromPromise(api.editUser(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: EDIT_USER_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: EDIT_USER_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const signupUserEpic = (action$) =>
  action$.pipe(
    ofType(SIGNUP_USER),
    mergeMap((action) => {
      return fromPromise(api.signupUser(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: SIGNUP_USER_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: SIGNUP_USER_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getUserEpic = (action$) =>
  action$.pipe(
    ofType(GET_USER),
    mergeMap((action) => {
      return fromPromise(api.getUser(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_USER_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_USER_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const cancelSubscriptionsEpic = (action$) =>
  action$.pipe(
    ofType(CANCEL_SUBSCRIPTIONS),
    mergeMap((action) => {
      return fromPromise(api.cancelSub(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: CANCEL_SUBSCRIPTIONS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: CANCEL_SUBSCRIPTIONS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getPaymentHistoryEpic = (action$) =>
  action$.pipe(
    ofType(GET_PAYMENT_HISTORY),
    mergeMap((action) => {
      return fromPromise(api.getPaymentHistory(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_PAYMENT_HISTORY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_PAYMENT_HISTORY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getSubscriptionsEpic = (action$) =>
  action$.pipe(
    ofType(GET_SUBSCRIPTIONS),
    mergeMap((action) => {
      return fromPromise(api.getSubscriptions(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_SUBSCRIPTIONS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_SUBSCRIPTIONS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getAgentEpic = (action$) =>
  action$.pipe(
    ofType(GET_AGENT),
    mergeMap((action) => {
      return fromPromise(api.getAgent(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_AGENT_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_AGENT_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const addCardEpic = (action$) =>
  action$.pipe(
    ofType(ADD_CARD),
    mergeMap((action) => {
      return fromPromise(api.addCard(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: ADD_CARD_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: ADD_CARD_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getAdminAgentEpic = (action$) =>
  action$.pipe(
    ofType(GET_ADMIN_AGENT),
    mergeMap((action) => {
      return fromPromise(api.getAdminAgent(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_ADMIN_AGENT_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_ADMIN_AGENT_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const updateDomainEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_DOMAIN),
    mergeMap((action) => {
      return fromPromise(api.updateDomain(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_DOMAIN_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_DOMAIN_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const updateCardEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_CARD),
    mergeMap((action) => {
      return fromPromise(api.updateCard(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_CARD_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_CARD_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

// const createLeadPageEpic = action$ =>
// action$.pipe(
//   ofType(CREATE_LEAD_PAGE),
//   mergeMap(action => {
//     return fromPromise(api.createLeadPage(action.payload)).pipe(
//       flatMap(payload => [{
//         type: CREATE_LEAD_PAGE_SUCCESS,
//         payload
//       }
//     ]),
//     catchError(error =>
//         of({
//           type: CREATE_LEAD_PAGE_ERROR,
//           payload: { error }
//         })
//       )
//     )
//   })
// )

// const getLeadPageByIdEpic = (action$) =>
//   action$.pipe(
//     ofType(GET_LEAD_PAGE_BY_ID),
//     mergeMap((action) => {
//       return fromPromise(api.getLeadPageById(action.payload)).pipe(
//         flatMap((payload) => [
//           {
//             type: GET_LEAD_PAGE_BY_ID_SUCCESS,
//             payload,
//           },
//         ]),
//         catchError((error) =>
//           of({
//             type: GET_LEAD_PAGE_BY_ID_ERROR,
//             payload: { error },
//           })
//         )
//       );
//     })
//   );

const addLeadPageSettingEpic = (action$) =>
  action$.pipe(
    ofType(ADD_LEAD_PAGE_SETTING),
    mergeMap((action) => {
      return fromPromise(api.addLeadPageSetting(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: ADD_LEAD_PAGE_SETTING_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: ADD_LEAD_PAGE_SETTING_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getLeadPageSettingIdEpic = (action$) =>
  action$.pipe(
    ofType(GET_LEAD_PAGE_SETTING_ID),
    mergeMap((action) => {
      return fromPromise(api.getLeadPageSettingId(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_LEAD_PAGE_SETTING_ID_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_LEAD_PAGE_SETTING_ID_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const applyPromoCodeEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROMO),
    mergeMap((action) => {
      return fromPromise(api.applyCoupon(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_PROMO_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_PROMO_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

export const userEpic = combineEpics(
  loginUserEpic,
  verifyOtpEpic,
  signupUserEpic,
  getUserEpic,
  loginUserTemplateEpic,
  getAgentEpic,
  getAdminAgentEpic,
  getSubscriptionsEpic,
  resendOtpEpic,
  cancelSubscriptionsEpic,
  updateUserStatusEpic,
  editUserEpic,
  addCardEpic,
  updateUserStatusEpic,
  updateDomainEpic,
  updateCardEpic,
  updateSubscriptionPlanEpic,
  getPaymentHistoryEpic,
  reactiveSubscriptionEpic,
  //getLeadPageByIdEpic,
  addLeadPageSettingEpic,
  getLeadPageSettingIdEpic,
  applyPromoCodeEpic
);
