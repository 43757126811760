import * as React from "react";

const Twitter = ({ ...props }) => {
  return (
    // <svg
    //   width={17}
    //   height={14}
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <path
    //     d="M17 2.096c-.62.31-1.238.413-1.96.516.722-.413 1.238-1.032 1.444-1.858-.62.413-1.342.62-2.167.826A3.604 3.604 0 0 0 11.84.548c-1.754 0-3.302 1.548-3.302 3.405 0 .31 0 .516.103.723-2.787-.103-5.367-1.445-7.018-3.509-.31.516-.413 1.032-.413 1.754 0 1.136.62 2.168 1.548 2.787-.516 0-1.032-.207-1.548-.413 0 1.651 1.136 2.993 2.684 3.302-.31.104-.62.104-.93.104-.206 0-.412 0-.618-.104.412 1.342 1.65 2.374 3.199 2.374-1.135.929-2.58 1.445-4.231 1.445H.488c1.548.929 3.302 1.548 5.16 1.548 6.192 0 9.598-5.16 9.598-9.598v-.413c.722-.516 1.341-1.135 1.754-1.857Z"
    //     fill="#fff"
    //   />
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      fill="none"
      viewBox="0 0 22 18"
      {...props}
    >
      <path
        fill="currentColor"
        d="M21.41 2.508c-.784.393-1.568.523-2.484.654.916-.523 1.57-1.307 1.831-2.354-.785.523-1.7.785-2.746 1.047-.785-.785-1.962-1.308-3.139-1.308-2.223 0-4.184 1.961-4.184 4.315 0 .393 0 .654.13.916-3.53-.131-6.8-1.831-8.892-4.447-.392.654-.523 1.308-.523 2.224 0 1.438.785 2.746 1.962 3.53-.654 0-1.308-.261-1.962-.523 0 2.093 1.439 3.793 3.4 4.185-.392.13-.784.13-1.177.13-.261 0-.523 0-.784-.13.523 1.7 2.092 3.008 4.053 3.008-1.438 1.176-3.269 1.83-5.361 1.83H.488c1.961 1.177 4.184 1.962 6.538 1.962 7.846 0 12.162-6.539 12.162-12.162v-.523c.915-.654 1.7-1.438 2.223-2.354z"
      ></path>
    </svg>
  );
};

export default Twitter;
