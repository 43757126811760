import { connect } from "react-redux";

import LeadPageSettingsComponent from "./component";
import {
  addLeadPageSetting,
  resetLeadPageSetting,
  getLeadPageSettingId,
  uploadImages,
  editUser
} from "../../../store/user/duck";

const LeadPageSettingsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    getLeadPageSettingIdPhase: state.user.getLeadPageSettingIdPhase,
    getLeadPageSettingIdData: state.user.getLeadPageSettingIdData,
    addLeadPageSettingPhase: state.user.addLeadPageSettingPhase,
  }),
  // Map actions to dispatch and props
  {
    resetLeadPageSetting,
    addLeadPageSetting,
    getLeadPageSettingId,
    uploadImages,
    editUser
  }
)(LeadPageSettingsComponent);

export default LeadPageSettingsContainer;
