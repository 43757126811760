import * as React from "react";

const Close = ({ title, titleId, ...props }) => {
  return (
    <svg
      width={13}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M13 1.31 11.69 0 6.5 5.19 1.31 0 0 1.31 5.19 6.5 0 11.69 1.31 13 6.5 7.81 11.69 13 13 11.69 7.81 6.5 13 1.31Z"
        fill="#1A1F36"
      />
    </svg>
  );
};

export default Close;
