import React, { PureComponent } from "react";
import PreviewHeader from "./PreviewHeader/component";
import Preview from "./Preview/component";

import "./styles.scss";

export default class PreviewTemplateComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    return (
      <>
        <Preview {...this.props} />
      </>
    );
  }
}
