import { connect } from "react-redux";
import TabsComponent from "./component";

const TabsContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {}
)(TabsComponent);

export default TabsContainer;
