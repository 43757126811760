import { connect } from "react-redux";
import LoginComponent from "./component";
import {
  getUser,
  loginUser,
  verifyOtp,
  resetLogin,
  resendOtp,
} from "../../../store/user/duck";

const LoginContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.user.phase,
    errorMessage: state.user.message,
    otpPhase: state.user.otpPhase,
    otpMessage: state.user.otpMessage,
    resendPhase: state.user.resendPhase,
    resendMessage: state.user.resendMessage,
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    loginUser,
    verifyOtp,
    resendOtp,
    resetLogin,
    getUser,
  }
)(LoginComponent);

export default LoginContainer;
