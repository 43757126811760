import * as React from "react";

const Whatsapp = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        fill="currentColor"
        d="M9 0a9 9 0 00-9 9c0 1.688.474 3.263 1.283 4.613L.081 18l4.48-1.176A8.951 8.951 0 009 18 9 9 0 009 0zM5.92 4.802c.146 0 .296-.001.426.006.16.003.335.015.502.385.199.44.632 1.542.687 1.654.056.111.095.243.018.39-.074.15-.112.24-.22.373-.112.129-.234.29-.335.387-.112.111-.228.234-.099.457.13.222.577.953 1.24 1.542.85.76 1.57.994 1.793 1.106.223.112.353.094.482-.056.132-.146.557-.648.707-.871.146-.224.296-.185.498-.112.206.074 1.302.614 1.525.726.223.111.37.167.426.257.058.094.058.54-.127 1.06s-1.095 1.023-1.503 1.059c-.412.038-.796.185-2.676-.556-2.268-.893-3.699-3.216-3.81-3.366-.112-.146-.909-1.208-.909-2.303 0-1.098.576-1.636.778-1.86a.82.82 0 01.597-.278z"
      ></path>
    </svg>
  );
};

export default Whatsapp;
