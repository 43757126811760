import * as React from "react";

const TikTok = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      fill="none"
      viewBox="0 0 16 18"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.212 0c.007.926.3 1.827.838 2.581a4.54 4.54 0 002.172 1.64 4.44 4.44 0 001.528.258v2.906a7.494 7.494 0 01-4.534-1.525v.223c0 2.063 0 4.123.021 6.183a5.576 5.576 0 01-.77 2.97 5.61 5.61 0 01-2.228 2.119 5.64 5.64 0 01-5.91-.415A5.599 5.599 0 01.42 14.531a5.572 5.572 0 01.977-5.819 5.622 5.622 0 012.59-1.66 5.879 5.879 0 012.304-.198c.055.006.109.017.162.033v2.92a7.64 7.64 0 00-.847-.093 2.65 2.65 0 00-1.458.449 2.62 2.62 0 00-.403 4.03 2.645 2.645 0 002.86.594 2.64 2.64 0 001.193-.946c.295-.426.458-.929.468-1.446.022-1.525 0-3.05 0-4.575V.223c0-.072 0-.148.022-.223h2.923z"
      ></path>
    </svg>
  );
};

export default TikTok;
