import * as React from "react";

const AppSumo = ({ ...props }) => {
  return (
    <svg
      width={125}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M78.9 12.6c0 3.3-1.5 3.3-2.3 3.3-.7 0-2.3 0-2.3-3.3V3.4h-3.8v9.8c0 3.8 2.5 6.4 6.1 6.4 3.6 0 6.1-2.6 6.1-6.4V3.4h-3.8v9.2ZM116.4 3c-4.7 0-8.5 3.8-8.5 8.5s3.7 8.5 8.5 8.5 8.5-3.7 8.5-8.5c0-4.7-3.8-8.5-8.5-8.5Zm0 13.1c-2.5 0-4.6-2.1-4.6-4.6 0-2.6 2-4.6 4.6-4.6 2.6 0 4.6 2 4.6 4.6 0 2.5-2 4.6-4.6 4.6ZM8.7.8.2 19.5h4.3l1.6-3.6h5.2l1.6 3.6h4.3L8.7.8ZM7.1 12.7l1.6-3.9 1.6 3.9H7.1ZM27.2 3.5H21v15.7h3.9v-4.5H27c3.9 0 6.3-2.2 6.3-5.6 0-3.5-2.3-5.6-6.1-5.6Zm2.1 5.7c0 1.3-.9 2.1-2.4 2.1h-2V7h2c1.6 0 2.4.8 2.4 2.2ZM43.4 3.5h-6.2v15.7h3.9v-4.5h2.1c3.9 0 6.3-2.2 6.3-5.6-.1-3.5-2.4-5.6-6.1-5.6Zm2.1 5.7c0 1.3-.9 2.1-2.4 2.1h-2V7h2c1.5 0 2.4.8 2.4 2.2ZM61 8.5c-3.1-.8-3.7-1.2-3.7-2.2v-.1c0-.9.8-1.4 2.2-1.4 1.4 0 2.9.6 4.6 1.7l.1.1 2.2-3.2-.1-.1C64.4 1.8 62.1 1 59.6 1c-3.8 0-6.4 2.3-6.4 5.5v.1c0 3.8 2.6 4.7 6 5.6 3 .8 3.5 1.3 3.5 2.1v.1c0 1-.9 1.5-2.4 1.5-1.8 0-3.5-.7-5.2-2.1l-.1-.1-2.5 3 .1.1c2.1 1.9 4.8 2.9 7.7 2.9 4.1 0 6.7-2.2 6.7-5.7-.2-3.3-2.5-4.6-6-5.5ZM95.7 20.1l-4.7-8-1.4 7.4h-3.4L89.7 3l6.2 10.4L101.8 3l3.5 16.5h-3.6l-1.4-7.3-4.6 7.9Z"
        fill="#231F20"
      />
    </svg>
  );
};
export default AppSumo;
