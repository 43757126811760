import React, { PureComponent } from "react";
import DemoHeader from "./DemoHeader/component";
import DemoPreview from "./DemoPreview/component";

import "./styles.scss";

export default class DemoTemplateComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    return (
      <>
        <DemoHeader {...this.props} />
        <DemoPreview {...this.props} />
      </>
    );
  }
}
