// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from "immutable";
import { assign } from "lodash";
import Cookies from "universal-cookie";
import { INIT, LOADING, SUCCESS, ERROR } from "../../constants/phase";
import { get } from "lodash";

import { fromPromise } from "rxjs/observable/fromPromise";
import { of } from "rxjs";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
const cookies = new Cookies();

export const GET_ADMIN_AGENT = "yardpost/admin/GET_ADMIN_AGENT";
export const GET_ADMIN_AGENT_SUCCESS = "yardpost/admin/GET_ADMIN_AGENT_SUCCESS";
export const GET_ADMIN_AGENT_ERROR = "yardpost/admin/GET_ADMIN_AGENT_ERROR";

export const IMPERSONATE_AGENT = "yardpost/admin/IMPERSONATE_AGENT";
export const IMPERSONATE_AGENT_SUCCESS =
  "yardpost/admin/IMPERSONATE_AGENT_SUCCESS";
export const IMPERSONATE_AGENT_ERROR = "yardpost/admin/IMPERSONATE_AGENT_ERROR";

export const CANCEL_SUBSCRIPTIONS = "yardpost/admin/CANCEL_SUBSCRIPTIONS";
export const CANCEL_SUBSCRIPTIONS_SUCCESS =
  "yardpost/admin/CANCEL_SUBSCRIPTIONS_SUCCESS";
export const CANCEL_SUBSCRIPTIONS_ERROR =
  "yardpost/admin/CANCEL_SUBSCRIPTIONS_ERROR";

export const DELETE_AGENT = "yardpost/admin/DELETE_AGENT";
export const DELETE_AGENT_SUCCESS = "yardpost/admin/DELETE_AGENT_SUCCESS";
export const DELETE_AGENT_ERROR = "yardpost/admin/DELETE_AGENT_ERROR";

export const ADD_PLIVO_NUMBER = "yardpost/admin/ADD_PLIVO_NUMBER";
export const ADD_PLIVO_NUMBER_SUCCESS =
  "yardpost/admin/ADD_PLIVO_NUMBER_SUCCESS";
export const ADD_PLIVO_NUMBER_ERROR = "yardpost/admin/ADD_PLIVO_NUMBER_ERROR";

export const UPDATE_GRANDFATHER_STATUS =
  "yardpost/admin/UPDATE_GRANDFATHER_STATUS";
export const UPDATE_GRANDFATHER_STATUS_SUCCESS =
  "yardpost/admin/UPDATE_GRANDFATHER_STATUS_SUCCESS";
export const UPDATE_GRANDFATHER_STATUS_ERROR =
  "yardpost/admin/UPDATE_GRANDFATHER_STATUS_ERROR";

export const RESET_ADMIN = "yardpost/admin/RESET_ADMIN";
export const SUBCRIPTION_RESET = "yardpost/admin/SUBCRIPTION_RESET";
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  adminAgentPhase: INIT,
  adminAgentData: [],
  impersonatePhase: INIT,
  impersonateData: [],
  cancelSubscriptionPhase: INIT,
  cancelSubscriptionsMessage: "",
  deleteAgentPhase: INIT,
  deleteAgentMessage: "",
  addPlivoNumberPhase: INIT,
  addPlivoNumberMessage: "",
  updateGrandfatherStatusMessage: "",
  updateGrandfatherStatusPhase: INIT,
  totalCount: 0,
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case GET_ADMIN_AGENT: {
      return state
        .set("adminAgentPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_ADMIN_AGENT_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("adminAgentPhase", SUCCESS)
          .set("adminAgentData", payload.data)
          .set("error", payload.message)
          .set("totalCount", payload.userCount)
          .set("isSubmitting", true);
      } else {
        return state
          .set("adminAgentPhase", SUCCESS)
          .set("adminAgentData", payload.data)
          .set("error", payload.message)
          .set("totalCount", payload.userCount)
          .set("isSubmitting", true);
      }
    }

    case GET_ADMIN_AGENT_ERROR: {
      const { payload } = action;
      return state
        .set("adminAgentPhase", ERROR)
        .set("impersonateData", [])
        .set("error", payload.message)
        .set("isSubmitting", true);
    }

    case ADD_PLIVO_NUMBER: {
      return state
        .set("addPlivoNumberPhase", LOADING)
        .set("addPlivoNumberMessage", "");
      // .set('isSubmitting', true)
    }

    case ADD_PLIVO_NUMBER_SUCCESS: {
      // console.log(action.payload,'data')
      const { payload } = action;
      if (payload.success) {
        return state
          .set("addPlivoNumberPhase", SUCCESS)
          .set("addPlivoNumberMessage", payload.message);
      }
    }

    case ADD_PLIVO_NUMBER_ERROR: {
      const { payload } = action;
      return state
        .set("addPlivoNumberPhase", ERROR)
        .set("addPlivoNumberMessage", payload.message);
    }

    case UPDATE_GRANDFATHER_STATUS: {
      return state
        .set("updateGrandfatherStatusPhase", LOADING)
        .set("updateGrandfatherStatusMessage", "");
      // .set('isSubmitting', true)
    }

    case UPDATE_GRANDFATHER_STATUS_SUCCESS: {
      // console.log(action.payload,'data')
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateGrandfatherStatusPhase", SUCCESS)
          .set("updateGrandfatherStatusMessage", payload.message);
      }
    }

    case UPDATE_GRANDFATHER_STATUS_ERROR: {
      const { payload } = action;
      return state
        .set("updateGrandfatherStatusPhase", ERROR)
        .set("updateGrandfatherStatusMessage", payload.message);
    }

    case IMPERSONATE_AGENT: {
      return state
        .set("impersonatePhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case IMPERSONATE_AGENT_SUCCESS: {
      const { payload } = action;
      if (get(payload, "token", "")) {
        localStorage.setItem("Authorization", payload.token);
      }
      if (payload.success) {
        return state
          .set("impersonatePhase", SUCCESS)
          .set("error", payload.message)
          .set("isSubmitting", true);
      }
    }

    case IMPERSONATE_AGENT_ERROR: {
      const { payload } = action;
      return state
        .set("impersonatePhase", ERROR)
        .set("impersonateData", [])
        .set("error", payload.message)
        .set("isSubmitting", true);
    }

    case CANCEL_SUBSCRIPTIONS: {
      return state
        .set("cancelSubscriptionPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case CANCEL_SUBSCRIPTIONS_SUCCESS: {
      const { payload } = action;
      return state
        .set("cancelSubscriptionPhase", SUCCESS)
        .set("cancelSubscriptionsMessage", payload.message);
    }

    case CANCEL_SUBSCRIPTIONS_ERROR: {
      const { payload } = action;
      return state
        .set("cancelSubscriptionPhase", ERROR)
        .set("cancelSubscriptionsMessage", payload.message);
    }

    case DELETE_AGENT: {
      return state
        .set("deleteAgentPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case DELETE_AGENT_SUCCESS: {
      const { payload } = action;
      return state
        .set("deleteAgentPhase", SUCCESS)
        .set("deleteAgentMessage", payload.message);
    }

    case DELETE_AGENT_ERROR: {
      const { payload } = action;
      return state
        .set("deleteAgentPhase", ERROR)
        .set("deleteAgentMessage", payload.message);
    }

    case RESET_ADMIN: {
      return state
        .set("adminAgentPhase", INIT)
        .set("impersonatePhase", INIT)
        .set("impersonateData", {})
        .set("addPlivoNumberPhase", INIT)
        .set("updateGrandfatherStatusPhase", INIT);
    }

    case SUBCRIPTION_RESET: {
      return state
        .set("cancelSubscriptionPhase", INIT)
        .set("deleteAgentPhase", INIT);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

// User Auth

export const resetAdmin = (credentials) => {
  return {
    type: RESET_ADMIN,
  };
};

export const updateGrandfatherStatus = (credentials) => {
  return {
    type: UPDATE_GRANDFATHER_STATUS,
    payload: credentials,
  };
};

export const getAdminAgent = (credentials) => {
  return {
    type: GET_ADMIN_AGENT,
    payload: credentials,
  };
};

export const addPlivoNumber = (credentials) => {
  // console.log('addPlivoNumber',credentials)
  return {
    type: ADD_PLIVO_NUMBER,
    payload: credentials,
  };
};

export const userImpersonate = (credentials) => {
  return {
    type: IMPERSONATE_AGENT,
    payload: credentials,
  };
};

export const cancelSubscriptions = (credentials) => {
  return {
    type: CANCEL_SUBSCRIPTIONS,
    payload: credentials,
  };
};

export const deleteAgent = (credentials) => {
  // console.log(credentials,'credentials')
  return {
    type: DELETE_AGENT,
    payload: credentials,
  };
};

export const resetSubscriptions = (credentials) => {
  return {
    type: SUBCRIPTION_RESET,
    payload: credentials,
  };
};

/***********************************
 * Epics
 ***********************************/

const getAdminAgentEpic = (action$) =>
  action$.pipe(
    ofType(GET_ADMIN_AGENT),
    mergeMap((action) => {
      return fromPromise(api.getAdminAgent(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_ADMIN_AGENT_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_ADMIN_AGENT_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const userImpersonateEpic = (action$) =>
  action$.pipe(
    ofType(IMPERSONATE_AGENT),
    mergeMap((action) => {
      return fromPromise(api.userImpersonate(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: IMPERSONATE_AGENT_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: IMPERSONATE_AGENT_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const cancelSubscriptionsEpic = (action$) =>
  action$.pipe(
    ofType(CANCEL_SUBSCRIPTIONS),
    mergeMap((action) => {
      return fromPromise(api.cancelSub(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: CANCEL_SUBSCRIPTIONS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: CANCEL_SUBSCRIPTIONS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const deleteAgentEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_AGENT),
    mergeMap((action) => {
      return fromPromise(api.deleteAgent(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: DELETE_AGENT_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: DELETE_AGENT_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const addPlivoNumberEpic = (action$) =>
  action$.pipe(
    ofType(ADD_PLIVO_NUMBER),
    mergeMap((action) => {
      return fromPromise(api.addPlivoNumber(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: ADD_PLIVO_NUMBER_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: ADD_PLIVO_NUMBER_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const updateGrandfatherStatusEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_GRANDFATHER_STATUS),
    mergeMap((action) => {
      return fromPromise(api.updateGrandfatherStatus(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_GRANDFATHER_STATUS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_GRANDFATHER_STATUS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

export const adminEpic = combineEpics(
  getAdminAgentEpic,
  userImpersonateEpic,
  cancelSubscriptionsEpic,
  deleteAgentEpic,
  addPlivoNumberEpic,
  updateGrandfatherStatusEpic
);
