import React, { PureComponent } from "react";
// import PropTypes from 'prop-types'

import "./styles.scss";

export default class CheckboxComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    return (
      <>
        <label className="custom__checkbox">
          {" "}
          {this.props.value}
          <input
            className="custom__checkbox--input"
            type="checkbox"
            id={this.props.index}
            value={this.props.value}
            onChange={this.props.handleFeature}
            checked={this.props.featureArray.includes(this.props.value)}
          />
          <span className="custom__checkbox--check"></span>
        </label>
      </>
    );
  }
}
