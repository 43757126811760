import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Impersonate from "../../../components/common/Impersonate/container";
import _, { get, isEmpty, values } from "lodash";
import TopNavigation from "../../../components/common/TopNavigation/component";
import Tabs from "../../../components/common/Tabs/container";
import { Plus, Remove } from "../../../components/icons";
import Dropzone from "react-dropzone";
import RadioGroupInput from "../../../components/ui/RadioGroupInput/component";
import PhoneInput from "react-phone-input-2";
import { LoadingOutlined } from "@ant-design/icons";
import { Formik, Field, FieldArray, ErrorMessage } from "formik";
import { notification, Spin } from "antd";
import { error } from "jquery";
import * as Yup from "yup";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default class SocialLinkInBioComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isEmailExists: false,
      isSpinLoading: false,
      profileImage: "",
      profileImageError: "",
      // userData: [],
      userData: "",
      imageUrl: "",
      isPhoneExits: false,
      imagePreviewUrl: "",
      countryCode: "us",
      phone: "",
      propertylist: [],
      reRender: true,
      // yardpostLogo: true,
      showSocialLinks: true,
      showNewsLetter: true,
      showPropertyVal: true,
    };
  }

  // handelYardPostLogo = (value) => {
  //   this.setState({ yardpostLogo: value });
  // };

  handelShowSocialLinks = (value) => {
    this.setState({ showSocialLinks: value });
  };

  handelShowPropertyVal = (value) => {
    this.setState({ showPropertyVal: value });
  };

  handelShowNewsLetter = (value) => {
    this.setState({ showNewsLetter: value });
  };

  openNotificationWithIcon = (type) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: "Profile updated successfully",
    });
  };

  componentDidMount = () => {
    window.scrollTo({ top: 0 });
    if (
      window.location.origin !== "https://app.yardpost.com" &&
      window.location.origin !== "https://stag-app.yardpost.com" &&
      window.location.origin !== "http://localhost:3000" &&
      window.location.origin !== "https://yardpost-frontend-04tp.onrender.com"
    ) {
      this.props.getUser({domain:window.location.origin?.replaceAll("https://", "")});
    } else {
      this.props.getUser();
    }

    if (!isEmpty(this.props.user)) {
      this.props.getUser();
      this.setState({
        userData: this.props.userData,
        profileImage: get(this.props.userData, "profileImage", ""),
      });
    }
  };

  componentDidUpdate = (prev) => {
    if (!isEmpty(this.props.userData)) {
      this.setState({ userData: this.props.userData });
    }

    if (this.props.editUserPhase === "success") {
      window.scrollTo({ top: 0 });
      this.props.getUser();
      this.props.resetLogin();
      this.setState({
        isSpinLoading: false,
      });
    } else if (this.props.editUserPhase === "error") {
      this.props.resetLogin();
      if (
        this.props.editUserData.duplicate &&
        this.props.editUserData.duplicateKey[0] === "email"
      ) {
        this.setState({
          isEmailExists: true,
          isSpinLoading: false,
          isLoading: false,
        });
      }
    }
    if (this.props.userPhase === "success") {
      window.scrollTo({ top: 0 });
      this.props.resetLoginUser();
      this.setState(
        {
          countryCode: get(this.props.user, "user.countryCode", "+1"),
          phone: get(this.props.user, "user.phone", ""),
          userData: get(this.props.user, "user", {}),
          profileImage: get(this.props.user, "user.profileImage", {}),
          propertylist: this.props.user?.user?.propertylist,
          // yardpostLogo: get(this.props.user, "user.yardpostLogo", true),
          showPropertyVal: get(this.props.user, "user.showPropertyVal", true),
          showNewsLetter: get(this.props.user, "user.showNewsLetter", true),
          showSocialLinks: get(this.props.user, "user.showSocialLinks", true),
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  };

  openNotificationWithIcon = (type) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: "Profile updated successfully",
    });
  };

  handleChangeCountryCode = (value, phone) => {
    console.log(value, phone);
    this.setState(
      { countryCode: value, phone: phone.replace(value, ""), phoneError: "" },
      () => {
        this.forceUpdate();
      }
    );
  };

  removeImageUrl(value) {
    this.setState({ imagePreviewUrl: "", imageUrl: "", profileImage: "" });
  }

  onDropImage = async (acceptedFiles, key) => {
    acceptedFiles[0].preview = URL.createObjectURL(acceptedFiles[0]);
    if (acceptedFiles[0].type.split("/")[0] === "image") {
      // let form_data = new FormData();
      // form_data.append("key", key);
      // form_data.append("images[]", acceptedFiles[0]);
      // form_data.append(`numOfImage`, 1);
      const { value } = await this.props.uploadImages(acceptedFiles[0]);
      this.setState(
        {
          imagePreviewUrl: URL.createObjectURL(acceptedFiles[0]),
          profileImage: value.data.images[0],
          profileImageError: "",
        },
        () => {
          this.forceUpdate();
          // console.log(this.state.profileImage,'profileImage')
        }
      );
    } else {
      this.setState(
        {
          profileImageError: "File type should be image.",
        },
        () => {
          this.forceUpdate();
          // console.log(this.state.profileImage,'profileImage')
        }
      );
    }
  };

  render() {
    const { userData, isEmailExists, isPhoneExits, propertylist } = this.state;
    const showSocialLinks = [
      { key: "Active", value: true },
      { key: "Not Active", value: false },
    ];
    const showPropertyVal = [
      { key: "showPro", value: true },
      { key: "hidePro", value: false },
    ];
    const showNewsLetter = [
      { key: "Show", value: true },
      { key: "Hide", value: false },
    ];
    // const yardpostLogo = [
    //   { key: "show", value: true },
    //   { key: "hide", value: false },
    // ];

    return (
      <>
        <main
          className="main"
          role="header-with-tabs"
          impersonate={
            localStorage.getItem("AdminAuthorization") &&
            localStorage.getItem("Authorization")
              ? "impersonate"
              : ""
          }
        >
          <div className="main__header">
            {localStorage.getItem("AdminAuthorization") &&
              localStorage.getItem("Authorization") && (
                <Impersonate
                  data={get(this.props.user, "user", "")}
                  {...this.props}
                />
              )}
            <TopNavigation {...this.props} />
            <div className="tabs__links">
              <Tabs attr="socialLink" {...this.props} />
            </div>
          </div>

          <div className="page__header">
            <div className="page__header--container">
              <div className="page__header--row">
                <div className="page__header--left">
                  <h1 className="page__header--title">Social Link</h1>
                </div>
                <div className="page__header--right">
                  <Link
                    target="_blank"
                    to="/social-link-preview/"
                    type="button"
                    className="btn btn__purple"
                  >
                    Preview Social Link
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="custom__container">
            <div className="custom__wrap">
              <div className="setup-social-info form-group">
                <div className="setup-social-info-desc">
                  Set up your social link in minutes. Fill out the information
                  below, add your featured properties, and then simply publish
                  your social link.
                </div>
                <div className="setup-social-info-url">
                  Social Link URL:{" "}
                  {get(userData, "domain", "") === "" ? (
                    ""
                  ) : get(userData, "showSocialLinks", true) === true ? (
                    <Link
                      target="_blank"
                      to="/social-link-preview"
                      className="link"
                    >
                      https://{get(userData, "domain", "")}/social
                    </Link>
                  ) : (
                    <Link
                      target="_blank"
                      to="/social-link-empty"
                      className="link"
                    >
                      https://{get(userData, "domain", "")}/social
                    </Link>
                  )}
                </div>
              </div>

              {!isEmpty(userData) && (
                <Formik
                  initialValues={{
                    firstName: get(userData, "firstName", ""),
                    lastName: get(userData, "lastName", ""),
                    email: get(userData, "email", ""),
                    title: get(userData, "title", ""),
                    phone: this.state.phone,
                    profileImage:
                      this.state.profileImage !== ""
                        ? this.state.profileImage
                        : get(userData, "profileImage", ""),
                    role: "Admin",
                    facebookUrl: get(userData, "facebookUrl", ""),
                    whatsupUrl: get(userData, "whatsupUrl", ""),
                    instagramUrl: get(userData, "instagramUrl", ""),
                    twitterUrl: get(userData, "twitterUrl", ""),
                    tiktokUrl: get(userData, "tiktokUrl", ""),
                    linkdinUrl: get(userData, "linkdinUrl", ""),
                    youtubeUrl: get(userData, "youtubeUrl", ""),
                    // yardpostLogo: get(userData, "yardpostLogo", true),
                    showNewsLetter: get(userData, "showNewsLetter", true),
                    showPropertyVal: get(userData, "showPropertyVal", true),
                    showSocialLinks: get(userData, "showSocialLinks", true),
                    propertylist: get(userData, "propertylist", []),
                  }}
                  validationSchema={Yup.object({
                    propertylist: Yup.array().of(
                      Yup.object().shape({
                        title: Yup.string().required("Please enter a title."),
                        link: Yup.string()
                          .required("Please enter a link.")
                          .url("Please enter a valid url."),
                      })
                    ),
                  })}
                  validate={(values) => {
                    this.setState({
                      isEmailExists: false,
                    });
                    const errors = {};
                    const regex = new RegExp(
                      "^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$"
                    );
                    if (!values.firstName) {
                      errors.firstName = "Please enter first name";
                    }
                    if (!values.lastName) {
                      errors.lastName = "Please enter last name";
                    }
                    if (!values.email) {
                      errors.email = "Please enter email";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }
                    // console.log(this.state.phone.length, "");
                    // if (this.state.phone === "") {
                    //   errors.phone = "Please enter valid number";
                    // } else if (this.state.phone.length < 9) {
                    //   this.setState({
                    //     phoneError: "Please enter valid Number",
                    //   });
                    //   errors["phone"] = "Please enter valid phone number";
                    // }

                    if (this.state.profileImage === "") {
                      errors.profileImage = "Please upload profile image.";
                      this.setState({
                        profileImageError: "Please upload profile image.",
                      });
                    }
                    if (
                      !regex.test(values.facebookUrl) &&
                      values.facebookUrl !== ""
                    ) {
                      errors.facebookUrl = "Please enter valid Facebook URL";
                    }
                    if (
                      !regex.test(values.instagramUrl) &&
                      values.instagramUrl !== ""
                    ) {
                      errors.instagramUrl = "Please enter valid Instagram URL";
                    }
                    if (
                      !regex.test(values.youtubeUrl) &&
                      values.youtubeUrl !== ""
                    ) {
                      errors.youtubeUrl = "Please enter valid Youtube URL";
                    }
                    if (
                      !regex.test(values.twitterUrl) &&
                      values.twitterUrl !== ""
                    ) {
                      errors.twitterUrl = "Please enter valid Twitter URL";
                    }
                    if (
                      !regex.test(values.linkdinUrl) &&
                      values.linkdinUrl !== ""
                    ) {
                      errors.linkdinUrl = "Please enter valid Linkdin URL";
                    }
                    if (
                      !regex.test(values.whatsupUrl) &&
                      values.whatsupUrl !== ""
                    ) {
                      errors.whatsupUrl = "Please enter valid Whatsapp URL";
                    }
                    if (
                      !regex.test(values.tiktokUrl) &&
                      values.tiktokUrl !== ""
                    ) {
                      errors.tiktokUrl = "Please enter valid Tiktok URL";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    this.setState({
                      isSpinLoading: true,
                      isEmailExists: false,
                    });
                    setTimeout(() => {
                      setSubmitting(false);
                      this.setState({ isLoading: true });
                      // values.yardpostLogo = this.state.yardpostLogo;
                      values.showNewsLetter = this.state.showNewsLetter;
                      values.showPropertyVal = this.state.showPropertyVal;
                      values.showSocialLinks = this.state.showSocialLinks;
                      // let form_data = {};
                      let form_data = new FormData();
                      values.profileImage =
                        this.state.profileImage !== ""
                          ? this.state.profileImage
                          : get(userData, "profileImage", "");
                      values.phone = this.state.phone;
                      values.countryCode = this.state.countryCode;
                      values.propertylist = values.propertylist;

                      for (const [keyOF, valueOF] of Object.entries(values)) {
                        if (keyOF === "propertylist") {
                          form_data.append(
                            "propertylist",
                            JSON.stringify(values.propertylist)
                          );
                        } else {
                          form_data.append(keyOF, valueOF);
                        }
                      }
                      // for (let key in values) {
                      //   console.log("key-->", key, values);
                      //   if (values[key] === get(userData, "profileImage", "")) {
                      //   } else {
                      //     console.log(
                      //       "append",
                      //       form_data.append(key, values[key])
                      //     );
                      //     form_data.append(key, values[key]);
                      //     // form_data[key] = values[key];
                      //   }
                      // }
                      // form_data.append(
                      //   "propertylist",
                      //   JSON.stringify(this.state.propertylist)
                      // );
                      this.props.editUser(form_data);
                      this.setState({
                        isSpinLoading: false,
                      });
                      this.openNotificationWithIcon("success");
                    }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="row">
                        <div className="form-group">
                          <div className="d-flex">
                            <label className="label-primary mb-0">
                              Publish Social Link Status
                            </label>
                            <div className="ms-auto">
                              {showSocialLinks.map((data, i) => {
                                return (
                                  <>
                                    <RadioGroupInput
                                      value={data.value}
                                      setter={this.handelShowSocialLinks}
                                      checked={this.state.showSocialLinks}
                                      label={data.key}
                                    />
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="d-flex">
                            <label className="label-primary mb-0">
                              Property Valuation section
                            </label>
                            <div className="ms-auto">
                              {showPropertyVal.map((data, i) => {
                                return (
                                  <>
                                    <RadioGroupInput
                                      value={data.value}
                                      setter={this.handelShowPropertyVal}
                                      checked={this.state.showPropertyVal}
                                      label={data.key}
                                    />
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="d-flex">
                            <label className="label-primary mb-0">
                              Sign up to newsletter section
                            </label>
                            <div className="ms-auto">
                              {showNewsLetter.map((data, i) => {
                                return (
                                  <>
                                    <RadioGroupInput
                                      value={data.value}
                                      setter={this.handelShowNewsLetter}
                                      checked={this.state.showNewsLetter}
                                      label={data.key}
                                    />
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        {/* <div className="form-group">
                          <div className="d-flex">
                            <label className="label-primary mb-0">
                              Hide Yardpost logo
                            </label>
                            <div className="ms-auto">
                              {yardpostLogo.map((data, i) => {
                                return (
                                  <>
                                    <RadioGroupInput
                                      value={data.value}
                                      setter={this.handelYardPostLogo}
                                      checked={this.state.yardpostLogo}
                                      label={data.key}
                                    />
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="label-primary">
                              First Name<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstName}
                              className="form-control"
                              required
                            />
                            {errors.firstName && (
                              <div className="invalid-feedback">
                                {errors.firstName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="label-primary">
                              Last Name<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastName}
                              className="form-control"
                              required
                            />
                            {errors.lastName && (
                              <div className="invalid-feedback">
                                {errors.lastName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="label-primary">
                              Email Address<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              name="email"
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({ isEmailExists: false }, () => {
                                  this.forceUpdate();
                                });
                              }}
                              onBlur={handleBlur}
                              value={values.email}
                              className="form-control"
                              required
                            />
                            {errors.email && (
                              <div className="invalid-feedback">
                                {errors.email}
                              </div>
                            )}
                            {isEmailExists && (
                              <div className="invalid-feedback">
                                Email already exists
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="label-primary">
                              Phone Number
                            </label>
                            <PhoneInput
                              inputProps={{
                                readOnly: false,
                                name: "phone",
                                required: true,
                                className: "form-control form-control-flat",
                              }}
                              country={"us"}
                              value={this.state.countryCode + this.state.phone}
                              isValid={(value, country) => {
                                // console.log(value, country, "country");
                                // this.setState({countryCode: country.iso2})
                              }}
                              onChange={(phone, val) =>
                                this.handleChangeCountryCode(
                                  val.dialCode,
                                  phone
                                )
                              }
                            />
                            {errors.phone && (
                              <div className="invalid-feedback">
                                {errors.phone}
                              </div>
                            )}
                            {isPhoneExits && (
                              <div className="invalid-feedback">
                                Phone number already exists
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="label-primary">Title</label>
                          <input
                            type="text"
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            className="form-control"
                            // required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label-primary">
                          Profile Picture<span className="required">*</span>
                        </label>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.onDropImage(acceptedFiles, "profileImage")
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section className="dropzone">
                              <div
                                className="dropzone__area"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <button
                                  type="button"
                                  className="btn btn__purple"
                                >
                                  <Plus className="btn__icon" /> Upload Photo
                                </button>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        {this.state.imagePreviewUrl ? (
                          <div className="dropzone__grid">
                            <div className="dropzone__grid--item">
                              <div className="dropzone__grid--preview">
                                <img
                                  className="preview__thumb"
                                  src={this.state.imagePreviewUrl}
                                  alt=""
                                />
                                <div className="dropzone__grid--remove">
                                  <button
                                    onClick={this.removeImageUrl.bind(this, 1)}
                                    className="btn btn__remove"
                                  >
                                    <Remove />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.profileImage &&
                        !this.state.imagePreviewUrl ? (
                          <div className="dropzone__grid">
                            <div className="dropzone__grid--item">
                              <div className="dropzone__grid--preview">
                                <img
                                  className="preview__thumb"
                                  src={this.state.profileImage}
                                  alt=""
                                />
                                <div className="dropzone__grid--remove">
                                  <button
                                    onClick={this.removeImageUrl.bind(this, 2)}
                                    className="btn btn__remove"
                                  >
                                    <Remove />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.profileImageError && (
                          <div className="invalid-feedback">
                            {this.state.profileImageError}
                          </div>
                        )}
                      </div>
                      <hr />
                      <FieldArray
                        name="propertylist"
                        render={(arrayHelpers) => {
                          const propertylist = values.propertylist;
                          return (
                            <div className="row">
                              {propertylist && propertylist.length > 0
                                ? propertylist.map((item, index) => (
                                    <div key={index}>
                                      <div className="form-group">
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                          <label className="label-primary mb-0">
                                            Title {index + 1}{" "}
                                            <span className="required">*</span>
                                          </label>
                                          <button
                                            type="button"
                                            className="btn btn__link btn__link--danger"
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                            }}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                        <Field
                                          as="textarea"
                                          className="form-control"
                                          name={`propertylist.${index}.title`}
                                          maxLength="80"
                                        />
                                        <p className="form-control-note text-end fst-normal">
                                          {propertylist[index].title.length}
                                          /80
                                        </p>
                                        <div className="invalid-feedback">
                                          <ErrorMessage
                                            name={`propertylist.${index}.title`}
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group">
                                        <label className="label-primary">
                                          Link {index + 1}
                                          <span className="required">*</span>
                                        </label>
                                        <Field
                                          className="form-control"
                                          name={`propertylist.${index}.link`}
                                        />

                                        <div className="invalid-feedback">
                                          <ErrorMessage
                                            className="invalid-feedback"
                                            name={`propertylist.${index}.link`}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : null}
                              <button
                                className="btn btn-lg btn__green w-100"
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({
                                    title: "",
                                    link: "",
                                  })
                                }
                              >
                                Add Link
                              </button>
                            </div>
                          );
                        }}
                      />
                      <hr />
                      <div className="form-group">
                        <label className="label-primary">Facebook</label>
                        <input
                          type="text"
                          name="facebookUrl"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.facebookUrl}
                          // required
                        />
                        {errors.facebookUrl && touched.facebookUrl && (
                          <div className="invalid-feedback">
                            {errors.facebookUrl}
                          </div>
                        )}
                        <p className="form-control-note">
                          Example: https://www.facebook.com/yardpost/
                        </p>
                      </div>
                      <div className="form-group">
                        <label className="label-primary">Instagram</label>
                        <input
                          type="text"
                          name="instagramUrl"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.instagramUrl}
                          className="form-control"
                          // required
                        />
                        {errors.instagramUrl && touched.instagramUrl && (
                          <div className="invalid-feedback">
                            {errors.instagramUrl}
                          </div>
                        )}
                        <p className="form-control-note">
                          Example: https://www.instagram.com/yardpost/
                        </p>
                      </div>
                      <div className="form-group">
                        <label className="label-primary">Youtube</label>
                        <input
                          type="text"
                          name="youtubeUrl"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.youtubeUrl}
                          className="form-control"
                          // required
                        />
                        {errors.youtubeUrl && touched.youtubeUrl && (
                          <div className="invalid-feedback">
                            {errors.youtubeUrl}
                          </div>
                        )}
                        <p className="form-control-note">
                          Example: https://www.youtube.com/yardpost/
                        </p>
                      </div>
                      <div className="form-group">
                        <label className="label-primary">Twitter</label>
                        <input
                          type="text"
                          name="twitterUrl"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.twitterUrl}
                          className="form-control"
                          // required
                        />
                        {errors.twitterUrl && touched.twitterUrl && (
                          <div className="invalid-feedback">
                            {errors.twitterUrl}
                          </div>
                        )}
                        <p className="form-control-note">
                          Example: https://www.twitter.com/yardpost/
                        </p>
                      </div>
                      <div className="form-group">
                        <label className="label-primary">Linkedin</label>
                        <input
                          type="text"
                          name="linkdinUrl"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.linkdinUrl}
                          className="form-control"
                          // required
                        />
                        {errors.linkdinUrl && touched.linkdinUrl && (
                          <div className="invalid-feedback">
                            {errors.linkdinUrl}
                          </div>
                        )}
                        <p className="form-control-note">
                          Example: https://www.linkedin.com/yardpost/
                        </p>
                      </div>
                      <div className="form-group">
                        <label className="label-primary">Whatsapp</label>
                        <input
                          type="text"
                          name="whatsupUrl"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.whatsupUrl}
                          className="form-control"
                          // required
                        />
                        {errors.whatsupUrl && touched.whatsupUrl && (
                          <div className="invalid-feedback">
                            {errors.whatsupUrl}
                          </div>
                        )}
                        <p className="form-control-note">
                          Example: https://www.whatsapp.com/yardpost/
                        </p>
                      </div>
                      <div className="form-group">
                        <label className="label-primary">TikTok</label>
                        <input
                          type="text"
                          name="tiktokUrl"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.tiktokUrl}
                          className="form-control"
                          // required
                        />
                        {errors.tiktokUrl && touched.tiktokUrl && (
                          <div className="invalid-feedback">
                            {errors.tiktokUrl}
                          </div>
                        )}
                        <p className="form-control-note">
                          Example: https://www.tiktok.com/yardpost/
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-lg btn__purple w-100"
                      >
                        {this.state.isSpinLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </main>
      </>
    );
  }
}
