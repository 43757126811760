import * as React from "react";

const Mobile = ({ ...props }) => {
  return (
    <svg
      width={11}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.802 0H1.198C.531 0 0 .585 0 1.31v13.38c0 .347.126.68.35.925.225.246.53.384.848.385h8.604c.667 0 1.198-.586 1.198-1.31V1.31c0-.347-.126-.68-.35-.925A1.153 1.153 0 0 0 9.801 0ZM8.294 14.118a.706.706 0 1 0 0-1.412H2.706a.706.706 0 1 0 0 1.412h5.588Z"
        fill="#A3ACB9"
      />
    </svg>
  );
};

export default Mobile;
