import React, { PureComponent } from "react";
import { get } from "lodash";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles.scss";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default class QRCodeComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      isSpinLoading: false,
      showQrCode: false,
      qrCodeImage: null,
      isQrCode: false,
    };
    this.getqrCodeImage = this.getqrCodeImage.bind(this);
  }
  getqrCodeImage = () => {
    this.setState({ isSpinLoading: true });
    const qrCodeImageData = this.props.leadPageByIdData.qrCodeImage;
    console.log(qrCodeImageData, "qrcode-->");
    this.setState({
      qrCodeImage: qrCodeImageData,
      isSpinLoading: false,
      showQrCode: true,
    });
  };

  // componentDidMount() {
  //   console.log(this.props, "<<<<isqrcode");
  //   this.setState({ isQrCode: this.props.isQrCode });
  // }
  componentDidMount() {
    console.log(this.props, "<<<<isqrcode");
    this.setState({ isQrCode: this.props.isQrCode || false });
  }

  render() {
    const { qrCodeImage, isSpinLoading, showQrCode } = this.state;
    console.log(
      this.props.leadPageByIdData.isQrCode,
      "status of isQrcode====>"
    );
    return (
      <>
        {(this.props.leadPageByIdData.isQrCode && !showQrCode) ||
        (!this.props.leadPageByIdData && this.props.isQrCode) ? (
          <div className="col-md-12">
            <div className="form-group">
              <button
                type="submit"
                className="btn btn__purple btn__head fullwidth"
                onClick={this.getqrCodeImage}
              >
                {this.state.isSpinLoading ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <>View QR Code</>
                )}
              </button>
            </div>
          </div>
        ) : (
          !this.props.leadPageByIdData.isQrCode &&
          !showQrCode && (
            <div className="col-md-12">
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn__flat--dark btn__head fullwidth"
                  onClick={() => this.props.updateQrcode()}
                >
                  {this.state.isSpinLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>Generate QR Code</>
                  )}
                </button>
              </div>
            </div>
          )
        )}
        {qrCodeImage && (
          <div className="col-md-12 text-center">
            <img src={qrCodeImage} alt="QR Code" />
          </div>
        )}
      </>
    );
  }
}
