import React, { PureComponent } from "react";
import Header from "../Header/component";
import Footer from "../Footer/component";
import SocialAtLeft from "../SocialAtLeft/component";
import Money from "./../../../images/money.png";
import { get, isEmpty } from "lodash";
import Autocomplete from "react-google-autocomplete";
import Script from "react-load-script";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles.scss";

import { EqualHO, Realtor } from "../../../components/icons";
import ReCAPTCHA from "react-google-recaptcha";
import ReactModal from "react-modal";
import id from "date-fns/esm/locale/id/index.js";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default class SellComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      leadData: {},
      isLoading: false,
      AddressError: false,
      dataError: false,
      unitError: false,
      userId: "",
      isLoading: false,
      message: "",
      image: "",
      error: {},
      onOptionSelect: {},
      existProperty: {},
      unit: "",
      address: "",
      AddressError: "",
      unitError: "",
      zipcode: 0,
      zipcodeError: "",
      cityError: "",
      city: "",
      state: "",
      stateValue: "",
      stateError: "",
      place: {},
      submitLoading: false,
      scriptLoaded: false,
      changeTab: "single-family",
      leadSettingData: {},
      leadPageUserData: {},
      homeData: {},
      isDataCome: false,
      formDATA: {},
      email: "",
      emailNew: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.onSubmitAddress = this.onSubmitAddress.bind(this);
    this.handleChangeManage = this.handleChangeManage.bind(this);
  }

  componentDidMount() {
    let tab = this.props.location.pathname;
    const tabArr = tab.split("/").indexOf("preview");
    if (isEmpty(this.props.leadPageByIdData)) {
      this.props.getLeadPageById({
        id: get(this.props.match, "params.id", ""),
      });
    } else {
      this.setState(
        {
          leadData: this.props.leadPageByIdData,
          leadSettingData: this.props.leadPageSetting,
          leadPageUserData: this.props.leadPageUserData,
        },
        () => {
          if (get(this.state, "leadData.status", "") === "active") {
          } else {
            if (!tabArr === 1) {
              this.props.history.push("/404");
            }
          }
          this.forceUpdate();
        }
      );
    }
  }

  onSubmitAddress(e) {
    e.preventDefault();
    this.setState({ dataError: false, AddressError: false });
    const { address, place, unit, city, stateValue, zipcode } = this.state;
    let state = stateValue;
    let errors = {};
    if (address === "") {
      errors.address = "Please enter address.";
      this.setState({
        AddressError: true,
      });
    }
    if (isEmpty(errors)) {
      this.setState({ isLoading: false, modalIsOpen: true });
    }
  }

  handleChangeManage(value) {
    this.setState({
      changeTab: value,
      address: "",
      place: "",
      unit: "",
      dataError: false,
    });
  }

  setPlace = (place) => {
    this.setState({ AddressError: false, dataError: false });
    this.setState({ address: place });
    if (get(place, "address_components[8].types[0]", "") === "postal_code") {
      this.setState({
        zipcode: get(place, "address_components[8].short_name", ""),
      });
    } else {
      if (get(place, "address_components[7].types[0]", "") === "postal_code") {
        this.setState({
          zipcode: get(place, "address_components[7].short_name", ""),
        });
      } else {
        this.setState({ zipcode: "" });
      }
    }
  };

  setModalIsOpen = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen }, () => {
      this.forceUpdate();
    });
  };

  cancelModal() {
    document.body.classList.remove("ReactModal__Body--open");
    this.setState(
      {
        modalIsOpen: false,
        isLoading: false,
        submitLoading: false,
        recaptchaError: "",
        lastNameError: "",
        firstNameError: "",
        emailError: "",
        phoneError: "",
      },
      () => {
        this.forceUpdate();
      }
    );
  }

  camelCase = (str) => {
    return str
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function (b) {
        return b.toLowerCase();
      });
  };

  onSubmit(event) {
    event.preventDefault();
    this.setState({
      lastNameError: "",
      firstNameError: "",
      emailError: "",
      phoneError: "",
    });
    let multiInput = {};
    let singleInput = {};
    let error = {};
    let formData = {};
    const userId = get(this.state.leadPageUserData, "_id", "");
    const campaignId = get(this.props.match, "params.id", "");
    let target = event.target;
    for (let i = 0; i < target.length; i++) {
      var name = target.elements[i].getAttribute("name");
      var fieldType = target.elements[i].getAttribute("fieldtype");
      if (name) {
        formData[this.camelCase(name)] = target.elements[i].value;
        if (name === "email") {
          if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              target.elements[i].value
            ) &&
            target.elements[i].value !== ""
          ) {
            this.setState({
              emailError: "Please enter valid Email",
            });
            error["email"] = "Please enter valid Email";
          } else if (target.elements[i].value === "") {
            this.setState({
              emailError: "Please Enter Email",
            });
          } else {
            this.setState({
              email: target.elements[i].value,
              emailNew: target.elements[i].value,
            });
          }
        }

        if (target.elements[i].value === "") {
          let newname;
          if (name === "phone") {
            this.setState({
              phoneError: "Please Enter Phone Number",
            });
          }
          // if (name === "email") {
          //   this.setState({
          //     emailError: "Please Enter Email",
          //   });
          // }
          if (name === "firstName") {
            this.setState({
              firstNameError: "Please Enter First Name",
            });
          }
          if (name === "lastName") {
            this.setState({
              lastNameError: "Please Enter Last Name",
            });
          }
          error[name] = "Please Enter " + newname;
          // console.log(error,'error')
        }
      }
    }
    // if (this.state.phone === "") {
    //   this.setState({
    //     phoneError: "Please Enter Phone Number",
    //   });
    //   error["phone"] = "Please enter phone number";
    // } else if (this.state.phone.length <= 9) {
    //   this.setState({
    //     phoneError: "Please enter valid Number",
    //   });
    //   error["phone"] = "Please enter valid phone number";
    // }
    //21-42 81st Street, East Elmhurst, Queens, NY 11370, USA
    if (!this.state.recaptcha) {
      this.setState({ recaptchaError: "Please select re-captcha" });
    }
    this.setState({ error });
    if (isEmpty(error)) {
      document.body.classList.remove("ReactModal__Body--open");
      const { address, place, unit, city, stateValue, zipcode } = this.state;
      this.setState({ submitLoading: true });
      if (!isEmpty(address)) {
        this.props.getAddressDetails({
          address: address,
          unit: unit,
          zipcode: zipcode,
        });
      } else {
        // this.props.getAddressDetails({
        //   address: address,
        //   unit: unit,
        //   zipcode: zipcode,
        // });
      }
      this.setState({ homeData: this.props.getAddressDetailsData[0] });
      let price = parseInt(
        get(this.state.homeData, "avm.amount.value", 0)
      ).toLocaleString("en-US");
      let valuationPrice = "$" + price;
      this.setState({ isLoading: false, submitLoading: true });
      formData.email = this.state.emailNew;
      formData.userId = userId;
      formData.campaignId = campaignId;
      formData.isFrom = "sell";
      formData.campaignName = get(
        this.props.leadPageByIdData,
        "campaignName",
        ""
      );
      formData.valuationPrice = valuationPrice.toString();
      formData.address = `${get(
        this.state.homeData,
        "address.line1",
        ""
      )}${", "}${get(
        this.state.homeData,
        "area.countrysecsubd",
        ""
      )}${", "}${get(
        this.state.homeData,
        "address.countrySubd",
        ""
      )}${", "}${get(this.state.homeData, "address.postal1", "")}`;
      formData.websiteUrl = `/sell/${get(
        this.props.leadPageByIdData,
        "slugUrl",
        ""
      )}/${get(this.props.leadPageByIdData, "_id", "")}`;
      formData.type = this.props.location.pathname.split("/")[2];
      formData.phone = "";
      // this.props.addInquiry(formData);
      this.setState({ formDATA: formData });
    }
  }

  openNotificationWithIcon = (type) => {
    notification[type]({
      key: "updatable",
      className: "cm-notification",
      description:
        "Thank you for your interest in this property. Someone from our team will reach out to you shortly.",
    });
  };

  componentDidUpdate(prev) {
    let id = get(this.props.match, "params.id", "");
    let tab = this.props.location.pathname;
    const tabArr = tab.split("/").indexOf("preview");
    if (this.props.leadPageByIdPhase === "success") {
      this.props.initLeadPage();
      this.setState(
        {
          leadData: this.props.leadPageByIdData,
          leadSettingData: this.props.leadPageSetting,
          leadPageUserData: this.props.leadPageUserData,
        },
        () => {
          let title =
            get(this.props.leadPageByIdData, "seo.metaTitle", "Sell Page") !==
            ""
              ? get(this.props.leadPageByIdData, "seo.metaTitle", "Sell Page")
              : "Sell Page";
          let companyName =
            get(this.props.leadPageUserData, "companyName", "Yardpost") !== ""
              ? get(this.props.leadPageUserData, "companyName", "Yardpost")
              : "Yardpost";
          document.title = title + " | " + companyName;
          document.getElementById("favicon").href = get(
            this.props.leadPageSetting,
            "favicon",
            "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png"
          );
          if (get(this.state, "leadData.status", "") === "active") {
          } else {
            if (!tabArr === 1) {
              this.props.history.push("/404");
            }
          }
          this.forceUpdate();
        }
      );
    } else if (this.props.leadPageByIdPhase === "error") {
      this.props.history.push("/404");
    }

    if (this.props.addInquiryPhase == true) {
      this.props.initLeadPage();
      this.openNotificationWithIcon("success");
      if (
        get(this.props, "location.pathname", "")
          .split("/")
          .indexOf("preview") !== -1
      ) {
        this.props.history.push({
          pathname: `/preview/sell-detail/${id}`,
          state: { key: "sellDetail" },
        });
      } else if (
        get(this.props, "location.pathname", "")
          .split("/")
          .indexOf("edit-lead-page") !== -1
      ) {
        this.props.history.push({
          pathname: `/preview/sell-detail/${id}`,
          state: { key: "sellDetail" },
        });
      } else {
        this.props.history.push({
          pathname: `/sell-detail/${id}`,
          state: { liveKey: "sellDetail" },
        });
      }
      this.setState({
        isLoading: false,
        AddressError: false,
        dataError: false,
        unitError: false,
      });
      this.setState(
        {
          submitLoading: false,
          isLoading: false,
          message: this.props.addInquiryMessage,
          phone: "",
          // email: "",
          firstName: "",
          lastName: "",
          countryCode: "",
          recaptcha: false,
          modalIsOpen: false,
        },
        () => {
          this.forceUpdate();
        }
      );
    } else if (!this.props.addInquiryPhase) {
      this.setState(
        {
          isLoading: false,
          emailError: this.props.addInquiryMessage,
        },
        () => {
          this.forceUpdate();
        }
      );
    }

    if (this.props.getAddressDetailsPhase === "success") {
      this.props.initLeadPage();
      this.setState(
        {
          isLoading: false,
          AddressError: false,
          dataError: false,
          isDataCome: true,
          unitError: false,
          homeData: this.props.getAddressDetailsData[0],
        },
        () => {
          const userId = get(this.state.leadPageUserData, "_id", "");
          const campaignId = get(this.props.match, "params.id", "");
          let { formDATA } = this.state;
          let formData = formDATA;
          let price = parseInt(
            get(this.state.homeData, "avm.amount.value", 0)
          ).toLocaleString("en-US");
          let valuationPrice = "$" + price;
          formData.email = this.state.emailNew;
          formData.userId = userId;
          formData.campaignId = campaignId;
          formData.isFrom = "sell";
          formData.campaignName = get(
            this.props.leadPageByIdData,
            "campaignName",
            ""
          );
          formData.valuationPrice = valuationPrice.toString();
          formData.address = `${get(
            this.state.homeData,
            "address.line1",
            ""
          )}${", "}${get(
            this.state.homeData,
            "area.countrysecsubd",
            ""
          )}${", "}${get(
            this.state.homeData,
            "address.countrySubd",
            ""
          )}${", "}${get(this.state.homeData, "address.postal1", "")}`;
          formData.websiteUrl = `/sell/${get(
            this.props.leadPageByIdData,
            "slugUrl",
            ""
          )}/${get(this.props.leadPageByIdData, "_id", "")}`;
          formData.type = this.props.location.pathname.split("/")[2];
          formData.phone = "";
          this.props.addInquiry(formData);
        }
      );
    }
    console.log("before address error");
    if (this.props.getAddressDetailsPhase === "error") {
      console.log("getAddressphase error");
      this.props.initLeadPage();
      this.setState({
        isLoading: false,
        modalIsOpen: false,
        firstName: "",
        lastName: "",
        phone: "",
        // email: "",
        recaptcha: false,
        submitLoading: false,
        dataError: true,
        AddressError: false,
      });
    }
  }

  handleChange = (e) => {
    if ((e.target.name = "unit")) {
      this.setState({
        unit: e.target.value,
      });
    }
  };

  render() {
    const {
      leadData,
      isLoading,
      cityError,
      stateError,
      AddressError,
      zipcodeError,
      unitError,
      dataError,
      changeTab,
      leadPageUserData,
      leadSettingData,
      submitLoading,
    } = this.state;
    const { leadPageSetting } = this.props;

    return (
      <>
        {isEmpty(leadData) ? (
          <Spin
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: "#000" }} spin />
            }
            className="loader__full"
          />
        ) : (
          <>
            <main className="listing__website--main">
              <Header {...this.state} {...this.props} />
              <section className="section__sell">
                <div className="sell__row">
                  <div
                    className="sell__col--left"
                    style={{
                      background: get(leadData, "backgroundColor.hex", ""),
                    }}
                  ></div>
                  <div className="sell__wrapper">
                    <div className="sell__text--content">
                      {get(leadData, "showHeading", false) && (
                        <h2
                          style={{ color: get(leadData, "textColor.hex", "") }}
                          className="sell__text--title"
                        >
                          <pre>{get(leadData, "heading", "")}</pre>
                        </h2>
                      )}
                      {get(leadData, "showSubHeading", false) && (
                        <p
                          style={{ color: get(leadData, "textColor.hex", "") }}
                          className="sell__text--subtitle"
                        >
                          <pre>{get(leadData, "subHeading", "")}</pre>
                        </p>
                      )}
                    </div>

                    <div className="sell__form">
                      <div className="sell__card--tabs">
                        <button
                          className={
                            changeTab === "single-family"
                              ? "btn btn__tabs active "
                              : "btn btn__tabs"
                          }
                          onClick={() =>
                            this.handleChangeManage("single-family")
                          }
                        >
                          Single Family Home
                        </button>
                        <button
                          className={
                            changeTab === "condo"
                              ? "btn btn__tabs active"
                              : "btn btn__tabs"
                          }
                          onClick={() => this.handleChangeManage("condo")}
                        >
                          ConDO
                        </button>
                      </div>
                      <div className="sell__card">
                        <div className="sell__form--wrap">
                          {changeTab === "single-family" && (
                            <div className="sell__form--col">
                              <label className="label-secondary">
                                STREET ADDRESS
                              </label>
                              <Autocomplete
                                className="form-control form-control-flat"
                                name="address"
                                title="Address Name, City, State, 00000"
                                placeholder="Address Name, City, State, 00000"
                                defaultValue={this.state.address}
                                onPlaceSelected={(place) => {
                                  this.setPlace(place, "place");
                                }}
                                onBlur={(e) => {
                                  e.target.value !== ""
                                    ? this.setState({ address: e.target.value })
                                    : this.setState({ address: "" });
                                }}
                                types={["geocode"]}
                                required
                              />
                              {AddressError && (
                                <div className="invalid-feedback">
                                  Please enter address.
                                </div>
                              )}
                              {dataError && (
                                <div className="invalid-feedback">
                                  Address not found.
                                </div>
                              )}
                            </div>
                          )}
                          {changeTab === "condo" && (
                            <>
                              <div className="sell__form--col">
                                <label className="label-secondary">
                                  STREET ADDRESS
                                </label>
                                <Autocomplete
                                  className="form-control form-control-flat"
                                  name="address"
                                  title="Address Name, City, State, 00000"
                                  placeholder="Address Name, City, State, 00000"
                                  // value={this.state.address}
                                  defaultValue={this.state.address}
                                  onPlaceSelected={(place) =>
                                    this.setPlace(place)
                                  }
                                  onBlur={(e) =>
                                    e.target.value !== ""
                                      ? this.setState({
                                          address: e.target.value,
                                        })
                                      : this.setState({ address: "" })
                                  }
                                  types={["geocode"]}
                                  required
                                />
                                {AddressError && (
                                  <div className="invalid-feedback">
                                    Please enter address.
                                  </div>
                                )}
                                {dataError && (
                                  <div className="invalid-feedback">
                                    Address not found.
                                  </div>
                                )}
                              </div>

                              <div className="sell__form--col sell__form--col-last">
                                <label className="label-secondary">
                                  Unit #
                                </label>
                                <input
                                  type="text"
                                  name="unit"
                                  value={this.state.unit}
                                  placeholder="Unit"
                                  className="form-control form-control-flat"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="sell__form--submit">
                          <button
                            onClick={this.onSubmitAddress}
                            disabled={isLoading}
                            className="btn btn__flat btn__flat--dark btn__valuation"
                          >
                            {isLoading ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              <>
                                <span className="btn__icon--left">
                                  <img src={Money} width={26} />
                                </span>
                                See my Properties Valuation
                              </>
                            )}
                          </button>
                        </div>
                      </div>

                      {/* <div className="sell__footer">
                      <div className="sell__footer--copyrights">
                        © 2022 {get(leadPageUserData, "companyName", "")}
                      </div>
                      <ul className="sell__mls--list">
                        {get(leadPageSetting, "showHousingLogo", false) && (
                          <li>
                            <EqualHO/>
                          </li>
                          )
                        }
                        {get(leadPageSetting, "showMlsLogo", false) && (
                          <li>
                            <Realtor/>
                          </li>
                          )
                        }
                      </ul>
                    </div>*/}
                    </div>
                  </div>
                  <div className="sell__col--right">
                    {get(leadData, "image", "") !== "" && (
                      <div className="sell__thumb">
                        <img src={get(leadData, "image", "")} alt="" />
                      </div>
                    )}
                  </div>
                </div>
              </section>
              <ReactModal
                isOpen={this.state.modalIsOpen}
                contentLabel="One More Step"
                ariaHideApp={false}
                onRequestClose={this.cancelModal}
                shouldCloseOnOverlayClick={false}
                shouldFocusAfterRender={true}
                portalClassName="react-modal"
                overlayClassName="modal"
                className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
              >
                <div className="modal-content modal-content-flat">
                  <div className="react-modal-header">
                    <h2 className="react-modal-title react-modal-title-style-2">
                      One More Step
                    </h2>
                    <h5 className="react-modal-subtitle react-modal-subtitle-style-2">
                      Verify that this is your address
                    </h5>
                  </div>
                  <div className="react-modal-body react-modal-body-style-2">
                    <form onSubmit={this.onSubmit.bind(this)} noValidate>
                      <div className="sell__detail--form">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label className="label-secondary">
                                First Name <span class="required">*</span>
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                value={this.state.firstName}
                                onChange={(e) =>
                                  this.setState({ firstName: e.target.value })
                                }
                                className="form-control form-control-flat material-textfield-input"
                                required
                              />
                              {this.state.firstNameError !== "" && (
                                <div className="invalid-feedback">
                                  {this.state.firstNameError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label className="label-secondary">
                                Last Name <span class="required">*</span>
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                value={this.state.lastName}
                                onChange={(e) =>
                                  this.setState({ lastName: e.target.value })
                                }
                                autoComplete="false"
                                className="form-control form-control-flat material-textfield-input"
                                required
                              />
                              {this.state.lastNameError !== "" && (
                                <div className="invalid-feedback">
                                  {this.state.lastNameError}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label className="label-secondary">
                            Email Address <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                            autoComplete="false"
                            className="form-control form-control-flat material-textfield-input"
                            required
                          />
                          {this.state.emailError !== "" && (
                            <div className="invalid-feedback">
                              {this.state.emailError}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <ReCAPTCHA
                            ref={(r) => (this.captcha = r)}
                            sitekey="6Lc2BMQZAAAAAARE4jzuMJTQSjPIZLCGX_bNOtUc"
                            onChange={() => {
                              this.setState(
                                { recaptcha: true, recaptchaError: "" },
                                () => {
                                  this.forceUpdate();
                                }
                              );
                              this.onChange();
                            }}
                          />
                          {!this.state.recaptcha && (
                            <div className="invalid-feedback">
                              {this.state.recaptchaError}
                            </div>
                          )}
                        </div>
                        <button className="btn btn__flat btn__flat--dark w-100">
                          {submitLoading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            "Confirm"
                          )}
                        </button>
                        <button
                          type="button"
                          onClick={this.cancelModal.bind(this)}
                          className="btn btn__flat btn__flat--link w-100"
                        >
                          {"Cancel"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </ReactModal>
              {/* <Footer {...this.state} {...this.props} /> */}
              {/* <SocialAtLeft {...this.state} {...this.props} /> */}
            </main>
          </>
        )}
      </>
    );
  }
}
