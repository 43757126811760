import React, { PureComponent } from "react";
import { Close } from "../../../components/icons";
import CreateComponent from "./Create/container";
import Edit from "./Edit/component";
import PreviewTemplate from "./PreviewTemplate/component";
import DemoTemplate from "./DemoTemplate/component";
import ReactModal from "react-modal";
import { isEmpty, get, property, includes } from "lodash";
import { Formik } from "formik";
import "./styles.scss";
import { notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "cropperjs/dist/cropper.css";
import NumberFormat from "react-number-format";
import axios from "axios";
import $ from "jquery";
let cancelToken;
const antIconDark = (
  <LoadingOutlined style={{ fontSize: 24, color: "#000" }} spin />
);
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
export default class CampaignComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      isLead: false,
      information: false,
      isSpinLoading: false,
      userData: {},
      publishDate: "",
      socialLinkTemplate: {
        campaignType: "social-link",
        campaignName: "",
        heading: "",
        subHeading: "",
        isQrCode: true,
        qrCodeImage: "",
        textColor: {
          hex: "#ffffff",
          rgb: {
            r: "255",
            g: "255",
            b: "255",
            a: "1",
          },
        },
        backgroundColor: {
          hex: "#000000",
          rgb: {
            r: "0",
            g: "0",
            b: "0",
            a: "1",
          },
        },
        image: "",
        logo: "https://res.cloudinary.com/duatl7ntm/image/upload/v1710751654/2224_1_zszykj.png",
        desktopLogoSize: 50,
        mobileLogoSize: 50,
        isHeadingShow: false,
        isSubHeadingShow: false,
        seo: {
          metaTitle: "",
          metaDescription: "",
          urlHandle: "",
        },
        showHeading: false,
        showSubHeading: false,
        slugUrl: "",
        propertySlugUrl: "",
        status: "active",
      },
      sellTemplate: {
        campaignType: "sell",
        campaignName: "",
        heading: "Ready to see how much your home is worth ?",
        subHeading:
          "Whether you're ready to sell or looking for answers, we'll guide you with data and expertise specific to your area.",
        textColor: {
          hex: "#ffffff",
          rgb: {
            r: "255",
            g: "255",
            b: "255",
            a: "1",
          },
        },
        backgroundColor: {
          hex: "#000000",
          rgb: {
            r: "0",
            g: "0",
            b: "0",
            a: "1",
          },
        },
        image:
          "https://res.cloudinary.com/duatl7ntm/image/upload/v1710751701/Rectangle_46_1_hlek0o.jpg",
        logo: "https://res.cloudinary.com/duatl7ntm/image/upload/v1710751654/2224_1_zszykj.png",
        desktopLogoSize: 50,
        mobileLogoSize: 50,
        isHeadingShow: false,
        isSubHeadingShow: false,
        isQrCode: true,
        qrCodeImage: "",
        seo: {
          metaTitle: "",
          metaDescription: "",
          urlHandle: "",
        },
        showHeading: true,
        showSubHeading: true,
        slugUrl: "",
        propertySlugUrl: "",
        status: "active",
      },
      listingWebsiteTemplate: {
        campaignType: "listing-website",
        campaignName: "",
        heading: "",
        subHeading: "",
        background: "#ffffff",
        textColor: {
          hex: "#000000",
          rgb: {
            r: "0",
            g: "0",
            b: "0",
            a: "1",
          },
        },
        backgroundColor: {
          hex: "#ffffff",
          rgb: {
            r: "255",
            g: "255",
            b: "255",
            a: "1",
          },
        },
        showHeading: true,
        showSubHeading: true,
        isQrCode: true,
        qrCodeImage: "",
        image:
          "https://res.cloudinary.com/duatl7ntm/image/upload/v1710751763/Rectangle_378_qhutib.jpg",
        logo: "https://res.cloudinary.com/duatl7ntm/image/upload/v1710751654/2224_1_zszykj.png",
        desktopLogoSize: 50,
        mobileLogoSize: 50,
        isHeadingShow: false,
        isSubHeadingShow: false,
        seo: {
          metaTitle: "",
          metaDescription: "",
          urlHandle: "",
        },
        propertySlugUrl: "",
        slugUrl: "",
        status: "active",
        testimonial:{
          title:"My Reviews",
          dataList:[]
        },
        myWork:{
          title:"My Work",
          dataList:[]
        },
        pdfData: [],
        videosData: []
      },
      campaignType: "social-link",
      // campaignType: "sell",
      isQrCode: true,
      qrCodeImage: "",
      campaignName: "",
      campaignId: "",
      leadPageData: {},
      isData: false,
      isLoading: false,
      activeTab: "listing-details",
      editorTab: "",
      seoTab: "",
      listingDetailsTab: "",
      qRCodeTab: "",
      imageLoading: "",
      imageLoadingKey: "",
      rotateLoading: "",
      preImage: {},
      cropImg: {},
      imagesSet: false,
      imagePickerisOpen: false,
      cropper: {},
      fullImage: {},
      isListingWebsite: false,
      listingData: [],
      selectValue: "0",
      publishLeadPageData: {},
      isMessageShow: "",
      isPublishLoading: false,
      isSaveAndExitLoading: false,
      campaignNameError: "",
      selectValueError: "",
      isDraft: false,
      logoLoading: false,
      imageLoading: false,
      isDataSet: false,
      imageError: false,
      logoError: false,
      isActive: false,
      isEditSite: true,
      isPreviewSite: false,
      isUpdate: false,
      isCampaignType: "",
      isSocialLeadExist: false,
    };
    this.changeTab = this.changeTab.bind(this);
    this.changeCampaignType = this.changeCampaignType.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount = async () => {
    if (get(this.props.match, "params.id", "")) {
      this.props.publishLeadPageById({
        id: get(this.props.match, "params.id", ""),
      });
    }

    if (isEmpty(this.props.user)) {
      this.props.getUser();
    } else {
      this.setState({ userData: this.props.user.user }, () =>
        this.forceUpdate()
      );
      let payload = {
        id: get(this.props.user, "user._id", ""),
        type: "social-link",
      };
      //console.log(payload, "bsdffd");

      const { value } = await this.props.getSocialLeadPageExist(payload);
      // if (value?.success) {
      //   this.setState(
      //     {
      //       isCampaignType: value?.data[0]?.campaignType,
      //       isSocialLeadExist: true,
      //     },
      //     () => this.forceUpdate()
      //   );
      //   if (value?.data[0]?.campaignType === "social-link") {
      //     this.setState(
      //       {
      //         campaignType: "sell",
      //       },
      //       () => this.forceUpdate()
      //     );
      //   }
      // }
    }
    if (get(this.props.match, "params.id", "")) {
      this.props.getLeadPageById({
        id: get(this.props.match, "params.id", ""),
      });
      let tab = this.props.location.pathname;
      let checkSellTab = `/edit-landing-page/sell/site-editor/${get(
        this.props.match,
        "params.id",
        ""
      )}`;
      let checkSellSeoTab = `/edit-landing-page/sell/seo/${get(
        this.props.match,
        "params.id",
        ""
      )}`;
      let checkSellQrCodeTab = `/edit-landing-page/sell/QR-Code/${get(
        this.props.match,
        "params.id",
        ""
      )}`;
      let checkListingTab = `/edit-landing-page/listing-website/site-editor/${get(
        this.props.match,
        "params.id",
        ""
      )}`;
      let checkListingSeoTab = `/edit-landing-page/listing-website/seo/${get(
        this.props.match,
        "params.id",
        ""
      )}`;
      let checkListingDetailsTab = `/edit-landing-page/listing-website/listing-details/${get(
        this.props.match,
        "params.id",
        ""
      )}`;
      let checkListingQrCodeTab = `/edit-landing-page/listing-website/QR-Code/${get(
        this.props.match,
        "params.id",
        ""
      )}`;
      let checkSocialQrCodeTab = `/edit-social-link/QR-Code/${get(
        this.props.match,
        "params.id",
        ""
      )}`;
      let checkSocialEditorTab = `/edit-social-link/site-editor/${get(
        this.props.match,
        "params.id",
        ""
      )}`;
      let checkSocialSeoTab = `/edit-social-link/seo/${get(
        this.props.match,
        "params.id",
        ""
      )}`;
      if (tab === checkListingTab) {
        this.setState({
          listingDetailsTab: `/edit-landing-page/listing-website/listing-details/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          editorTab: `/edit-landing-page/listing-website/site-editor/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          seoTab: `/edit-landing-page/listing-website/seo/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          qRCodeTab: `/edit-landing-page/listing-website/QR-Code/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          activeTab: "editor",
          campaignType: "listing-website",
        });
        this.props.history.push(checkListingTab);
      } else if (tab == checkListingDetailsTab) {
        this.setState({
          listingDetailsTab: `/edit-landing-page/listing-website/listing-details/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          editorTab: `/edit-landing-page/listing-website/site-editor/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          seoTab: `/edit-landing-page/listing-website/seo/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          qRCodeTab: `/edit-landing-page/listing-website/QR-Code/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          activeTab: "listing-details",
          campaignType: "listing-website",
        });
        this.props.history.push(checkListingDetailsTab);
      } else if (tab === checkListingQrCodeTab) {
        this.setState({
          listingDetailsTab: `/edit-landing-page/listing-website/listing-details/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          editorTab: `/edit-landing-page/listing-website/site-editor/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          seoTab: `/edit-landing-page/listing-website/seo/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          qRCodeTab: `/edit-landing-page/listing-website/QR-Code/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          activeTab: "QR-Code",
          campaignType: "listing-website",
        });
        this.props.history.push(checkListingQrCodeTab);
      } else if (tab === checkSellTab) {
        this.setState({
          editorTab: `/edit-landing-page/sell/site-editor/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          seoTab: `/edit-landing-page/sell/seo/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          qRCodeTab: `/edit-landing-page/sell/QR-Code/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          activeTab: "editor",
          campaignType: "sell",
        });
        this.props.history.push(checkSellTab);
      } else if (tab === checkSellQrCodeTab) {
        this.setState({
          editorTab: `/edit-landing-page/sell/site-editor/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          seoTab: `/edit-landing-page/sell/seo/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          qRCodeTab: `/edit-landing-page/sell/QR-Code/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          activeTab: "QR-Code",
          campaignType: "sell",
        });
        this.props.history.push(checkSellQrCodeTab);
      } else if (tab === checkListingSeoTab) {
        this.setState({
          editorTab: `/edit-landing-page/listing-website/site-editor/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          seoTab: `/edit-landing-page/listing-website/seo/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          qRCodeTab: `/edit-landing-page/listing-website/QR-Code/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          activeTab: "seo",
          campaignType: "listing-website",
        });
        this.props.history.push(checkListingSeoTab);
      } else if (tab === checkSellSeoTab) {
        this.setState({
          editorTab: `/edit-landing-page/sell/site-editor/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          seoTab: `/edit-landing-page/sell/seo/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          qRCodeTab: `/edit-landing-page/sell/QR-Code/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          activeTab: "seo",
          campaignType: "sell",
        });
        this.props.history.push(checkSellSeoTab);
      } else if (tab === checkSocialQrCodeTab) {
        this.setState({
          editorTab: `/edit-social-link/site-editor/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          seoTab: `/edit-social-link/seo/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          qRCodeTab: `/edit-social-link/QR-Code/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          activeTab: "QR-Code",
          campaignType: "social-link",
        });
        this.props.history.push(checkSocialQrCodeTab);
      } else if (tab === checkSocialEditorTab) {
        this.setState({
          editorTab: `/edit-social-link/site-editor/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          seoTab: `/edit-social-link/seo/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          qRCodeTab: `/edit-social-link/QR-Code/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          activeTab: "editor",
          campaignType: "social-link",
        });
        this.props.history.push(checkSocialEditorTab);
      } else if (tab === checkSocialSeoTab) {
        this.setState({
          editorTab: `/edit-social-link/site-editor/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          seoTab: `/edit-social-link/seo/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          qRCodeTab: `/edit-social-link/QR-Code/${get(
            this.props.match,
            "params.id",
            ""
          )}`,
          activeTab: "seo",
          campaignType: "social-link",
        });
        this.props.history.push(checkSocialSeoTab);
      }
      this.setState({
        isData: true,
        campaignId: get(this.props.match, "params.id", ""),
      });
    } else {
      let tab = this.props.location.pathname;
      if (tab === "/create-new-landing-page/listing-website") {
        this.props.getListings();
        this.props.history.push("/create-new-landing-page/listing-website");
        this.setState(
          { campaignType: "listing-website", activeTab: "listing-details" },
          () => {
            this.forceUpdate();
          }
        );
      } else if (tab === "/create-new-landing-page/sell") {
        this.props.history.push("/create-new-landing-page/sell");
        this.setState({ campaignType: "sell", activeTab: "editor" });
      } else {
        if (this.state.isCampaignType === "social-link") {
          this.props.history.push("/create-new-landing-page/sell");
          this.setState({ campaignType: "sell", activeTab: "editor" }, () =>
            this.forceUpdate()
          );
        } else {
          this.props.history.push("/create-social-link");
          this.setState({ campaignType: "social-link", activeTab: "editor" });
        }
      }
    }
    this.setState({
      isLead: get(this.props.user, "user.isLead", true),
      information: get(this.props.user.user, "information", false),
    });
  };

  onTabChange(value, seoUrl, editorUrl, listingdetailsUrl = "", qRcodeUrl) {
    const { activeTab } = this.state;
    if (activeTab !== value) {
      this.setState({ activeTab: value }, () => {
        if (value === "seo") {
          this.props.history.push(seoUrl);
        } else if (value === "editor") {
          this.props.history.push(editorUrl);
        } else if (value === "listing-details") {
          this.props.getLeadPageById({
            id: get(this.props.match, "params.id", ""),
          });
          this.props.history.push(listingdetailsUrl);
        } else if (value === "QR-Code") {
          this.props.history.push(qRcodeUrl);
        }
        this.forceUpdate();
      });
    }
  }

  uploadCropImage = async(key) => {
    // console.log(key,'key')
    let self = this;
    self.setState({ imageLoading: key });
    self.forceUpdate();
    const { cropImg, fullImage } = self.state;
    // let form_data = new FormData();
    // form_data.append("key", key);
    // form_data.append("images[]", cropImg);
    // form_data.append(`numOfImage`, 1);
   const {value} = await self.props.uploadImages(cropImg);
   this.setState({listingWebsiteTemplate: {...this.state.listingWebsiteTemplate, [key]: value}})
   this.props.createLeadPage({...this.props.leadPageByIdData, [key]: value})
    setTimeout(() => {
      self.setState({ imageLoading: "", imageLoadingKey:"" });
    }, 2000);
  };

  dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  handleChangeListing = (e) => {
    const { listingWebsiteTemplate } = this.state;
    listingWebsiteTemplate.propertySlugUrl = e.target.value;
    this.setState(
      { selectValue: e.target.value, listingWebsiteTemplate },
      () => {
        this.forceUpdate();
        this.onSave();
      }
    );
  };

  imageRotate = (key, image) => {
    this.setState({ rotateLoading: key });
    let uniqueCode = Math.floor(1000 + Math.random() * 9000);
    let self = this;
    let screenwidth = 400;
    let imageName = "rotatedimage";
    const TempImage = window.Image;
    let imgurl = image + "?t=" + uniqueCode;
    //create a canvas
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    //create a image
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imgurl;
    //on load image rotate image
    img.onload = async function () {
      img.crossOrigin = "Anonymous";
      let maxsize = screenwidth;
      let w = maxsize;
      let ratio = img.width / w;
      let h = img.height / ratio;
      canvas.width = h;
      canvas.height = w;
      ctx.translate(w - h, w);
      ctx.rotate((-90 * Math.PI) / 180);
      ctx.translate(0, -(w - h));
      ctx.drawImage(img, 0, 0, w, h);
      ctx.save();
      let imgurl = canvas.toDataURL();
      // let form_data = new FormData();
      // form_data.append("key", key);
      // form_data.append("images[]", self.dataURLtoFile(imgurl, imageName));
      // form_data.append(`numOfImage`, 1);
      const {value} = await self.props.uploadImages(self.dataURLtoFile(imgurl, imageName));
      self.setState({listingWebsiteTemplate: {...self.state.listingWebsiteTemplate, [key]: value}})
      self.props.createLeadPage({...self.props.leadPageByIdData, [key]: value})
      self.setState({ rotateLoading: "", imageLoadingKey:"" },()=> self.forceUpdate());
    };
  };

  changeTab(value) {
    if (value === "edit") {
      this.setState(
        { isEditSite: true, isPreviewSite: false, isUpdate: true },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      this.setState(
        { isEditSite: false, isPreviewSite: true, isUpdate: true },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  onChangeCropImage = (key, image) => {
    let uniqueCode = Math.floor(1000 + Math.random() * 9000);
    let self = this;
    let screenwidth = 400;
    //make data url of selected image
    let imageName = "";
    const TempImage = window.Image;
    let imgurl = image + "?t=" + uniqueCode;
    //create a canvas
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    //create a image
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imgurl;
    //on load image rotate image
    img.onload = function () {
      img.crossOrigin = "Anonymous";
      let maxsize = screenwidth;
      let w = maxsize;
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      ctx.save();
      let imgurl = canvas.toDataURL();
      let form_data = new FormData();
      self.setState({
        imageLoadingKey: key,
        preImage: URL.createObjectURL(self.dataURLtoFile(imgurl, imageName)),
      });
      let height = 0;
      let width = 0;
      let maxHeight = 0;
      let maxWidth = 0;
      if (key === "logo") {
        height = 50;
        width = 50;
        maxHeight = 800;
        maxWidth = 1400;
      } else {
        height = 50;
        width = 50;
        maxHeight = 800;
        maxWidth = 1400;
      }
      // setTimeout(() => {
      //   const { cropper } = self.state
      //   console.log(cropper.cropper,'cropper.console')
      //   cropper.cropper.cropBoxData.maxWidth = maxWidth;
      //   cropper.cropper.cropBoxData.maxHeight = maxHeight;
      //   cropper.cropper.cropBox.style.height = height + "px";
      //   cropper.cropper.cropBox.style.width = width + "px";
      //   cropper.zoomTo(1);
      // }, 500);
    };
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  _crop = (e, cropper) => {
    this.setState({ cropper });
    // image in dataUrl
    clearInterval(this.cropImg);
    this.cropImg = setInterval(() => {
      if (
        cropper.getCroppedCanvas() &&
        "toDataURL" in cropper.getCroppedCanvas()
      ) {
        let toDataURL = cropper.getCroppedCanvas().toDataURL();
        const file = this.dataURLtoFile(toDataURL, "");
        this.setState({ cropImg: file });
      }
      clearInterval(this.cropImg);
    }, 500);
  };

  cancelCropImage = () => {
    this.setState({ imageLoadingKey: "", preImage: {} }, () => {
      this.forceUpdate();
    });
  };

  handleChangeSize = (val, name) => {
    let { listingWebsiteTemplate } = this.state;
    if (name === "desktopLogoSize") {
      listingWebsiteTemplate.desktopLogoSize = String(val);
    } else {
      listingWebsiteTemplate.mobileLogoSize = String(val);
    }
    this.setState({ listingWebsiteTemplate }, () => {
      this.forceUpdate();
      this.onSave();
    });
  };

  removeImage = (name) => {
    let { listingWebsiteTemplate } = this.state;
    if (name === "logo") {
      listingWebsiteTemplate.logo = "";
    } else {
      listingWebsiteTemplate.image = "";
    }
    this.setState({ listingWebsiteTemplate }, () => {
      this.forceUpdate();
      this.onSave();
    });
  };

  openNotificationWithIcon = (type, msg) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: msg,
    });
  };

  componentDidUpdate = async (prev) => {
    document.title = "Create Social Links | Yardpost ";
    const { isDataSet } = this.state;
    if (!this.state.imagesSet) {
      let self = this;
      $(".checkImageWidth").each(function () {
        self.setState({ imagesSet: true }, () => {
          var $this = $(this);
          if ($this.prop("naturalWidth") > $this.prop("naturalHeight")) {
            // if ($this.width() > 767) {
            $this.removeClass("vertical");
            $this.addClass("horizontal");
            // console.log("calss is horizontal")
          } else {
            $this.addClass("vertical");
            $this.removeClass("horizontal");
            // console.log("calss is vertical")
          }
        });
      });
    }
    if (this.props.publishLeadPageByIdPhase === "success") {
      this.props.initLeadPage();
      this.setState({
        publishDate: get(this.props.publishLeadPageByIdData, "updatedAt", ""),
      });
    }
    if (this.props.editUserPhase === "success") {
      this.props.resetLogin();
      this.props.getUser();
      // this.openNotificationWithIcon(
      //   "success",
      //   "Updated Informations successfully "
      // );
      this.setState({ isLead: true, isSpinLoading: false, information: true });
    }
    if (this.props.editUserPhase === "error") {
      this.props.resetLogin();
      // this.openNotificationWithIcon("error", "Something went wrong");
      this.setState({ isLead: true, isSpinLoading: false, information: true });
    }
    if (this.props.userPhase === "success") {
      this.props.resetLogin();
      this.props.resetLoginUser();
      this.setState(
        { userData: this.props.user.user, information: true },
        () => {
          this.forceUpdate();
        }
      );
    }
    if (this.props.createLeadPagePhase === "success") {
      this.props.initLeadPage();
      if (this.state.isDraft) {
        this.setState(
          {
            isSaveAndExitLoading: false,
            isDraft: false,
            campaignNameError: "",
          },
          () => {
            this.forceUpdate();
            this.props.history.push("/social-links");
          }
        );
        this.openNotificationWithIcon(
          "success",
          "Your Listing landing page is drafted successfully"
        );
      } else if (this.state.isActive) {
        this.setState(
          {
            isSaveAndExitLoading: false,
            isActive: false,
            campaignNameError: "",
          },
          () => {
            this.forceUpdate();

            this.props.history.push("/social-links");
          }
        );
        this.openNotificationWithIcon(
          "success",
          "Your Listing landing page is saved successfully"
        );
      }
      // else{
      //   this.setState({ isSaveAndExitLoading: false, isDraft: false, campaignNameError: '' },()=>{
      //     this.props.history.push("/links");
      //   });
      //   this.openNotificationWithIcon(
      //     "success",
      //     "Your Listing landing page is saved successfully"
      //   );
      // }
      this.setState({ campaignId: this.props.createLeadPageData.id });
      this.props.getLeadPageById({ id: this.props.createLeadPageData.id });
    } else if (this.props.createLeadPagePhase === "new_error") {
      this.props.initLeadPage();
      this.setState({
        campaignNameError: "Link name already exist",
        isLoading: false,
      });
      this.openNotificationWithIcon(
        "error",
        "You have already created your social link."
      );
    }
    if (this.props.publishLeadPagePhase === "success") {
      this.props.initLeadPage();
      this.setState({
        publishLeadPageData: this.props.publishLeadPageData,
        isPublishLoading: false,
      });
      this.openNotificationWithIcon(
        "success",
        "Your site published successfully!"
      );
      this.props.history.push("/social-links");
    }
    // if (this.props.publishSitePhase === 'success') {
    //   this.props.initTemplate()
    // }
    // if (this.props.publishSitePhase === 'error' && isEmpty(this.props.publishSiteData)) {
    //   this.props.initTemplate()
    //   if (get(this.props, 'user.user', '') !== '') {
    //     if ((get(this.props, 'user.user.domain', '') !== '' && !isEmpty(get(this.props, 'user.user.domainInfo', {})))) {
    //       if (!get(this.props, 'user.user.domainInfo.active')) {
    //         this.openNotificationWithIcon('error', 'Your domain is not active.')
    //       }
    //     }
    //   }
    // }
    if (this.props.leadPageByIdPhase === "success") {
      this.props.initLeadPage();
      if (
        this.state.campaignType === "listing-website" ||
        get(this.props, "leadPageByIdData.campaignType", "") ===
          "listing-website"
      ) {
        if (this.state.activeTab == "seo") {
          this.props.history.push(
            `/edit-landing-page/listing-website/seo/${this.state.campaignId}`
          );
          this.setState({ activeTab: "seo" });
        }
        // else if(this.state.activeTab == "editor") {
        //   this.props.history.push(
        //     `/edit-landing-page/listing-website/site-editor/${this.state.campaignId}`
        //   );
        //   this.setState({ activeTab: "editor" });
        // }
        //(this.state.activeTab == "editor")
        else if (this.state.activeTab == "editor") {
          this.props.history.push(
            `/edit-landing-page/listing-website/site-editor/${this.state.campaignId}`
          );
          this.setState({ activeTab: "editor" });
        } else if (this.state.activeTab == "listing-details") {
          this.props.history.push(
            `/edit-landing-page/listing-website/listing-details/${this.state.campaignId}`
          );
          this.setState({ activeTab: "listing-details" });
        } else {
          this.props.history.push(
            `/edit-landing-page/listing-website/QR-Code/${this.state.campaignId}`
          );
          this.setState({ activeTab: "QR-Code" });
        }
        this.setState(
          {
            editorTab: `/edit-landing-page/listing-website/site-editor/${this.state.campaignId}`,
            seoTab: `/edit-landing-page/listing-website/seo/${this.state.campaignId}`,
            listingDetailsTab: `/edit-landing-page/listing-website/listing-details/${this.state.campaignId}`,
            qRCodeTab: `/edit-landing-page/listing-website/QR-Code/${this.state.campaignId}`,
            campaignType: "listing-website",
          },
          () => {
            this.forceUpdate();
          }
        );
      } else {
        if (this.state.activeTab == "seo") {
          this.props.history.push(
            `/edit-landing-page/sell/seo/${this.state.campaignId}`
          );
          this.setState({ activeTab: "seo" });
        } else if (this.state.activeTab == "editor") {
          this.props.history.push(
            `/edit-landing-page/sell/site-editor/${this.state.campaignId}`
          );
          this.setState({ activeTab: "editor" });
        } else {
          this.props.history.push(
            `/edit-landing-page/sell/QR-Code/${this.state.campaignId}`
          );
          this.setState({ activeTab: "QR-Code" });
        }
        this.setState(
          {
            editorTab: `/edit-landing-page/sell/site-editor/${this.state.campaignId}`,
            seoTab: `/edit-landing-page/sell/seo/${this.state.campaignId}`,
            qRCodeTab: `/edit-landing-page/sell/QR-Code/${this.state.campaignId}`,
            campaignType: "sell",
          },
          () => {
            this.forceUpdate();
          }
        );
      }
      if (
        this.state.campaignType === "social-link" ||
        get(this.props, "leadPageByIdData.campaignType", "") === "social-link"
      ) {
        if (this.state.activeTab == "seo") {
          this.props.history.push(
            `/edit-social-link/seo/${this.state.campaignId}`
          );
          this.setState({ activeTab: "seo" });
        } else if (this.state.activeTab == "editor") {
          this.props.history.push(
            `/edit-social-link/site-editor/${this.state.campaignId}`
          );
          this.setState({ activeTab: "editor" });
        } else {
          this.props.history.push(
            `/edit-social-link/QR-Code/${this.state.campaignId}`
          );
          this.setState({ activeTab: "QR-Code" });
        }
        this.setState(
          {
            editorTab: `/edit-social-link/site-editor/${this.state.campaignId}`,
            seoTab: `/edit-social-link/seo/${this.state.campaignId}`,
            qRCodeTab: `/edit-social-link/QR-Code/${this.state.campaignId}`,
            campaignType: "social-link",
          },
          () => {
            this.forceUpdate();
          }
        );
      }
      if (this.props.leadPageByIdData !== prev.leadPageData) {
        let timeOut = setInterval(() => {
          document.getElementById("ifram_preview") &&
            document
              .getElementById("ifram_preview")
              .contentDocument.location.reload(true);
          clearInterval(timeOut);
        }, 5000);
      }
      if (!isDataSet) {
        this.setState(
          {
            leadPageData: this.props.leadPageByIdData,
            listingWebsiteTemplate: this.props.leadPageByIdData,
            isDataSet: true,
            sellTemplate: this.props.leadPageByIdData,
            isData: true,
            isLoading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      } else {
        this.setState(
          {
            leadPageData: this.props.leadPageByIdData,
            // listingWebsiteTemplate: this.props.leadPageByIdData,
            sellTemplate: this.props.leadPageByIdData,
            isData: true,
            isLoading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      }
    }
    if (this.props.uploadImagePhase === "success") {
      this.props.initLeadPage();
      let { listingWebsiteTemplate } = this.state;
      const { uploadImageData } = this.props;
      if (uploadImageData.key === "logo") {
        listingWebsiteTemplate.logo = uploadImageData.images[0];
      } else if (uploadImageData.key === "image") {
        listingWebsiteTemplate.image = uploadImageData.images[0];
      }
      this.setState(
        {
          listingWebsiteTemplate,
          preImage: "",
          imageLoadingKey: "",
          logoLoading: false,
          imageLoading: false,
          cropImg: {},
          imageLoading: "",
          rotateLoading: "",
        },
        () => {
          this.forceUpdate();
          this.onSave();
        }
      );
    }
    if (this.props.getListingsPhase === "success") {
      this.props.initLeadPage();
      this.setState({ listingData: this.props.getListingsData });
    }
  };

  headingShowingStatus = (name, value) => {
    const { listingWebsiteTemplate } = this.state;
    listingWebsiteTemplate[name] = value;
    this.setState({ listingWebsiteTemplate }, () => {
      this.onSave();
    });
  };

  onDropImage = async (file, key) => {
    if (file.length > 0) {
      if (key === "logo") {
        this.setState(
          { logoLoading: true, imageError: false, logoError: false },
          () => {
            this.forceUpdate();
          }
        );
      } else if (key === "image") {
        this.setState(
          { imageLoading: true, imageError: false, logoError: false },
          () => {
            this.forceUpdate();
          }
        );
      }
      file[0].preview = URL.createObjectURL(file[0]);
      this.forceUpdate();
      let form_data = new FormData();
      if (Object.keys(file).length != 0) {
        // let full_form_data = new FormData();
        // full_form_data.append("key", key);
        // full_form_data.append("images[]", file[0]);
        // full_form_data.append(`numOfImage`, 1);
        setTimeout(async() => {
         const {value} = await this.props.uploadImages(file[0]);
         this.setState({listingWebsiteTemplate: {...this.state.listingWebsiteTemplate, [key]: value}})
        this.props.createLeadPage({...this.props.leadPageByIdData, [key]: value})
        this.setState(
          { imageLoading: false, logoLoading:false, imageLoadingKey:"" },
          () => {
            this.forceUpdate();
          }
        );
        }, 1000);
      }
    } else {
      if (key === "image") {
        this.setState({ imageError: true });
      } else {
        this.setState({ logoError: true });
      }
    }
  };

  saveAndExist = () => {
    const {
      listingWebsiteTemplate,
      campaignType,
      userData,
      campaignNameError,
      sellTemplate,
      socialLinkTemplate,
    } = this.state;
    if (
      campaignType === "listing-website" &&
      (listingWebsiteTemplate.campaignName === "" || campaignNameError)
    ) {
      return;
    } else if (
      campaignType === "sell" &&
      (sellTemplate.campaignName === "" || campaignNameError)
    ) {
      return;
    }
    if (campaignType === "social-link") {
    }
    if (campaignType === "listing-website") {
      // console.log(campaignType, "campaignType");
      if (
        this.props.leadPageProperty.type === "rental" &&
        (includes(
          ["", null, undefined],
          get(this.props, "leadPageProperty.zipcode", null)
        ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.city", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.address", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.availability", null)
          ) ||
          includes(
            ["", null, undefined, 0],
            get(this.props, "leadPageProperty.images.length", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.bathrooms", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.description", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.bedrooms", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.squareFootage", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.monthlyRent", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.minMonths", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.maxMonths", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.state", null)
          ))
      ) {
        return;
      }
      const minMonths = parseInt(
        get(this.props, "leadPageProperty.minMonths", 0),
        10
      );
      const maxMonths = parseInt(
        get(this.props, "leadPageProperty.maxMonths", 0),
        10
      );
      // console.log(
      //   this.props.leadPageProperty.type === "rental",
      //   minMonths,
      //   maxMonths,
      //   minMonths > maxMonths,
      //   "error=>"
      // );
      if (
        this.props.leadPageProperty.type === "rental" &&
        minMonths > maxMonths
      ) {
        return;
      } else if (
        this.props.leadPageProperty.type === "for-purchase" &&
        (includes(
          ["", null, undefined],
          get(this.props, "leadPageProperty.zipcode", null)
        ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.city", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.address", null)
          ) ||
          includes(
            ["", null, undefined, 0],
            get(this.props, "leadPageProperty.images.length", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.bathrooms", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.description", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.bedrooms", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.interiorSqFeet", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.askingPrice", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.state", null)
          ))
      ) {
        return;
      } else {
        if (listingWebsiteTemplate.status === "draft") {
          this.setState({ isDraft: true });
          listingWebsiteTemplate.createdAt = new Date();
        } else {
          this.setState({ isActive: true });
        }
        // listingWebsiteTemplate.status = "active";
        this.setState({ listingWebsiteTemplate, isSaveAndExitLoading: true });
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.");
        }
        cancelToken = axios.CancelToken.source();
        if (this.props.leadPageByIdData) {
          listingWebsiteTemplate.isQrCode =
            this.props.leadPageByIdData.isQrCode;
        } else {
          listingWebsiteTemplate.isQrCode = false;
        }
        if (listingWebsiteTemplate) {
          listingWebsiteTemplate.token = cancelToken.token;
        }
        this.props.createLeadPage(listingWebsiteTemplate);
      }
    } else {
      if (listingWebsiteTemplate.status === "draft") {
        this.setState({ isDraft: true });
        listingWebsiteTemplate.createdAt = new Date();
      } else {
        this.setState({ isActive: true });
      }
      // listingWebsiteTemplate.status = "active";
      this.setState({ listingWebsiteTemplate, isSaveAndExitLoading: true });
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      cancelToken = axios.CancelToken.source();
      if (this.props.leadPageByIdData) {
        listingWebsiteTemplate.isQrCode = this.props.leadPageByIdData.isQrCode;
      } else {
        listingWebsiteTemplate.isQrCode = false;
      }
      if (listingWebsiteTemplate) {
        listingWebsiteTemplate.token = cancelToken.token;
      }
      this.props.createLeadPage(listingWebsiteTemplate);
    }
  };

  publishLeadPage = () => {
    const {
      listingWebsiteTemplate,
      sellTemplate,
      campaignNameError,
      campaignType,
    } = this.state;
    if (
      campaignType === "listing-website" &&
      (listingWebsiteTemplate.campaignName === "" || campaignNameError)
    ) {
      return;
    } else if (
      campaignType === "sell" &&
      (sellTemplate.campaignName === "" || campaignNameError)
    ) {
      return;
    }
    if (listingWebsiteTemplate.campaignType === "social-link") {
      if (
        this.props.user.user.firstName.trim() === "" ||
        this.props.user.user.lastName.trim() === "" ||
        this.props.user.user.email.trim() === "" ||
        this.props.user.user.profileImage === ""
      ) {
        return;
      }
    }
    if (listingWebsiteTemplate.campaignType === "listing-website") {
      if (
        this.props.leadPageProperty.type === "rental" &&
        (includes(
          ["", null, undefined],
          get(this.props, "leadPageProperty.zipcode", null)
        ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.city", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.address", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.availability", null)
          ) ||
          includes(
            ["", null, undefined, 0],
            get(this.props, "leadPageProperty.images.length", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.bathrooms", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.description", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.bedrooms", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.squareFootage", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.monthlyRent", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.minMonths", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.maxMonths", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.state", null)
          ))
      ) {
        return;
      }
      const minMonths = parseInt(
        get(this.props, "leadPageProperty.minMonths", 0),
        10
      );
      const maxMonths = parseInt(
        get(this.props, "leadPageProperty.maxMonths", 0),
        10
      );
      if (
        this.props.leadPageProperty.type === "rental" &&
        minMonths > maxMonths
      ) {
        return;
      } else if (
        this.props.leadPageProperty.type === "for-purchase" &&
        (includes(
          ["", null, undefined],
          get(this.props, "leadPageProperty.zipcode", null)
        ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.city", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.address", null)
          ) ||
          includes(
            ["", null, undefined, 0],
            get(this.props, "leadPageProperty.images.length", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.bathrooms", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.description", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.bedrooms", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.interiorSqFeet", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.askingPrice", null)
          ) ||
          includes(
            ["", null, undefined],
            get(this.props, "leadPageProperty.state", null)
          ))
      ) {
        //console.log("sssqqqs");
        return;
      }
    }
    if (listingWebsiteTemplate.campaignName === "") {
      this.setState({ campaignNameError: "Please enter link name" });
    } else {
      this.setState({ campaignNameError: "" });
      if (listingWebsiteTemplate.status === "draft") {
        listingWebsiteTemplate.status = "active";
        listingWebsiteTemplate.createdAt = new Date();
        this.setState({ listingWebsiteTemplate });
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.");
        }
        cancelToken = axios.CancelToken.source();
        if (listingWebsiteTemplate) {
          listingWebsiteTemplate.token = cancelToken.token;
        }
        if (this.props.leadPageByIdData) {
          listingWebsiteTemplate.isQrCode =
            this.props.leadPageByIdData.isQrCode;
        } else {
          listingWebsiteTemplate.isQrCode = false;
        }
        this.props.createLeadPage(listingWebsiteTemplate);
      }
      this.props.publishLeadPage(listingWebsiteTemplate);
    }
  };

  onSave = () => {
    const { listingWebsiteTemplate, isQrCode } = this.state;
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();

    if (listingWebsiteTemplate) {
      listingWebsiteTemplate.token = cancelToken.token;
    }
    if (listingWebsiteTemplate) {
      listingWebsiteTemplate.propertyId = this.props.leadPageProperty?._id;
    }
    if (this.props.leadPageByIdData) {
      listingWebsiteTemplate.isQrCode = this.props.leadPageByIdData.isQrCode;
    } else {
      listingWebsiteTemplate.isQrCode = false;
    }
    this.props.createLeadPage(listingWebsiteTemplate);
  };

  updateQrcode = (value) => {
    const { leadPageByIdData } = this.props;
    if (leadPageByIdData && !leadPageByIdData.isQrCode) {
      if (leadPageByIdData.campaignType === "listing-website") {
        leadPageByIdData.isQrCode = true;
        this.props.createLeadPage(leadPageByIdData);
      } else if (leadPageByIdData.campaignType === "sell") {
        leadPageByIdData.isQrCode = true;
        this.props.createLeadPage(leadPageByIdData);
      } else if (leadPageByIdData.campaignType === "social-link") {
        leadPageByIdData.isQrCode = true;
        this.props.createLeadPage(leadPageByIdData);
      }
    }
  };

  createListingType = (e) => {
    this.props.history.push("/listings/create-listing");
  };

  handleChangeText = (e) => {
    e.preventDefault();
    const { listingWebsiteTemplate, sellTemplate , userData} = this.state;
    console.log("users ==>", this.props.user)
    if (e.target.value.trim() === "") {
      this.setState(
        {
          campaignNameError:
            e.target.name === "campaignName"
              ? "Please enter link name"
              : "",
          listingWebsiteTemplate: {
            ...this.state.listingWebsiteTemplate,
            [e.target.name]: e.target.value,
          },
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ campaignNameError: "" });
      this.setState(
        {
          listingWebsiteTemplate: {
            ...this.state.listingWebsiteTemplate,
            [e.target.name]: e.target.value,
          },
          campaignNameError: "",
        },
        () => {
          this.forceUpdate();
          this.onSave();
        }
      );
    }
  };
  handleColorChange = (c, type) => {
    let { listingWebsiteTemplate, sellTemplate } = this.state;
    if (type === "textColor") {
      listingWebsiteTemplate.textColor = c;
    } else {
      listingWebsiteTemplate.backgroundColor = c;
    }
    this.setState({ listingWebsiteTemplate }, () => {
      this.forceUpdate();
      this.onSave();
    });
  };

  handleSeoChange = (e) => {
    let { listingWebsiteTemplate, sellTemplate } = this.state;
    let seo = listingWebsiteTemplate.seo;
    seo[e.target.name] = e.target.value;
    this.setState(
      {
        listingWebsiteTemplate: {
          ...this.state.listingWebsiteTemplate,
          ["seo"]: seo,
        },
      },
      () => {
        this.forceUpdate();
        this.onSave();
      }
    );
  };

  handleTestimonialChange = (e) => {
    this.setState({
      listingWebsiteTemplate:{...this.state.listingWebsiteTemplate,testimonial:{...e,title:e?.title ?? "My Reviews"}}
    },() => {
      this.forceUpdate();
      this.onSave();
    })
  }

  handleMyWorkChange = (e) => {
    this.setState({
      listingWebsiteTemplate:{...this.state.listingWebsiteTemplate,myWork:{...e,title:e?.title ?? "My Work"}}
    },() => {
      this.forceUpdate();
      this.onSave();
    })
  }

  handlePDFDataChange = (pdfData) => {
    this.setState({
      listingWebsiteTemplate:{...this.state.listingWebsiteTemplate,pdfData}
    },() => {
      this.forceUpdate();
      this.onSave();
    })
  }

  handleVideosDataChange = (videosData) => {
    this.setState({
      listingWebsiteTemplate:{...this.state.listingWebsiteTemplate,videosData}
    },() => {
      this.forceUpdate();
      this.onSave();
    })
  }

  handleBackgroundChange = (event) => {
    this.setState({
      listingWebsiteTemplate:{...this.state.listingWebsiteTemplate,background:event}
    },() => {
      this.forceUpdate();
      this.onSave();
    })
  }

  handleCreateText = (e) => {
    // console.log("value",e.target.value)
    const { listingWebsiteTemplate, sellTemplate, socialLinkTemplate } = this.state;
    listingWebsiteTemplate["campaignName"] = e.target.value;
    sellTemplate["campaignName"] = e.target.value;
    socialLinkTemplate["campaignName"] = e.target.value;
    this.setState(
      { campaignName: e.target.value, listingWebsiteTemplate, sellTemplate, socialLinkTemplate },
      () => {
        this.forceUpdate();
        // console.log("test=====>",this.state.campaignName)
      }
    );
  };

  handleUserDataChange = (value , key)=>{
    const {userData}= this.state;
    this.setState({userData: {...userData , [key]:value}})
  }

  handleSubmitCampaign = (e) => {
    localStorage.setItem("isSelectDropDown", true);
    const { sellTemplate, listingWebsiteTemplate, socialLinkTemplate } =
      this.state;
    if (this.state.campaignType === "listing-website") {
      if (this.state.campaignName === "") {
        //if (this.state.campaignName === "" && this.state.selectValue === "0") {
        this.setState(
          {
            activeTab: "listing-details",
            campaignNameError: "Please enter link name",
            selectValueError: "",
          },
          () => {
            this.forceUpdate();
          }
        );
      } else if (this.state.campaignName === "") {
        this.setState({ campaignNameError: "Please enter link name" });
      }
      // else if (this.state.selectValue === "0") {
      //   this.setState({ selectValueError: "Please enter listing" });
      // }
      else {
        this.setState({
          isLoading: true,
          campaignNameError: "",
          selectValueError: "",
        });
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.");
        }
        // this.setState({searchText: ''})
        // save the cancel token for the current request
        cancelToken = axios.CancelToken.source();
        if (listingWebsiteTemplate) {
          listingWebsiteTemplate.token = cancelToken.token;
        }
        // if(this.props.leadPageByIdData){
        //   listingWebsiteTemplate.isQrCode = this.props.leadPageByIdData.isQrCode
        // }else{
        listingWebsiteTemplate.isQrCode = this.state.isQrCode;
        // }
        this.props.createLeadPage(listingWebsiteTemplate);
      }
    } else if (this.state.campaignType === "sell") {
      if (this.state.campaignName === "") {
        this.setState({
          activeTab: "editor",
          campaignNameError: "Please enter link name",
        });
      } else if (this.state.campaignName !== "") {
        this.setState({
          activeTab: "editor",
          isLoading: true,
          campaignNameError: "",
        });
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.");
        }
        // this.setState({searchText: ''})
        // save the cancel token for the current request
        cancelToken = axios.CancelToken.source();
        if (sellTemplate) {
          sellTemplate.token = cancelToken.token;
        }
        // if(this.props.leadPageByIdData){
        //   sellTemplate.isQrCode = this.props.leadPageByIdData.isQrCode
        // }else{
        sellTemplate.isQrCode = this.state.isQrCode;
        // }
        this.props.createLeadPage(sellTemplate);
      }
    } else if (this.state.campaignType === "social-link") {
      // if(this.props.leadPageByIdData){
      //   socialLinkTemplate.isQrCode = this.props.leadPageByIdData.isQrCode
      // }else{
        if (this.state.campaignName === "") {
          this.setState(
            {
              activeTab: "listing-details",
              campaignNameError: "Please enter link name",
              selectValueError: "",
            },
            () => {
              this.forceUpdate();
            }
          );
        }else{
          this.setState({
            isLoading: true,
            activeTab: "editor",
          });
          socialLinkTemplate.isQrCode = this.state.isQrCode;
          // }
          this.props.createLeadPage(socialLinkTemplate);
        }
    }
  };

  closeModal() {
    this.setState({ isLead: true, information: true });
  }

  referenceFromchildToParent = (e) => {
    if (e && e !== null) {
      this.setState({ cropper: e });
    }
  };
  onCheckHandle = (e) => {
    this.setState({ isQrCode: e.target.checked }, () => {
      this.forceUpdate();
    });
  };
  changeCampaignType(type) {
    const { listingWebsiteTemplate, sellTemplate, socialLinkTemplate } =
      this.state;
    if (type === "listing-website") {
      this.props.history.push("/create-new-landing-page/listing-website");
      this.props.getListings();
      listingWebsiteTemplate.campaignType = type;
      this.setState(
        {
          listingWebsiteTemplate,
          campaignType: "listing-website",
          campaignNameError: "",
          selectValueError: "",
          activeTab: "listing-details",
        },
        () => {
          this.forceUpdate();
        }
      );
    } else if (type === "sell") {
      sellTemplate.campaignType = type;
      this.props.history.push("/create-new-landing-page/sell");
      this.setState(
        {
          sellTemplate,
          campaignType: type,
          campaignNameError: "",
          selectValueError: "",
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      socialLinkTemplate.campaignType = type;
      this.props.history.push("/create-social-link");
      this.setState(
        {
          socialLinkTemplate,
          campaignType: type,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  render() {
    // console.log("campaigntype", this.state.isCampaignType);
    const campaignTypeArray = [
      {
        key: "social-link",
        value:
          "One link to share everything so you can post on social media platforms",
      },
      // { key: "sell", value: "Target people looking to sell their home" },
      // {
      //   key: "listing-website",
      //   value: "Get people to buy the listing you're trying to sell",
      // },
    ];
    const {
      isLead,
      information,
      userData,
      isSaveAndExitLoading,
      isPublishLoading,
      isMessageShow,
      campaignType,
      isData,
      isDataSet,
      leadPageData,
    } = this.state;
    // const mode = isData? "edit":"create"
    const isSelectDropDown =
      localStorage.getItem("isSelectDropDown") === "true";
    return (
      <>
        <header className="head">
          <div className="head__container head__container--full">
            <div className="head__row">
              <div className="head__left">
                <div className="head__close">
                  {/* {get(this.state.leadPageData, "status", "") === "draft" ? (
                    <button
                      onClick={() =>
                        
                        this.props.history.push("/links")
                      }
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        this.props.history.push("/links")
                      }
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  )} */}
                  <button
                    onClick={() => {
                      if (
                        get(this.state.leadPageData, "status", "") === "draft"
                      ) {
                        this.props.history.push("/social-links");
                        this.openNotificationWithIcon(
                          "success",
                          "Your Listing landing page is drafted successfully"
                        );
                      } else {
                        this.props.history.push("/social-links");
                      }
                    }}
                    className="btn btn__close"
                  >
                    <Close />
                  </button>
                </div>
                <div className="head__divider"></div>
                <div className="head__title">{ !isData ?"Create New Link" : "Edit Link"}</div>
              </div>
              {isData && this.state.campaignType === "social-link" ? (
                <div className="head__right">
                  <button
                    onClick={() => this.publishLeadPage()}
                    type="button"
                    className={`btn btn__head ${this.state.userData.firstName && this.state.userData.email && this.state.userData.lastName && this.state.userData.profileImage ? 'btn__purple' : "btn__default"}`}
                  >
                    {isSaveAndExitLoading ? (
                      <Spin indicator={antIconDark} />
                    ) : (
                      "Save & Exit"
                    )}
                  </button>
                </div>
              ) : (
                isData && (
                  <div className="head__right">
                    <button
                      onClick={() => this.saveAndExist()}
                      type="button"
                      className={`btn btn__head ${this.state.userData.firstName && this.state.userData.email && this.state.userData.lastName && this.state.userData.profileImage ? 'btn__purple' : "btn__default"}`}
                      >
                      {isSaveAndExitLoading ? (
                        <Spin indicator={antIconDark} />
                      ) : (
                        "Save & Exit"
                      )}
                    </button>
                    <button
                      onClick={() => this.publishLeadPage()}
                      type="button"
                      className="btn btn__purple btn__head"
                    >
                      {isPublishLoading ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        "Publish"
                      )}
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </header>
        <div className="editor__sidebar">
          <div
            className={
              this.state.isEditSite
                ? "editor__sidebar--left is__edit"
                : "editor__sidebar--left"
            }
          >
            <div className="editor__sidebar--left-wrapper">
              {!isData && (
                <CreateComponent
                  campaignType={campaignType}
                  campaignTypeArray={campaignTypeArray}
                  isCampaignType={this.state.isCampaignType}
                  isSocialLeadExist={this.state.isSocialLeadExist}
                  changeCampaignType={this.changeCampaignType}
                  handleSubmitCampaign={this.handleSubmitCampaign}
                  handleChange={this.handleCreateText}
                  createListingType={this.createListingType}
                  handleChangeListing={this.handleChangeListing}
                  onCheckHandle={this.onCheckHandle}
                  isQrCode={this.state.isQrCode}
                  updateQrcode={this.updateQrcode}
                  isSelectDropDown={isSelectDropDown}
                  campaignNameError={this.state.campaignNameError}
                  {...this.state}
                />
              )}
              {isData && (
                <Edit
                  leadPageData={leadPageData}
                  _crop={this._crop}
                  headingShowingStatus={this.headingShowingStatus}
                  onChangeCropImage={this.onChangeCropImage}
                  handleChange={this.handleChangeText}
                  handleChangeSize={this.handleChangeSize}
                  handleUserDataChange={this.handleUserDataChange}
                  handleTestimonialChange={this.handleTestimonialChange}
                  handleMyWorkChange={this.handleMyWorkChange}
                  handlePDFDataChange={this.handlePDFDataChange}
                  handleVideosDataChange={this.handleVideosDataChange}
                  handleBackgroundChange={this.handleBackgroundChange}
                  handleSeoChange={this.handleSeoChange}
                  tabChange={this.onTabChange}
                  handleColorChange={this.handleColorChange}
                  uploadCropImage={this.uploadCropImage}
                  cancelCropImage={this.cancelCropImage}
                  referenceFromchildToParent={this.referenceFromchildToParent}
                  removeImage={this.removeImage}
                  onDropImage={this.onDropImage}
                  getLeadPageById={this.props.getLeadPageById}
                  leadPageByIdPhase={this.props.leadPageByIdPhase}
                  leadPageByIdData={this.props.leadPageByIdData}
                  leadPageByIdproperty={this.props.leadPageByIdproperty}
                  leadPageProperty={this.props.leadPageProperty}
                  imageRotate={this.imageRotate}
                  isQrCode={this.state.isQrCode}
                  updateQrcode={this.updateQrcode}
                  isSelectDropDown={isSelectDropDown}
                  campaignNameError={this.state.campaignNameError}
                  {...this.state}
                />
              )}
            </div>
          </div>
          <div
            className={
              this.state.isPreviewSite
                ? "editor__sidebar--right is__preview"
                : "editor__sidebar--right"
            }
          >
            <div className="editor__sidebar--right-wrapper">
              {isData && <PreviewTemplate {...this.props} {...this.state} />}
              {!isData && <DemoTemplate {...this.state} />}
            </div>
          </div>
          <div className="editor__sidebar--footer">
            <button
              onClick={() => this.changeTab("edit")}
              type="button"
              className="btn btn__default"
            >
              Edit
            </button>
            <button
              onClick={() => this.changeTab("preview")}
              type="button"
              className="btn btn__purple"
            >
              Preview
            </button>
          </div>
        </div>
        {/* <ReactModal
          isOpen={
            get(leadPageData, "status", "") !== "draft" && !isEmpty(userData)
              ? !information
              : false
          }
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Information about you"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <Formik
            initialValues={{
              email: get(userData, "email", ""),
              firstName: get(userData, "firstName", ""),
              lastName: get(userData, "lastName", ""),
              companyName: get(userData, "companyName", ""),
              role: get(userData, "role", ""),
              medianPrice: get(userData, "medianPrice", ""),
              yearOfExperience: get(userData, "yearOfExperience", ""),
              totalTransaction: get(userData, "totalTransaction", ""),
            }}
            validate={(values) => {
              const errors = {};
              if (!values.medianPrice) {
                errors.medianPrice = "Please enter median price.";
              }
              if (!values.yearOfExperience) {
                errors.yearOfExperience = "Please enter year of experience.";
              }
              if (!values.totalTransaction) {
                errors.totalTransaction = "Please enter total experience.";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                this.setState({ isSpinLoading: true });
                setSubmitting(false);
                let form_data = new FormData();
                values.information = true;
                for (let key in values) {
                  form_data.append(key, values[key]);
                }
                this.props.editUser(form_data);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="modal-content">
                  <div className="react-modal-header">
                    <h5 className="react-modal-title">Information about you</h5>
                  </div>
                  <div className="modal-content">
                    <div className="react-modal-body">
                      <p className="react-modal-body-text">
                        Before you create your first landing page we need some
                        information from you to help create the highest
                        converting page.
                      </p>
                      <div className="form-group">
                        <label className="label-primary">Median Price</label>
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                          className="form-control material-textfield-input"
                          name="medianPrice"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.medianPrice}
                          required
                        />
                        <p className="form-control-note">
                          {" "}
                          Median price of homes you either help clients buy or
                          sell.
                        </p>
                        {errors.medianPrice && (
                          <div className="invalid-feedback">
                            {errors.medianPrice}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="label-primary">
                          Total Number of transactions
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                          className="form-control material-textfield-input"
                          name="totalTransaction"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.totalTransaction}
                          required
                        />
                        {errors.totalTransaction && (
                          <div className="invalid-feedback">
                            {errors.totalTransaction}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="label-primary">
                          Year of Experience
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                          className="form-control material-textfield-input"
                          name="yearOfExperience"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.yearOfExperience}
                          required
                        />
                        {errors.yearOfExperience && (
                          <div className="invalid-feedback">
                            {errors.yearOfExperience}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="react-modal-footer">
                      <button
                        onClick={() => this.closeModal()}
                        className="btn btn__default"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn__purple ms-2"
                        disabled={this.state.isSpinLoading || isSubmitting}
                      >
                        {this.state.isSpinLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </ReactModal> */}
      </>
    );
  }
}
