import React, { PureComponent } from "react";
import Dropzone from "react-dropzone";
import { Close, Plus, Remove } from "../../../../../components/icons";
import RadioGroupInput from "../../../../../components/ui/RadioGroupInput/component";
import CheckboxComponent from "../../../../../components/ui/Checkbox/component";
import RadioInput from "../../../../../components/ui/Radio/component";
import { Formik } from "formik";
import { getAllUsStates } from "../../../../../constants/config";
import { get, size, isEmpty, includes, debounce } from "lodash";
import Autocomplete from "react-google-autocomplete";
import NumberFormat from "react-number-format";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import imageCompression from "browser-image-compression";
import "./styles.scss";

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
const jwtToken = localStorage.getItem("Authorization");

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

const config = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "blockQuote",
  ],
};

const initialFormValues = {
  type: "rental",
  status: "",
  subPropertyType: "singleFamilyHouse",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  monthlyRent: "",
  bedrooms: 0,
  bathrooms: 0,
  squareFootage: "",
  interiorSqFeet: "",
  minMonths: "",
  maxMonths: "",
  availability: "",
  description: "",
  propertyFeature: [],
  buildingFeature: [],
  images: [],
  numOfImage: 0,
  askingPrice: "",
};

const SortableItem = SortableElement(
  ({ value, indexKey, index, onRemove, data, onSortEnd, onReset }) =>
    get(value, "preview", "") !== "" ? (
      <div
        className={`dropzone__grid--item index_${indexKey}`}
        key={`input-${indexKey}`}
      >
        <div className="dropzone__grid--preview">
          <img
            id={`imgId${indexKey}`}
            className="preview__thumb"
            src={value.preview}
            alt=""
          />
        </div>
        <div className="dropzone__grid--remove">
          <button
            type="button"
            key={`input-${value}`}
            onClick={() => onRemove(indexKey, onReset)}
            className="btn btn__remove"
          >
            <Remove />
          </button>
        </div>
      </div>
    ) : (
      <div
        className={`dropzone__grid--item index_${indexKey}`}
        key={`input-${indexKey}`}
      >
        <div className="dropzone__grid--preview">
          <img
            id={`imgId${indexKey}`}
            className="preview__thumb"
            src={value}
            alt=""
          />
        </div>
        <div className="dropzone__grid--remove">
          <button
            type="button"
            key={`input-${value}`}
            onClick={() => onRemove(indexKey, onReset)}
            className="btn btn__remove"
          >
            <Remove />
          </button>
        </div>
      </div>
    )
);

const SortableList = SortableContainer(
  ({ onRemove, data, onSortEnd, onReset }) => {
    return (
      <div className="dropzone__grid--sorter">
        {data.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            indexKey={index}
            index={index}
            value={value}
            onRemove={onRemove}
            onSortEnd={onSortEnd}
            onReset={onReset}
          />
        ))}
      </div>
    );
  }
);

export default class ListingDetailsComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      selectedPropertyType: "",
      selectedPropertySubType: "",
      selectedStatus: "active",
      imageError: "",
      previewArr: [],
      buildingFeatureArray: [],
      propertyFeatureArray: [],
      data: "",
      dataError: false,
      loader: false,
      propertyData: {},
      getAllPropertyAddressData: [],
      error: {},
      shouldSelectOption: true,
    };
    this.changePropertyType = this.changePropertyType.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.changePropertySubType = this.changePropertySubType.bind(this);
    this.onSelectedStatus = this.onSelectedStatus.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleChangeValueNew = this.handleChangeValueNew.bind(this);
  }

  onEditorChange(value) {
    if (value == "") {
      this.setState({ dataError: true });
    } else {
      this.setState({ data: value, dataError: false });
    }
  }

  changePropertyType(e, type) {
    this.setState({ selectedPropertyType: e }, () => {
      this.forceUpdate();
      this.onListingSave();
    });
  }

  changePropertySubType(e, type) {
    this.setState({ selectedPropertySubType: e }, () => {
      this.forceUpdate();
      this.onListingSave();
    });
  }

  onSelectedStatus(value) {
    this.setState({ selectedStatus: value });
  }

  handleChangeValue = (event, name) => {
    const { propertyData } = this.state;
    let errors = {};
    if (!event.target.value) {
      errors[name] = "required*";
      this.setState({ error: errors }, () => {
        this.forceUpdate();
      });
    } else {
      this.setState({ error: "" }, () => {
        this.forceUpdate();
      });
    }
    propertyData[name] = event.target.value;
    this.setState(
      {
        propertyData: propertyData,
      },
      () => {
        this.forceUpdate();
        this.onListingSave();
      }
    );
  };

  // handleChangeValueNew = (e, item, name) => {
  //   const value = e.target.value;
  //   console.log(value,"----->")
  //   const { propertyData } = this.state;
  //   const { getAllPropertyAddressData } = this.state;
  //   if (value === "") {
  //     this.setState(
  //       {
  //         propertyData: {
  //           address: "",
  //           city: "",
  //           state: "",
  //           zipcode: "",
  //           units: "",
  //           monthlyRent: "",
  //           askingPrice: "",
  //           squareFootage: "",
  //           interiorSqFeet: "",
  //           bedrooms: 0,
  //           bathrooms: 0,
  //           minMonths: "",
  //           maxMonths: "",
  //           availability: "",
  //           description: "",
  //           images: [],
  //           propertyFeature: [],
  //           buildingFeature: [],
  //         },
  //         previewArr: [],
  //         buildingFeatureArray: [],
  //         propertyFeatureArray: [],
  //         selectedPropertyType:"rental",
  //         selectedPropertySubType:"singleFamilyHouse"
  //       },
  //       () => {
  //         this.onListingSave();
  //         this.forceUpdate();
  //         console.log(propertyData,"new propertyData after Select--->")
  //       }
  //     );
  //   }
  //     else {
  //       let d =
  //       getAllPropertyAddressData?.find((item) => {
  //         if (item.address === value) {
  //           return item;
  //         }
  //       });

  //     console.log(d, getAllPropertyAddressData, "what is d---->");
  //     if (d !== undefined  && Object.keys(d).length !== 0) {
  //       Object.keys(d).map((keyName, i) => {
  //         if (keyName === "address") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "city") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "state") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "zipcode") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "units") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "monthlyRent") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "askingPrice") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "squareFootage") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "interiorSqFeet") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "bedrooms") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "bathrooms") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "minMonths") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "maxMonths") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "availability") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "description") {
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "images") {
  //           this.setState({ previewArr: d[keyName] });
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "propertyFeature") {
  //           this.setState({ propertyFeatureArray: d[keyName] });
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "buildingFeature") {
  //           this.setState({ buildingFeatureArray: d[keyName] });
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "type") {
  //           this.setState({ selectedPropertyType: d[keyName] });
  //           propertyData[keyName] = d[keyName];
  //         } else if (keyName === "subPropertyType") {
  //           this.setState({ selectedPropertySubType: d[keyName] });
  //           propertyData[keyName] = d[keyName];
  //         }
  //       });

  //       this.setState(
  //         {
  //           propertyData: propertyData

  //         },
  //         () => {
  //           this.onListingSave();
  //           this.forceUpdate();
  //         }
  //       );
  //     }
  //   }
  //   }
  handleChangeValueNew = (e, item, name) => {
    const value = e.target.value;
    console.log(value, "----->");
    const { getAllPropertyAddressData } = this.state;
    const { propertyData } = this.state;
    if (value === "") {
      delete propertyData.address;
      delete propertyData.city;
      delete propertyData.state;
      delete propertyData.zipcode;
      delete propertyData.units;
      delete propertyData.monthlyRent;
      delete propertyData.askingPrice;
      delete propertyData.squareFootage;
      delete propertyData.interiorSqFeet;
      delete propertyData.bedrooms;
      delete propertyData.bathrooms;
      delete propertyData.minMonths;
      delete propertyData.maxMonths;
      delete propertyData.availability;
      delete propertyData.description;
      propertyData.images = [];
      propertyData.propertyFeature = [];
      propertyData.buildingFeature = [];
      propertyData.type = "rental";
      propertyData.subPropertyType = "singleFamilyHouse";
      this.setState(
        {
          //shouldSelectOption:true,
          propertyData: {
            ...propertyData,
            address: "",
            city: "",
            state: "",
            zipcode: "",
            units: "",
            monthlyRent: "",
            askingPrice: "",
            squareFootage: "",
            interiorSqFeet: "",
            bedrooms: 0,
            bathrooms: 0,
            minMonths: "",
            maxMonths: "",
            availability: "",
            description: "",
            images: [],
            propertyFeature: [],
            buildingFeature: [],
          },
          previewArr: [],
          buildingFeatureArray: [],
          propertyFeatureArray: [],
          selectedPropertyType: "rental",
          selectedPropertySubType: "singleFamilyHouse",
        },
        () => {
          this.forceUpdate();
          this.onListingSave();

          console.log(
            this.state.propertyData,
            "new propertyData after Select--->"
          );
        }
      );
    } else {
      let selectedAddressData = getAllPropertyAddressData?.find(
        (item) => item.address === value
      );

      console.log(
        selectedAddressData,
        getAllPropertyAddressData,
        "what is d---->"
      );
      if (
        selectedAddressData !== undefined &&
        Object.keys(selectedAddressData).length !== 0
      ) {
        const updatedPropertyData = {
          ...this.state.propertyData,
          address: selectedAddressData.address,
          city: selectedAddressData.city,
          state: selectedAddressData.state,
          zipcode: selectedAddressData.zipcode,
          units: selectedAddressData.units,
          monthlyRent: selectedAddressData.monthlyRent,
          askingPrice: selectedAddressData.askingPrice,
          squareFootage: selectedAddressData.squareFootage,
          interiorSqFeet: selectedAddressData.interiorSqFeet,
          bedrooms: selectedAddressData.bedrooms,
          bathrooms: selectedAddressData.bathrooms,
          minMonths: selectedAddressData.minMonths,
          maxMonths: selectedAddressData.maxMonths,
          availability: selectedAddressData.availability,
          description: selectedAddressData.description,
        };

        this.setState(
          {
            propertyData: updatedPropertyData,
            previewArr: selectedAddressData.images,
            propertyFeatureArray: selectedAddressData.propertyFeature,
            buildingFeatureArray: selectedAddressData.buildingFeature,
            selectedPropertyType: selectedAddressData.type,
            selectedPropertySubType: selectedAddressData.subPropertyType,
          },
          () => {
            this.forceUpdate();
            this.onListingSave();

            // console.log(
            //   this.state.propertyData,
            //   "new propertyData after otheroption is selected--->"
            // );
          }
        );
      }
    }
  };

  renderPropertyAddress = (allUsStates) => {
    const { propertyData } = this.state;
    const states = size(allUsStates) > 0 ? allUsStates : [];
    const propertyAddresses =
      states &&
      states.map((item, index) => {
        if (propertyData && String(item._id) !== String(propertyData._id)) {
          return `${item.address}`;
        }
        return "";
      });
    return propertyAddresses.map((address, index) => {
      if (address !== "") {
        return (
          <option key={index} value={address}>
            {address}
          </option>
        );
      }
    });
  };
  // renderPropertyAddress = (allUsStates) => {
  //   const { propertyData } = this.state;
  //   const states = size(allUsStates) > 0 ? allUsStates : [];
  //   const propertyAddresses = states.map((item, index) => {
  //     if (item._id?.toString() !== propertyData._id?.toString()) {
  //       return `${item.address}`;
  //     }
  //     return "";
  //   });
  //   const isSelectOption = propertyData.address === "";
  //   console.log(propertyAddresses, isSelectOption, "what is the address when select-->");
  //   return (
  //     <>
  //       {isSelectOption && (
  //         <option key="select" value="">
  //           Select
  //         </option>
  //       )}
  //       {propertyAddresses.map((address, index) => {
  //         if (address !== "") {
  //           return (
  //             <option key={index} value={address}>
  //               {address}
  //             </option>
  //           );
  //         }
  //       })}
  //     </>
  //   );
  // };

  handlePropertyFeature(e) {
    let { propertyFeatureArray } = this.state;
    if (propertyFeatureArray.includes(e.target.value)) {
      const index = propertyFeatureArray.indexOf(e.target.value);
      if (index > -1) {
        propertyFeatureArray.splice(index, 1);
      }
    } else {
      propertyFeatureArray.push(e.target.value);
    }
    this.setState({ propertyFeatureArray }, () => {
      this.onListingSave();
      this.forceUpdate();
    });
  }

  handleBuildingFeature(e) {
    let { buildingFeatureArray } = this.state;
    if (buildingFeatureArray.includes(e.target.value)) {
      const index = buildingFeatureArray.indexOf(e.target.value);
      if (index > -1) {
        buildingFeatureArray.splice(index, 1);
      }
    } else {
      buildingFeatureArray.push(e.target.value);
    }
    this.setState({ buildingFeatureArray }, () => {
      this.onListingSave();
      this.forceUpdate();
    });
  }

  bytesToSize = (bytes) => bytes / (1024 * 1024);

  addImages = async (acceptedFiles, images, setFieldValue) => {
    if (acceptedFiles.length > 0) {
      const { previewArr } = this.state;
      this.setState({ imageError: "", loader: true });
      if (acceptedFiles.length > 50) {
        const imageError = "imageLength";
        this.setState({ imageError });
        this.forceUpdate();
      } else {
        acceptedFiles.map(async (data, key) => {
          if (data.type.split("/")[0] === "image") {
            if (this.bytesToSize(data.size) <= 10) {
              acceptedFiles[key].preview = URL.createObjectURL(data);
              const fileSize = (this.bytesToSize(data.size) * 80) / 100;
              const newFle = await imageCompression(data, {
                maxSizeMB: fileSize,
              });
              const {value} = await this.props.uploadImages(newFle)

              const file = new File([newFle], "image");
              previewArr.push({preview:value});
              this.setState({ previewArr, loader: false }, () => {
                this.onListingSave();
                this.forceUpdate();
              });
            } else {
              const imageError = "imageize";
              this.setState({ imageError, loader: false });
              this.forceUpdate();
            }
          } else {
            const imageError = "imageType";
            this.setState({ imageError, loader: false });
            this.forceUpdate();
          }
        });
      }
      setFieldValue("images", previewArr);
    } else {
      const imageError = "imageType";
      this.setState({ imageError, loader: false });
      this.forceUpdate();
    }
    this.forceUpdate();
  };

  removeImage = (index) => {
    const { propertyData } = this.state;
    const files = this.state.previewArr;
    files.splice(index, 1);
    this.setState({ previewArr: files }, () => {
      this.forceUpdate();
      this.onListingSave();
    });
  };

  resetImages = (setFieldValue) => {
    setFieldValue("images", this.state.previewArr);
  };
  onListingSave = () => {
    const { propertyData } = this.state;
    propertyData.propertyFeature = this.state.propertyFeatureArray;
    propertyData.buildingFeature = this.state.buildingFeatureArray;
    propertyData.status = "active";
    propertyData.numOfImage = this.state.previewArr?.length;
    propertyData.propertyStatus = "active";
    propertyData.type = this.state.selectedPropertyType;
    propertyData.subPropertyType = this.state.selectedPropertySubType;
    let form_data = new FormData();
    for (const [keyOF, valueOF] of Object.entries(propertyData)) {
      if (keyOF == "images") {
        this.state.previewArr.map((file, key) => {
          if (get(file, "preview", "").includes("amazon") || get(file, "preview", "").includes("cloudinary")) {
            form_data.append("images", file.preview);
          } else {
            form_data.append("images", file);
          }
        });
      } else if (keyOF == "availability") {
        if (propertyData[keyOF] === "1") {
          form_data.append(keyOF, this.state.availability);
        } else {
          form_data.append(keyOF, propertyData[keyOF]);
        }
      } else if (keyOF === "property") {
        form_data.append("property", JSON.stringify(propertyData));
      } else {
        form_data.append(keyOF, valueOF);
      }
    }
    this.props.addProperty(form_data);
  };

  componentDidMount = async () => {
    this.props.getAllPropertyAddress();
  };

  componentDidUpdate(prevProps) {
    document.title = "Create Listing | Yardpost";
    if (
      this.state.previewArr.length == 0 &&
      !isEmpty(this.props.property) &&
      (this.props.property !== prevProps.property ||
        this.props.addPropertyPhase !== prevProps.addPropertyPhase)
    ) {
      this.props.property["type"] = this.props.property["type"]
        ? this.props.property["type"]
        : "Rental";
      this.setState(
        {
          propertyData: get(this.props, "property", ""),
          selectedPropertySubType: get(
            this.props,
            "property.subPropertyType",
            ""
          ),
          selectedPropertyType: get(this.props, "property.type", ""),

          previewArr: this.props.property.images,
          propertyFeatureArray: this.props.property.propertyFeature,
          buildingFeatureArray: this.props.property.buildingFeature,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
    if (this.props.getAllPropertyAddressPhase === "success") {
      this.props.initPropertyPhase();
      this.setState(
        {
          getAllPropertyAddressData: this.props.getAllPropertyAddressData,
        },
        () => {
          this.forceUpdate();
        }
      );
    } else if (this.props.getAllPropertyAddressPhase === "error") {
      this.props.initPropertyPhase();
    }
    if (this.props.addPropertyPhase === "success") {
      this.setState({ isSpinLoading: false });
      // this.props.history.push("/listings/active");
      this.props.initPropertyPhase();
      //this.openNotificationWithIcon("success", "Listing Created successfully.");
      let timeOut = setInterval(() => {
        document.getElementById("ifram_preview") &&
          document
            .getElementById("ifram_preview")
            .contentDocument.location.reload(true);
        clearInterval(timeOut);
      }, 1000);
    } else if (this.props.addPropertyPhase === "error") {
      this.props.initPropertyPhase();
      this.setState({
        isSpinLoading: false,
        AddressError: this.props.addPropertyMessage,
      });
      let addError =
        this.props.addPropertyMessage !== ""
          ? this.props.addPropertyMessage
          : "Something Went Wrong!";
      this.openNotificationWithIcon("error", addError);
    }
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: message,
    });
  };

  orderList({ oldIndex, newIndex }) {
    this.setState(
      {
        previewArr: arrayMoveImmutable(
          this.state.previewArr,
          oldIndex,
          newIndex
        ),
      },
      () => {
        this.forceUpdate();
        this.onListingSave();
      }
    );
  }

  resetImages(setFieldValue) {
    setFieldValue("images", this.state.previewArr);
  }
  selectedPlace = (place, values, setFieldValue) => {
    const { propertyData } = this.state;
    setFieldValue("area", "");
    let streetNumber = "";
    let streetName = "";
    let buildingName = "";
    let cityArea = "";
    if (get(place, "formatted_address", "")) {
      const address = place.formatted_address;
    }

    if (get(place, "address_components", "")) {
      let neighborhood = place.address_components.filter((obj) => {
        return obj.types.indexOf("neighborhood") !== -1;
      });

      if (neighborhood.length > 0) {
        cityArea = neighborhood[0].long_name;
        propertyData["area"] = neighborhood[0].long_name;
        this.setState(
          {
            propertyData,
          },
          () => {
            this.forceUpdate();
            this.onListingSave();
          }
        );
        setFieldValue("area", neighborhood[0].long_name);
      }

      for (let i = 0; i < place.address_components.length; i++) {
        if (place.address_components[i].types[0] === "postal_code") {
          const zipcode = place.address_components[i].long_name;
          propertyData["zipcode"] = zipcode;
          this.setState(
            {
              propertyData,
            },
            () => {
              this.forceUpdate();
              this.onListingSave();
            }
          );
          setFieldValue("zipcode", zipcode);
        }
        if (place.address_components[i].types[0] === "locality") {
          const city = place.address_components[i].long_name;
          propertyData["city"] = city;
          this.setState(
            {
              propertyData,
            },
            () => {
              this.forceUpdate();
              this.onListingSave();
            }
          );
          setFieldValue("city", city);
        } else if (place.address_components[i].types[1] === "sublocality") {
          const city = place.address_components[i].long_name;
          propertyData["city"] = city;
          this.setState(
            {
              propertyData,
            },
            () => {
              this.forceUpdate();
              this.onListingSave();
            }
          );
          setFieldValue("city", city);
        }

        if (place.address_components[i].types[0] === "route") {
          streetName = place.address_components[i].long_name;
        }

        if (place.address_components[i].types[0] === "street_number") {
          streetNumber = place.address_components[i].long_name;
        }

        let address = streetNumber + " " + streetName;
        propertyData["address"] = address;

        setFieldValue("address", address);
        this.setState(
          {
            propertyData,
          },
          () => {
            this.forceUpdate();
            this.onListingSave();
          }
        );
        if (
          place.address_components[i].types[0] === "administrative_area_level_1"
        ) {
          const state = place.address_components[i].short_name;
          if (size(getAllUsStates) > 0) {
            let index = getAllUsStates.findIndex(
              (x) => x.abbreviation === state
            );
            if (index != -1) {
              propertyData["state"] = state;
              this.setState(
                {
                  propertyData,
                },
                () => {
                  this.forceUpdate();
                  this.onListingSave();
                }
              );
              setFieldValue("state", state);
            } else {
              setFieldValue("state", "");
            }
          }
        }
      }
    }
  };

  renderStates = (allUsStates) => {
    const states = size(allUsStates) > 0 ? allUsStates : [];
    return states.map((item, index) => {
      return (
        <option key={index} value={item.abbreviation}>
          {item.name}
        </option>
      );
    });
  };

  validateForm = (values) => {
    const errors = {};
    if (!this.state.selectedPropertyType) {
      errors.type = "Please select type";
    } else if (values.type === "0") {
      errors.type = "Please select primary agent";
    }
    if (!values.address) {
      errors.address = "Please enter address";
    }
    if (!values.city) {
      errors.city = "Please enter city name";
    }
    if (!values.state) {
      errors.state = "Please enter state";
    } else if (values.state === "0") {
      errors.state = "Please select primary agent";
    }
    if (!values.zipcode) {
      errors.zipcode = "Please enter zipcode";
    }
    if (this.state.selectedStatus !== "draft") {
      if (!values.bedrooms) {
        errors.bedrooms = "Please enter bedrooms";
      }
      if (!values.bathrooms) {
        errors.bathrooms = "Please enter bathrooms";
      }
      if (size(values.images) == 0) {
        errors.images = "Please upload at least one property image";
      }
    }
    if (values.description === "") {
      if (this.state.selectedStatus !== "draft") {
        errors.description = "Please enter description";
      } else {
        errors.description = "";
      }
    }
    if (this.state.selectedPropertyType === "rental") {
      if (this.state.selectedStatus !== "draft") {
        if (!values.monthlyRent) {
          errors.monthlyRent = "Please enter monthly rent";
        }
        if (!values.squareFootage) {
          errors.squareFootage = "Please enter square footage";
        }
        if (!values.minMonths) {
          errors.minMonths = "Please enter Min Lease";
        }
        if (!values.maxMonths) {
          errors.maxMonths = "Please enter Max Lease";
        }
        // if (
        //   parseFloat(values.minMonths.replace(/,/g, "")) >
        //   parseFloat(values.maxMonths.replace(/,/g, ""))
        // ) {
        //   errors.maxMonths = "Max lease must be greater than Min lease";
        // }
        if (values.availability === "" || values.availability === "Custom") {
          errors.availability = "Please enter availability";
        }
      }
    } else if (this.state.selectedPropertyType === "for-purchase") {
      if (!values.askingPrice) {
        errors.askingPrice = "Please enter asking price";
      } else if (!values.interiorSqFeet) {
        errors.interiorSqFeet = "Please enter square feet";
      }
    }

    return errors;
  };

  render() {
    const fieldA = {
      form: {
        message: "hey",
      },
    };
    const fieldB = undefined;
    const res = fieldB?.form;
    const propertySubType = [
      { key: "singleFamilyHouse", value: "Single Family House" },
      { key: "townhouse", value: "Townhouse" },
      { key: "condo", value: "Condo" },
      { key: "co-op", value: "Co-op" },
      { key: "apartment", value: "Apartment" },
      { key: "land", value: "Land" },
      { key: "office", value: "Office" },
    ];
    // const propertyStatus = [
    //   { key: "active", value: "Active" },
    //   { key: "sold", value: "Closed/Sold" },
    //   { key: "draft", value: "Draft" },
    // ];
    const propertyType = [
      { key: "rental", value: "Rental" },
      { key: "for-purchase", value: "For Purchase" },
    ];
    const propertyFeature = [
      "Dishwasher",
      "Laundry in building",
      "Balcony",
      "Laundry in unit",
      "Fireplace",
      "Central AC",
      "Garage",
      "Central heat",
      "Pool",
      "Elevator",
      "Hardwood floors",
      "Exposed brick",
    ];
    const buildingFeature = [
      "Pool",
      "Bike room",
      "Gym",
      "Mail room",
      "Fireplace",
      "Children's playroom",
      "Garage",
      "Roofdeck",
      "Outdoor grills",
      "Elevator",
      "Media room",
      "Live in super",
      "Wheelchair accessible",
      "Doorman",
      "Laundry room",
    ];
    const { propertyData } = this.state;

    const from = get(this.props, "match.params.list_id", ""); //get(this.props,'location.state.from','')
    const initialValues = from
      ? get(this.props, "property", "")
      : get(this.props, "property", "");
    const { imageError } = this.state;
    const custom_config = {
      extraPlugins: [MyCustomUploadAdapterPlugin],
      // toolbar: {
      //   shouldNotGroupWhenFull: true,
      // },
      toolbar: {
        items: [
          "bold",
          "italic",
          "link",
          "|",
          "undo",
          "redo",
          "|",
          "numberedList",
          "bulletedList",
        ],
      },
      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
      },
    };

    function MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader, this.props);
      };
    }

    class MyUploadAdapter {
      constructor(props) {
        // CKEditor 5's FileLoader instance.
        this.loader = props;
        // URL where to send files.
        this.url = `${HOSTNAME}/api/v1/uploadImage`;
      }

      // Starts the upload process.
      upload() {
        return new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject);
          this._sendRequest();
        });
      }

      // Aborts the upload process.
      abort() {
        if (this.xhr) {
          this.xhr.abort();
        }
      }

      // Example implementation using XMLHttpRequest.
      _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());
        xhr.open("POST", this.url, true);
        xhr.responseType = "json";
        //xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
        xhr.setRequestHeader("Authorization", jwtToken);
      }

      // Initializes XMLHttpRequest listeners.
      _initListeners(resolve, reject) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText =
          "Couldn't upload file:" + ` ${loader.file.name}.`;

        xhr.addEventListener("error", () => reject(genericErrorText));
        xhr.addEventListener("abort", () => reject());
        xhr.addEventListener("load", () => {
          const response = xhr.response;
          if (!response || response.error) {
            return reject(
              response && response.error
                ? response.error.message
                : genericErrorText
            );
          }

          // If the upload is successful, resolve the upload promise with an object containing
          // at least the "default" URL, pointing to the image on the server.
          resolve({
            default: response.data.images[0],
          });
        });

        if (xhr.upload) {
          xhr.upload.addEventListener("progress", (evt) => {
            if (evt.lengthComputable) {
              loader.uploadTotal = evt.total;
              loader.uploaded = evt.loaded;
            }
          });
        }
      }

      _sendRequest() {
        const data = new FormData();

        this.loader.file.then((result) => {
          data.append("file", result);
          this.xhr.send(data);
        });
      }
    }
    const { data } = this.state;
    const { getAllPropertyAddressData } = this.state;
    const { shouldSelectOption } = this.state;
    // const { isSelectDropDown } = this.props;
    // const { mode } = this.props
    // console.log(this.props.campaignType, "what is campaignType---->");
    return (
      <>
        {!isEmpty(this.state.propertyData) && (
          <Formik
            enableReinitialize={true}
            initialValues={this.state?.propertyData}
            validate={this.validateForm.bind(this)}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                this.setState({ errors: {} });
                values.userId = get(this.props.user, "user._id", "");
                values.status = this.state.selectedStatus;
                if (values.status === "") {
                  values.propertyStatus = "active";
                } else {
                  values.propertyStatus = values.status.toLowerCase();
                }
                values.type = this.state.selectedPropertyType;
                values.subPropertyType = this.state.selectedPropertySubType;
                values.propertyFeature = this.state.propertyFeatureArray;
                values.buildingFeature = this.state.buildingFeatureArray;
                let form_data = new FormData();
                // form_data.append(`numOfImage`, this.state.previewArr.length);
                values.numOfImage = this.state.previewArr.length;
                for (let key in values) {
                  if (key == "images") {
                    this.state.previewArr.map((file, key) => {
                      if (get(file, "preview", "").includes("amazon")||get(file, "preview", "").includes("cloudinary")) {
                        form_data.append("images", file.preview);
                      } else {
                        form_data.append("images", file);
                      }
                    });
                  } else if (key == "availability") {
                    if (values[key] === "1") {
                      form_data.append(key, this.state.availabilityDate);
                    } else {
                      form_data.append(key, values[key]);
                    }
                  } else {
                    form_data.append(key, values[key]);
                  }
                }
                if (true) {
                  this.setState({ isSpinLoading: true });
                  this.props.addProperty(form_data);
                }
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              getValues,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                {this.props.isSelectDropDown &&
                this.props.campaignType === "listing-website" &&
                getAllPropertyAddressData?.length > 1 ? (
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="label-primary">
                        Select from previous listings
                      </label>
                      <select
                        name="address"
                        value={propertyData.address}
                        //value={shouldSelectOption ? "":propertyData.address}
                        onChange={(e) => this.handleChangeValueNew(e)}
                        onBlur={handleBlur}
                        className="form-control custom-select material-textfield-input"
                      >
                        <option
                          value=""
                          //disabled={shouldSelectOption?true:false}
                        >
                          Select
                        </option>
                        {size(this.state.getAllPropertyAddressData) > 0 &&
                          this.renderPropertyAddress(
                            this.state.getAllPropertyAddressData
                          )}
                      </select>

                      {/* {((errors.address &&
                        touched.address &&
                        !propertyData.address) ||
                        (get(this.state, "errors.address", false) &&
                          !propertyData.address)) && (
                        <div className="invalid-feedback">
                          {errors.address ||
                            get(this.state, "errors.address", "")}
                        </div>
                      )} */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group">
                  <label className="label-primary">
                    Type<span className="required">*</span>
                  </label>
                  <ul className="property__type--list">
                    {propertyType.map((data, i) => {
                      return (
                        <li key={i}>
                          <RadioInput
                            label={data.value}
                            value={data.key}
                            type="pro-type"
                            checked={this.state?.selectedPropertyType}
                            setter={this.changePropertyType}
                            // onChange={(e)=>this.changePropertyType(e,"selectedPropertType")}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="form-group">
                  <label className="label-primary">
                    Property Type<span className="required">*</span>
                  </label>
                  <ul className="property__type--list">
                    {propertySubType.map((data, i) => {
                      return (
                        <li key={i}>
                          <RadioInput
                            label={data.value}
                            value={data.key}
                            type="pro-sub-type"
                            checked={this.state.selectedPropertySubType}
                            setter={this.changePropertySubType}
                            // onChange={(e)=>this.changePropertySubType(e,"selectedPropertySubType")}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* <div className="form-group">
                <label className="label-primary">
                  Status<span className="required">*</span>
                </label>
                <div className="d-flex">
                  {propertyStatus.map((data, i) => {
                    return (
                      <>
                        <RadioGroupInput
                          value={data.key}
                          setter={this.onSelectedStatus}
                          checked={this.state.selectedStatus}
                          label={data.value}
                        />
                      </>
                    );
                  })}
                </div>
              </div> */}
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-group">
                      <label className="label-primary">
                        Street Address<span className="required">*</span>
                      </label>
                      <Autocomplete
                        className="form-control material-textfield-input"
                        name="address"
                        value={propertyData.address}
                        placeholder=""
                        onChange={(e) => this.handleChangeValue(e, "address")}
                        onBlur={handleBlur}
                        onPlaceSelected={(place) =>
                          this.selectedPlace(place, values, setFieldValue)
                        }
                        types={["geocode"]}
                        required
                      />
                      {((errors.address && !propertyData.address) ||
                        (get(this.address, "errors.address", false) &&
                          !propertyData.address) ||
                        this.state?.error.address ||
                        (!touched.address && !propertyData.address)) && (
                        <div className="invalid-feedback">
                          {this.state.selectedStatus !== "draft"
                            ? errors.address ||
                              get(this.state, "errors.address", "") ||
                              this.state?.error.address ||
                              "Please enter address"
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label-primary">Unit or Apt #</label>
                      <input
                        type="text"
                        className="form-control material-textfield-input"
                        name="units"
                        onChange={(e) => this.handleChangeValue(e, "units")}
                        onBlur={handleBlur}
                        value={propertyData.units}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label-primary">
                        City<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control material-textfield-input"
                        name="city"
                        onChange={(e) => this.handleChangeValue(e, "city")}
                        onBlur={handleBlur}
                        value={propertyData.city}
                        required
                      />
                      {((errors.city && !propertyData.city) ||
                        (get(this.city, "errors.city", false) &&
                          !propertyData.city) ||
                        this.state?.error.city ||
                        (!touched.city && !propertyData.city)) && (
                        <div className="invalid-feedback">
                          {this.state.selectedStatus !== "draft"
                            ? errors.city ||
                              get(this.state, "errors.city", "") ||
                              this.state?.error.city ||
                              "Please enter city"
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label-primary">
                        State<span className="required">*</span>
                      </label>
                      <select
                        name="state"
                        value={propertyData.state}
                        onChange={(e) => this.handleChangeValue(e, "state")}
                        onBlur={handleBlur}
                        className="form-control custom-select material-textfield-input"
                        required
                      >
                        <option
                          value="0"
                          disabled={values.state ? true : false}
                        >
                          Select
                        </option>
                        {size(getAllUsStates) > 0 &&
                          this.renderStates(getAllUsStates)}
                      </select>
                      {((errors.state && !propertyData.state) ||
                        (get(this.state, "errors.state", false) &&
                          !propertyData.state) ||
                        this.state?.error.state ||
                        (!touched.state && !propertyData.state)) && (
                        <div className="invalid-feedback">
                          {this.state.selectedStatus !== "draft"
                            ? errors.state ||
                              get(this.state, "errors.state", "") ||
                              this.state?.error.state ||
                              "Please select state"
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label-primary">
                        Zip<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control material-textfield-input"
                        name="zipcode"
                        onChange={(e) => this.handleChangeValue(e, "zipcode")}
                        onBlur={handleBlur}
                        value={propertyData.zipcode}
                        required
                      />
                      {((errors.zipcode && !propertyData.zipcode) ||
                        (get(this.state, "errors.zipcode", false) &&
                          !propertyData.zipcode) ||
                        this.state?.error.zipcode ||
                        (!touched.zipcode && !propertyData.zipcode)) && (
                        <div className="invalid-feedback">
                          {this.state.selectedStatus !== "draft"
                            ? errors.zipcode ||
                              get(this.state, "errors.zipcode", "") ||
                              this.state?.error.zipcode ||
                              "Please enter zipcode"
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  {this.state.selectedPropertyType === "rental" ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="label-primary">
                          Price
                          {this.state.selectedStatus !== "draft" ? (
                            <span className="required">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                          className="form-control material-textfield-input"
                          name="monthlyRent"
                          onChange={(e) =>
                            this.handleChangeValue(e, "monthlyRent")
                          }
                          onBlur={handleBlur}
                          value={propertyData.monthlyRent}
                          required
                        />
                        {((errors.monthlyRent && !propertyData.monthlyRent) ||
                          (get(this.state, "errors.monthlyRent", false) &&
                            !propertyData.monthlyRent) ||
                          this.state?.error.monthlyRent ||
                          (!touched.monthlyRent &&
                            !propertyData.monthlyRent)) && (
                          <div className="invalid-feedback">
                            {this.state.selectedStatus !== "draft"
                              ? errors.monthlyRent ||
                                get(this.state, "errors.monthlyRent", "") ||
                                this.state?.error.monthlyRent ||
                                "Please enter monthly rent"
                              : ""}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="label-primary">
                          Price
                          {this.state.selectedStatus !== "draft" ? (
                            <span className="required">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                          className="form-control material-textfield-input"
                          name="askingPrice"
                          onChange={(e) =>
                            this.handleChangeValue(e, "askingPrice")
                          }
                          onBlur={handleBlur}
                          value={propertyData.askingPrice}
                          required
                        />
                        {((errors.askingPrice && !propertyData.askingPrice) ||
                          (get(this.state, "errors.askingPrice", false) &&
                            !propertyData.askingPrice) ||
                          this.state?.error.askingPrice ||
                          (!touched.askingPrice &&
                            !propertyData.askingPrice)) && (
                          <div className="invalid-feedback">
                            {this.state.selectedStatus !== "draft"
                              ? errors.askingPrice ||
                                get(this.state, "errors.askingPrice", "") ||
                                this.state?.error.askingPrice ||
                                "Please enter asking price"
                              : ""}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    {this.state.selectedPropertyType === "rental" ? (
                      <div className="form-group">
                        <label className="label-primary">
                          Square Footage
                          {this.state.selectedStatus !== "draft" ? (
                            <span className="required">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                          type="text"
                          className="form-control material-textfield-input"
                          name="squareFootage"
                          onChange={(e) =>
                            this.handleChangeValue(e, "squareFootage")
                          }
                          onBlur={handleBlur}
                          value={propertyData.squareFootage}
                          required
                        />
                        {((errors.squareFootage &&
                          !propertyData.squareFootage) ||
                          (get(this.state, "errors.squareFootage", false) &&
                            !propertyData.squareFootage) ||
                          this.state?.error.squareFootage ||
                          (!touched.squareFootage &&
                            !propertyData.squareFootage)) && (
                          <div className="invalid-feedback">
                            {this.state.selectedStatus !== "draft"
                              ? errors.squareFootage ||
                                get(this.state, "errors.squareFootage", "") ||
                                this.state?.error.squareFootage ||
                                "Please enter square footage"
                              : ""}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="form-group">
                        <label className="label-primary">
                          Sq.Ft
                          {this.state.selectedStatus !== "draft" ? (
                            <span className="required">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                          type="text"
                          className="form-control material-textfield-input"
                          name="interiorSqFeet"
                          onChange={(e) =>
                            this.handleChangeValue(e, "interiorSqFeet")
                          }
                          onBlur={handleBlur}
                          value={propertyData.interiorSqFeet}
                          required
                        />
                        {((errors.interiorSqFeet &&
                          !propertyData.interiorSqFeet) ||
                          (get(this.state, "errors.interiorSqFeet", false) &&
                            !propertyData.interiorSqFeet) ||
                          this.state?.error.interiorSqFeet ||
                          (!touched.interiorSqFeet &&
                            !propertyData.interiorSqFeet)) && (
                          <div className="invalid-feedback">
                            {this.state.selectedStatus !== "draft"
                              ? errors.interiorSqFeet ||
                                get(this.state, "errors.interiorSqFeet", "") ||
                                this.state?.error.interiorSqFeet ||
                                "Please enter sq feet"
                              : ""}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-primary">
                        Bedrooms
                        {this.state.selectedStatus !== "draft" ? (
                          <span className="required">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        allowNegative={false}
                        // prefix={'$'}
                        // type="text"
                        className="form-control material-textfield-input"
                        name="bedrooms"
                        onChange={(e) => this.handleChangeValue(e, "bedrooms")}
                        onBlur={handleBlur}
                        value={propertyData.bedrooms}
                        required
                      />
                      {((errors.bedrooms && !propertyData.bedrooms) ||
                        (get(this.state, "errors.bedrooms", false) &&
                          !propertyData.bedrooms) ||
                        this.state?.error.bedrooms ||
                        (!touched.bedrooms && !propertyData.bedrooms)) && (
                        <div className="invalid-feedback">
                          {this.state.selectedStatus !== "draft"
                            ? errors.bedrooms ||
                              get(this.state, "errors.bedrooms", "") ||
                              this.state?.error.bedrooms ||
                              "Please enter bedrooms"
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-primary">
                        Bathrooms
                        {this.state.selectedStatus !== "draft" ? (
                          <span className="required">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        allowNegative={false}
                        className="form-control material-textfield-input"
                        name="bathrooms"
                        onChange={(e) => this.handleChangeValue(e, "bathrooms")}
                        onBlur={handleBlur}
                        value={propertyData.bathrooms}
                        required
                      />
                      {((errors.bathrooms && !propertyData.bathrooms) ||
                        (get(this.state, "errors.bathrooms", false) &&
                          !propertyData.bathrooms) ||
                        this.state?.error.bathrooms ||
                        (!touched.bathrooms && !propertyData.bathrooms)) && (
                        <div className="invalid-feedback">
                          {this.state.selectedStatus !== "draft"
                            ? errors.bathrooms ||
                              get(this.state, "errors.bathrooms", "") ||
                              this.state?.error.bathrooms ||
                              "Please enter bathrooms"
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.selectedPropertyType === "rental" && (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">
                            Min Lease
                            {this.state.selectedStatus !== "draft" ? (
                              <span className="required">*</span>
                            ) : (
                              ""
                            )}
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            className="form-control material-textfield-input"
                            name="minMonths"
                            onChange={(e) =>
                              this.handleChangeValue(e, "minMonths")
                            }
                            onBlur={handleBlur}
                            value={propertyData.minMonths}
                            required
                          />
                          {((errors.minMonths && !propertyData.minMonths) ||
                            (get(this.state, "errors.minMonths", false) &&
                              !propertyData.minMonths) ||
                            this.state?.error.minMonths ||
                            (!touched.minMonths && !propertyData.minMonths) ||
                            (propertyData.type === "rental" &&
                              parseInt(get(propertyData, "minMonths", 0), 10) >
                                parseInt(
                                  get(propertyData, "maxMonths", 0),
                                  10
                                )) ||
                            (!touched.minMonths &&
                              touched.maxMonths &&
                              propertyData.minMonths === "") ||
                            (touched.maxMonths &&
                              touched.minMonths &&
                              propertyData.maxMonths !== "" &&
                              propertyData.minMonths === "")) && (
                            <div className="invalid-feedback">
                              {this.state.selectedStatus !== "draft"
                                ? errors.minMonths ||
                                  get(this.state, "errors.minMonths", "") ||
                                  (touched.maxMonths &&
                                  touched.minMonths &&
                                  propertyData.maxMonths !== "" &&
                                  propertyData.type === "rental" &&
                                  propertyData.minMonths === ""
                                    ? "Please enter minimum lease"
                                    : propertyData.type === "rental" &&
                                      touched.maxMonths &&
                                      touched.minMonths &&
                                      propertyData.minMonths !== "" &&
                                      propertyData.maxMonths !== "" &&
                                      parseInt(propertyData.maxMonths) <
                                        parseInt(propertyData.minMonths)
                                    ? "Max lease must be greater than min lease"
                                    : "Please enter minimum lease")
                                : ""}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">
                            Max Lease
                            {this.state.selectedStatus !== "draft" ? (
                              <span className="required">*</span>
                            ) : (
                              ""
                            )}
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            className="form-control material-textfield-input"
                            name="maxMonths"
                            onChange={(e) =>
                              this.handleChangeValue(e, "maxMonths")
                            }
                            onBlur={handleBlur}
                            value={propertyData.maxMonths}
                            required
                          />
                          {((errors.maxMonths && !propertyData.maxMonths) ||
                            (get(this.state, "errors.maxMonths", false) &&
                              !propertyData.maxMonths) ||
                            this.state?.error?.maxMonths ||
                            (!touched.maxMonths && !propertyData.maxMonths) ||
                            (propertyData.type === "rental" &&
                              parseInt(get(propertyData, "minMonths", 0), 10) >
                                parseInt(
                                  get(propertyData, "maxMonths", 0),
                                  10
                                )) ||
                            (!touched.maxMonths &&
                              touched.minMonths &&
                              propertyData.maxMonths === "") ||
                            (touched.maxMonths &&
                              touched.minMonths &&
                              propertyData.maxMonths === "" &&
                              propertyData.minMonths !== "")) && (
                            <div className="invalid-feedback">
                              {this.state.selectedStatus !== "draft"
                                ? errors.maxMonths ||
                                  get(this.state, "errors.maxMonths", "") ||
                                  this.state?.error?.maxMonths ||
                                  (touched.maxMonths &&
                                  touched.minMonths &&
                                  propertyData.minMonths !== "" &&
                                  propertyData.type === "rental" &&
                                  propertyData.maxMonths === ""
                                    ? "Please enter maximum lease"
                                    : propertyData.type === "rental" &&
                                      touched.maxMonths &&
                                      touched.minMonths &&
                                      propertyData.minMonths !== "" &&
                                      propertyData.maxMonths !== "" &&
                                      parseInt(propertyData.minMonths) >
                                        parseInt(propertyData.maxMonths)
                                    ? "Max lease must be greater than min lease"
                                    : "Please enter maximum lease")
                                : ""}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {this.state.selectedPropertyType === "rental" && (
                  <div className="form-group">
                    <label className="label-primary">
                      Availability
                      {this.state.selectedStatus !== "draft" ? (
                        <span className="required">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control material-textfield-input"
                      name="availability"
                      onChange={(e) =>
                        this.handleChangeValue(e, "availability")
                      }
                      onBlur={handleBlur}
                      value={propertyData.availability}
                      required
                    />
                    {((errors.availability && !propertyData.availability) ||
                      (get(this.state, "errors.availability", false) &&
                        !propertyData.availability) ||
                      this.state?.error.availability ||
                      (!touched.availability &&
                        !propertyData.availability)) && (
                      <div className="invalid-feedback">
                        {this.state.selectedStatus !== "draft"
                          ? errors.availability ||
                            get(this.state, "errors.availability", "") ||
                            this.state?.error.availability ||
                            "Please enter availability"
                          : ""}
                      </div>
                    )}
                  </div>
                )}
                <div className="form-group">
                  <label className="label-primary">
                    Property Description
                    {this.state.selectedStatus !== "draft" ? (
                      <span className="required">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  {/*
                        <div className="ck__editor--customized">
                        <CKEditor
                          editor={DecoupledEditor}
                          data={data}
                          config={custom_config}
                          onInit={(editor) => {
                            editor.execute("fontSize", { value: 20 });
                            editor.ui
                              .getEditableElement()
                              .parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                              );
                          }}
                          onChange={(event, editor) =>
                            this.onEditorChange(editor.getData())
                          }
                        />*/}
                  <div className="form-group material-textfield">
                    <textarea
                      type="text"
                      className="form-control material-textfield-input"
                      name="description"
                      onChange={(e) => this.handleChangeValue(e, "description")}
                      onBlur={handleBlur}
                      value={propertyData.description}
                      required
                      cols={20}
                      rows={10}
                      wrap="description"
                    ></textarea>
                    {/* {((errors.description &&
                      touched.description &&
                      !propertyData.description) ||
                      (get(this.state, "errors.description", false) &&
                        !propertyData.description)||
                        this.state?.error.description) && (
                      <div className="invalid-feedback">
                        {this.state.selectedStatus !== "draft"
                          ?  get(this.state, "errors.description", "")
                          || this.state?.error.description ||
                          "Please enter description" 
                        : ""}
                      </div>
                    )} */}
                    {((errors.description && !propertyData.description) ||
                      (get(this.state, "errors.description", false) &&
                        !propertyData.description) ||
                      this.state?.error.description ||
                      (!touched.description && !propertyData.description)) && (
                      <div className="invalid-feedback">
                        {this.state.selectedStatus !== "draft"
                          ? errors.description ||
                            get(this.state, "errors.description", "") ||
                            this.state?.error.description ||
                            "Please enter description"
                          : ""}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label className="label-primary">
                    Property Photos
                    {this.state.selectedStatus !== "draft" ? (
                      <span className="required">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <Dropzone
                    accept="image/*"
                    multiple={true}
                    disabled={this.state.previewArr.length >= 50}
                    onDrop={(acceptedFiles) =>
                      this.addImages(
                        acceptedFiles,
                        values.images,
                        setFieldValue
                      )
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="dropzone">
                        <div className="dropzone__area" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <button type="button" className="btn btn__purple">
                            {this.state.loader ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              <>
                                <Plus className="btn__icon" /> Upload Photo
                              </>
                            )}
                          </button>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {((errors.images && touched.images && !propertyData.images) ||
                    (get(this.state, "errors.images", false) &&
                      !propertyData.images)) && (
                    <div className="invalid-feedback">
                      {this.state.selectedStatus !== "draft"
                        ? errors.images || get(this.state, "errors.images", "")
                        : ""}
                    </div>
                  )}
                  {imageError == "imageLength" && (
                    <>
                      <div className="invalid-feedback">Maximum 50 photos</div>
                    </>
                  )}
                  {imageError === "imageType" && (
                    <>
                      <div className="invalid-feedback">
                        Please use jpeg or png format.
                      </div>
                    </>
                  )}
                  {imageError == "imageize" && (
                    <>
                      <div className="invalid-feedback">
                        Please upload an image that is 10mb or smaller in size.
                        You can use the following compressors to make shrink
                        your images:
                      </div>
                      <div className="invalid-feedback">
                        JPEG or JPG: https://compressjpeg.com/
                      </div>
                      <div className="invalid-feedback">
                        PNG: https://compresspng.com/"
                      </div>
                    </>
                  )}
                  {this.state.previewArr.length == 50 && (
                    <div className="invalid-feedback">Maximum 50 photos</div>
                  )}
                  <div className="dropzone__grid">
                    {/*this.state.previewArr.length > 0 &&
                          this.state.previewArr.map((value, i) => {
                            return (
                              <div className="dropzone__grid--item">
                                <div className="dropzone__grid--preview">
                                  <img
                                    className="preview__thumb"
                                    src={value.preview}
                                    alt=""
                                  />
                                  <div className="dropzone__grid--remove">
                                    <button className="btn btn__remove">
                                      <Remove
                                        onClick={(e) => this.removeImage(i)}
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })*/}
                    <SortableList
                      axis={"xy"}
                      onRemove={(index, onReset) =>
                        this.removeImage(index, onReset)
                      }
                      // imageRotate={(index) => this.imageRotate(index)}
                      data={this.state.previewArr}
                      onSortEnd={this.orderList.bind(this)}
                      onReset={() => this.resetImages(setFieldValue)}
                    />
                  </div>
                  {this.state.previewArr.length == 0 && (
                    <div className="invalid-feedback">
                      Please upload at least one property image
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label className="label-primary">Property Features</label>
                  <ul className="property__features--list">
                    {propertyFeature.map((option, index) => {
                      return (
                        <li key={index}>
                          <CheckboxComponent
                            index={index}
                            value={option}
                            handleFeature={this.handlePropertyFeature.bind(
                              this
                            )}
                            featureArray={this.state.propertyFeatureArray}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="form-group">
                  <label className="label-primary">Building Features</label>
                  <ul className="building__features--list">
                    {buildingFeature.map((option, index) => {
                      return (
                        <li key={index}>
                          <CheckboxComponent
                            index={index}
                            value={option}
                            handleFeature={this.handleBuildingFeature.bind(
                              this
                            )}
                            featureArray={this.state.buildingFeatureArray}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* <button
                      type="submit"
                      disabled={this.state.isSpinLoading}
                      className="btn btn-lg btn__purple w-100"
                    >
                      {this.state.isSpinLoading ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <>Create a listing</>
                      )}
                    </button> */}
              </form>
            )}
          </Formik>
        )}
      </>
    );
  }
}
