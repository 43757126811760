import React, { PureComponent } from "react";
import FooterComponent from "../Footer/component";
import Header from "../Header/component";
import { get } from "lodash";
import SocialAtLeftComponent from "../SocialAtLeft/component";
// import PropTypes from 'prop-types'

import "./styles.scss";

export default class PageNotFoundComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  componentDidMount() {
    document.getElementById("favicon").href = get(
      this.props.leadPageSetting,
      "favicon",
      "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png"
    );
  }

  render() {
    return (
      <>
        <main className="listing__website--main">
          <Header {...this.props} />
          <div className="page__not__found">
            <div className="page__not__found--wrapper">
              <div className="page__not__found--content">
                <h1>404 Page Not Found</h1>
                <p>The page you requested does not exist anymore.</p>
              </div>
            </div>
          </div>
          <FooterComponent {...this.props} />
          <SocialAtLeftComponent {...this.props} />
        </main>
      </>
    );
  }
}
