import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Close } from "../../../components/icons";
import Preview from "../../../images/preview.png";
import { get, isEmpty } from "lodash";
import { Formik } from "formik";
import moment from "moment";
import "./styles.scss";
import ReCAPTCHA from "react-google-recaptcha";

const MARKETING_URL =
  process.env.REACT_APP_MARKETING_URL || "https://yardpost.com";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default class SignupComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEmailExists: false,
      plan: 0,
      tillDate: moment(),
      isCompanyNameExists: false,
      users: {},
      iniLoading: true,
      lastNameError: false,
      isSpinLoading: false,
      recaptcha: false,
    };
  }

  componentDidMount() {
    document.title = "Signup | Yardpost";
    if (!isEmpty(this.props)) {
      if (localStorage.getItem("AdminAuthorization")) {
        this.props.getUser();

        this.props.history.push("/admin/active");
      } else if (localStorage.getItem("Authorization")) {
        this.props.getUser();

        this.props.history.push("/landing-pages");
      }
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    return { user: props.user };
  };

  componentDidUpdate(previousProps) {
    if(this.props.signupPhase !== previousProps.signupPhase){
      if(this.props.signupPhase === "error"){
        if (this.captcha) {
          this.captcha.reset();
        }
      }
    }
    if (this.props.user !== previousProps.user) {
      this.setState({ user: this.props.user });
      if (!this.props.user.duplicate && this.props.user.duplicateKey === "") {
        if (!get(this.props.user, "user.isTrialEnd", "")) {
          if (!get(this.props.user, "user.redirectStatus", false)) {
            this.props.updateUserStatus({
              userId: get(this.props.user, "user._id", ""),
              redirectStatus: true,
            });
            // this.props.history.push('/landing-pages')
            window.location.href = `/successful-sign-up/${get(
              this.props.user,
              "user._id",
              ""
            )}`;
          } else {
            this.props.history.push("/landing-pages");
          }
        }
      } else {
        if (
          this.props.user.duplicate &&
          this.props.user.duplicateKey?.includes("Email")
        ) {
          this.setState({
            isEmailExists: true,
            isSpinLoading: false,
            isLoading: false,
            recaptcha: false,
          });
        }
        if (
          this.props.user.duplicate &&
          this.props.user.duplicateKey?.includes("Website Name")
        ) {
          this.setState({
            isCompanyNameExists: true,
            isSpinLoading: false,
            isLoading: false,
            recaptcha: false,
          });
        }
        if (
          this.props.user.duplicate &&
          this.props.user.duplicateKey?.includes("domain")
        ) {
          this.setState({
            isStoreExists: true,
            isLoading: false,
            recaptcha: false,
            isSpinLoading: false,
            isCompanyNameExists:true
          });
        }
        if (
          this.props.user.duplicate &&
          this.props.user.duplicateKey?.includes("companyName")
        ) {
          this.setState({
            isCompanyExists: true,
            isLoading: false,
            recaptcha: false,
          });
        }
      }
    }
  }

  render() {
    const { user, isEmailExists, isCompanyNameExists } = this.state;
    const initialValues = Object.keys(get(this.props.user, "user", {})).length
      ? {
        email: get(this.props.user, "user.email", ""),
        firstName: get(this.props.user, "user.firstName", ""),
        lastName: get(this.props.user, "user.lastName", ""),
        phone: get(this.props.user, "user.phone", ""),
        companyName: get(this.props.user, "user.companyName", ""),
        title: get(this.props.user, "user.title", ""),
        websiteName: get(this.props.user, "user.websiteName", "")
          ? get(this.props.user, "user.websiteName", "")
          : "",
        role: "Admin",
        profileImage: "",
        companyImage: "",
        captcha: false
      }
      : {
        email: get(this.props.user, "user.email", ""),
        firstName: get(this.props.user, "user.firstName", ""),
        lastName: get(this.props.user, "user.lastName", ""),
        phone: get(this.props.user, "user.phone", ""),
        companyName: get(this.props.user, "user.companyName", ""),
        title: get(this.props.user, "user.title", ""),
        websiteName: get(this.props.user, "user.websiteName", "")
          ? get(this.props.user, "user.websiteName", "")
          : "",
        role: "Admin",
        profileImage: "",
        companyImage: "",
        captcha: false
      };
    return (
      <>
        <div className="signup__main">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <Link to="/login" className="btn btn__close">
                      <Close />
                    </Link>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">Sign up for Yardpost</div>
                </div>
              </div>
            </div>
          </header>

          <main className="signup__left-right-main">
            <div className="left__view">
              <div className="mac__window">
                <div className="mac__window--header">
                  <div className="mac__window--dot dot__1"></div>
                  <div className="mac__window--dot dot__2"></div>
                  <div className="mac__window--dot dot__3"></div>
                </div>
                <div className="mac__window--body">
                  <img src={Preview} alt="" />
                </div>
              </div>
            </div>
            <div className="right__view">
              <div className="view__wrapper">
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, { setSubmitting }) => {
                    this.setState({
                      isSpinLoading: true,
                      isCompanyNameExists: false,
                      isEmailExists: false,
                    });
                    setTimeout(() => {
                      setSubmitting(false);
                      values.websiteName = values.websiteName.replace(
                        ".yardpost.com",
                        ""
                      );
                      if (!get(user, "user._id", "")) {
                        let form_data = new FormData();
                        for (let key in values) {
                          form_data.append(key, values[key]);
                        }
                        // if (this.captcha) {
                        //   this.captcha.reset();
                        // }
                        this.props.signupUser(form_data);
                      } else {
                        let form_data = new FormData();
                        for (let key in values) {
                          form_data.append(key, values[key]);
                        }
                        this.setState({ isLoading: true });
                        this.props.editUser(form_data);
                      }
                    }, 400);
                  }}
                  validate={(values) => {
                    this.setState({
                      isCompanyNameExists: false,
                      isEmailExists: false,
                    });
                    const errors = {};
                    if (!this.state.recaptcha) {
                      errors.captcha = "Please select re-captcha";
                    }
                    if (!values.firstName) {
                      errors.firstName = "Please enter first name";
                    }
                    if (!values.lastName) {
                      this.setState({ lastNameError: true });
                      errors.lastName = "Please enter last name";
                    }
                    if (!values.email) {
                      errors.email = "Please enter an email";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }
                    if (!values.companyName) {
                      this.setState({ companyName: true });
                      errors.companyName = "Please enter company name";
                    }
                    if (!values.title) {
                      this.setState({ title: true });
                      errors.title = "Please enter title";
                    }
                    if (!values.websiteName) {
                      errors.websiteName = "Please enter Yardpost domain";
                    }
                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="card-body">
                        <div className="signup__head">
                          <h2>Start your free 14-day trial of YardPost</h2>
                          <p>No credit card required</p>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label-primary">
                                First Name<span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                                className="form-control"
                                required
                              />
                              {errors.firstName && touched.firstName && (
                                <div className="invalid-feedback">
                                  {errors.firstName}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label-primary">Last Name<span className="required">*</span></label>
                              <input
                                type="text"
                                name="lastName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                                className="form-control"
                                required
                              />
                              {errors.lastName && touched.lastName && !isSubmitting && (
                                <div className="invalid-feedback">
                                  {errors.lastName}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label-primary">
                                Title<span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                name="title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title}
                                className="form-control"
                                required
                              />
                              {errors.title && touched.title && !isSubmitting && (
                                <div className="invalid-feedback">
                                  {errors.title}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label-primary">Company Name<span className="required">*</span></label>
                              <input
                                type="text"
                                name="companyName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.companyName}
                                className="form-control"
                                required
                              />
                              {errors.companyName  && touched.companyName && !isSubmitting && (
                                <div className="invalid-feedback">
                                  {errors.companyName}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="label-primary">Company Email<span className="required">*</span></label>
                          <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            className="form-control"
                            required
                          />
                          {errors.email && touched.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                          {isEmailExists && (
                            <div className="invalid-feedback">
                              Email already exists. Please enter another email
                            </div>
                          )}
                        </div>

                        <hr />

                        <div className="signup__info">
                          <h4>
                            Enter the website name you would like to use for
                            your new website. (Without spaces)
                          </h4>
                          <p>
                            <b>Quick tip:</b> Your website name could be your
                            name (first and/or last) or the name of your real
                            estate company. Now's your time to shine!
                          </p>
                        </div>

                        <div className="form-group">
                          <label className="label-primary">Website Name<span className="required">*</span></label>
                          <input
                            type="text"
                            name="websiteName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.websiteName}
                            className="form-control"
                            required
                          />
                          {errors.websiteName && touched.websiteName && (
                            <div className="invalid-feedback">
                              {errors.websiteName}
                            </div>
                          )}
                          {isCompanyNameExists && (
                            <div className="invalid-feedback">
                              Website name already exists. Please enter another
                              website name.
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <ReCAPTCHA
                            ref={(r) => (this.captcha = r)}
                            sitekey="6Lc2BMQZAAAAAARE4jzuMJTQSjPIZLCGX_bNOtUc"
                            onChange={() => {
                              this.setState({ recaptcha: true }, () => {
                                this.forceUpdate();
                              });
                              this.onChange();
                            }}
                          />
                          {!this.state.recaptcha && errors.captcha && touched.captcha && !isSubmitting && (
                            <div className="invalid-feedback">
                              {errors.captcha}
                            </div>
                          )}
                        </div>
                        <div className="view__wrapper--cta">
                          <button
                            type="submit"
                            className="btn btn-lg btn__purple w-100"
                            disabled={this.state.isSpinLoading || isSubmitting}
                          >
                            {this.state.isSpinLoading ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              "Start my free trial"
                            )}
                          </button>
                        </div>
                        <div className="view__wrapper--footer">
                          Already have an account?{" "}
                          <Link to="/login" className="btn__link--underline">
                            Log In
                          </Link>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}
