import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import TopNavigation from "../../components/common/TopNavigation/component";
import TablePagination from "../../components/common/TablePagination/component";
import ActionMore from "../../components/common/ActionMore/container";
import CheckboxOnly from "../../components/ui/CheckboxOnly/component";
import { Export } from "../../components/icons";
import moment from "moment";
import { get, size, isEmpty } from "lodash";
import "./styles.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, notification } from "antd";
import { CSVLink } from "react-csv";

export default class AdminComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      activeTab: "active",
      sortKey: "createdAt",
      sortType: "desc",
      agentData: [],
      isActiveAgent: true,
      isNotActiveAgent: false,
      isAbandonedAgent: false,
      checkMarkData: [],
      page: 1,
      totalCount: 0,
      currentData: 0,
      previousStep: 0,
      isLoading: false,
      nextStep: 0,
    };
  }

  impersonate = (id) => {
    localStorage.setItem("userId", id);
    localStorage.removeItem("UserAuthorization");
    this.props.userImpersonate({ id });
  };

  pageChange(value) {
    const { nextStep, previousStep, page } = this.state;
    if (value === "next") {
      let next = nextStep - 1;
      this.setState({ page: this.state.page + 1 }, () => {
        this.forceUpdate();
        this.props.getAdminAgent({
          activeTab: this.state.activeTab,
          sortKey: this.state.sortKey,
          sortType: this.state.sortType,
          page: this.state.page,
          isLoading: true,
        });
      });
    } else if (value === "previous") {
      this.setState(
        { page: this.state.page - 1 === 0 ? 1 : this.state.page - 1 },
        () => {
          this.forceUpdate();
          this.props.getAdminAgent({
            activeTab: this.state.activeTab,
            sortKey: this.state.sortKey,
            sortType: this.state.sortType,
            page: this.state.page,
            isLoading: true,
          });
          // console.log(nextStepCount,'nextStep',page, previousStep)
        }
      );
    }
  }

  onTabChange(tab) {
    this.setState({ activeTab: tab, checkMarkData: [] }, () => {
      if (tab === "active") {
        this.props.history.push("/admin/active");
        this.setState({
          isActiveAgent: true,
          isNotActiveAgent: false,
          isAbandonedAgent: false,
          page: 1,
          previousStep: 0,
          nextStep: 0,
          isLoading: true,
          agentData: [],
        });
      } else if (tab === "not-active") {
        this.setState({
          isActiveAgent: false,
          isNotActiveAgent: true,
          isAbandonedAgent: false,
          page: 1,
          previousStep: 0,
          nextStep: 0,
          isLoading: true,
          agentData: [],
        });
        this.props.history.push("/admin/not-active");
      } else if (tab === "app-sumo") {
        this.setState({
          isActiveAgent: false,
          isNotActiveAgent: true,
          isAbandonedAgent: false,
          page: 1,
          previousStep: 0,
          nextStep: 0,
          isLoading: true,
          agentData: [],
        });
        this.props.history.push("/admin/app-sumo");
      } else if (tab === "not-signed-up") {
        this.setState({
          isActiveAgent: false,
          isNotActiveAgent: false,
          isAbandonedAgent: true,
          page: 1,
          previousStep: 0,
          nextStep: 0,
          isLoading: true,
          agentData: [],
        });
        this.props.history.push("/admin/not-signed-up");
      }
      this.props.getAdminAgent({
        activeTab: tab,
        sortKey: this.state.sortKey,
        sortType: this.state.sortType,
        page: 1,
      });
    });
  }

  onCheck = async (item, key = "single") => {
    const { checkMarkData, activeTab, getPropertyData, allChecked } =
      this.state;
    if (key === "single") {
      const found = checkMarkData.findIndex((el) => el._id === item._id);
      if (found === -1) {
        item.createdAt = moment(item.createdAt).format("MMM D, YYYY LT");
        item.siteUrl = "https://" + item.domain;
        checkMarkData.push(item);
        this.setState({ checkMarkData }, () => {
          this.forceUpdate();
        });
      } else {
        checkMarkData.splice(found, 1);
        this.setState({ checkMarkData }, () => {
          this.forceUpdate();
        });
      }
    } else {
      if (!allChecked) {
        let usersData = [];
        let dataPromise = this.state.agentData.map((data) => {
          data.siteUrl = "https://" + data.domain;
          data.createdAt = moment(data.createdAt).format("MMM D, YYYY  LT");
          usersData.push(data);
        });
        await Promise.all(dataPromise);
        this.setState({
          allChecked: true,
          checkMarkData: usersData,
        });
      } else {
        this.setState({ allChecked: false, checkMarkData: [] });
      }
    }
  };

  componentDidMount() {
    document.title = "Admin Dashboard | Yardpost";
    document.getElementById("favicon").href =
      "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png";
    let tab = this.props.location.pathname;
    let selectedTab = "";
    if (localStorage.getItem("Authorization")) {
      this.props.history.push("/social-links");
    }
    if (tab === "/admin/active") {
      selectedTab = "active";
      this.setState({
        isActiveAgent: true,
        isNotActiveAgent: false,
        isLoading: true,
        isAbandonedAgent: false,
      });
    } else if (tab === "/admin/not-active") {
      selectedTab = "not-active";
      this.setState({
        isAbandonedAgent: false,
        isActiveAgent: false,
        isNotActiveAgent: true,
        isLoading: true,
      });
    } else if (tab === "/admin/app-sumo") {
      selectedTab = "app-sumo";
      this.setState({
        isAbandonedAgent: false,
        isActiveAgent: false,
        isNotActiveAgent: true,
        isLoading: true,
      });
    } else if (tab === "/admin/not-signed-up") {
      selectedTab = "not-signed-up";
      this.setState({
        isActiveAgent: false,
        isNotActiveAgent: false,
        isAbandonedAgent: true,
        isLoading: true,
      });
    }
    this.props.getAdminAgent({
      activeTab: selectedTab,
      sortKey: this.state.sortKey,
      sortType: this.state.sortType,
      page: this.state.page,
    });
    this.setState({ activeTab: selectedTab, checkMarkData: [] });
  }

  openNotificationWithIcon = (type) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: "User deleted successfully.",
    });
  };

  openNotification = (type) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: "Cancel subscription successfully.",
    });
  };

  componentDidUpdate() {
    document.title = "Admin Dashboard | Yardpost";
    document.getElementById("favicon").href =
      "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png";
    if (this.props.adminAgentPhase === "success") {
      this.props.resetAdmin();
      this.setState(
        {
          agentData: this.props.adminAgentData,
          totalCount: this.props.totalCount,
          isLoading: false,
        },
        () => {
          let nextPageCount = parseInt(Math.ceil(this.state.totalCount / 50));
          this.setState({ nextStep: nextPageCount });
          this.forceUpdate();
        }
      );
    }
    if (this.props.impersonatePhase === "loading") {
      this.props.resetAdmin();
      this.setState({ impersonateLoading: true });
    }
    if (this.props.impersonatePhase === "success") {
      this.props.resetAdmin();
      setTimeout(() => {
        this.props.history.push("/social-links");
      }, 500);
      this.setState({ impersonateLoading: false });
    }
    if (this.props.cancelSubscriptionPhase === "success") {
      this.props.resetSubscriptions();
      this.openNotification("success");
    }
    if (this.props.deleteAgentPhase === "success") {
      this.props.resetSubscriptions();
      this.openNotificationWithIcon("success");
      this.props.getAdminAgent({
        activeTab: this.state.activeTab,
        sortKey: this.state.sortKey,
        sortType: this.state.sortType,
      });
    }
  }

  render() {
    const headings = [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Email", key: "email" },
      { label: "Phone Number", key: "phone" },
      { label: "Site Url", key: "siteUrl" },
      { label: "Sign Up Date", key: "createdAt" },
      // { label: 'status', key: 'status' },
    ];
    const {
      activeTab,
      checkMarkData,
      isActiveAgent,
      isNotActiveAgent,
      isAbandonedAgent,
      agentData,
      isLoading,
    } = this.state;
    return (
      <>
        <main className="main" role="header">
          <div className="main__header">
            <TopNavigation {...this.props} />
          </div>
          <div className="page__header">
            <div className="page__header--container">
              <div className="page__header--row">
                <div className="page__header--left">
                  <h1 className="page__header--title">Admin Dash</h1>
                </div>
                <div className="page__header--right">
                  {size(checkMarkData) > 0 && (
                    <>
                      {isActiveAgent && (
                        <CSVLink
                          className="btn btn__default"
                          data={checkMarkData}
                          headers={headings}
                          filename={"ActiveAgents.csv"}
                        >
                          <Export className="btn__icon" /> Export
                        </CSVLink>
                      )}
                      {isNotActiveAgent && (
                        <CSVLink
                          className="btn btn__default"
                          data={checkMarkData}
                          headers={headings}
                          filename={"NotActiveAgents.csv"}
                        >
                          <Export className="btn__icon" /> Export
                        </CSVLink>
                      )}
                      {isAbandonedAgent && (
                        <CSVLink
                          className="btn btn__default"
                          data={checkMarkData}
                          headers={headings}
                          filename={"AbandonedAgents.csv"}
                        >
                          <Export className="btn__icon" /> Export
                        </CSVLink>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="tabs__links tabs__links--space">
            <button
              className={
                activeTab === "active" ? "tabs__link active" : "tabs__link"
              }
              onClick={this.onTabChange.bind(this, "active")}
            >
              Active
            </button>
            <button
              className={
                activeTab === "app-sumo" ? "tabs__link active" : "tabs__link"
              }
              onClick={this.onTabChange.bind(this, "app-sumo")}
            >
              AppSumo
            </button>
            <button
              className={
                activeTab === "not-active" ? "tabs__link active" : "tabs__link"
              }
              onClick={this.onTabChange.bind(this, "not-active")}
            >
              Not Active
            </button>
            <button
              className={
                activeTab === "not-signed-up"
                  ? "tabs__link active"
                  : "tabs__link"
              }
              onClick={this.onTabChange.bind(this, "not-signed-up")}
            >
              Abandoned Sign Ups
            </button>
          </div>
          <div className="custom__container">
            {isLoading ? (
              <Spin
                size="large"
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 30, color: "#000", align: "center" }}
                    spin
                  />
                }
                className="loader__full"
              />
            ) : (
              <>
                {agentData.length > 0 ? (
                  <div className="table-responsive table__responsive--custom">
                    <table className="table table__custom">
                      <thead>
                        <tr>
                          <th className="table__selection--column">
                            <label className="custom__checkbox--only">
                              <input
                                type="checkbox"
                                checked={size(checkMarkData)}
                                onChange={(e) =>
                                  this.onCheck(agentData, "multiple")
                                }
                                className="custom__checkbox--only-input"
                              />
                              <span className="custom__checkbox--only-check"></span>
                            </label>
                          </th>
                          <th>Sign up Date</th>
                          <th>Name</th>
                          <th>Email</th>
                          {activeTab === "app-sumo" ? (
                            <th>AppSumo Code</th>
                          ) : (
                            <></>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {agentData.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td className="table__selection--column">
                                <label className="custom__checkbox--only">
                                  <input
                                    type="checkbox"
                                    value={i}
                                    checked={checkMarkData.indexOf(data) !== -1}
                                    onChange={(e) =>
                                      this.onCheck(data, "single")
                                    }
                                    className="custom__checkbox--only-input"
                                  />
                                  <span className="custom__checkbox--only-check"></span>
                                </label>
                              </td>
                              <td>
                                {moment(data.createdAt).format(
                                  "MMM D, YYYY LT "
                                )}
                              </td>
                              <td>
                                {get(data, "firstName", "") +
                                  " " +
                                  get(data, "lastName", "")}
                              </td>
                              <td>{get(data, "email", "")}</td>
                              {activeTab === "app-sumo" && (
                                <td>
                                  {get(
                                    data,
                                    "subscription.discount.coupon.id",
                                    ""
                                  )}
                                </td>
                              )}
                              <td>
                                <button
                                  type="button"
                                  onClick={() => this.impersonate(data._id)}
                                  className="btn btn__link"
                                >
                                  Impersonate
                                </button>
                              </td>

                              <td className="table__cell--last">
                                <ActionMore
                                  class="admin-dash"
                                  className="admin-dash"
                                  data={data}
                                  {...this.props}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="table__blank">
                    <div className="table__blank--text">No users</div>
                  </div>
                )}
              </>
            )}
            {agentData.length > 0 && (
              <div className="table__pagination">
                <div className="table__pagination--results">
                  {size(agentData)} results
                </div>
                <div className="table__pagination--nav">
                  <>
                    {this.state.previousStep + (this.state.page - 1) ? (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--prev"
                        onClick={this.pageChange.bind(this, "previous")}
                      >
                        Previous
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--prev disabled"
                      >
                        Previous
                      </button>
                    )}
                  </>
                  <>
                    {this.state.nextStep - this.state.page > 0 ? (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--next"
                        onClick={this.pageChange.bind(this, "next")}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--next disabled"
                      >
                        Next
                      </button>
                    )}
                  </>
                </div>
              </div>
            )}
          </div>
        </main>
      </>
    );
  }
}
