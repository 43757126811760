import React, { PureComponent } from "react";
import Dropzone from "react-dropzone";
import { notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Plus, Remove } from "../../../../components/icons";
import { get, isEmpty } from "lodash";
import { Formik } from "formik";
import InputMask from "react-text-mask";
import NumberFormat from "react-number-format";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./styles.scss";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export default class ProfileComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEmailExists: false,
      isCompanyNameExists: false,
      isSpinLoading: false,
      profileImage: "",
      profileImageError: "",
      userData: "",
      imageUrl: "",
      isPhoneExits: false,
      imagePreviewUrl: "",
      countryCode: "us",
      phone: "",
    };
  }

  openNotificationWithIcon = (type) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: "Profile updated successfully",
    });
  };

  componentDidMount() {
    if (!isEmpty(this.props.user)) {
      this.props.getUser();
      this.setState({
        userData: this.props.userData,
        profileImage: get(this.props.userData, "profileImage", ""),
      });
    }
  }

  handleChangeCountryCode = (value, phone) => {
    console.log(value, phone);
    this.setState(
      { countryCode: value, phone: phone.replace(value, ""), phoneError: "" },
      () => {
        this.forceUpdate();
      }
    );
  };

  componentDidUpdate(prev) {
    if (!isEmpty(this.props.userData)) {
      this.setState({ userData: this.props.userData });
    }

    if (this.props.editUserPhase === "success") {
      this.props.getUser();
      this.props.resetLogin();
      this.openNotificationWithIcon("success");
      this.setState({ isSpinLoading: false });
    } else if (this.props.editUserPhase === "error") {
      this.props.resetLogin();
      if (
        this.props.editUserData.duplicate &&
        this.props.editUserData.duplicateKey[0] === "email"
      ) {
        this.setState({
          isEmailExists: true,
          isSpinLoading: false,
          isLoading: false,
        });
      }
      if (
        this.props.editUserData.duplicate &&
        this.props.editUserData.duplicateKey[0] === "companyName"
      ) {
        this.setState({
          isCompanyNameExists: true,
          isLoading: false,
          isSpinLoading: false,
        });
      }
    }
    if (this.props.userPhase === "success") {
      this.props.resetLoginUser();

      this.setState(
        {
          countryCode: get(this.props.user, "user.countryCode", "+1"),
          phone: get(this.props.user, "user.phone", ""),
          userData: get(this.props.user, "user", {}),
          profileImage: get(this.props.user, "user.profileImage", {}),
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  removeImageUrl(value) {
    this.setState({ imagePreviewUrl: "", imageUrl: "", profileImage: "" });
  }

  onDropImage = async (acceptedFiles, key) => {
    acceptedFiles[0].preview = URL.createObjectURL(acceptedFiles[0]);
    if (acceptedFiles[0].type.split("/")[0] === "image") {
      // let form_data = new FormData();
      // form_data.append("key", key);
      // form_data.append("images[]", acceptedFiles[0]);
      // form_data.append(`numOfImage`, 1);
      const { value } = await this.props.uploadImages(acceptedFiles[0]);
      this.setState(
        {
          imagePreviewUrl: URL.createObjectURL(acceptedFiles[0]),
          // profileImage: acceptedFiles[0],
          profileImage: value,
          profileImageError: "",
        },
        () => {
          this.forceUpdate();
          // console.log(this.state.profileImage,'profileImage')
        }
      );
    } else {
      this.setState(
        {
          profileImageError: "File type should be image.",
        },
        () => {
          this.forceUpdate();
          // console.log(this.state.profileImage,'profileImage')
        }
      );
    }
  };

  render() {
    const { userData, isCompanyNameExists, isEmailExists, isPhoneExits } =
      this.state;
    return (
      <>
        {isEmpty(userData) ? (
          <Spin
            size="large"
            indicator={
              <LoadingOutlined
                style={{ fontSize: 50, color: "#000", align: "center" }}
                spin
              />
            }
            className="loader__full"
          />
        ) : (
          <Formik
            initialValues={{
              companyName: get(userData, "companyName", ""),
              phone: this.state.phone,
              // yearOfExperience: get(userData, "yearOfExperience", ""),
              // totalTransaction: get(userData, "totalTransaction", ""),
              medianPrice: get(userData, "medianPrice", ""),
              // mlsMemberId: get(userData, "mlsMemberId", ""),
              // profileImage: this.state.profileImage,
              // profileImage: get(userData, "ProfileImage", ""),
              profileImage:
                this.state.profileImage !== ""
                  ? this.state.profileImage
                  : get(userData, "profileImage", ""),
              title: get(userData, "title", ""),
              email: get(userData, "email", ""),
              firstName: get(userData, "firstName", ""),
              lastName: get(userData, "lastName", ""),
              // companyName: get(userData, "websiteName", ""),
              role: "Admin",
            }}
            validate={(values) => {
              this.setState({
                isCompanyNameExists: false,
                isEmailExists: false,
              });
              values.phone = this.state.phone;
              const errors = {};
              if (!values.firstName) {
                errors.firstName = "Please enter first name";
              }
              if (!values.lastName) {
                errors.lastName = "Please enter last name";
              }
              if (!values.email) {
                errors.email = "Please enter email";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              console.log(this.state.phone.length, "");
              if (this.state.phone === "") {
                errors.phone = "Please enter valid number";
              } else if (this.state.phone.length < 9) {
                this.setState({
                  phoneError: "Please enter valid Number",
                });
                errors["phone"] = "Please enter valid phone number";
              }
              if (!values.companyName) {
                errors.companyName = "Please enter company name";
              }
              if (this.state.profileImage === "") {
                errors.profileImage = "Please upload profile image.";
                this.setState({
                  profileImageError: "Please upload profile image.",
                });
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              this.setState({
                isSpinLoading: true,
                isCompanyNameExists: false,
                isEmailExists: false,
              });
              setTimeout(() => {
                setSubmitting(false);
                this.setState({ isLoading: true });
                let form_data = new FormData();
                // values.profileImage = this.state.profileImage;
                values.profileImage =
                  this.state.profileImage !== ""
                    ? this.state.profileImage
                    : get(userData, "profileImage", "");
                values.phone = this.state.phone;
                values.countryCode = this.state.countryCode;
                // for (let key in values) {
                //   console.log("key----->", key, values, typeof values);
                //   if (values[key] === get(userData, "profileImage", "")) {
                //   } else {
                //     form_data.append(key, values[key]);
                //   }
                // }
                for (const [keyOF, valueOF] of Object.entries(values)) {
                  if (keyOF === "propertylist") {
                    form_data.append(
                      "propertylist",
                      JSON.stringify(this.state.propertylist)
                    );
                  } else {
                    form_data.append(keyOF, valueOF);
                  }
                }

                this.props.editUser(form_data);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-primary">
                        First Name<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        className="form-control"
                        required
                      />
                      {errors.firstName && (
                        <div className="invalid-feedback">
                          {errors.firstName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-primary">
                        Last Name<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        className="form-control"
                        required
                      />
                      {errors.lastName && (
                        <div className="invalid-feedback">
                          {errors.lastName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-primary">
                        Email Address<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="email"
                        onChange={(e) => {
                          handleChange(e);
                          this.setState({ isEmailExists: false }, () => {
                            this.forceUpdate();
                          });
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                        className="form-control"
                        required
                      />
                      {errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                      {isEmailExists && (
                        <div className="invalid-feedback">
                          Email already exists
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-primary">Phone Number</label>
                      <PhoneInput
                        inputProps={{
                          readOnly: false,
                          name: "phone",
                          required: true,
                          autoFocus: true,
                          className: "form-control form-control-flat",
                        }}
                        country={"us"}
                        value={this.state.countryCode + this.state.phone}
                        isValid={(value, country) => {
                          // console.log(value, country, "country");
                          // this.setState({countryCode: country.iso2})
                        }}
                        onChange={(phone, val) =>
                          this.handleChangeCountryCode(val.dialCode, phone)
                        }
                      />
                      {errors.phone && (
                        <div className="invalid-feedback">{errors.phone}</div>
                      )}
                      {isPhoneExits && (
                        <div className="invalid-feedback">
                          Phone number already exists
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="label-primary">
                    Profile Picture<span className="required">*</span>
                  </label>
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      this.onDropImage(acceptedFiles, "profileImage")
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="dropzone">
                        <div className="dropzone__area" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <button type="button" className="btn btn__purple">
                            <Plus className="btn__icon" /> {this.state.imagePreviewUrl || this.state.profileImage ? "Replace Photo" :"Upload Photo"}
                          </button>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {this.state.imagePreviewUrl ? (
                    <div className="dropzone__grid">
                      <div className="dropzone__grid--item">
                        <div className="dropzone__grid--preview">
                          <img
                            className="preview__thumb"
                            src={this.state.imagePreviewUrl}
                            alt=""
                          />
                          <div className="dropzone__grid--remove">
                            <button
                              onClick={this.removeImageUrl.bind(this, 1)}
                              className="btn btn__remove"
                            >
                              <Remove />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.profileImage && !this.state.imagePreviewUrl ? (
                    <div className="dropzone__grid">
                      <div className="dropzone__grid--item">
                        <div className="dropzone__grid--preview">
                          <img
                            className="preview__thumb"
                            src={this.state.profileImage}
                            alt=""
                          />
                          <div className="dropzone__grid--remove">
                            <button
                              onClick={this.removeImageUrl.bind(this, 2)}
                              className="btn btn__remove"
                            >
                              <Remove />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.profileImageError && (
                    <div className="invalid-feedback">
                      {this.state.profileImageError}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label className="label-primary">Title</label>
                  <input
                    type="text"
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    className="form-control"
                    required
                  />
                  {errors.title && (
                    <div className="invalid-feedback">{errors.title}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="label-primary">
                    Company Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="companyName"
                    onChange={(e) => {
                      handleChange(e);
                      this.setState({ isCompanyNameExists: false });
                    }}
                    onBlur={handleBlur}
                    value={values.companyName}
                    className="form-control"
                    required
                  />
                  {errors.companyName && (
                    <div className="invalid-feedback">{errors.companyName}</div>
                  )}
                  {isCompanyNameExists && (
                    <div className="invalid-feedback">
                      Company name already exists.
                    </div>
                  )}
                </div>
                {/* <div className="form-group">
                  <label className="label-primary">MLS member ID</label>
                  <input
                    type="text"
                    name="mlsMemberId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mlsMemberId}
                    className="form-control"
                    required
                  />
                  {errors.mlsMemberId && (
                    <div className="invalid-feedback">{errors.mlsMemberId}</div>
                  )}
                </div> */}
                <hr />
                <div className="form-group">
                  <label className="label-primary">Median Price </label>
                  <NumberFormat
                    thousandSeparator={true}
                    decimalScale={2}
                    allowNegative={false}
                    className="form-control material-textfield-input"
                    name="medianPrice"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.medianPrice}
                    required
                  />
                  <p className="form-control-note">
                    Median price of homes you either help clients buy or sell
                  </p>
                  {errors.medianPrice && (
                    <div className="invalid-feedback">{errors.medianPrice}</div>
                  )}
                </div>
                {/* <div className="form-group">
                  <label className="label-primary">
                    Total Number of transactions
                  </label>
                  <NumberFormat
                    thousandSeparator={true}
                    decimalScale={2}
                    allowNegative={false}
                    className="form-control material-textfield-input"
                    name="totalTransaction"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.totalTransaction}
                    required
                  />
                  {errors.totalTransaction && (
                    <div className="invalid-feedback">
                      {errors.totalTransaction}
                    </div>
                  )}
                </div> */}
                {/* <div className="form-group">
                  <label className="label-primary">Years of Experience</label>
                  <NumberFormat
                    thousandSeparator={true}
                    decimalScale={2}
                    allowNegative={false}
                    className="form-control material-textfield-input"
                    name="yearOfExperience"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.yearOfExperience}
                    required
                  />
                  {errors.yearOfExperience && (
                    <div className="invalid-feedback">
                      {errors.yearOfExperience}
                    </div>
                  )}
                </div> */}

                <button className={`btn btn-lg btn__purple w-100 ${values.firstName && values.email && values.lastName && values.profileImage ? 'bg_color' : ""}`}>
                  {this.state.isSpinLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    "Save & Exit"
                  )}
                </button>
              </form>
            )}
          </Formik>
        )}
      </>
    );
  }
}
