import axios from "axios";
import get from "lodash/get";

// return promise with data for apply request
const request = (option) => {
  return new Promise(async (resolve) => {
    try {
      // call api and get data and resolve
      const data = await axios(option);
      return resolve(get(data, "data", {}));
    } catch (error) {
      resolve(error);
    }
  });
};
// make default cancel token false
export default (options, cancelToken = false) => {
  const jwtToken = localStorage.getItem("Authorization");
  if (jwtToken) {
    let authAddedOptions = options;
    if (typeof options !== "object") {
      authAddedOptions = {};
    }
    if (typeof authAddedOptions.headers !== "object") {
      authAddedOptions.headers = {};
    }
    // set header and update authorization
    authAddedOptions.headers = {
      ...authAddedOptions.headers,
      Authorization: jwtToken,
    };
    // check
    if (cancelToken) {
      // add cancel request token append with axios request
      authAddedOptions.cancelToken = cancelToken;
    }
    // call request and update data
    return request(authAddedOptions);
  } else {
    return request(options);
  }
};
