import { connect } from "react-redux";
import { resetImpersonatedUser } from "../../../store/user/duck";
import ImpersonateComponent from "./component";

const ImpersonateContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    // Map actions to dispatch and props
  }),
  {
    resetImpersonatedUser,
  }
)(ImpersonateComponent);

export default ImpersonateContainer;
