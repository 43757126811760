import { connect } from "react-redux";
import {
  getLeadPageById,
  initLeadPage,
  getAllLeadPage,
  addInquiry,
} from "../../../store/leadPage/duck";
import ListingWebsiteComponent from "./component";
import { getUser, resetLogin, resetLoginUser } from "../../../store/user/duck";

const ListingWebsiteContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    userPhase: state.user.userPhase,
    leadPageByIdPhase: state.leadPage.leadPageByIdPhase,
    leadPageByIdData: state.leadPage.leadPageByIdData,
    leadPageSetting: state.leadPage.leadPageSetting,
    leadPageUserData: state.leadPage.leadPageUserData,
    leadPageProperty: state.leadPage.leadPageProperty,
    addInquiryPhase: state.leadPage.addInquiryPhase,
    addInquiryMessage: state.leadPage.addInquiryMessage,
    getAllLeadPagePhase: state.leadPage.getAllLeadPagePhase,
    getAllLeadPageData: state.leadPage.getAllLeadPageData,
    isSell: state.leadPage.isSell,
    isRent: state.leadPage.isRent,
  }),
  // Map actions to dispatch and props
  {
    getUser,
    resetLoginUser,
    resetLogin,
    getAllLeadPage,
    addInquiry,
    initLeadPage,
    getLeadPageById,
  }
)(ListingWebsiteComponent);

export default ListingWebsiteContainer;
