import * as React from "react";

const Youtube = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="18"
      fill="none"
      viewBox="0 0 25 18"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.082 12.32V5.128l6.865 3.607-6.865 3.583zm15.076-8.437s-.249-1.764-1.01-2.54C23.18.323 22.098.318 21.602.259 18.046 0 12.712 0 12.712 0H12.7S7.366 0 3.81.26c-.497.058-1.58.063-2.547 1.083-.761.776-1.009 2.54-1.009 2.54S0 5.954 0 8.025v1.942c0 2.07.254 4.141.254 4.141s.248 1.764 1.01 2.54c.967 1.02 2.236.988 2.802 1.095 2.033.196 8.64.257 8.64.257s5.34-.008 8.896-.267c.496-.06 1.58-.065 2.546-1.085.761-.776 1.01-2.54 1.01-2.54s.254-2.07.254-4.141V8.025c0-2.07-.254-4.142-.254-4.142z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Youtube;
