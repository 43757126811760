import { connect } from "react-redux";
import {
  addPlivoNumber,
  deleteAgent,
  resetAdmin,
  cancelSubscriptions,
  resetSubscriptions,
} from "../../../store/admin/duck";
import ActionMoreComponent from "./component";

const ActionMoreContainer = connect(
  // Map state to props
  (state) => ({
    cancelSubscriptionPhase: state.admin.cancelSubscriptionPhase,
    cancelSubscriptionsMessage: state.admin.cancelSubscriptionsMessage,
    deleteAgentPhase: state.admin.deleteAgentPhase,
    addPlivoNumberPhase: state.admin.addPlivoNumberPhase,
    addPlivoNumberMessage: state.admin.addPlivoNumberMessage,
    deleteAgentMessage: state.admin.deleteAgentMessage,
  }),
  // Map actions to dispatch and props
  {
    addPlivoNumber,
    resetAdmin,
    resetSubscriptions,
    cancelSubscriptions,
    deleteAgent,
  }
)(ActionMoreComponent);

export default ActionMoreContainer;
