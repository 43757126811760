import React, { PureComponent } from "react";

const RadioInput = ({
  label,
  value,
  checked,
  type,
  setter,
  isTrue = false,
}) => {
  // console.log(isTrue, "isTrue");
  return isTrue ? (
    <>
      <label className="custom__radio">
        {" "}
        {label}
        <input
          disabled
          className="custom__radio--input"
          type="radio"
          //checked={checked == value}
          onChange={() => setter(value, type)}
        />
        <span className="custom__radio--check"></span>
      </label>
    </>
  ) : (
    <>
      <label className="custom__radio">
        {" "}
        {label}
        <input
          className="custom__radio--input"
          type="radio"
          checked={checked == value}
          onChange={() => setter(value, type)}
        />
        <span className="custom__radio--check"></span>
      </label>
    </>
  );
};

export default RadioInput;
