import React, { PureComponent } from "react";
import { get } from "lodash";

import "./styles.scss";

export default class SeoComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  // handleSeoChange = (e) => {
  //   this.props.handleSeoChange(e)
  // }

  render() {
    const { listingWebsiteTemplate, userData, campaignType } = this.props;
    console.log(this.props, "props");
    return (
      <>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="form-group">
            <label className="label-primary">Page Title</label>
            <input
              type="text"
              name="metaTitle"
              maxLength={70}
              onChange={this.props.handleSeoChange}
              value={get(listingWebsiteTemplate, "seo.metaTitle", "")}
              className="form-control"
              required
            />
            <p className="form-control-note">
              {get(listingWebsiteTemplate, "seo.metaTitle", "").length}/70
              characters used
            </p>
          </div>
          <div className="form-group">
            <label className="label-primary">Meta Description</label>
            <textarea
              type="text"
              onChange={(e) => {
                this.props.handleSeoChange(e);
              }}
              onpaste={(e) => {
                //do some IE browser checking for e
                let max = test.getAttribute("maxlength");
                e.clipboardData.getData("text/plain").slice(0, max);
              }}
              maxLength={320}
              defaultValue={get(
                listingWebsiteTemplate,
                "seo.metaDescription",
                ""
              )}
              name="metaDescription"
              className="form-control"
              required
              rows="4"
            ></textarea>
            <p className="form-control-note">
              {get(listingWebsiteTemplate, "seo.metaDescription", "").length}
              /320 characters used
            </p>
          </div>
          {campaignType === "social-link" ? (
            <div className="form-group"></div>
          ) : (
            <div className="form-group">
              <label className="label-primary">URL handle</label>
              <input
                onChange={this.props.handleSeoChange}
                value={
                  get(listingWebsiteTemplate, "seo.urlHandle", "") !== ""
                    ? get(listingWebsiteTemplate, "seo.urlHandle", "")
                    : get(listingWebsiteTemplate, "slugUrl", "")
                }
                type="text"
                name="urlHandle"
                className="form-control"
                required
              />
              <p className="form-control-note">
                https://{get(userData, "domainInfo.domain", "")}/
                {get(listingWebsiteTemplate, "campaignType", "")}/
                {get(listingWebsiteTemplate, "slugUrl", "")}/
                {get(listingWebsiteTemplate, "_id", "")}
              </p>
            </div>
          )}
        </form>
      </>
    );
  }
}
