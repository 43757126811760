import * as React from "react";

const LinkCopyIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        // fill={props?.shortSlugUrl ? "#FF64FF": "#C800FF"}
        fill={!props?.shortSlugUrl ? "#07C9C9" : "#FF64FF"}
        d="M11.065 7.015a4.09 4.09 0 00-.844-1.235 4.096 4.096 0 00-1.235-.844l-.554.553-.384.385-.4.4a1.475 1.475 0 00-.192.238c.53.035 1.026.248 1.405.628.39.39.593.891.626 1.402.02.283-.015.568-.107.84a2.172 2.172 0 01-.5.833c-.007.007-.011.016-.018.023l-.454.453-.629.63-.587.586-1.53 1.53a2.176 2.176 0 01-1.549.641 2.179 2.179 0 01-1.55-.641 2.177 2.177 0 01-.64-1.55c0-.585.227-1.135.64-1.549l1.533-1.532a4.843 4.843 0 01-.189-.83 4.836 4.836 0 01-.049-.63 4.866 4.866 0 01.135-1.155L1.204 8.979a4.114 4.114 0 002.908 7.022 4.1 4.1 0 002.909-1.205l2.784-2.784.415-.415a4.1 4.1 0 00.602-.773 4.072 4.072 0 00.497-3.044 4.002 4.002 0 00-.254-.765z"
      ></path>
      <path
        fill={!props?.shortSlugUrl ? "#00E1E1" : "#C800FF"}
        // fill="#C800FF"
        d="M14.796 1.205A4.1 4.1 0 0011.888 0a4.1 4.1 0 00-2.909 1.205L5.78 4.404a4.112 4.112 0 00-.602.773 4.088 4.088 0 00-.243 3.809c.2.447.478.867.845 1.234s.787.646 1.235.845l.553-.554.385-.384.399-.4c.074-.073.137-.154.192-.238a2.169 2.169 0 01-1.406-.628 2.17 2.17 0 01-.626-1.405 2.197 2.197 0 01.108-.838c.1-.303.263-.589.5-.832.007-.007.011-.016.019-.023l.453-.453.63-.63.587-.587 1.529-1.529a2.177 2.177 0 011.55-.642 2.193 2.193 0 011.55 3.74l-1.533 1.533c.087.272.15.55.188.83.029.21.048.42.049.63.003.388-.045.776-.135 1.155l2.789-2.789a4.114 4.114 0 000-5.816z"
      ></path>
    </svg>
  );
};

export default LinkCopyIcon;
