import React, { PureComponent } from "react";
import { get } from "lodash";
import "./styles.scss";

export default class CardComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const { allLeadPage, activePropertyTab, data } = this.props;

    const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
      <>
        <a href="#" className="card__property--block" title="">
          <div
            onClick={() => this.props.changeListing(data._id, data.slugUrl)}
            className="card__property"
          >
            <div className="card__property--thumb">
              <img
                src={get(this.props, "data.newProperties[0].images[0]", "")}
                alt=""
              />
              <div className="card__property--overview">
                <div className="card__property--title">
                  {get(this.props, "data.newProperties[0].address", "")}
                </div>
              </div>
            </div>
            <div className="card__property--info">
              $
              {get(this.props, "data.newProperties[0].type", "") === "rental"
                ? numberWithCommas(
                    get(this.props, "data.newProperties[0].monthlyRent", "")
                  )
                : numberWithCommas(
                    get(this.props, "data.newProperties[0].askingPrice", "")
                  )}{" "}
              |
              {get(this.props, "data.newProperties[0].type", "") === "rental"
                ? " " +
                  numberWithCommas(
                    get(this.props, "data.newProperties[0].squareFootage", "")
                  )
                : " " +
                  numberWithCommas(
                    get(this.props, "data.newProperties[0].interiorSqFeet", "")
                  )}{" "}
              sq.ft |
              {get(this.props, "data.newProperties[0].bedrooms", "") !== ""
                ? " " + get(this.props, "data.newProperties[0].bedrooms", "")
                : ""}{" "}
              Bedrooms |
              {get(this.props, "data.newProperties[0].bathrooms", "") !== ""
                ? " " + get(this.props, "data.newProperties[0].bathrooms", "")
                : ""}{" "}
              Baths
            </div>
          </div>
        </a>
      </>
    );
  }
}
