import React, { PureComponent, useCallback } from "react";
import ColorPicker from "../../../../../components/common/ColorPicker/component";
import ImagePicker from "../../../../../components/common/ImagePicker/component";
import RangePickerComponent from "../../../../../components/common/RangePicker/component";
import { get, property, stubTrue } from "lodash";
import "./styles.scss";
import { Eye } from "../../../../../components/icons";
import ReactModal from "react-modal";
import { BlockPicker } from 'react-color'

import RadioGroupInput from "../../../../../components/ui/RadioGroupInput/component";
import { Plus, Remove } from "../../../../../components/icons";
import Dropzone from "react-dropzone";
import _, { isEmpty, values } from "lodash";
import { notification, Spin } from "antd";
import { Formik, Field, FieldArray, ErrorMessage } from "formik";
import { LoadingOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
import { debounce } from "lodash";
import { Rating } from 'react-simple-star-rating'
import PDFThumbnail from "../PDF";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
export default class EditorComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      data: {},
      userDetails: {},
      myWorkData: {},
      pdfData: [],
      videosData: [],
      isLoading: false,
      isEmailExists: false,
      isSpinLoading: false,
      profileImage: "",
      profileImageError: "",
      PDFError: "",
      workImage: "",
      workImageError: "",
      myWorkSectionTitle: "",
      myWorkTitleError: "",
      imageUrl: "",
      workImageUrl: "",
      isPhoneExits: false,
      imagePreviewUrl: "",
      backgroundPreviewUrl: "",
      workImagePreviewUrl: "",
      countryCode: "us",
      phone: "",
      indx: null,
      titleError: "",
      linkError: "",
      isModalOpen: false,
      isMyWorkModalOpen: false,
      propertyData: {},
      showNewsLetter: true,
      showPropertyVal: true,
      isEdit: false,
      isSaveButtonEnable: false,
      isSaveMyWorkButtonEnable: false,
      firstName: "",
      lastName: "",
      email: "",
      title: "",
      value: "",
      emailError: "",
      phoneError: "",
      priceError: "",
      firstNameError: "",
      lastNameError: "",
      isCalled: true,
      isTestimonialModalOpen:false,
      testimonialData:{},
      testimonialSaveDisabled:false,
      backgroundTypeTab: 'image',
      background: "#ffffff",
      hexColorRegex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
      seeMoreEnabled: false
    };
    this.handleBackgroundChange = this.handleBackgroundChange.bind(this);
    this.handlefirstName = this.handlefirstName.bind(this);
    this.handlelastName = this.handlelastName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlephone = this.handlephone.bind(this);
    this.handletitle = this.handletitle.bind(this);
    this.onChangeDebouncedfirstName = debounce(
      this.onChangeDebouncedfirstName,
      100
    );
    this.onChangeDebouncedlastName = debounce(
      this.onChangeDebouncedlastName,
      100
    );
    this.onChangeDebouncedEmail = debounce(this.onChangeDebouncedEmail, 100);
    this.onChangeDebouncedphone = debounce(this.onChangeDebouncedphone, 100);
    this.onChangeDebouncedtitle = debounce(this.onChangeDebouncedtitle, 100);
  }
  

  handletitle = (e) => {
    this.setState(
      {
        title: e.target.value,
      },
      () => {
        this.forceUpdate();
      }
    );
    this.onChangeDebouncedtitle(e.target.value);
  };
  

  onChangeDebouncedtitle = (value) => {
    const { userData } = this.state;
    userData.title = value;
    this.setState({ userData }, () => {
      this.onUserSave();
    });
  };
  handleEmail = (e) => {
    const email = e.target.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    this.props.handleUserDataChange(email , "email");
    if (email.length === 0) {
      this.setState({
        email: "",
        emailError: "Please enter email",
      });
    } else if (!emailRegex.test(email)) {
      this.setState({
        email: email,
        emailError: "Invalid email address",
      });
    } else {
      this.setState(
        {
          email: email,
          emailError: "",
        },
        () => {
          this.forceUpdate();
        }
      );
      this.onChangeDebouncedEmail(e.target.value);
    }
  };

  
  seeMore = () => {
   this.setState({seeMoreEnabled: true})
  }

  onChangeDebouncedEmail = (value) => {
    const { userData } = this.state;
    userData.email = value;
    this.setState({ userData }, () => {
      this.onUserSave();
    });
  };

  handlefirstName = (e) => {
    this.props.handleUserDataChange(e.target.value , "firstName");
    if (e.target.value.trim() === "") {
      this.setState(
        {
          firstNameError: "Please enter first name",
          firstName: "",
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      this.setState(
        {
          firstName: e.target.value,
          firstNameError: "",
        },
        () => {
          this.forceUpdate();
        }
      );
    }
    this.onChangeDebouncedfirstName(e.target.value);
  };

  onChangeDebouncedfirstName = (value) => {
    const { userData } = this.state;
    userData.firstName = value;
    this.setState({ userData }, () => {
      this.onUserSave();
    });
  };

  handlelastName = (e) => {
    this.props.handleUserDataChange(e.target.value , "lastName");
    if (e.target.value.trim() === "") {
      this.setState(
        {
          lastNameError: "Please enter last name",
          lastName: "",
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      this.setState(
        {
          lastName: e.target.value,
          lastNameError: "",
        },
        () => {
          this.forceUpdate();
        }
      );
    }
    this.onChangeDebouncedlastName(e.target.value);
  };
  handleMyWorkSectionTitle = (e) => {
    if (e.target.value.trim() === "") {
      this.setState(
        {
          myWorkTitleError: "Please enter my work title",
          myWorkSectionTitle: "",
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      this.setState(
        {
          myWorkSectionTitle: e.target.value,
          myWorkTitleError: "",
        },
        () => {
          this.forceUpdate();
        }
      );
    }
    this.onChangeDebouncedlastName(e.target.value);
  };

  onChangeDebouncedlastName = (value) => {
    const { userData } = this.state;
    userData.lastName = value;
    this.setState({ userData }, () => {
      this.onUserSave();
    });
  };

  handlephone = (e) => {
    // if(e.target.value.length === 0){
    //   this.setState({
    //     phoneError: "Please enter phone number",
    //     phone: ""
    //   });
    // }

    this.setState(
      {
        phone: e.target.value,
        phoneError: "",
      }
      // () => {
      //   this.forceUpdate();
      // }
    );
    this.onChangeDebouncedphone(e.target.value);
  };

  onChangeDebouncedphone = (value) => {
    const { userData } = this.state;
    userData.phone = value;
    this.setState({ userData }, () => {
      this.onUserSave();
    });
  };
  handelShowSocialLinks = (value) => {
    const { userData } = this.state;
    userData.showSocialLinks = value;
    this.setState({ userData }, () => {
      this.onUserSave();
    });
  };

  handelShowPropertyVal = (value) => {
    const { userData } = this.state;
    userData.showPropertyVal = value;
    this.setState({ userData }, () => {
      this.onUserSave();
    });
  };

  handelShowNewsLetter = (value) => {
    const { userData } = this.state;
    userData.showNewsLetter = value;
    this.setState({ userData }, () => {
      this.onUserSave();
    });
  };
  // openNotificationWithIcon = (type) => {
  //   notification[type]({
  //     key: "updatable",
  //     message: "Yardpost",
  //     description: "Profile updated successfully",
  //   });
  // };
  isValidUrl = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,})([/\\w .-]*)*/?"
    );
    return (
      regex.test(URL) && (URL.includes("https://") || URL.includes("http://"))
    );
  };
  handleChangeCountryCode = (value, phone) => {
    this.setState(
      { countryCode: value, phone: phone.replace(value, ""), phoneError: "" },
      () => {
        this.forceUpdate();
      }
    );
  };
  handleOnChange = (e, indx, isMyWorkData = false) => {
    this.setState({ isCalled: true });
    const { propertyData, myWorkData } = this.state;
    const { name, value } = e.target;

    if(isMyWorkData){
      if (name === "mywork-title") {
        if (value.trim() === "") {
          this.setState({ titleError: "Please enter title" }, () => {
            if (String(myWorkData.price).trim() !== "") {
              this.setState({ isSaveMyWorkButtonEnable: true });
            }
          });
        } else {
          this.setState({ titleError: "" }, () => {
            if (String(myWorkData.price).trim() !== "") {
              this.setState({ isSaveMyWorkButtonEnable: false });
            }
          });
        }

        myWorkData[`title`] = value;
      } else if (name === "mywork-price") {
        const price = value.trim();
        if (price === "") {
          this.setState({ priceError: "Please enter price" }, () => {
            if (myWorkData.title.trim() !== "") {
              this.setState({ isSaveButtonEnable: true });
            }
          });
        } else {
          this.setState({ priceError: "" }, () => {
            if (myWorkData.title.trim() !== "") {
              this.setState({ isSaveButtonEnable: false });
            }
          });
        }

        myWorkData[`price`] = value;
      }
      this.setState({ myWorkData }, () => {
        this.forceUpdate();
      });
    } else {
      if (name === "title") {
        if (value.trim() === "") {
          this.setState({ titleError: "Please enter title" }, () => {
            if (propertyData.link.trim() !== "") {
              this.setState({ isSaveButtonEnable: true });
            }
          });
        } else {
          this.setState({ titleError: "" }, () => {
            if (propertyData.link.trim() !== "") {
              this.setState({ isSaveButtonEnable: false });
            }
          });
        }

        propertyData[`title`] = value;
      } else if (name === "link") {
        const url = value.trim();
        if (url === "") {
          this.setState({ linkError: "Please enter a link" }, () => {
            if (propertyData.title.trim() !== "") {
              this.setState({ isSaveButtonEnable: true });
            }
          });
        } else if (!this.isValidUrl(url)) {
          this.setState({ linkError: "Please enter a valid url" }, () => {
            if (propertyData.title.trim() !== "") {
              this.setState({ isSaveButtonEnable: true });
            }
          });
        } else {
          this.setState({ linkError: "" }, () => {
            if (propertyData.title.trim() !== "") {
              this.setState({ isSaveButtonEnable: false });
            }
          });
        }

        propertyData[`link`] = value;
      }
      this.setState({ propertyData }, () => {
        this.forceUpdate();
      });
    }



  };

  handleTestimonialChange = (e) => {
    const { name, value } = e.target;
    const { testimonialData } = this.state;
    this.setState({
      testimonialData: {...testimonialData,[name]:value}
    })
  }



  openModal = (data, indx, isMyWorkModal = false) => {
    const { isEdit } = this.state;
    if (isEdit) {
      if(isMyWorkModal){
        this.setState(
          {
            indx: indx !== null ? indx : 0,
            isMyWorkModalOpen: true,
            myWorkData: data

          },
          () => {
            this.forceUpdate();
          }
        );
      } else {
        this.setState(
          {
            indx: indx !== null ? indx : 0,
            isModalOpen: true,
          },
          () => {
            this.forceUpdate();
          }
        );
      }
    } else {
      if(isMyWorkModal){
        this.setState(
          {
            isMyWorkModalOpen: true,
            myWorkData: { title: "", link: "" },
            isSaveButtonEnable: true,
          },
          () => {
            this.forceUpdate();
          }
        );
      } else {
        this.setState(
          {
            isModalOpen: true,
            propertyData: { title: "", link: "" },
            isSaveButtonEnable: true,
          },
          () => {
            this.forceUpdate();
          }
        );
      }

    }
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      isMyWorkModalOpen: false,
      propertyData: {},
      indx: 0,
      titleError: "",
      linkError: "",
      isTestimonialModalOpen:false
    });
  };

  // handleRemove = (index) => {
  //   const { userData } = this.state;
  //   this.setState({ userData: userData.propertylist.splice(index, 1) });
  //   this.onUserSave();
  // };
  handleRemove = (index , isMyWork = false) => {
    this.setState({ isCalled: true }, () => {
      const { userData, myWorkData } = this.state;
      const updatedPropertyList = [...userData.propertylist];
      updatedPropertyList.splice(index, 1);

      this.setState(
        {
          userData: {
            ...userData,
            propertylist: updatedPropertyList,
          },
        },
        () => {
          this.onUserSave();
        }
      );
    });
  };
  // handleSave = () => {
  //   const { propertyData, indx, userData } = this.state;
  //   if (propertyData?.title.trim() === "" || propertyData?.link.trim() === "") {
  //     this.setState({ isSaveButtonEnable: true });
  //     return;
  //   }

  //   if (indx !== null && this.state?.isEdit) {
  //     let propertylist = userData.propertylist;
  //     propertylist[indx]["title"] = propertyData.title;
  //     propertylist[indx]["link"] = propertyData.link;
  //     userData["propertylist"] = [...propertylist];
  //     this.setState({ userData: { ...userData } }, () => {
  //       this.forceUpdate();
  //     });
  //   } else {
  //     if (userData.propertylist === undefined) {
  //       userData.propertylist = [];
  //       userData.propertylist.push(propertyData);
  //       this.setState({
  //         userData: userData.propertylist,
  //       });
  //       //values.propertylist = userData.propertylist;
  //     } else {
  //       this.setState({ userData: userData.propertylist.push(propertyData) });
  //       values.propertylist = userData.propertylist;
  //     }
  //   }
  //   this.onUserSave();
  // };
  handleSave = () => {
    //this.setState({isCalled:true})
    const { propertyData, indx, userData} = this.state;
    if (propertyData?.title.trim() === "" || propertyData?.link.trim() === "") {
      this.setState({ isSaveButtonEnable: true });
      return;
    }

    if (indx !== null && this.state?.isEdit) {
      let propertylist = userData.propertylist;
      propertylist[indx]["title"] = propertyData.title;
      propertylist[indx]["link"] = propertyData.link;
      userData["propertylist"] = [...propertylist];
      this.setState({ userData: { ...userData } }, () => {
        this.forceUpdate();
      });
    } else {
      if (userData.propertylist === undefined) {
        userData.propertylist = [];
        userData.propertylist.push(propertyData);
        this.setState(
          {
            userData: userData.propertylist,
          },
          () => {
            this.forceUpdate();
          }
        );
        //values.propertylist = userData.propertylist;
      } else {
        this.setState({ userData: userData.propertylist.push(propertyData) });
        //values.propertylist = userData.propertylist;
      }
    }
    this.onUserSave();
  };
  onUserSave = () => {
    const { userData, propertyData } = this.state;
    let form_data = new FormData();
    for (const [keyOF, valueOF] of Object.entries(userData)) {
      if (keyOF === "propertylist") {
        form_data.append("propertylist", JSON.stringify(userData.propertylist));
      } else {
        // if(keyOF === "showPropertyVal"){
        //   form_data.append(
        //     "showPropertyVal",
        //     this.state.showPropertyVal
        //   );
        // }
        // if(keyOF === "showPropertyVal"){
        //   form_data.append(
        //     "showPropertyVal",
        //     this.state.showPropertyVal
        //   );
        // }
        // if(keyOF === "showSocialLinks"){
        //   form_data.append(
        //     "showSocialLinks",
        //     this.state.showSocialLinks
        //   );
        // }
        form_data.append(keyOF, valueOF);
      }
    }
    form_data.updatedAt = new Date();
    if (this.state.profileImage === "") {
      this.setState(
        { profileImageError: "Please upload profile image" },
        () => {
          this.forceUpdate();
        }
      );
    }
    if (this.state.firstName && this.state.lastName && this.state.email) {
      this.props.editUser(form_data);
    }
    this.setState({ isModalOpen: false });
  };

  removeImageUrl = (key, index = 0) => {
    const { userData, myWorkData , pdfData} = this.state;
    if(key === 0){
      userData.profileImage = "";
      this.setState(
        { userData, profileImageError: "Please upload profile image." },
        () => {
          this.forceUpdate();
          this.onUserSave();
        }
      );
      this.setState(
        { imagePreviewUrl: "", imageUrl: "", profileImage: "" },
        () => {
          this.forceUpdate();
        }
      );
    } else if(key === 1){
      this.setState(
        { backgroundPreviewUrl: "", background: "#ffffff" },
        () => {
          this.props.handleBackgroundChange("#ffffff")
          this.forceUpdate();
        }
      );
    } else if(key === 3){
      const pdfData = this.props.listingWebsiteTemplate.pdfData;
      pdfData.splice(index , 1);
      this.props.listingWebsiteTemplate.pdfData = pdfData;
      this.setState({pdfData}, ()=>{
        this.forceUpdate();
        this.props.handlePDFDataChange(pdfData);
      })
    } else {
      myWorkData.workImage = "";
      this.setState(
        { myWorkData, workImageError: "Please upload profile image." },
        () => {
          this.forceUpdate();
          this.onUserSave();
        }
      );
      this.setState(
        { workImagePreviewUrl: "", workImageUrl: "", workImage: "" },
        () => {
          this.forceUpdate();
        }
      );
    }

  };

  onDropImage = async (acceptedFiles, key) => {
    acceptedFiles[0].preview = URL.createObjectURL(acceptedFiles[0]);
    if (acceptedFiles[0].type.split("/")[0] === "image") {
      const { value } = await this.props.uploadImages(acceptedFiles[0]);
      if(key === "workImage"){
        const { myWorkData } = this.state;
        myWorkData.workImage = value;
        this.setState({ myWorkData }, () => {
          this.forceUpdate();
        });
        this.setState(
          {
            workImagePreviewUrl: URL.createObjectURL(acceptedFiles[0]),
            workImage: value,
          },
          () => {
            this.forceUpdate();
          }
        );
      } else if(key === "background"){
        this.setState({ background: value }, () => {
          this.props.handleBackgroundChange(value);
          this.forceUpdate();
        });
        this.setState(
          {
            backgroundPreviewUrl: URL.createObjectURL(acceptedFiles[0]),
            background: value,
          },
          () => {
            this.forceUpdate();
          }
        );
      } else {
        const { userData } = this.state;
        userData.profileImage = value;
        this.setState({ userData }, () => {
          this.onUserSave();
          this.forceUpdate();
        });
        this.setState(
          {
            imagePreviewUrl: URL.createObjectURL(acceptedFiles[0]),
            profileImage: value,
            profileImageError: "",
          },
          () => {
            this.forceUpdate();
          }
        );
      }

    } else {
      this.setState(
        {
          profileImageError: "File type should be image.",
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  };

  onDropPDF = async (acceptedFiles) => {
    const pdfData = this.props.listingWebsiteTemplate.pdfData;
    acceptedFiles[0].preview = URL.createObjectURL(acceptedFiles[0]);
    if (acceptedFiles[0].type.split("/")[1] === "pdf") {
      const { value } = await this.props.uploadImages(acceptedFiles[0]);
        if(value){
          pdfData.push({name:  acceptedFiles[0]?.name, file: value});
          this.props.listingWebsiteTemplate.pdfData = pdfData;
          this.setState({ pdfData }, () => {
            this.forceUpdate();
            this.props.handlePDFDataChange(pdfData);
          });
        }

        // this.setState(
        //   {
        //     workImagePreviewUrl: URL.createObjectURL(acceptedFiles[0]),
        //     workImage: value,
        //   },
        //   () => {
        //     this.forceUpdate();
        //   }
        // );
    } else {
      this.setState(
        {
          PDFError: "File type should be pdf.",
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  };

  handleAddVideo = ()=>{
    const videosData = this.props.listingWebsiteTemplate.videosData;
    videosData.push('');
    this.props.listingWebsiteTemplate.videosData = videosData;
    this.setState({ videosData }, () => {
      this.forceUpdate();
      // this.props.handleVideosDataChange(videosData);
    });
  }

  hanldeChangeVideo = async (value , index) =>{
    const videosData = this.props.listingWebsiteTemplate.videosData;
    videosData[index] = value;
    this.props.listingWebsiteTemplate.videosData = videosData;
    this.setState({ videosData }, () => {
      this.forceUpdate();
      this.props.handleVideosDataChange(videosData);
    });
  }

  handleDeleteVideo = async (index)=>{
    const videosData = this.props.listingWebsiteTemplate.videosData;
    videosData.splice(index, 1);
    this.props.listingWebsiteTemplate.videosData = videosData;
    this.setState({ videosData }, () => {
      this.forceUpdate();
      this.props.handleVideosDataChange(videosData);
    });
  }

  componentDidMount() {
    document.title = "Editor Lead Pages | Yardpost ";
    if (this.props.campaignType === "sell") {
      this.setState({ data: this.props.sellTemplate });
    } else {
      this.setState({  pdfData: this.props.listingWebsiteTemplate.pdfData , videosData: this.props.listingWebsiteTemplate.videosData , data: this.props.listingWebsiteTemplate, background: this.props.listingWebsiteTemplate.background});
      this.forceUpdate()
    }

    //for social link editor
    if (
      window.location.origin !== "https://app.yardpost.com" &&
      window.location.origin !== "https://stag-app.yardpost.com" &&
      window.location.origin !== "http://localhost:3000" &&
      window.location.origin !== "https://yardpost-frontend-04tp.onrender.com"
    ) {
      this.props.getUser({domain:window.location.origin?.replaceAll("https://", "")});
    } else {
      // this.props.getUser();
    }
    // if (isEmpty(this.props.user)) {
    //   this.props.getUser();
    // }
    if (!isEmpty(this.props.user)) {
      this.props.getUser();
      this.setState(
        {
          title: this.props.userData.title,
          firstName: this.props.userData.firstName,
          email: this.props.userData.email,
          lastName: this.props.userData.lastName,
          phone: this.props.userData.phone,
          userDetails: this.props.userData,
          userData: this.props.userData,
          profileImage: get(this.props.userData, "profileImage", ""),
        },
        () => {
          this.forceUpdate();
        }
      );
    }

    if (this.props.userData) {
      this.setState(
        {
          phone: this.props.userData.phone,
          profileImage: this.props.userData.profileImage,
        },
        () => this.forceUpdate()
      );
    }
  }

  componentDidUpdate(prev, prevState) {
    document.title = "Editor Lead Pages | Yardpost ";
    const { isCalled } = this.state;
    if (!isEmpty(this.props.userData) && isCalled) {
      this.setState(
        {
          title: this.props.userData.title,
          firstName: this.props.userData.firstName,
          email: this.props.userData.email,
          lastName: this.props.userData.lastName,
          phone: this.props.userData.phone,
          userDetails: this.props.userData,
          userData: this.props.userData,
          profileImage: this.props.userData.profileImage,
          isCalled: false
        }

        // ()=>{
        //   this.forceUpdate()
        // }
      );
    }
    if (this.props.editUserPhase === "success") {
      window.scrollTo({ top: 0 });
      // this.props.hand();
      // this.props.resetLogin();
      this.setState({
        isSpinLoading: false,
      });
    } else if (this.props.editUserPhase === "error") {
      // this.props.resetLogin();
      if (
        this.props.editUserData.duplicate &&
        this.props.editUserData.duplicateKey[0] === "email"
      ) {
        this.setState({
          isEmailExists: true,
          isSpinLoading: false,
          isLoading: false,
        });
      }
    }

    if (
      this.props.editUserData !== prev.userDetails &&
      !this.state.isModalOpen
    ) {
      let timeOut = setInterval(() => {
        document.getElementById("ifram_preview") &&
          document
            .getElementById("ifram_preview")
            .contentDocument.location.reload(true);
        clearInterval(timeOut);
      }, 5000);
    }
    if (this.props.userPhase === "success" && isCalled) {
      window.scrollTo({ top: 0 });
      this.props.resetLoginUser();
      this.setState(
        {
          title: this.props.user.user.title,
          firstName: this.props.user.user.firstName,
          email: this.props.user.user.email,
          lastName: this.props.user.user.lastName,
          phone: this.props.user.user.phone,
          countryCode: get(this.props.user, "user.countryCode", "+1"),
          phone: get(this.props.user, "user.phone", ""),
          userData: get(this.props.user, "user", {}),
          profileImage: get(this.props.user, "user.profileImage", {}),
          showPropertyVal: get(this.props.user, "user.showPropertyVal", true),
          showNewsLetter: get(this.props.user, "user.showNewsLetter", true),
          showSocialLinks: get(this.props.user, "user.showSocialLinks", true),
          isCalled: false,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
    if(this.state.testimonialData !== prevState.testimonialData){
      const {testimonialData} = this.state;
      let isDisabled;
        Object.keys(testimonialData).map(v =>{
          if(!testimonialData[v] || testimonialData[v]?.toString()?.trim("") === ""){
          isDisabled = true;
        }
      })
       this.setState({testimonialSaveDisabled:isDisabled})
    }
  }

  handleChange = (e) => {
    this.props.handleChange(e);
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  addTestimonial = ({setValues,values})=>{
    const prevTest = values?.testimonials ?? [];
      setValues({...values,testimonials:[...prevTest,{
        name:"",
        description:"",
        stars:0,
        time:null
      }]})
  }

  testimonialModalOpen = () => {
    this.setState({
      isTestimonialModalOpen: true,
      testimonialData: {
        name: "",
        description: "",
        stars: 0,
      }
    });
  }

  handleSaveTestimonial = (changeType,value) => {
    const {isEdit,indx} = this.state;
    const testimonial = this.props.listingWebsiteTemplate.testimonial
    if(changeType === "title"){
      testimonial.title = value;
      this.props.handleTestimonialChange(testimonial)
    }else{
      if(isEdit){
        const allTestimonials = [...testimonial?.dataList];
        allTestimonials[indx] = this.state.testimonialData;
        testimonial.dataList = allTestimonials;
        this.props.handleTestimonialChange(testimonial)
        this.setState({isTestimonialModalOpen:false,testimonialData:{}})
      }else{
        const allTestimonials = [...testimonial?.dataList,this.state.testimonialData];
        testimonial.dataList = allTestimonials
        this.props.handleTestimonialChange(testimonial)
        this.setState({isTestimonialModalOpen:false,testimonialData:{}})
      }
    }
  }

  handleSaveMyWork = (changeType,value) => {
    const {isEdit,indx, myWorkData} = this.state;
    const myWork = this.props.listingWebsiteTemplate.myWork;
    if(changeType === "title"){
      myWork.title = value;
      this.props.handleMyWorkChange(myWork)
    }else{
      if(isEdit){
        const allMyWork = [...myWork?.dataList];
        allMyWork[indx] = this.state.myWorkData;
        myWork.dataList = allMyWork;
        this.props.handleMyWorkChange(myWork)
        this.setState({isMyWorkModalOpen:false,myWorkData:{}})
      }else{
        const allMyWork = [...myWork?.dataList,this.state.myWorkData];
        myWork.dataList = allMyWork;
        this.props.handleMyWorkChange(myWork)
        this.setState({isMyWorkModalOpen:false,myWorkData:{}})
      }
    }
  }

  handleBackgroundChange(event){
    this.setState({ background: event.hex },  () => {
      this.forceUpdate();
    });
    this.props.handleBackgroundChange(event.hex)
  }


  render() {
    const { listingWebsiteTemplate } = this.props;
    const {
      isEdit,
      titleError,
      linkError,
      phoneError,
      emailError,
      lastNameError,
      firstNameError,
      propertyData,
      myWorkData,
      indx,
      userData,
      isEmailExists,
      isPhoneExits,
      testimonialData,
      background
    } = this.state;
    const showSocialLinks = [
      { key: "Active", value: true },
      { key: "Not Active", value: false },
    ];
    const showPropertyVal = [
      { key: "showPro", value: true },
      { key: "hidePro", value: false },
    ];
    const showNewsLetter = [
      { key: "Show", value: true },
      { key: "Hide", value: false },
    ];

    const EditDeleteBtns = ({onEdit,onDelete}) => {
      return  <div>
        <button
          type="button"
          className="btn btn__link btn__link--deafult"
          onClick={onEdit}
        >
          Edit
        </button>

        <button
          type="button"
          className="btn btn__link btn__link--danger"
          onClick={onDelete}
        >
          Remove
        </button>
      </div>
    }

    const ImageBackgroundTab = ()=>{
      return (
        <div className="form-group">
          <Dropzone
            onDrop={(acceptedFiles) =>
              this.onDropImage(acceptedFiles, "background")
            }
          >
            {({ getRootProps, getInputProps }) => (
              <section className="dropzone">
                <div className="dropzone__area" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <button type="button" className="btn btn__purple">
                    <Plus className="btn__icon" /> {!this.state.hexColorRegex.test(background) && background !== "" &&
                     background !== null ? "Replace Photo":"Upload Photo"}
                  </button>
                </div>
              </section>
            )}
          </Dropzone>
          {!this.state.hexColorRegex.test(get(listingWebsiteTemplate, "background", "")) && get(listingWebsiteTemplate, "background", "") !== "" &&
              get(listingWebsiteTemplate, "background", "") !== null && (
              <div className="dropzone__grid">
                <div className="dropzone__grid--item">
                  <div className="dropzone__grid--preview">
                    <img
                      className="preview__thumb"
                      src={get(listingWebsiteTemplate, "background", "")}
                      alt=""
                    />
                    <div className="dropzone__grid--remove">
                      <button
                        onClick={this.removeImageUrl.bind(this, 1)}
                        className="btn btn__remove"
                      >
                        <Remove />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      )
    }

    const ColorBackgroundTab = ()=>{
      return (
        <BlockPicker  color={ get(listingWebsiteTemplate, "background", "") } onChange={ this.handleBackgroundChange } />
      )
    }

    return (
      <>
        {this.props.campaignType === "social-link" ? (
          <>
            {!isEmpty(userData) && (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  firstName: get(userData, "firstName", ""),
                  lastName: get(userData, "lastName", ""),
                  email: get(userData, "email", ""),
                  title: get(userData, "title", ""),
                  phone: this.state.phone,
                  profileImage:
                    userData.profileImage !== ""
                      ? userData.profileImage
                      : get(userData, "profileImage", ""),
                  role: "Admin",
                  // yardpostLogo: get(userData, "yardpostLogo", true),
                  showNewsLetter: get(userData, "showNewsLetter", true),
                  showPropertyVal: get(userData, "showPropertyVal", true),
                  showSocialLinks: get(userData, "showSocialLinks", true),
                  propertylist: get(userData, "propertylist", []),
                  testimonial:this.props.listingWebsiteTemplate.testimonial,
                  myWork:this.props.listingWebsiteTemplate.myWork,
                  pdfData:this.props.listingWebsiteTemplate.pdfData,
                  videosData:this.props.listingWebsiteTemplate.videosData,
                  background:this.props.listingWebsiteTemplate.background
                }}
                validationSchema={Yup.object({
                  propertylist: Yup.array().of(
                    Yup.object().shape({
                      title: Yup.string().required("Please enter a title."),
                      link: Yup.string()
                        .required("Please enter a link.")
                        .url("Please enter a valid url."),
                    })
                  ),
                })}
                validate={(values) => {
                  this.setState({
                    isEmailExists: false,
                  });
                  const errors = {};
                  if (!values.firstName) {
                    errors.firstName = "Please enter first name";
                  }
                  if (!values.lastName) {
                    errors.lastName = "Please enter last name";
                  }
                  if (!values.email) {
                    errors.email = "Please enter email";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }
                  if (!values.phone) {
                    errors.phone = "Please enter valid Number";
                  } else if (
                    values.phone.length < 10 ||
                    values.phone.length > 10
                  ) {
                    errors.phone = "Please enter a valid Number";
                  }
                  // if (this.state.phone === "") {
                  //   errors.phone = "Please enter valid number";
                  // } else if (this.state.phone.length < 9) {
                  //   this.setState({
                  //     phoneError: "Please enter valid Number",
                  //   });
                  //   errors["phone"] = "Please enter valid phone number";
                  // }
                  if (userData.profileImage === "") {
                    errors.profileImage = "Please upload profile image.";
                    this.setState({
                      profileImageError: "Please upload profile image.",
                    });
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.setState({
                    isSpinLoading: true,
                    isEmailExists: false,
                    isPhoneExits: false,
                  });
                  const errors = this.validate(values);
                  setTimeout(() => {
                    setSubmitting(false);
                    this.setState({ isLoading: true });
                    // values.yardpostLogo = this.state.yardpostLogo;
                    // values.showNewsLetter = this.state.showNewsLetter;
                    // values.showPropertyVal = this.state.showPropertyVal;
                    // values.showSocialLinks = this.state.showSocialLinks;
                    // let form_data = {};
                    let form_data = new FormData();
                    // values.profileImage =
                    //   userData.profileImage !== ""
                    //     ? userData.profileImage
                    //     : get(userData, "profileImage", "");
                    // values.phone = this.state.phone;
                    // values.countryCode = this.state.countryCode;
                    values.propertylist = values.propertylist;

                    for (const [keyOF, valueOF] of Object.entries(values)) {
                      if (keyOF === "propertylist") {
                        form_data.append(
                          "propertylist",
                          JSON.stringify(values.propertylist)
                        );
                      } else {
                        form_data.append(keyOF, valueOF);
                      }
                    }
                    this.props.editUser(form_data);
                    this.setState({
                      isSpinLoading: false,
                    });
                  }, 400);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setValues
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* <div className="form-group">
                      <div className="d-flex">
                        <label className="label-primary mb-0">
                          Publish Social Link Status
                        </label>
                        <div className="ms-auto">
                          {showSocialLinks.map((data, i) => {
                            return (
                              <>
                                <RadioGroupInput
                                  value={data.value}
                                  setter={this.handelShowSocialLinks}
                                  checked={userData.showSocialLinks}
                                  // checked={this.state.showSocialLinks}
                                  label={data.key}
                                />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div> */}
                    <label className="label-primary">
                      Background  <small className="bg_recommanded_text">(Recommended Size: 3840x2160 pixels)</small>
                    </label>
                    <div className="tabs__links tabs__links--space tabs__links--full">
                      <button
                        className={
                          this.state.backgroundTypeTab === "image" ? "tabs__link active" : "tabs__link"
                        }
          onClick={()=>{
            this.setState({backgroundTypeTab: "image"})
                        }}
                      >
                        Image
                      </button>
                      <button
                        className={this.state.backgroundTypeTab === "color" ? "tabs__link active" : "tabs__link"}
            onClick={()=>{
              this.setState({backgroundTypeTab: "color"})
                        }}
                      >
                        Color
                      </button>
                    </div>
                    {
         this.state.backgroundTypeTab === "image" && (<ImageBackgroundTab/>)
                    }
                    {
         this.state.backgroundTypeTab === "color" && (<ColorBackgroundTab/>)
                    }
                    <hr />
                    <div className="form-group">
                      <div className="d-flex">
                        <label className="label-primary mb-0">
                          Property Valuation section
                        </label>
                        <div className="ms-auto">
                          {showPropertyVal.map((data, i) => {
                            return (
                              <>
                                <RadioGroupInput
                                  value={data.value}
                                  setter={this.handelShowPropertyVal}
                                  checked={userData.showPropertyVal}
                                  label={data.key}
                                />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <label className="label-primary mb-0">
                          Sign up to newsletter section
                        </label>
                        <div className="ms-auto">
                          {showNewsLetter.map((data, i) => {
                            return (
                              <>
                                <RadioGroupInput
                                  value={data.value}
                                  setter={this.handelShowNewsLetter}
                                  checked={userData.showNewsLetter}
                                  label={data.key}
                                />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">
                            First Name<span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            value={this.state?.firstName}
                            onChange={(e) => {
                              this.handlefirstName(e);
                            }}
                            onBlur={handleBlur}
                            className="form-control"
                            required
                          />
                          {this.state.firstNameError && (
                            <div className="invalid-feedback">
                              {this.state?.firstNameError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">
                            Last Name<span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            value={this.state?.lastName}
                            onChange={(e) => {
                              this.handlelastName(e);
                            }}
                            onBlur={handleBlur}
                            className="form-control"
                            required
                          />
                          {this.state.lastNameError && (
                            <div className="invalid-feedback">
                              {this.state?.lastNameError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">
                            Email Address<span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="email"
                            value={this.state?.email}
                            onChange={(e) => {
                              this.handleEmail(e);
                            }}
                            onBlur={handleBlur}
                            className="form-control"
                            required
                          />
                          {this.state.emailError && (
                            <div className="invalid-feedback">
                              {this.state?.emailError}
                            </div>
                          )}
                          {isEmailExists && (
                            <div className="invalid-feedback">
                              Email already exists
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">Phone Number</label>
                          <InputMask
                            mask="(999) 999-9999"
                            name="phone"
                            maskChar=""
                            onChange={(e) => {
                              this.handlephone(e);
                            }}
                            value={this.state?.phone}
                            className="form-control"
                            required
                          />
                          {/* <input
                            type="number"
                            name="phone"
                            value={this.state?.phone}
                            onChange={(e) => {
                              this.handlephone(e);
                            }}
                            //onChange={(e) => this.handlePhone(e.target.value)}
                            className="form-control"
                            onBlur={handleBlur}
                            required
                          /> */}
                          {/* <PhoneInput
                        inputProps={{
                          readOnly: false,
                          name: "phone",
                          required: true,
                          className: "form-control form-control-flat",
                        }}
                        country={"us"}
                        value={userData.phone}
                        onChange={(phone, val) => {
                            this.handleChangeCountryCode(
                            val.dialCode,
                            phone
                          )
                        }}
                      /> */}
                          {this.state?.phoneError && (
                            <div className="invalid-feedback">
                              {this.state?.phoneError}
                            </div>
                          )}
                          {isPhoneExits && (
                            <div className="invalid-feedback">
                              Phone number already exists
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="label-primary">Title</label>
                        <input
                          type="text"
                          name="title"
                          onChange={(e) => {
                            this.handletitle(e);
                          }}
                          value={this.state?.title}
                          onBlur={handleBlur}
                          className="form-control"
                        // required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label-primary">
                        Profile Picture (ideal dimensions 145px x 145px)<span className="required">*</span>
                      </label>
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          this.onDropImage(acceptedFiles, "profileImage")
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone">
                            <div className="dropzone__area" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <button type="button" className="btn btn__purple">
                                <Plus className="btn__icon" /> {userData.profileImage !== "" &&
                      userData.profileImage !== null ? "Replace Photo":"Upload Photo"}
                              </button>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      {/* {this.state.imagePreviewUrl ? (
                      <div className="dropzone__grid">
                        <div className="dropzone__grid--item">
                          <div className="dropzone__grid--preview">
                            <img
                              className="preview__thumb"
                              src={this.state.imagePreviewUrl}
                              alt=""
                            />
                            <div className="dropzone__grid--remove">
                              <button
                                onClick={this.removeImageUrl.bind(this, 1)}
                                className="btn btn__remove"
                              >
                                <Remove />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                      {/* {userData.profileImage &&
                    !this.state.imagePreviewUrl ? (
                      <div className="dropzone__grid">
                        <div className="dropzone__grid--item">
                          <div className="dropzone__grid--preview">
                            <img
                              className="preview__thumb"
                              src={userData.profileImage}
                              alt=""
                            />
                            <div className="dropzone__grid--remove">
                              <button
                                onClick={this.removeImageUrl.bind(this, 2)}
                                className="btn btn__remove"
                              >
                                <Remove />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                      {userData.profileImage !== "" &&
                        userData.profileImage !== null ? (
                        <div className="dropzone__grid">
                          <div className="dropzone__grid--item">
                            <div className="dropzone__grid--preview">
                              <img
                                className="preview__thumb"
                                src={userData.profileImage}
                                alt=""
                              />
                              <div className="dropzone__grid--remove">
                                <button
                                  onClick={this.removeImageUrl.bind(this, 0)}
                                  className="btn btn__remove"
                                >
                                  <Remove />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : this.state.profileImageError ? (
                        <div className="invalid-feedback">
                          {this.state.profileImageError}
                        </div>
                      ) : (
                        userData.profileImage === "" && (
                          <div className="invalid-feedback">
                            Please upload profile image
                          </div>
                        )
                      )}
                    </div>
                    <hr />
                    <div className="form-group">

                     <div>
                          <label className="label-primary">
                        PDF
                      </label>
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          this.onDropPDF(acceptedFiles)
                        }
                        accept="application/pdf"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone">
                            <div className="dropzone__area" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <button type="button" className="btn btn__purple">
                                <Plus className="btn__icon" />Upload PDF</button>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <div className="dropzone__grid d-flex gap-2">
                       {
                        this.props.listingWebsiteTemplate.pdfData?.map((pdf , index) =>{
                          return (
                             <div className="dropzone__grid--item">
                               <div className="dropzone__grid--preview">
                               <PDFThumbnail pdfUrl={pdf?.file} key={index} />
                                 <div className="dropzone__grid--remove">
                                   <button
                                     onClick={this.removeImageUrl.bind(this, 3, index)}
                                     className="btn btn__remove"
                                   >
                                     <Remove />
                                </button>
                              </div>
                             </div>
                            </div>
                          )
                        })
                      }
                      </div>
                      </div>
                    </div>
                    <hr />

                    {
                      this.props.listingWebsiteTemplate.videosData?.map((video , index) =>{
                        return (
                               <div key={index}>
                                 <div className="form-group">
                                   <div className="d-flex align-items-center justify-content-between mb-2">
                                     <label className="label-primary mb-0">
                                       Video {index + 1}{" "}
                                       {/* <span className="required">*</span> */}
                                     </label>
                                   </div>
                                   <div className="d-flex justify-content-between align-items-center">
                                     <input
                                       type="url"
                                       name="video"
                                       value={video}
                                       onChange={(e) => {
                                         this.hanldeChangeVideo(e.target.value , index);
                                       }}
                                       onBlur={handleBlur}
                                       className="form-control"
                                       required
                                    />
                                   <button
                                     onClick={()=>{
                                      this.handleDeleteVideo(index)}}
                                     className="btn btn__remove"
                                   >
                                       <Remove />
                                    </button>
                                   </div>
                                  
                                 </div>
                               </div>
                        );
                      })
                    }
                    <button
                      className="btn btn-lg btn__green w-100 mb-3"
                      type="button"
                      onClick={() => {
                        this.handleAddVideo()
                      }}
                    >
                      Add Video
                    </button>
                    <hr />
                    <FieldArray
                      name="propertylist"
                      render={(arrayHelpers) => {
                        const propertylist = values.propertylist;
                        return (
                          <div className="row">
                            <div className="col-md-12">
                              {propertylist && propertylist.length > 0
                                ? propertylist.map((item, index) => (
                                  <div key={index}>
                                    <div className="form-group">
                                      <div className="d-flex align-items-center justify-content-between mb-2">
                                        <label className="label-primary mb-0">
                                          Link {index + 1}{" "}
                                          {/* <span className="required">*</span> */}
                                        </label>
                                        <EditDeleteBtns
                                          onEdit={() => {
                                            this.setState({
                                              isEdit: true,
                                              propertyData: { ...item },
                                            });
                                            setTimeout(() => {
                                              this.openModal(item, index);
                                            }, 100);
                                          }}
                                          onDelete={() => {
                                            arrayHelpers.remove(index);
                                            this.handleRemove(index);
                                          }}
                                        />
                                      </div>

                                      {/* <Field
                                          as="textarea"
                                          className="form-control"
                                          name={`propertylist.${index}.title`}
                                          maxLength="80"
                                          // onChange={(e) => this.handleOnChange(e)}
                                        /> */}
                                      <p className="LinkTitle">
                                        {propertylist[index].title}
                                      </p>
                                      {/* <p className="form-control-note text-end fst-normal">
                                          {propertylist[index].title.length}
                                          /80
                                        </p> */}
                                      <div className="invalid-feedback">
                                        <ErrorMessage
                                          name={`propertylist.${index}.title`}
                                        />
                                      </div>
                                    </div>
                                    {/* <div className="form-group">
                                        <label className="label-primary">
                                          Link {index + 1}
                                          <span className="required">*</span>
                                        </label>
                                        <Field
                                          className="form-control"
                                          name={`propertylist.${index}.link`}
                                        />
                                        <div className="invalid-feedback">
                                          <ErrorMessage
                                            className="invalid-feedback"
                                            name={`propertylist.${index}.link`}
                                          />
                                        </div>
                                      </div> */}
                                    {/* <button
                                className="btn btn-lg btn__green w-100 mb-3"
                                type="button"
                                onClick={() => this.openModal(item,index)}
                                // onClick={() => {
                                //   arrayHelpers.push({
                                //     title: "",
                                //     // link: "",
                                //   });
                                // }}
                              >
                                Add Link
                              </button> */}
                                  </div>
                                ))
                                : null}
                              <button
                                className="btn btn-lg btn__green w-100 mb-3"
                                type="button"
                                onClick={() => {
                                  this.setState({ isEdit: false, indx: null });
                                  setTimeout(() => {
                                    this.openModal(null, null);
                                  }, 100);
                                }}
                              // onClick={() => {
                              //   arrayHelpers.push({
                              //     title: "",
                              //     // link: "",
                              //   });
                              // }}
                              >
                                Add Link
                              </button>
                            </div>
                          </div>
                        );
                      }}
                    />
                    <hr />
                    {values.testimonial?.dataList?.length > 0 ? <div className="form-group">
                      <label className="label-primary">Testimonial Title</label>
                      <input
                        type="text"
                        onChange={(e)=> this.handleSaveTestimonial("title",e?.target?.value)}
                        value={get(listingWebsiteTemplate, "testimonial.title", "")}
                        className="form-control"
                        required
                      />
                    </div>:null}
                    <FieldArray
                      name="testimonial"
                      render={(arrayHelpers) => {
                        const testimonials = values.testimonial?.dataList;
                        return (
                          <div className="row">
                            <div className="col-md-12">
                              {testimonials && testimonials.length > 0
                                ? testimonials.map((item, index) => (
                                  <div className="rating__main mb-2">
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <Rating
                                          initialValue={item?.stars}
                                          allowFraction
                                          readonly
                                          emptyColor="#b3afaf"
                                          size={14}
                                          fillColor='#000'
                                        />
                                      </div>
                                      <EditDeleteBtns
                                        onDelete={() => {
                                          arrayHelpers.remove(index);
                                          const filteredTestimonials = testimonials.filter((_, i) => i !== index);
                                          this.props.handleTestimonialChange({ dataList: filteredTestimonials, title: values.testimonials?.title })
                                        }}
                                        onEdit={() => {
                                          this.setState({
                                            isTestimonialModalOpen: true,
                                            testimonialData: { ...item },
                                            isEdit: true,
                                            indx: index
                                          });

                                        }}
                                      />
                                    </div>
                                    <p>
                                      {item?.description?.length > 400 ?
                                        this.state.seeMoreEnabled ? <>
                                          {item?.description}{" "}
                                          <button className='see__more__btn' onClick={() => this.setState({seeMoreEnabled: false})}>See Less</button>
                                        </> :
                                          <>
                                            {item?.description?.slice(0, 400)} {" "} <button className='see__more__btn' onClick={this.seeMore}>See More</button>
                                          </>
                                        : item?.description}
                                      {/* {item?.description} */}
                                    </p>
                                    <p>
                                      {item?.name}
                                    </p>
                                    <hr />
                                  </div>
                                ))
                                : null}
                              <button
                                className="btn btn-lg btn__green w-100 mb-3"
                                type="button"
                                onClick={this.testimonialModalOpen}
                              >
                                Add Testimonial
                              </button>
                            </div>
                          </div>
                        );
                      }}
                    />
                    <hr />
                    {values.myWork?.dataList?.length > 0 ? <div className="form-group">
                      <label className="label-primary">My Work Title</label>
                      <input
                        type="text"
                        onChange={(e)=> this.handleSaveMyWork("title",e?.target?.value)}
                        value={get(listingWebsiteTemplate, "myWork.title", "")}
                        className="form-control"
                        required
                      />
                    </div>:null}
                    <FieldArray
                      name="myWork"
                      render={(arrayHelpers) => {
                        const myWorkList = values.myWork?.dataList;
                        return (
                          <div className="row">
                            <div className="col-md-12">
                              {myWorkList && myWorkList.length > 0
                                ? myWorkList.map((item, index) => (
                                  <div key={index}>
                                    <div className="form-group">
                                      <div className="d-flex align-items-center justify-content-between mb-2">
                                        <label className="label-primary mb-0">
                                          Work {index + 1}{" "}
                                          {/* <span className="required">*</span> */}
                                        </label>
                                        <div>
                                          <button
                                            type="button"
                                            className="btn btn__link btn__link--deafult"
                                            onClick={() => {
                                              this.setState({
                                                isEdit: true,
                                                propertyData: { ...item },
                                              });
                                              setTimeout(() => {
                                                this.openModal(item, index, true);
                                              }, 100);
                                            }}
                                          >
                                            Edit
                                          </button>

                                          <button
                                            type="button"
                                            className="btn btn__link btn__link--danger"
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                                const filteredMyWorkList = myWorkList.filter((_,i) => i !== index);
                                                this.props.handleMyWorkChange({dataList:filteredMyWorkList,title:values.myWork?.title})
                                            }}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                       {myWorkList[index].workImage ? <div className="work__image__container">
                                           <img className="work-image" src={myWorkList[index].workImage} /> 
                                        </div>:""}
                                        <div className={`${myWorkList?.[index]?.workImage ? "has__image":""} work_name_section`}>
                                          <span className="work-name">
                                            {myWorkList[index].title}
                                          </span>
                                          <span className="work-price">
                                        {myWorkList[index]?.price && new Intl.NumberFormat('en-US', { style: 'currency',currency: 'USD'}).format(myWorkList[index]?.price)}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="invalid-feedback">
                                        <ErrorMessage
                                          name={`myWorkList.${index}.title`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))
                                : null}
                              <button
                                className="btn btn-lg btn__green w-100 mb-3"
                                type="button"
                                onClick={() => {
                                  this.setState({ isEdit: false, indx: null });
                                  setTimeout(() => {
                                    this.openModal(null, null , true);
                                  }, 100);
                                }}
                              >
                                Add My Work
                              </button>
                            </div>
                          </div>
                        );
                      }}
                    />
                    {/* <button
                      type="submit"
                      className="btn btn-lg btn__purple w-100"
                    >
                      {this.state.isSpinLoading ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        "Save"
                      )}
                    </button> */}
                  </form>
                )}
              </Formik>
            )}
          </>
        ) : (
          <>
            <form onSubmit={this.handleSubmit} noValidate>
              <div className="form-group">
                <label className="label-primary">Campaign Name</label>
                <input
                  type="text"
                  name="campaignName"
                  onChange={this.handleChange}
                  value={get(listingWebsiteTemplate, "campaignName", "")}
                  className="form-control"
                  required
                />
                {this.props.campaignNameError !== "" && (
                  <div className="invalid-feedback">
                    {this.props.campaignNameError}
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label className="label-primary">Heading</label>
                  {get(listingWebsiteTemplate, "showHeading", false) ? (
                    <button
                      onClick={() =>
                        this.props.headingShowingStatus("showHeading", false)
                      }
                      className="btn"
                    >
                      <Eye className="eye-icon" />
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        this.props.headingShowingStatus("showHeading", true)
                      }
                      className="btn btn-eye-disabled"
                    >
                      <Eye className="eye-icon" />
                    </button>
                  )}
                </div>
                <input
                  type="text"
                  name="heading"
                  onChange={this.handleChange}
                  value={get(listingWebsiteTemplate, "heading", "")}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label className="label-primary">Sub Heading</label>
                  {get(listingWebsiteTemplate, "showSubHeading", false) ? (
                    <button
                      onClick={() =>
                        this.props.headingShowingStatus("showSubHeading", false)
                      }
                      className="btn"
                    >
                      <Eye className="eye-icon" />
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        this.props.headingShowingStatus("showSubHeading", true)
                      }
                      className="btn btn-eye-disabled"
                    >
                      <Eye className="eye-icon" />
                    </button>
                  )}
                </div>
                <textarea
                  type="text"
                  onChange={this.handleChange}
                  value={get(listingWebsiteTemplate, "subHeading", "")}
                  name="subHeading"
                  className="form-control"
                  required
                  rows="3"
                ></textarea>
              </div>
              <div className="form-group">
                <label className="label-primary">Text Color</label>
                <ColorPicker name="textColor" {...this.props} {...this.state} />
              </div>
              <div className="form-group">
                <label className="label-primary">Background Color</label>
                <ColorPicker
                  name="backgroundColor"
                  {...this.props}
                  {...this.state}
                />
              </div>
              <div className="form-group">
                <label className="label-primary">Campaign Feature Image</label>
                <ImagePicker name="image" {...this.props} />
                <p className="form-control-note">
                  If there is no picture the campaign section will not show.
                </p>
              </div>
              <div className="form-group">
                <label className="label-primary">Logo</label>
                <ImagePicker name="logo" {...this.props} />
              </div>
              <div className="form-group">
                <label className="label-primary">Desktop logo size</label>
                <RangePickerComponent name="desktopLogoSize" {...this.props} />
              </div>
              <div className="form-group">
                <label className="label-primary">Mobile logo size</label>
                <RangePickerComponent name="mobileLogoSize" {...this.props} />
              </div>
            </form>
          </>
        )}

        <ReactModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          contentLabel="OTP"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-450 modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Social Link</h5>
            </div>
            <div className="react-modal-content" key={1}>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <label className="label-primary mb-0">
                    Title{this.state?.isEdit && indx + 1}
                    <span className="required">*</span>
                  </label>
                </div>
                <textarea
                  type="text"
                  value={propertyData?.title}
                  className="form-control"
                  name="title"
                  maxLength="80"
                  onChange={(e) => this.handleOnChange(e, indx)}
                />
                <p className="form-control-note text-end fst-normal">
                  {/* {userData && get(userData, "propertylist", [])[indx]?.title?.length} */}
                  {propertyData?.title?.length}
                  /80
                </p>
                <div className="invalid-feedback">{this.state.titleError}</div>
              </div>
              <div className="form-group">
                <label className="label-primary">
                  Link<span className="required">*</span>
                </label>
                <input
                  type="text"
                  value={propertyData?.link}
                  className="form-control"
                  name="link"
                  onChange={(e) => this.handleOnChange(e, indx)}
                />
                <div className="invalid-feedback">{this.state.linkError}</div>
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                onClick={this.closeModal.bind(this)}
                className="btn btn__default me-3"
              >
                Cancel
              </button>
              <button
                disabled={this.state.isSaveButtonEnable}
                type="button"
                className="btn btn__purple"
                onClick={() => {
                  this.setState({ isCalled: true });
                  this.handleSave(indx);
                }}
              >
                {this.state.isSpinLoading ? (
                  <Spin indicator={antIcon} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.isTestimonialModalOpen}
          onRequestClose={this.closeModal}
          contentLabel="OTP"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-450 modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Testimonial</h5>
            </div>
            <div className="react-modal-content" key={1}>
              <div>
                <div className="form-group">
                  <label className="label-primary">Name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={this.handleTestimonialChange}
                    value={testimonialData?.name}
                    className="form-control"
                    required
                  />
                  <div className="invalid-feedback">
                    {/* <ErrorMessage
                       name={`testimonials.${0}.name`}
                     /> */}
                  </div>
                </div>
                <div className="form-group">
                  <label className="label-primary">Description</label>
                  <textarea
                    type="text"
                    name="description"
                    onChange={this.handleTestimonialChange}
                    value={testimonialData?.description}
                    className="form-control"
                    required
                  />
                  <div className="invalid-feedback">
                    {/* <ErrorMessage
                       name={`testimonials.${0}.description`}
                     /> */}
                  </div>
                </div>
                <label className="label-primary">Rating</label>
                <div>
                  <Rating
                    initialValue={testimonialData?.stars}
                    allowFraction
                   onClick={(e)=> this.handleTestimonialChange({target:{value:e,name:"stars"}})}
                   onPointerEnter={(e)=> console.log("Rating enter,",e)}
                   onPointerLeave={(e)=> console.log("Rating leave,",e)}
                   onPointerMove={(e)=> console.log("Rating move,",e)}
                    size={24}
                  />
                </div>
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                onClick={this.closeModal.bind(this)}
                className="btn btn__default me-3"
              >
                Cancel
              </button>
              <button
                disabled={this.state.testimonialSaveDisabled}
                type="button"
                className="btn btn__purple"
                onClick={() => {
                  this.setState({ isCalled: true });
                  this.handleSaveTestimonial()
                }}
              >
                {this.state.isSpinLoading ? (
                  <Spin indicator={antIcon} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </ReactModal>
        {/* My Work Popup Start  */}
        <ReactModal
          isOpen={this.state.isMyWorkModalOpen}
          onRequestClose={this.closeModal}
          contentLabel="OTP"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-450 modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">My Work</h5>
            </div>
            <div className="react-modal-content" key={1}>
              <div className="form-group">
                <label className="label-primary">
                  Work Photo
                </label>
                <Dropzone
                  onDrop={(acceptedFiles) =>
                    this.onDropImage(acceptedFiles, "workImage")
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone">
                      <div className="dropzone__area" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <button type="button" className="btn btn__purple">
                          <Plus className="btn__icon" /> {myWorkData?.workImage !== "" &&
                      myWorkData?.workImage !== null && myWorkData?.workImage !== undefined ? "Replace Photo":"Upload Photo"}
                        </button>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {myWorkData?.workImage !== "" &&
                      myWorkData?.workImage !== null && myWorkData?.workImage !== undefined  ? (
                  <div className="dropzone__grid">
                    <div className="dropzone__grid--item">
                      <div className="dropzone__grid--preview">
                        <img
                          className="preview__thumb"
                          src={myWorkData?.workImage}
                          alt=""
                        />
                        <div className="dropzone__grid--remove">
                          <button
                            onClick={this.removeImageUrl.bind(this)}
                            className="btn btn__remove"
                          >
                            <Remove />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <label className="label-primary mb-0">
                    Title{this.state?.isEdit && indx + 1}
                    <span className="required">*</span>
                  </label>
                </div>
                <textarea
                  type="text"
                  value={myWorkData?.title}
                  className="form-control"
                  name="mywork-title"
                  maxLength="80"
                  onChange={(e) => this.handleOnChange(e, indx, true)}
                />
                <p className="form-control-note text-end fst-normal">
                  {myWorkData?.title?.length}
                  /80
                </p>
                <div className="invalid-feedback">{this.state.titleError}</div>
              </div>
              <div className="form-group">
                <label className="label-primary">
                  Price<span className="required">*</span>
                </label>
                <input
                  type="number"
                  value={myWorkData?.price}
                  className="form-control"
                  name="mywork-price"
                  onChange={(e) => this.handleOnChange(e, indx, true)}
                />
                <div className="invalid-feedback">{this.state.priceError}</div>
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                onClick={this.closeModal.bind(this)}
                className="btn btn__default me-3"
              >
                Cancel
              </button>
              <button
                disabled={this.state.isSaveMyWorkButtonEnable}
                type="button"
                className="btn btn__purple"
                onClick={() => {
                  this.setState({ isCalled: true });
                  this.handleSaveMyWork(indx);
                }}
              >
                {this.state.isSpinLoading ? (
                  <Spin indicator={antIcon} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </ReactModal>
        {/* My Work Popup End  */}
      </>
    );
  }
}
