import { connect } from "react-redux";
import {
  getProperty,
  initPropertyPhase,
  deleteProperty,
} from "../../../store/mylisting/duck";
import MyListingsComponent from "./component";

const MyListingsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    getPropertyPhase: state.mylisting.getPropertyPhase,
    getPropertyData: state.mylisting.getPropertyData,
    propertyCount: state.mylisting.propertyCount,
    deletePropertyPhase: state.mylisting.deletePropertyPhase,
  }),
  // Map actions to dispatch and props
  {
    deleteProperty,
    initPropertyPhase,
    getProperty,
  }
)(MyListingsComponent);

export default MyListingsContainer;
