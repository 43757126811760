import * as React from "react";

const LinkIcon = ({ ...props }) => {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.799 13.007a3.402 3.402 0 0 1-4.805 0 3.402 3.402 0 0 1 0-4.806L3.396 5.8a3.402 3.402 0 0 1 4.805 0 .51.51 0 1 1-.72.72 2.362 2.362 0 0 0-3.364 0L1.714 8.923a2.362 2.362 0 0 0 0 3.364 2.362 2.362 0 0 0 3.364 0l2.162-2.162a.51.51 0 1 1 .72.72L5.8 13.007ZM10.604 8.2a3.402 3.402 0 0 1-4.805 0 .509.509 0 1 1 .72-.72 2.362 2.362 0 0 0 3.364 0l2.403-2.403a2.362 2.362 0 0 0 0-3.364 2.362 2.362 0 0 0-3.364 0L6.76 3.877a.51.51 0 1 1-.721-.721L8.201.994a3.402 3.402 0 0 1 4.805 0 3.402 3.402 0 0 1 0 4.805L10.604 8.2Z"
        fill="#000"
      />
    </svg>
  );
};

export default LinkIcon;
