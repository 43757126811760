import * as React from "react";

const Export = ({ ...props }) => {
  return (
    <svg
      width={13}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.002 8.057a.84.84 0 1 1-1.678 0v-4.7l-8.902 8.9a.833.833 0 0 1-1.178-1.18l8.9-8.898h-4.7a.84.84 0 0 1 0-1.679h6.731a.84.84 0 0 1 .827.836v6.721Z"
        fill="#4F566B"
      />
    </svg>
  );
};

export default Export;
