import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import "./styles.scss";
const cookies = new Cookies();

export default class ImpersonateComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  logoutImpersonate() {
    localStorage.removeItem("Authorization");
    this.props.resetImpersonatedUser();
    localStorage.removeItem("userId");
    this.props.history.push("/admin/active");
    cookies.remove("Authorization", { path: "/", domain: "yardpost.com" });
  }

  render() {
    return (
      <>
        <div className="impersonate">
          <div className="impersonate__container">
            <div className="impersonate__text">
              You are impersonating ( {this.props.data.firstName}{" "}
              {this.props.data.lastName} ){" "}
              <button
                onClick={this.logoutImpersonate.bind(this)}
                className="impersonate__link"
              >
                Click here to go back to your account
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
