import { connect } from "react-redux";

import DashboardComponent from "./component";

const DashboardContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(DashboardComponent);

export default DashboardContainer;
