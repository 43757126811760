import React, { PureComponent } from "react";
import Gif from "../../../../../images/preview.png";
import ListingWebsite from "../../../../../images/listing-website.png";
import social from "../../../../../images/social-link.png";
import "./styles.scss";

export default class DemoPreviewComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const { campaignType } = this.props;
    return (
      <>
        <div className="mac__window">
          <div className="mac__window--header">
            <div className="mac__window--dot dot__1"></div>
            <div className="mac__window--dot dot__2"></div>
            <div className="mac__window--dot dot__3"></div>
          </div>
          <div className="mac__window--body">
            <div className="mac__window--inner">
              {campaignType === "sell" && (
                <img className="preview__default" src={Gif} alt="" />
              )}
              {campaignType === "listing-website" && (
                <img className="preview__default" src={ListingWebsite} alt="" />
              )}
              {campaignType === "social-link" && (
                <img className="preview__default" src={social} alt="" />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
