import React, { PureComponent } from "react";
import Header from "../../User/Header/component";
import Footer from "../../User/Footer/component";
import SocialAtLeft from "../../User/SocialAtLeft/component";
import { Arrow, EqualHO, Realtor } from "../../../components/icons";
import { get, isEmpty } from "lodash";
import InputMask from "react-text-mask";
import "./styles.scss";
import Placeholder from "./../../../images/placeholder.svg";
import { Spin, message, notification } from "antd";
import { mapStyle } from "../../../constants/config";
import { LoadingOutlined } from "@ant-design/icons";
import { isThisISOWeek } from "date-fns/esm";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import ReactModal from "react-modal";
import PropertyCalculator from "./PropertyCalculator/component";
import {LeftCircleFilled} from "@ant-design/icons"

const google = window.google;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const success = (msg) => {
  message.success({ key: "updatable", content: msg });
};

const error = (err) => {
  message.error({ key: "updatable", content: err });
};
export default class PropertyDetailComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      leadData: {},
      leadSettingData: {},
      leadPageUserData: {},
      homeData: {},
      userId: "",
      isLoading: false,
      message: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      image: "",
      error: {},
      onOptionSelect: {},
      existProperty: {},
      unit: "",
      map: {},
      emailError: "",
      firstNameError: "",
      lastNameError: "",
      phoneError: "",
      address: "",
      homeData: {},
      error: false,
      map: {},
      modalIsOpen: true,
      countryCode: "",
      recaptcha: false,
      sellingConcession: 2,
      sellingConcessionValue: 0,
      homeImprovement: 0,
      ownershipCost: 1,
      ownershipCostValue: 0,
      transferTax: 1,
      transferTaxValue: 0,
      preWork: 2,
      preWorkValue: 0,
      realEstateAgentFees: 6,
      realEstateAgentFeesValue: 0,
      remainingMortgage: 0,
      remainingMortgageValue: 0,
      targetSellPrice: 200000,
      targetSellPriceValue: 0,
      estimationSellingCost: 0,
      netProcced: 0,
    };
    // this.onSubmit = this.onSubmit.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
  }

  componentDidMount() {
    if (isEmpty(this.props.leadPageByIdData)) {
      this.props.getLeadPageById({
        id: get(this.props.match, "params.id", ""),
      });
    } else {
      this.setState(
        {
          leadData: this.props.leadPageByIdData,
          leadSettingData: this.props.leadPageSetting,
          leadPageUserData: this.props.leadPageUserData,
        },
        () => {
          let title =
            get(
              this.props.leadPageByIdData,
              "seo.metaTitle",
              "Sell Details Page"
            ) !== ""
              ? get(this.props.leadPageByIdData, "seo.metaTitle", "Sell Page")
              : "Sell Details";
          let companyName =
            get(this.props.leadPageUserData, "companyName", "Yardpost") !== ""
              ? get(this.props.leadPageUserData, "companyName", "Yardpost")
              : "Yardpost";
          document.title = title + " | " + companyName;
          document.getElementById("favicon").href = get(
            this.props.leadPageSetting,
            "favicon",
            "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png"
          );
          this.forceUpdate();
        }
      );
    }
    if (get(this.props, "getAddressDetailsData", "")) {
      this.setState(
        {
          homeData: this.props.getAddressDetailsData,
          recaptcha: false,
          targetSellPrice: parseInt(
            get(this.props, "getAddressDetailsData.price", 0)
          ),
        },
        () => {
          this.handleMap();
          // let realEstateAgentFeesV = (this.state.targetSellPrice * this.state.realEstateAgentFees) / 100
          // let preWorkV = (this.state.targetSellPrice * this.state.preWork) / 100
          // let transferTaxV = (this.state.targetSellPrice * this.state.transferTax) / 100
          // let ownershipCostV = (this.state.targetSellPrice * this.state.ownershipCost) / 100
          // let sellingConcessionV = (this.state.targetSellPrice * this.state.sellingConcession) / 100
          // this.setState({realEstateAgentFeesValue: realEstateAgentFeesV, preWorkValue: preWorkV, transferTaxValue: transferTaxV, ownershipCostValue: ownershipCostV, sellingConcessionValue: sellingConcessionV, transferTaxValue: transferTaxV},()=>{

          // })
          this.valueUpdate();
          this.forceUpdate();
        }
      );
    } else {
      const socialLinkId = this.props.location?.state?.id;
      if (
        window.location.origin === "https://app.yardpost.com" ||
        window.location.origin === "https://stag-app.yardpost.com" ||
        window.location.origin === "http://localhost:3000" ||
        window.location.origin === "https://yardpost-frontend-04tp.onrender.com"
      ) {
        this.props.history.push(`/preview/social-link-preview/${socialLinkId}`);
      } else {
        this.props.history.push(`/social/${socialLinkId}`);
      }
      // if (
      //   get(this.props, "location.pathname", "")
      //     .split("/")
      //     .indexOf("preview") !== -1
      // ) {
      //   this.props.history.push("/social-link-preview");
      //   // this.props.history.push(
      //   //   `/preview/sell/${get(this.props.match, "params.id", "")}`
      //   // );
      // } else {
      //   this.props.history.push("/social");
      //   // this.props.history.push(
      //   //   `/sell/${get(this.props.match, "params.id", "")}`
      //   // );
      // }
    }
  }

  openNotificationWithIcon = (type) => {
    notification[type]({
      key: "updatable",
      className: "cm-notification",
      description:
        "Thank you for your interest in this property. Someone from our team will reach out to you shortly.",
    });
  };

  valueUpdate = () => {
    let realEstateAgentFeesV = (
      (this.state.targetSellPrice * this.state.realEstateAgentFees) /
      100
    ).toFixed(2);
    let preWorkV = (
      (this.state.targetSellPrice * this.state.preWork) /
      100
    ).toFixed(2);
    let transferTaxV = (
      (this.state.targetSellPrice * this.state.transferTax) /
      100
    ).toFixed(2);
    let ownershipCostV = (
      (this.state.targetSellPrice * this.state.ownershipCost) /
      100
    ).toFixed(2);
    let sellingConcessionV = (
      (this.state.targetSellPrice * this.state.sellingConcession) /
      100
    ).toFixed(2);
    let remaining =
      this.state.remainingMortgage !== "" &&
      this.state.remainingMortgage !== null &&
      this.state.remainingMortgage !== undefined
        ? parseFloat(this.state.remainingMortgage)
        : "";
    let homeImp =
      this.state.homeImprovement !== "" &&
      this.state.homeImprovement !== null &&
      this.state.homeImprovement !== undefined
        ? parseFloat(this.state.homeImprovement)
        : "";
    let estSellCost = parseFloat(realEstateAgentFeesV);
    estSellCost += parseFloat(preWorkV);
    estSellCost += parseFloat(transferTaxV);
    estSellCost += parseFloat(ownershipCostV);
    estSellCost += parseFloat(sellingConcessionV);
    estSellCost += parseFloat(homeImp);
    let net =
      parseFloat(this.state.targetSellPrice) -
      (remaining + parseFloat(estSellCost));
    this.setState(
      {
        remainingMortgage: remaining,
        netProcced: net,
        estimationSellingCost: estSellCost,
        realEstateAgentFeesValue: realEstateAgentFeesV,
        preWorkValue: preWorkV,
        transferTaxValue: transferTaxV,
        ownershipCostValue: ownershipCostV,
        sellingConcessionValue: sellingConcessionV,
        transferTaxValue: transferTaxV,
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  componentDidUpdate(prev) {
    document.title = "Sell Details";
    if (this.props.addInquiryPhase == true) {
      this.props.initLeadPage();
      this.openNotificationWithIcon("success");
      document.body.classList.remove("ReactModal__Body--open");
      this.setState(
        {
          isLoading: false,
          message: this.props.addInquiryMessage,
          phone: "",
          email: "",
          firstName: "",
          lastName: "",
          countryCode: "",
          recaptcha: false,
          modalIsOpen: false,
        },
        () => {
          this.forceUpdate();
        }
      );
    } else if (!this.props.addInquiryPhase) {
      this.setState(
        {
          isLoading: false,
          emailError: this.props.addInquiryMessage,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  camelCase = (str) => {
    return str
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function (b) {
        return b.toLowerCase();
      });
  };

  setModalIsOpen = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen }, () => {
      this.forceUpdate();
    });
  };

  cancelModal() {
    document.body.classList.remove("ReactModal__Body--open");
    this.setState({ modalIsOpen: false }, () => {
      this.forceUpdate();
    });
  }

  handleMap = () => {
    const { homeData } = this.state;
    const zoom = 18;
    if (document.getElementById("map-canvas")) {
      let map = new google.maps.Map(document.getElementById("map-canvas"), {
        zoom: zoom,
        center: new google.maps.LatLng(
          parseFloat(homeData?.latitude),
          parseFloat(homeData?.longitude)
        ),
        fullscreenControl: false,
        zoomControl: false,
        styles: mapStyle,
        streetViewControl: false,
        draggable: true,
        scrollwheel: false,
        scaleControl: false,
        panControl: false,
        clickableIcons: false,
        mapTypeControl: false,
      });
      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          parseFloat(homeData?.latitude),
          parseFloat(homeData?.longitude)
        ),
        icon: "https://yardpost-dev.s3.amazonaws.com/property_80501646920765595",
      });
      // To add the marker to the map, call setMap();
      marker.setMap(map);

      this.setState({
        map,
      });
    }
  };

  handleChangeValue = (e, keyV, keyName) => {
    console.log(e.target.value.replace("$", "").replace(/,/g, ""), "value");
    if (
      keyName === "targetSellPriceValue" ||
      keyName === "remainingMortgageValue" ||
      keyName === "homeImprovementValue"
    ) {
      let vl = e.target.value
        .replace("$", "")
        .replace(/,/g, "")
        .replace(/^0+/, "");
      vl = vl !== "" && vl !== null && vl !== undefined ? vl : 0;
      this.setState({ [`${keyV}`]: vl }, () => {
        this.valueUpdate();
        this.forceUpdate();
      });
    } else {
      let data = (
        (this.state.targetSellPrice *
          e.target.value.replace(/,/g, "").replace("%", "")) /
        100
      ).toFixed(2);
      this.setState(
        {
          [`${keyV}`]: e.target.value.replace(/,/g, "").replace("%", ""),
          [`${keyName}`]: data,
        },
        () => {
          this.valueUpdate();
          this.forceUpdate();
        }
      );
    }
  };

  handleChangeCountryCode = (value, phone) => {
    this.setState(
      { countryCode: value, phone: phone.replace(value, ""), phoneError: "" },
      () => {
        this.forceUpdate();
      }
    );
  };

  render() {
    const { leadPageSetting } = this.props;
    const { homeData, modalIsOpen, isLoading, leadPageUserData } = this.state;
    return (
      <>
        <main className="listing__website--main">
          <Header showBackBtn {...this.state} {...this.props} />
          <div className="sell__detail">
            <div className="sell__detail--row">
              <div className="sell__detail--left">
                <div className="sell__detail--content">
                  <h1 className="sell__detail--title">
                    👋 Right now your home is worth $
                    {parseInt(
                      get(homeData, "price", 0)
                    )?.toLocaleString("en-US")}
                    . Talk to me to see how we can get more
                    <Arrow className="ms-3" />
                  </h1>
                  <ul className="sell__detail--list">
                    <li>
                      <span>Address</span>
                      <span>
                        {`${homeData && homeData?.address}`}
                     
                      </span>
                    </li>
                    <li>
                      <span>
                        Estimated Price Range in{" "}
                        {homeData && homeData?.comparables && homeData?.comparables[0] && homeData?.comparables[0].zipCode}{" "}
                      </span>
                      <span>
                        $
                        {get(homeData, "priceRangeLow", "")?.toLocaleString(
                          "en-US"
                        )}{" "}
                        - $
                        {get(homeData, "priceRangeHigh", "")?.toLocaleString(
                          "en-US"
                        )}
                      </span>
                    </li>
                    {/* <li>
                    <span>
                         Avg. price per Sq. Ft. in{" "}
                         {homeData && homeData.comparables && homeData.comparables[0] && homeData.comparables[0].zipCode}
                       </span>
                       {homeData && homeData.comparables ? (
                         (() => {
                           const comparableWithLotSize = homeData.comparables.find(comparable => comparable.lotSize && comparable.lotSize !== 0);
                           if (comparableWithLotSize) {
                             return (
                               <span>
                                 $
                                 {(homeData.price / comparableWithLotSize.lotSize).toLocaleString("en-US")} / Sq. Ft.
                               </span>
                             );
                           } else {
                             return <span>$ - Sq.Ft.</span>;
                           }
                         })()
                       ) : (
                         <span>N/A</span>
                       )}
                   </li> */}
                   {/* <li>
                     <span>
                       Avg. price property price in{" "}
                       {homeData && homeData.comparables && homeData.comparables[0] && homeData.comparables[0].zipCode}
                     </span>
                     {homeData && homeData.comparables ? (
                       (() => {
                         const comparableWithLotSize = homeData.comparables.find(comparable => comparable.lotSize && comparable.lotSize !== 0);
                         if (comparableWithLotSize) {
                           return (
                             <span>
                               $
                               {(homeData.price / comparableWithLotSize.lotSize).toLocaleString("en-US")} - Sq.Ft.
                             </span>
                           );
                         } else {
                           const hasZeroLotSize = homeData.comparables.some(comparable => comparable.lotSize === 0);
                           if (hasZeroLotSize) {
                             return <span>$ - Sq.Ft.</span>;
                           } else {
                             return <span>N/A</span>;
                           }
                         }
                       })()
                     ) : (
                       <span>N/A</span>
                     )}
                   </li> */}
                  </ul>
                </div>
              </div>
              <div className="sell__detail--right">
                <div id="map-canvas" className="sell__detail--map"></div>
              </div>
            </div>
          </div>

          <PropertyCalculator
            handleChangeValue={this.handleChangeValue}
            {...this.state}
            {...this.props}
          />
          {/* <Footer {...this.state} {...this.props} /> */}
          {/* <SocialAtLeft {...this.state} {...this.props} /> */}
        </main>
      </>
    );
  }
}
