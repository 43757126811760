import React, { PureComponent } from "react";
import { upperFirst } from "lodash";
import _uniqueId from "lodash/uniqueId";

const RadioGroupInput = ({ label, value, checked, setter, onChnage }) => {
  return (
    <>
      <div className="custom__radio__group">
        <input
          className="custom__radio__group--button"
          type="radio"
          value={value}
          id={label}
          checked={checked == value}
          onChange={() => setter(value)}
        />
        {label == "showPro" || label == "hidePro" ? (
          <label className="custom__radio__group--label" htmlFor={label}>
            {upperFirst(label.slice(0, 4))}
          </label>
        ) : (
          <label className="custom__radio__group--label" htmlFor={label}>
            {upperFirst(label)}
          </label>
        )}
      </div>
    </>
  );
};

export default RadioGroupInput;
