import { connect } from "react-redux";

import SuccessfulSignUpComponent from "./component";

const SuccessfulSignUpContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(SuccessfulSignUpComponent);

export default SuccessfulSignUpContainer;
