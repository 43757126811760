import { connect } from "react-redux";
import {
  getAdminAgent,
  resetAdmin,
  resetSubscriptions,
  userImpersonate,
} from "../../store/admin/duck";
import AdminComponent from "./component";

const AdminContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    adminAgentPhase: state.admin.adminAgentPhase,
    adminAgentData: state.admin.adminAgentData,
    impersonatePhase: state.admin.impersonatePhase,
    deleteAgentPhase: state.admin.deleteAgentPhase,
    cancelSubscriptionPhase: state.admin.cancelSubscriptionPhase,
    totalCount: state.admin.totalCount,
  }),
  // Map actions to dispatch and props
  {
    resetSubscriptions,
    resetAdmin,
    userImpersonate,
    getAdminAgent,
  }
)(AdminComponent);

export default AdminContainer;
