// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from "immutable";
import { assign } from "lodash";
import Cookies from "universal-cookie";
import { INIT, LOADING, SUCCESS, ERROR } from "../../constants/phase";

import { fromPromise } from "rxjs/observable/fromPromise";
import { of } from "rxjs";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
const cookies = new Cookies();

export const GET_PROPERTY = "yardpost/mylisting/GET_PROPERTY";
export const GET_PROPERTY_SUCCESS = "yardpost/mylisting/GET_PROPERTY_SUCCESS";
export const GET_PROPERTY_ERROR = "yardpost/mylisting/GET_PROPERTY_ERROR";

export const GET_ALL_PROPERTY = "yardpost/mylisting/GET_ALL_PROPERTY";
export const GET_ALL_PROPERTY_SUCCESS =
  "yardpost/mylisting/GET_ALL_PROPERTY_SUCCESS";
export const GET_ALL_PROPERTY_ERROR =
  "yardpost/mylisting/GET_ALL_PROPERTY_ERROR";
export const GET_ALL_PROPERTY_ADDRESS =
  "yardpost/mylisting/GET_ALL_PROPERTY_ADDRESS";
export const GET_ALL_PROPERTY_ADDRESS_SUCCESS =
  "yardpost/mylisting/GET_ALL_PROPERTY_ADDRESS_SUCCESS";
export const GET_ALL_PROPERTY_ADDRESS_ERROR =
  "yardpost/mylisting/GET_ALL_PROPERTY_ADDRESS_ERROR";
export const GET_FEATURED_PROPERTY = "yardpost/mylisting/GET_FEATURED_PROPERTY";
export const GET_FEATURED_PROPERTY_SUCCESS =
  "yardpost/mylisting/GET_FEATURED_PROPERTY_SUCCESS";
export const GET_FEATURED_PROPERTY_ERROR =
  "yardpost/mylisting/GET_FEATURED_PROPERTY_ERROR";

export const GET_AMENITIES_PROPERTY =
  "yardpost/mylisting/GET_AMENITIES_PROPERTY";
export const GET_AMENITIES_PROPERTY_SUCCESS =
  "yardpost/mylisting/GET_AMENITIES_PROPERTY_SUCCESS";
export const GET_AMENITIES_PROPERTY_ERROR =
  "yardpost/mylisting/GET_AMENITIES_PROPERTY_ERROR";

export const GET_PROPERTY_OPTIONS = "yardpost/mylisting/GET_PROPERTY_OPTIONS";
export const GET_PROPERTY_OPTIONS_SUCCESS =
  "yardpost/mylisting/GET_PROPERTY_OPTIONS_SUCCESS";
export const GET_PROPERTY_OPTIONS_ERROR =
  "yardpost/mylisting/GET_PROPERTY_OPTIONS_ERROR";

export const ADD_PROPERTY = "yardpost/mylisting/ADD_CARD";
export const ADD_PROPERTY_SUCCESS = "yardpost/mylisting/ADD_CARD_SUCCESS";
export const ADD_PROPERTY_ERROR = "yardpost/mylisting/ADD_CARD_ERROR";

export const UPLOAD_LISTING = "yardpost/mylisting/UPLOAD_LISTING";
export const UPLOAD_LISTING_SUCCESS =
  "yardpost/mylisting/UPLOAD_LISTING_SUCCESS";
export const UPLOAD_LISTING_ERROR = "yardpost/mylisting/UPLOAD_LISTING_ERROR";

export const MAIL_PROPERTY = "yardpost/mylisting/MAIL_PROPERTY";
export const MAIL_PROPERTY_SUCCESS = "yardpost/mylisting/MAIL_PROPERTY_SUCCESS";
export const MAIL_PROPERTY_ERROR = "yardpost/mylisting/MAIL_PROPERTY_ERROR";

export const SMS_PROPERTY = "yardpost/mylisting/SMS_PROPERTY";
export const SMS_PROPERTY_SUCCESS = "yardpost/mylisting/SMS_PROPERTY_SUCCESS";
export const SMS_PROPERTY_ERROR = "yardpost/mylisting/SMS_PROPERTY_ERROR";

export const ADD_INQUIRY = "yardpost/mylisting/ADD_INQUIRY";
export const ADD_INQUIRY_SUCCESS = "yardpost/mylisting/ADD_INQUIRY_SUCCESS";
export const ADD_INQUIRY_ERROR = "yardpost/mylisting/ADD_INQUIRY_ERROR";

export const DELETE_PROPERTY = "yardpost/mylisting/DELETE_PROPERTY";
export const DELETE_PROPERTY_SUCCESS =
  "yardpost/mylisting/DELETE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_ERROR = "yardpost/mylisting/DELETE_PROPERTY_ERROR";

export const COPY_PROPERTY = "yardpost/mylisting/COPY_PROPERTY";
export const COPY_PROPERTY_SUCCESS = "yardpost/mylisting/COPY_PROPERTY_SUCCESS";
export const COPY_PROPERTY_ERROR = "yardpost/mylisting/COPY_PROPERTY_ERROR";

export const GET_PROPERTY_DETAIL = "yardpost/mylisting/GET_PROPERTY_DETAIL";
export const GET_PROPERTY_DETAIL_SUCCESS =
  "yardpost/mylisting/GET_PROPERTY_DETAIL_SUCCESS";
export const GET_PROPERTY_DETAIL_ERROR =
  "yardpost/mylisting/GET_PROPERTY_DETAIL_ERROR";

export const GET_PROPERTY_DETAILS = "yardpost/mylisting/GET_PROPERTY_DETAILS";
export const GET_PROPERTY_DETAILS_SUCCESS =
  "yardpost/mylisting/GET_PROPERTY_DETAILS_SUCCESS";
export const GET_PROPERTY_DETAILS_ERROR =
  "yardpost/mylisting/GET_PROPERTY_DETAILS_ERROR";

export const GET_PROPERTY_LEADS_COUNT =
  "yardpost/mylisting/GET_PROPERTY_LEADS_COUNT";
export const GET_PROPERTY_LEADS_COUNT_SUCCESS =
  "yardpost/mylisting/GET_PROPERTY_LEADS_COUNT_SUCCESS";
export const GET_PROPERTY_LEADS_COUNT_ERROR =
  "yardpost/mylisting/GET_PROPERTY_LEADS_COUNT_ERROR";

export const GET_PROPERTY_LEADS = "yardpost/mylisting/GET_PROPERTY_LEADS";
export const GET_PROPERTY_LEADS_SUCCESS =
  "yardpost/mylisting/GET_PROPERTY_LEADS_SUCCESS";
export const GET_PROPERTY_LEADS_ERROR =
  "yardpost/mylisting/GET_PROPERTY_LEADS_ERROR";

export const GET_CLIENT_DETAIL = "yardpost/mylisting/GET_CLIENT_DETAIL";
export const GET_CLIENT_DETAIL_SUCCESS =
  "yardpost/mylisting/GET_CLIENT_DETAIL_SUCCESS";
export const GET_CLIENT_DETAIL_ERROR =
  "yardpost/mylisting/GET_CLIENT_DETAIL_ERROR";

export const GET_EXIST_PROPERTY = "yardpost/mylisting/GET_EXIST_PROPERTY";
export const GET_EXIST_PROPERTY_SUCCESS =
  "yardpost/mylisting/GET_EXIST_PROPERTY_SUCCESS";
export const GET_EXIST_PROPERTY_ERROR =
  "yardpost/mylisting/GET_EXIST_PROPERTY_ERROR";

export const GET_COUNTRY_AND_STATE = "yardpost/mylisting/GET_COUNTRY_AND_STATE";
export const GET_COUNTRY_AND_STATE_SUCCESS =
  "yardpost/mylisting/GET_COUNTRY_AND_STATE_SUCCESS";
export const GET_COUNTRY_AND_STATE_ERROR =
  "yardpost/mylisting/GET_COUNTRY_AND_STATE_ERROR";

export const INIT_PROPERTY_PHASE = "yardpost/mylisting/INIT_PROMO_PHASE";
export const MAIL_PROPERTY_RESET = "yardpost/mylisting/MAIL_PROPERTY_RESET";
export const SMS_PROPERTY_RESET = "yardpost/mylisting/MAIL_PROPERTY_RESET";
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  error: null,
  message: null,
  isSubmitting: false,
  addPropertyPhase: INIT,
  addPropertyData: {},
  addPropertyMessage: "",
  getPropertyPhase: INIT,
  getPropertyData: [],
  getFeaturedPhase: INIT,
  getFeaturedData: [],
  getAmenitiesPhase: INIT,
  getAmenitiesData: [],
  getAllPropertyPhase: INIT,
  getAllPropertyData: [],
  getPropertyOptionsPhase: INIT,
  getPropertyOptionsData: {},
  deletePropertyPhase: INIT,
  deletePropertyMessage: "",
  copyPropertyPhase: INIT,
  copyPropertyMessage: "",
  addInquiryPhase: INIT,
  addInquiryMessage: "",
  getPropertyDetailPhase: INIT,
  getPropertyDetailData: [],
  getPropertyDetailsPhase: INIT,
  getPropertyDetailsData: [],
  propertyCount: INIT,
  propertyLeadsCount: INIT,
  propertyLeadsCountPhase: INIT,
  getPropertyLeadsPhase: INIT,
  getPropertyLeadsData: [],
  getClientDetailsPhase: INIT,
  getClientDetailsData: {},
  mailPhase: INIT,
  mailMessage: "",
  SMSPhase: INIT,
  SMSMessage: "",
  existPropertyPhase: INIT,
  existPropertyData: {},
  uploadListingsPhase: INIT,
  uploadListingsMessage: "",
  getCountryAndStatePhase: INIT,
  getCountryAndStateData: [],
  getAllPropertyAddressPhase: INIT,
  getAllPropertyAddressData: [],
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case GET_ALL_PROPERTY: {
      return state
        .set("getAllPropertyPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_ALL_PROPERTY_SUCCESS: {
      const { payload } = action;
      return state
        .set("getAllPropertyPhase", payload.success)
        .set("getAllPropertyData", payload.data)
        .set("propertyCount", payload.count);
    }

    case GET_ALL_PROPERTY_ERROR: {
      const { payload } = action;
      return state
        .set("getAllPropertyPhase", ERROR)
        .set("error", payload.message);
    }
    case GET_ALL_PROPERTY_ADDRESS: {
      return state
        .set("getAllPropertyAddressPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_ALL_PROPERTY_ADDRESS_SUCCESS: {
      const { payload } = action;
      return state
        .set("getAllPropertyAddressPhase", "success")
        .set("getAllPropertyAddressData", payload.data)
        .set("propertyCount", payload.count);
    }

    case GET_ALL_PROPERTY_ADDRESS_ERROR: {
      const { payload } = action;
      return state
        .set("getAllPropertyAddressPhase", ERROR)
        .set("error", payload.message);
    }
    case GET_COUNTRY_AND_STATE: {
      return state
        .set("getCountryAndStatePhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_COUNTRY_AND_STATE_SUCCESS: {
      const { payload } = action;
      console.log(payload);
      return state
        .set("getCountryAndStatePhase", SUCCESS)
        .set("getCountryAndStateData", {
          country: payload.country,
          states: payload.states,
        });
    }

    case GET_COUNTRY_AND_STATE_ERROR: {
      const { payload } = action;
      return state
        .set("getCountryAndStatePhase", ERROR)
        .set("error", payload.message);
    }

    case GET_FEATURED_PROPERTY: {
      return state
        .set("getFeaturedPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_FEATURED_PROPERTY_SUCCESS: {
      const { payload } = action;
      return state
        .set("getFeaturedPhase", payload.success)
        .set("getFeaturedData", payload.data);
    }

    case GET_FEATURED_PROPERTY_ERROR: {
      const { payload } = action;
      return state.set("getFeaturedPhase", ERROR).set("error", payload.message);
    }

    case GET_AMENITIES_PROPERTY: {
      return state
        .set("getAmenitiesPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_AMENITIES_PROPERTY_SUCCESS: {
      const { payload } = action;
      return state
        .set("getAmenitiesPhase", SUCCESS)
        .set("getAmenitiesData", payload.data);
    }

    case GET_AMENITIES_PROPERTY_ERROR: {
      const { payload } = action;
      return state
        .set("getAmenitiesPhase", ERROR)
        .set("error", payload.message);
    }

    case GET_PROPERTY: {
      return state
        .set("getPropertyPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_PROPERTY_SUCCESS: {
      const { payload } = action;
      return state
        .set("getPropertyPhase", SUCCESS)
        .set("getPropertyData", payload.data)
        .set("propertyCount", payload.count);
    }

    case GET_PROPERTY_ERROR: {
      const { payload } = action;
      return state.set("getPropertyPhase", ERROR).set("error", payload.message);
    }

    case GET_PROPERTY_OPTIONS: {
      return state
        .set("getPropertyOptionsPhase", LOADING)
        .set("getPropertyOptionsData", {});
    }

    case GET_PROPERTY_OPTIONS_SUCCESS: {
      const { payload } = action;
      return state
        .set("getPropertyOptionsPhase", payload.success)
        .set("getPropertyOptionsData", payload.data);
    }

    case GET_PROPERTY_OPTIONS_ERROR: {
      const { payload } = action;
      return state
        .set("getPropertyOptionsPhase", ERROR)
        .set("getPropertyOptionsData", {});
    }

    case ADD_PROPERTY: {
      return state
        .set("addPropertyPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case ADD_PROPERTY_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("addPropertyPhase", SUCCESS)
          .set("addPropertyMessage", payload.message);
      }
    }

    case ADD_PROPERTY_ERROR: {
      const { payload } = action;
      return state
        .set("addPropertyPhase", ERROR)
        .set("addPropertyMessage", payload.message);
    }

    case UPLOAD_LISTING: {
      return state.set("uploadListingsPhase", LOADING);
    }

    case UPLOAD_LISTING_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("uploadListingsPhase", SUCCESS)
          .set("uploadListingsMessage", payload.message);
      }
    }

    case UPLOAD_LISTING_ERROR: {
      const { payload } = action;
      return state
        .set("uploadListingsPhase", ERROR)
        .set("uploadListingsMessage", payload.message);
    }

    case MAIL_PROPERTY: {
      return state.set("mailPhase", LOADING).set("mailMessage", "");
    }

    case MAIL_PROPERTY_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("mailPhase", SUCCESS)
          .set("mailMessage", payload.message);
      }
    }

    case MAIL_PROPERTY_ERROR: {
      const { payload } = action;
      return state.set("mailPhase", ERROR).set("mailMessage", payload.message);
    }

    case SMS_PROPERTY: {
      return state.set("SMSPhase", LOADING).set("SMSMessage", "");
    }

    case SMS_PROPERTY_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("SMSPhase", SUCCESS)
          .set("SMSMessage", payload.message);
      }
    }

    case SMS_PROPERTY_ERROR: {
      const { payload } = action;
      return state.set("SMSPhase", ERROR).set("SMSMessage", payload.message);
    }

    case ADD_INQUIRY: {
      return state.set("addInquiryPhase", LOADING);
    }

    case ADD_INQUIRY_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("addInquiryPhase", payload.success)
          .set("addInquiryMessage", payload.message);
      } else {
        return state
          .set("addInquiryPhase", payload.success)
          .set("addInquiryMessage", payload.message);
      }
    }

    case ADD_INQUIRY_ERROR: {
      const { payload } = action;
      return state.set("addInquiryPhase", ERROR).set("error", payload.message);
    }

    case DELETE_PROPERTY: {
      return state
        .set("deletePropertyPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case DELETE_PROPERTY_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("deletePropertyPhase", SUCCESS)
          .set("deletePropertyMessage", payload.message);
      }
    }

    case DELETE_PROPERTY_ERROR: {
      const { payload } = action;
      return state
        .set("deletePropertyPhase", ERROR)
        .set("error", payload.message);
    }

    case COPY_PROPERTY: {
      return state
        .set("copyPropertyPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case COPY_PROPERTY_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("copyPropertyPhase", SUCCESS)
          .set("copyPropertyMessage", payload.message);
      }
    }

    case COPY_PROPERTY_ERROR: {
      const { payload } = action;
      return state
        .set("copyPropertyPhase", ERROR)
        .set("error", payload.message);
    }

    case GET_PROPERTY_DETAIL: {
      return state
        .set("getPropertyDetailPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_PROPERTY_DETAIL_SUCCESS: {
      const { payload } = action;
      return state
        .set("getPropertyDetailPhase", payload.success)
        .set("getPropertyDetailData", payload.data);
    }

    case GET_PROPERTY_DETAIL_ERROR: {
      const { payload } = action;
      return state
        .set("getPropertyDetailPhase", ERROR)
        .set("error", payload.message);
    }

    case GET_PROPERTY_DETAILS: {
      return state
        .set("getPropertyDetailsPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_PROPERTY_DETAILS_SUCCESS: {
      const { payload } = action;
      return state
        .set("getPropertyDetailsPhase", SUCCESS)
        .set("getPropertyDetailsData", payload.data);
    }

    case GET_PROPERTY_DETAILS_ERROR: {
      const { payload } = action;
      return state
        .set("getPropertyDetailsPhase", ERROR)
        .set("error", payload.message);
    }

    case GET_PROPERTY_LEADS_COUNT: {
      return state
        .set("propertyLeadsCountPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_PROPERTY_LEADS_COUNT_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("propertyLeadsCountPhase", SUCCESS)
          .set("propertyLeadsCount", payload.data);
      }
    }

    case GET_PROPERTY_LEADS_COUNT_ERROR: {
      const { payload } = action;
      return state
        .set("propertyLeadsCountPhase", ERROR)
        .set("error", payload.message);
    }

    case GET_PROPERTY_LEADS: {
      return state
        .set("getPropertyLeadsPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_PROPERTY_LEADS_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("getPropertyLeadsPhase", SUCCESS)
          .set("getPropertyLeadsData", payload.data);
      }
    }

    case GET_PROPERTY_LEADS_ERROR: {
      const { payload } = action;
      return state
        .set("getPropertyLeadsPhase", ERROR)
        .set("error", payload.message);
    }

    case GET_CLIENT_DETAIL: {
      return state
        .set("getClientDetailsPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_CLIENT_DETAIL_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("getClientDetailsPhase", SUCCESS)
          .set("getClientDetailsData", payload.data);
      }
    }

    case GET_CLIENT_DETAIL_ERROR: {
      const { payload } = action;
      return state
        .set("getClientDetailsPhase", ERROR)
        .set("error", payload.message);
    }

    case GET_EXIST_PROPERTY: {
      return state
        .set("existPropertyPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_EXIST_PROPERTY_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("existPropertyPhase", SUCCESS)
          .set("existPropertyData", payload.data);
      }
    }

    case GET_EXIST_PROPERTY_ERROR: {
      const { payload } = action;
      return state
        .set("existPropertyPhase", ERROR)
        .set("error", payload.message);
    }

    case INIT_PROPERTY_PHASE: {
      return state
        .set("getPropertyPhase", INIT)
        .set("addPropertyPhase", INIT)
        .set("deletePropertyPhase", INIT)
        .set("copyPropertyPhase", INIT)
        .set("getPropertyOptionsPhase", INIT)
        .set("addInquiryPhase", INIT)
        .set("getFeaturedPhase", INIT)
        .set("getAmenitiesPhase", INIT)
        .set("getPropertyDetailPhase", INIT)
        .set("propertyLeadsCountPhase", INIT)
        .set("existPropertyPhase", INIT)
        .set("mailPhase", INIT)
        .set("getPropertyDetailsPhase", INIT)
        .set("uploadListingsPhase", INIT)
        .set("SMSPhase", INIT)
        .set("getCountryAndStatePhase", INIT)
        .set("getAllPropertyAddressPhase", INIT);
    }

    case MAIL_PROPERTY_RESET: {
      return state
        .set("mailPhase", INIT)
        .set("mailMessage", "")
        .set("SMSPhase", INIT)
        .set("SMSMessage", "");
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
export const getAllProperty = (credentials) => {
  return {
    type: GET_ALL_PROPERTY,
    payload: credentials,
  };
};
export const getAllPropertyAddress = (credentials) => {
  return {
    type: GET_ALL_PROPERTY_ADDRESS,
    payload: credentials,
  };
};
export const getFeaturedProperty = (credentials) => {
  return {
    type: GET_FEATURED_PROPERTY,
    payload: credentials,
  };
};

export const getAmenitiesProperty = (credentials) => {
  return {
    type: GET_AMENITIES_PROPERTY,
    payload: credentials,
  };
};

export const sendMail = (credentials) => {
  return {
    type: MAIL_PROPERTY,
    payload: credentials,
  };
};

export const sendSMS = (credentials) => {
  return {
    type: SMS_PROPERTY,
    payload: credentials,
  };
};

export const resetMail = (credentials) => {
  return {
    type: MAIL_PROPERTY_RESET,
    payload: credentials,
  };
};

export const getProperty = (credentials) => {
  return {
    type: GET_PROPERTY,
    payload: credentials,
  };
};

export const getPropertyOptions = (credentials) => {
  return {
    type: GET_PROPERTY_OPTIONS,
    payload: credentials,
  };
};

export const addProperty = (credentials) => {
  return {
    type: ADD_PROPERTY,
    payload: credentials,
  };
};

export const uploadListings = (credentials) => {
  return {
    type: UPLOAD_LISTING,
    payload: credentials,
  };
};

export const addInquiry = (credentials) => {
  return {
    type: ADD_INQUIRY,
    payload: credentials,
  };
};

export const deleteProperty = (credentials) => {
  return {
    type: DELETE_PROPERTY,
    payload: credentials,
  };
};

export const copyProperty = (credentials) => {
  return {
    type: COPY_PROPERTY,
    payload: credentials,
  };
};

export const getPropertyDetail = (credentials) => {
  return {
    type: GET_PROPERTY_DETAIL,
    payload: credentials,
  };
};

export const getPropertyDetails = (credentials) => {
  return {
    type: GET_PROPERTY_DETAILS,
    payload: credentials,
  };
};

export const getPropertyLeadsCount = (credentials) => {
  return {
    type: GET_PROPERTY_LEADS_COUNT,
    payload: credentials,
  };
};

export const getPropertyLeads = (credentials) => {
  return {
    type: GET_PROPERTY_LEADS,
    payload: credentials,
  };
};

export const getClientDetails = (credentials) => {
  return {
    type: GET_CLIENT_DETAIL,
    payload: credentials,
  };
};

export const getCountryAndState = (credentials) => {
  return {
    type: GET_COUNTRY_AND_STATE,
    payload: credentials,
  };
};

export const getExistProperty = (credentials) => {
  return {
    type: GET_EXIST_PROPERTY,
    payload: credentials,
  };
};

export const initPropertyPhase = () => ({
  type: INIT_PROPERTY_PHASE,
});

/***********************************
 * Epics
 ***********************************/

const getAllPropertyEpic = (action$) =>
  action$.pipe(
    ofType(GET_ALL_PROPERTY),
    mergeMap((action) => {
      return fromPromise(api.getAllProperty(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_ALL_PROPERTY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_ALL_PROPERTY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );
const getAllPropertyAddressEpic = (action$) =>
  action$.pipe(
    ofType(GET_ALL_PROPERTY_ADDRESS),
    mergeMap((action) => {
      return fromPromise(api.getAllPropertyAddress(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_ALL_PROPERTY_ADDRESS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_ALL_PROPERTY_ADDRESS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getCountryAndStateEpic = (action$) =>
  action$.pipe(
    ofType(GET_COUNTRY_AND_STATE),
    mergeMap((action) => {
      return fromPromise(api.getCountryAndState(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_COUNTRY_AND_STATE_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_COUNTRY_AND_STATE_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getAmenitiesPropertyEpic = (action$) =>
  action$.pipe(
    ofType(GET_AMENITIES_PROPERTY),
    mergeMap((action) => {
      return fromPromise(api.getAmenitiesProperty(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_AMENITIES_PROPERTY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_AMENITIES_PROPERTY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getFeaturedPropertyEpic = (action$) =>
  action$.pipe(
    ofType(GET_FEATURED_PROPERTY),
    mergeMap((action) => {
      return fromPromise(api.getFeaturedProperty(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_FEATURED_PROPERTY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_FEATURED_PROPERTY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getPropertyEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROPERTY),
    mergeMap((action) => {
      return fromPromise(api.getProperty(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_PROPERTY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_PROPERTY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getPropertyOptionsEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROPERTY_OPTIONS),
    mergeMap((action) => {
      return fromPromise(api.getPropertyOptions(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_PROPERTY_OPTIONS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_PROPERTY_OPTIONS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const addPropertyEpic = (action$) =>
  action$.pipe(
    ofType(ADD_PROPERTY),
    mergeMap((action) => {
      return fromPromise(api.addProperty(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: ADD_PROPERTY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: ADD_PROPERTY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const sendMailEpic = (action$) =>
  action$.pipe(
    ofType(MAIL_PROPERTY),
    mergeMap((action) => {
      return fromPromise(api.sendMail(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: MAIL_PROPERTY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: MAIL_PROPERTY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const sendSMSEpic = (action$) =>
  action$.pipe(
    ofType(SMS_PROPERTY),
    mergeMap((action) => {
      return fromPromise(api.sendSMS(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: SMS_PROPERTY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: SMS_PROPERTY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

// const addInquiryEpic = action$ =>
// action$.pipe(
//   ofType(ADD_INQUIRY),
//   mergeMap(action => {
//       return fromPromise(api.addInquiry(action.payload)).pipe(
//         flatMap(payload => [
//           {
//             type: ADD_INQUIRY_SUCCESS,
//             payload
//           }
//         ]),
//         catchError(error =>
//           of({
//           type: ADD_INQUIRY_ERROR,
//           payload: { error }
//         })
//       )
//     )
//   })
// )

const deletePropertyEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_PROPERTY),
    mergeMap((action) => {
      return fromPromise(api.deleteProperty(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: DELETE_PROPERTY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: DELETE_PROPERTY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const copyPropertyEpic = (action$) =>
  action$.pipe(
    ofType(COPY_PROPERTY),
    mergeMap((action) => {
      return fromPromise(api.copyProperty(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: COPY_PROPERTY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: COPY_PROPERTY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getPropertyDetailEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROPERTY_DETAIL),
    mergeMap((action) => {
      return fromPromise(api.getPropertyDetail(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_PROPERTY_DETAIL_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_PROPERTY_DETAIL_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getPropertyDetailsEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROPERTY_DETAILS),
    mergeMap((action) => {
      return fromPromise(api.getPropertyDetails(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_PROPERTY_DETAILS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_PROPERTY_DETAILS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getPropertyLeadsCountEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROPERTY_LEADS_COUNT),
    mergeMap((action) => {
      return fromPromise(api.getPropertyLeadsCount(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_PROPERTY_LEADS_COUNT_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_PROPERTY_LEADS_COUNT_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getPropertyLeadsEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROPERTY_LEADS),
    mergeMap((action) => {
      return fromPromise(api.getPropertyLeads(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_PROPERTY_LEADS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_PROPERTY_LEADS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getClientDetailsEpic = (action$) =>
  action$.pipe(
    ofType(GET_CLIENT_DETAIL),
    mergeMap((action) => {
      return fromPromise(api.getClientDetails(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_CLIENT_DETAIL_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_CLIENT_DETAIL_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getExistPropertyEpic = (action$) =>
  action$.pipe(
    ofType(GET_EXIST_PROPERTY),
    mergeMap((action) => {
      return fromPromise(api.getExistProperty(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_EXIST_PROPERTY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_EXIST_PROPERTY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const uploadListingsEpic = (action$) =>
  action$.pipe(
    ofType(UPLOAD_LISTING),
    mergeMap((action) => {
      return fromPromise(api.uploadListings(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPLOAD_LISTING_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPLOAD_LISTING_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

export const mylistingEpic = combineEpics(
  getAllPropertyEpic,
  getPropertyEpic,
  addPropertyEpic,
  deletePropertyEpic,
  getPropertyOptionsEpic,
  copyPropertyEpic,
  // addInquiryEpic,
  getFeaturedPropertyEpic,
  getAmenitiesPropertyEpic,
  getPropertyDetailEpic,
  getPropertyDetailsEpic,
  getPropertyLeadsCountEpic,
  getPropertyLeadsEpic,
  getClientDetailsEpic,
  sendMailEpic,
  getExistPropertyEpic,
  uploadListingsEpic,
  sendSMSEpic,
  getCountryAndStateEpic,
  getAllPropertyAddressEpic
);
