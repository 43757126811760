import React from "react";
import { useEffect } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { get, isEmpty } from "lodash";


// Marketing Website

// Admin Panel
import Admin from "../views/Admin/container";

// Agent Panel
import Dashboard from "../views/Agent/Dashboard/container";
import AppSumo from "../views/Agent/AppSumo/container";
import Account from "../views/Agent/Account/container";
import Signup from "../views/Agent/Signup/container";
import Login from "../views/Agent/Login/container";
import Leads from "../views/Agent/Leads/container";
import CreateListing from "../views/Agent/Listings/CreateListing/container";
import EditListing from "../views/Agent/Listings/EditListing/container";
import Campaign from "../views/Agent/Campaign/container";
import LeadPages from "../views/Agent/LeadPages/container";
import LeadPageSettings from "../views/Agent/LeadPageSettings/container";
import MyListings from "../views/Agent/MyListings/container";
import SuccessfulSignUp from "../views/Agent/SuccessfulSignUp/container";
import PaymentCard from "../components/common/PaymentCard/container";
import SocialLinkInBio from "../views/Agent/SocialLinkInBio/container";
import SocialLink from "../views/Agent/SocialLink/container";
import SocialLinkEmpty from "../views/Agent/SocialLinkEmpty/container";
import PropertyDetail from "../views/Agent/PropertyDetail/container";

// User Panel
import Sell from "../views/User/Sell/container";
import SellDetail from "../views/User/SellDetail/container";
import ListingWebsite from "../views/User/ListingWebsite/container";
import PageNotFound from "../views/User/PageNotFound/container";
import DomainPage from "../views/User/DomainPage/container";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.scss";

let route = "/login";
if (
  window.location.origin !== "https://stag-app.yardpost.com" ||
  window.location.origin !== "http://localhost:3000" ||
  window.location.origin !== "https://app.yardpost.com" ||
  window.location.origin !== "https://yardpost-frontend-04tp.onrender.com"
) {
  route = window.location.pathname;
  // console.log(route,'route')
}
const social = `${window.location.href}`;
// const social = "/social"
const PrivateRoute = ({ component, user, ...rest }) => {
  const isAuthed =
    localStorage.getItem("Authorization") ||
    localStorage.getItem("AdminAuthorization")
      ? true
      : false;
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        isAuthed ? (
          <>{React.createElement(component, props)}</>
        ) : (
          <Redirect
            to={{
              pathname: route,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class AppComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      isSetUser: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    const { getUser, user } = this.props;
    if (
      localStorage.getItem("AdminAuthorization") &&
      !localStorage.getItem("Authorization")
    ) {
      let tab =
        this.props.location.pathname === "/admin/not-active"
          ? "/admin/not-active"
          : this.props.location.pathname === "/admin/not-signed-up"
          ? "/admin/not-signed-up"
          : this.props.location.pathname === "/admin/app-sumo"
          ? "/admin/app-sumo"
          : "/admin/active";
      this.props.history.push(tab);
    }
    if (isEmpty(this.props.user)) {
      if (localStorage.getItem("Authorization")) {
        const parameters = {}
        if(this.props.location?.pathname?.includes("social-link-preview")){
          const splitLocation = this.props.location.pathname.split("/");
          parameters.leadId = splitLocation[splitLocation?.length -1] ?? ""
        }
      getUser(parameters);
      } else {
        if (localStorage.getItem("AdminAuthorization")) {
          let tab =
            this.props.location.pathname === "/admin/not-active"
              ? "/admin/not-active"
              : this.props.location.pathname === "/admin/not-signed-up"
              ? "/admin/not-signed-up"
              : this.props.location.pathname === "/admin/app-sumo"
              ? "/admin/app-sumo"
              : "/admin/active";
          this.props.history.push(tab);
        } else {
          if (
            window.location.origin === "https://stag-app.yardpost.com" ||
            window.location.origin === "http://localhost:3000" ||
            window.location.origin === "https://app.yardpost.com" ||
            window.location.origin === "https://yardpost-frontend-04tp.onrender.com"
          ) {
            if (this.props.location.pathname === "/appsumo") {
              this.props.history.push("/appsumo");
            } else if (this.props.location.pathname === "/signup") {
              this.props.history.push("/signup");
            } else {
              this.props.history.push("/login");
            }
          }
        }
      }
    } else if (!isEmpty(this.props.user)) {
      // console.log()
      if (localStorage.getItem("Authorization")) {
        // this.props.history.push('/lead-pages')
        // getUser()
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  componentDidUpdate(prev) {
    if (this.props.userPhase === "success") {
      this.props.resetLoginUser();
      if (get(this.props.user, "user.role", "") === "Admin") {
        if (get(this.props.user, "user.isTrialEnd", "")) {
          this.props.history.push("/social-links");
          // this.props.history.push('/payment-card',{...this.props})
          this.setState({
            isLoading: false,
            user: this.props.user,
            isSetUser: true,
          });
        } else if (get(!this.props.user, "user.isTrialEnd", "")) {
          this.props.history.push("/social-links");
        }
      }
    }
  }

  render() {
    return (
      <>
        <div className="application-container">
          <Switch>
            <Route path="/appsumo" component={AppSumo} {...this.props} />
            <PrivateRoute
              exact
              path="/"
              component={LeadPages}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/admin/app-sumo"
              component={Admin}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/admin/active"
              component={Admin}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/admin/not-active"
              component={Admin}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/admin/not-signed-up"
              component={Admin}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/dashboard"
              component={Dashboard}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/listings/active"
              component={MyListings}
              {...this.props}
            />

            {/* <PrivateRoute
              exact
              path="/listings/closed-sold"
              component={MyListings}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/listings/drafts"
              component={MyListings}
              {...this.props}
            />
            */}
            <PrivateRoute
              exact
              path="/account/profile"
              component={Account}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/account/domain-setup"
              component={Account}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/account/billing"
              component={Account}
              {...this.props}
            />
            <Route exact path="/signup" component={Signup} {...this.props} />
            <Route exact path="/login" component={Login} {...this.props} />
            <PrivateRoute
              exact
              path="/lead-pages"
              component={LeadPages}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/lead-pages/active"
              component={LeadPages}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/lead-pages/draft"
              component={LeadPages}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/landing-pages"
              component={LeadPages}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/social-links"
              component={LeadPages}
              {...this.props}
            />
            {/* <PrivateRoute
              exact
              path="/landing-pages/draft"
              component={LeadPages}
              {...this.props}
            /> */}
            <PrivateRoute
              exact
              path="/leads"
              component={Leads}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/listings/create-listing"
              component={CreateListing}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/listings/edit-listing/general/:id"
              component={EditListing}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/listings/edit-listing/details/:id"
              component={EditListing}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/listings/edit-listing/photos/:id"
              component={EditListing}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/create-new-lead-page/sell"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/create-new-lead-page/listing-website"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-lead-page/listing-website/site-editor/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-lead-page/listing-website/seo/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-lead-page/listing-website/QR-Code/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-lead-page/sell/site-editor/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-lead-page/sell/seo/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-lead-page/sell/QR-Code/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-lead-page/listing-website/site-editor/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/create-social-link"
              component={Campaign}
              {...this.props}
            />
            {/* <PrivateRoute
              exact
              path="/create-new-landing-page/sell"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/create-new-landing-page/listing-website"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-landing-page/listing-website/site-editor/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-landing-page/listing-website/listing-details/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-landing-page/listing-website/seo/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-landing-page/listing-website/QR-Code/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-landing-page/sell/site-editor/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-landing-page/sell/seo/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-landing-page/sell/QR-Code/:id"
              component={Campaign}
              {...this.props}
            /> */}
            <PrivateRoute
              exact
              path="/edit-social-link/site-editor/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-social-link/seo/:id"
              component={Campaign}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/edit-social-link/QR-Code/:id"
              component={Campaign}
              {...this.props}
            />

            <PrivateRoute
              exact
              path="/payment-card"
              component={PaymentCard}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/lead-pages/lead-page-settings"
              component={LeadPageSettings}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/link-page-settings"
              component={LeadPageSettings}
              {...this.props}
            />
            {/* <Route
              exact
              path="/social"
              component={SocialLink}
              {...this.props}
            /> */}
            <Route
              exact
              path="/social/:id"
              component={SocialLink}
              {...this.props}
            />
            <Route exact path="/:id" component={DomainPage} {...this.props} />
            <Route
              exact
              path="/listing-website/:id"
              component={ListingWebsite}
              {...this.props}
            />
            <Route
              exact
              path="/preview/listing-website/:id"
              component={ListingWebsite}
              {...this.props}
            />
            <Route
              exact
              path="/listing-website/:slug/:id"
              component={ListingWebsite}
              {...this.props}
            />
            <Route
              exact
              path="/preview/listing-website/:slug/:id"
              component={ListingWebsite}
              {...this.props}
            />
            <Route
              exact
              path="/successful-sign-up/:customer_id"
              component={SuccessfulSignUp}
              {...this.props}
            />
            <Route exact path="/sell/:id" component={Sell} {...this.props} />
            <Route
              exact
              path="/preview/sell/:id"
              component={Sell}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/preview/sell/:id"
              component={SellDetail}
              {...this.props}
            />
            <Route
              exact
              path="/sell-detail/:id"
              component={SellDetail}
              {...this.props}
            />
            <Route
              exact
              path="/preview/sell-detail/:id"
              component={SellDetail}
              {...this.props}
            />
            <Route
              exact
              path="/sell/:slug/:id"
              component={Sell}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/preview/sell/:slug/:id"
              component={Sell}
              {...this.props}
            />
            <Route
              exact
              path="/sell-detail/:slug/:id"
              component={SellDetail}
              {...this.props}
            />
            <Route
              exact
              path="/preview/sell-detail/:slug/:id"
              component={SellDetail}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/social-link-in-bio"
              component={SocialLinkInBio}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/social-link-preview/:id"
              component={SocialLink}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/preview/social-link-preview/:id"
              component={SocialLink}
              {...this.props}
            />
            <Route
              exact
              path="/social-link-preview"
              component={SocialLink}
              {...this.props}
            />
            <Route
              exact
              path="/social-link-empty"
              component={SocialLinkEmpty}
              {...this.props}
            />
            <Route
              exact
              path="/preview/property-detail"
              component={PropertyDetail}
              {...this.props}
            />
            <Route path="/404" component={SocialLinkEmpty} {...this.props} />
            <Route
              path="*"
              exact={true}
              component={SocialLinkEmpty}
              {...this.props}
            />
          </Switch>
        </div>
      </>
    );
  }
}

const ScrollToTop = ({
  children,
  history: { action },
  location: { pathname },
}) => {
  useEffect(() => {
    if (action == "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return children || null;
};

export default withRouter(withCookies(AppComponent));
