import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import ListingDetails from "./ListingDetails/container";
import Editor from "./Editor/container";
import Seo from "./Seo/component";
import QRCode from "./QRCode/component";
import { get, isEmpty } from "lodash";
import "./styles.scss";

export default class EditComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      listingData: {},
      leadData: {},
      leadSettingData: {},
      leadPageUserData: {},
      leadPageProperty: {},
    };
  }

  // componentDidUpdate(){
  //   console.log(this.props.leadPageProperty,'listingData')

  // }

  callApi = async (id) => {
    await this.props.getLeadPageById({
      id: id,
    });
  };

  componentDidMount() {
    let id = get(window, "location.href", "").split("/")[
      get(window, "location.href", "").split("/").length - 1
    ];

    if (
      !isEmpty(this.props.leadPageByIdData) ||
      this.props.leadPageByIdData === undefined
    ) {
      this.callApi(id);
    } else {
      this.setState({
        leadData: this.props.leadPageByIdData,
        leadSettingData: this.props.leadPageSetting,
        leadPageUserData: this.props.leadPageUserData,
        leadPageProperty: this.props.leadPageProperty,
      });
    } // this.setState({
    //   campaignType: this.props.campaignType,
    //   campaignName: this.props.campaignName,
    // });
    // if(this.props.campaignType === 'sell'){
    //   this.setState({ editorTab: `/edit-lead-page/sell/site-editor/${this.props.campaignId}`, seoTab: `/edit-lead-page/sell/seo/${this.props.campaignId}` })
    // }else{
    //   this.setState({ editorTab: `/edit-lead-page/listing-website/site-editor/${this.props.campaignId}`, seoTab: `/edit-lead-page/listing-website/seo/${this.props.campaignId}` })
    // }
  }

  render() {
    //console.log("listing details--->", this.props);
    const { seoTab, editorTab, listingDetailsTab, activeTab, qRCodeTab } =
      this.props;
    return (
      <>
        <div className="tabs__links tabs__links--space tabs__links--full">
          {this.props.campaignType === "listing-website" && (
            <button
              className={
                activeTab === "listing-details"
                  ? "tabs__link active"
                  : "tabs__link"
              }
              onClick={() =>
                this.props.tabChange(
                  "listing-details",
                  seoTab,
                  editorTab,
                  listingDetailsTab,
                  qRCodeTab
                )
              }
            >
              Listing Details
            </button>
          )}
          <button
            className={
              activeTab === "editor" ? "tabs__link active" : "tabs__link"
            }
            onClick={() =>
              this.props.tabChange(
                "editor",
                seoTab,
                editorTab,
                listingDetailsTab,
                qRCodeTab
              )
            }
          >
            Site Editor
          </button>
          <button
            className={activeTab === "seo" ? "tabs__link active" : "tabs__link"}
            onClick={() =>
              this.props.tabChange(
                "seo",
                seoTab,
                editorTab,
                listingDetailsTab,
                qRCodeTab
              )
            }
          >
            SEO
          </button>
          <button
            className={
              activeTab === "QR-Code" ? "tabs__link active" : "tabs__link"
            }
            onClick={() =>
              this.props.tabChange(
                "QR-Code",
                seoTab,
                editorTab,
                listingDetailsTab,
                qRCodeTab
              )
            }
          >
            QR Code
          </button>
        </div>
        {this.props.activeTab === "listing-details" && (
          <ListingDetails
            property={this.props.leadPageProperty}
            isSelectDropDown={this.props.isSelectDropDown}
            campaignType={this.props.campaignType}
          />
        )}
        {this.props.activeTab === "editor" && <Editor {...this.props} />}
        {this.props.activeTab === "seo" && <Seo {...this.props} />}
        {this.props.activeTab === "QR-Code" && <QRCode {...this.props} />}
      </>
    );
  }
}
