import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Close } from "../../../components/icons";
import Preview from "../../../images/preview.png";
import { Formik } from "formik";
import { get } from "immutable";

import "./styles.scss";
require("dotenv").config();
const MARKETING_URL =
  process.env.REACT_APP_MARKETING_URL || "https://yardpost.com";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default class LoginComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      isResendLoading: false,
      errorMessage: "",
      otpMessage: "",
      otp: "",
      email: "",
      resendOtpMessage: "",
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  resendCode() {
    this.props.resendOtp({
      email: this.state.email,
      otp: "",
    });
  }

  onChangeOtp(e) {
    if (e.target.value.length <= 4) {
      this.setState({ otp: e.target.value });
    }
    if (e.target.value.length == 4) {
      this.props.verifyOtp({
        email: this.state.email,
        otp: e.target.value,
      });
    }
  }

  componentDidMount() {
    document.title = "Login | Yardpost";
    document.getElementById("favicon").href =
      "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png";
    if (
      localStorage.getItem("AdminAuthorization") &&
      localStorage.getItem("Authorization")
    ) {
      // this.props.history.push("/social-link-in-bio");
      this.props.history.push("/social-links");
    } else if (localStorage.getItem("AdminAuthorization")) {
      this.props.history.push("/admin/active");
    } else if (localStorage.getItem("Authorization")) {
      // this.props.history.push("/social-link-in-bio");
      this.props.history.push("/social-links");
    }
  }

  componentDidUpdate() {
    document.title = "Login | Yardpost";
    document.getElementById("favicon").href =
      "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png";
    if (this.props.loginPhase === "success" && this.state.modalIsOpen) {
      setTimeout(() => {
        this.testInput && this.testInput.focus();
      }, 500);
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.loginPhase === "success") {
      props.resetLogin();
      return {
        isLoading: false,
        modalIsOpen: true,
        errorMessage: "",
        otpMessage: "",
      };
    }
    if (props.loginPhase === "loading") {
      props.resetLogin();
      return { isLoading: true, errorMessage: "", otpMessage: "" };
    }
    if (props.loginPhase === "error") {
      props.resetLogin();
      return {
        isLoading: false,
        errorMessage: props.errorMessage,
        otpMessage: "",
      };
    }
    if (props.otpPhase === "success") {
      props.resetLogin();
      if (localStorage.getItem("AdminAuthorization")) {
        props.history.push("/admin/active");
      } else {
        props.getUser();
      }
      return {
        isOtpLoading: false,
        otpMessage: "",
        otp: "",
        modalIsOpen: false,
      };
    }
    if (props.otpPhase === "loading") {
      props.resetLogin();
      return { isOtpLoading: true, otpMessage: "" };
    }
    if (props.otpPhase === "error") {
      props.resetLogin();
      return { isOtpLoading: false, otpMessage: props.otpMessage };
    }

    //otp phase
    if (props.resendPhase === "success") {
      props.resetLogin();
      return {
        isResendLoading: false,
        resendOtpMessage: props.resendMessage,
        otp: "",
      };
    }
    if (props.resendPhase === "loading") {
      props.resetLogin();
      return { isResendLoading: true, otpMessage: "" };
    }
    if (props.resendPhase === "error") {
      props.resetLogin();
      return {
        isResendLoading: false,
        otpMessage: props.resendMessage,
        otp: "",
      };
    }

    if (localStorage.getItem("Authorization")) {
      if (Object.keys(props.user).length) {
        if (get(props.user.user, "role", "") === "Admin") {
          if (
            !get(props.user.user, "isTrialEnd", "") ||
            get(props.user.user, "plan", "")
          ) {
            if (get(props.user.user, "isLeadPage", false)) {
              props.history.push("/landing-pages");
            } else {
              props.history.push("/landing-pages");
            }
          }
        } else if (get(props.user.user, "role", "") === "Superadmin") {
          props.history.push("/admin/active");
        }
      }
    }
  };

  closeModal() {
    this.setState({
      modalIsOpen: false,
      resendOtpMessage: "",
      otpMessage: "",
      otp: "",
    });
  }

  render() {
    const { errorMessage, otp, otpMessage, resendOtpMessage } = this.state;
    return (
      <>
        <div className="login__main">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <a href={MARKETING_URL} className="btn btn-link btn-back">
                      <Close />
                    </a>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">Login to Yardpost</div>
                </div>
              </div>
            </div>
          </header>
          <main className="login__left-right-main">
            <div className="left__view">
              <div className="mac__window">
                <div className="mac__window--header">
                  <div className="mac__window--dot dot__1"></div>
                  <div className="mac__window--dot dot__2"></div>
                  <div className="mac__window--dot dot__3"></div>
                </div>
                <div className="mac__window--body">
                  <img src={Preview} alt="" />
                </div>
              </div>
            </div>
            <div className="right__view">
              <div className="view__wrapper">
                <div className="signup__head">
                  <h2>Sign in to your account</h2>
                  <p>Welcome to YardPost </p>
                </div>
                <Formik
                  initialValues={{ email: "" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      this.setState({ errorMessage: "Please enter an email." });
                      errors.email = "Please enter an email.";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      this.setState({ errorMessage: "Invalid email address" });
                      errors.email = "Invalid email address";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    this.setState({ isLoading: true });
                    setTimeout(() => {
                      setSubmitting(false);
                      this.props.loginUser(values);
                    }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="form-group">
                        <label className="label-primary">Email address</label>
                        <input
                          type="text"
                          name="email"
                          onChange={(e) => {
                            handleChange(e);
                            this.setState({
                              errorMessage: "",
                              email: e.target.value,
                            });
                          }}
                          autoCapitalize="none"
                          onBlur={handleBlur}
                          value={values.email}
                          // type="text"
                          className="form-control"
                          required
                        />
                        {errorMessage && (
                          <div className="invalid-feedback">{errorMessage}</div>
                        )}
                      </div>
                      <div className="view__wrapper--cta">
                        <button
                          type="submit"
                          className="btn btn-lg btn__purple w-100"
                          disabled={isSubmitting}
                        >
                          {this.state.isLoading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            "Login"
                          )}
                        </button>
                      </div>
                      <div className="view__wrapper--footer">
                        Don't have a <b>YardPost</b> account?{" "}
                        <Link to="/signup" className="btn__link--underline">
                          Let's get started.
                        </Link>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </main>
        </div>

        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="OTP"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                We've emailed you a code, please enter it here to log into your
                secure account.
              </h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Note: the code will expire in 3 minutes to ensure your account's
                security.
              </p>
              <div className="form-group">
                <label className="label-primary">Enter Code</label>
                <input
                  type="number"
                  className="form-control material-textfield-input"
                  name="otp"
                  maxLength="4"
                  onChange={this.onChangeOtp.bind(this)}
                  value={otp}
                  autoComplete="off"
                  placeholder="4-Digit code"
                  autoFocus
                  ref={(input) => {
                    this.testInput = input;
                  }}
                  required
                />
                {otpMessage && (
                  <div className="invalid-feedback">{otpMessage}</div>
                )}
                {resendOtpMessage && (
                  <div className="success-feedback">{resendOtpMessage}</div>
                )}
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                onClick={this.closeModal.bind(this)}
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={this.resendCode.bind(this)}
                className="btn btn__purple ms-3"
              >
                Resend
              </button>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}
