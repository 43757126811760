import { connect } from "react-redux";
import {
  editUser,
  getUser,
  resetLogin,
  resetLoginUser,
  uploadImages
} from "../../../store/user/duck";
import {
  createLeadPage,
  getLeadPageById,
  publishLeadPageById,
  getListings,
  publishLeadPage,
  // uploadImages,
  initLeadPage,
  getSocialLeadPageExist,
} from "../../../store/leadPage/duck";
import CampaignComponent from "./component";

const CampaignContainer = connect(
  // Map state to props
  (state) => ({
    userPhase: state.user.userPhase,
    user: state.user.user,
    editUserPhase: state.user.editUserPhase,
    editUserData: state.user.editUserData,
    leadPageProperty: state.leadPage.leadPageProperty,
    getListingsPhase: state.leadPage.getListingsPhase,
    getListingsData: state.leadPage.getListingsData,
    createLeadPagePhase: state.leadPage.createLeadPagePhase,
    createLeadPageData: state.leadPage.createLeadPageData,
    leadPageByIdPhase: state.leadPage.leadPageByIdPhase,
    leadPageByIdData: state.leadPage.leadPageByIdData,
    uploadImagePhase: state.leadPage.uploadImagePhase,
    uploadImageData: state.leadPage.uploadImageData,
    publishLeadPagePhase: state.leadPage.publishLeadPagePhase,
    publishLeadPageData: state.leadPage.publishLeadPageData,
    leadPageByIdproperty: state.user.leadPageByIdproperty,
    publishLeadPageByIdPhase: state.leadPage.publishLeadPageByIdPhase,
    publishLeadPageByIdData: state.leadPage.publishLeadPageByIdData,
  }),
  // Map actions to dispatch and props
  {
    publishLeadPageById,
    publishLeadPage,
    getListings,
    uploadImages,
    initLeadPage,
    getLeadPageById,
    createLeadPage,
    getUser,
    editUser,
    resetLoginUser,
    resetLogin,
    getSocialLeadPageExist,
  }
)(CampaignComponent);

export default CampaignContainer;
