import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import ActionMoreComponent from "../../../components/common/ActionMore/component";
import TablePaginationComponent from "../../../components/common/TablePagination/component";
import TopNavigationComponent from "../../../components/common/TopNavigation/container";
import {
  Edit,
  Export,
  Eye,
  LinkCopyIcon,
  LinkIcon,
  Plus,
  PoweredByStripe,
  Setting,
} from "../../../components/icons";
import RadioButtonGroup from "../../../components/ui/RadioButtonGroup/component";
import Impersonate from "../../../components/common/Impersonate/container";
import Tabs from "../../../components/common/Tabs/container";
import { get, size, isEmpty } from "lodash";
import { Spin, Dropdown, Menu, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import InputMask from "react-text-mask";
import load from "load-stripe";
import { Formik } from "formik";
import StarterPageComponent from "./StarterPage/container";
import Cookies from "universal-cookie";
import moment from "moment";
import SmsIcon from "./../../../images/sms.png";
import twitterX from '.././../../images/twitter.png'
import "./styles.scss";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share";
const cookies = new Cookies();
const stripeKey = process.env.REACT_APP_STRIPE_CLIENT_TEST_KEY;
const SiteUrl = process.env.REACT_APP_SITE_URL;
const ShortUrl = process.env.REACT_APP_SHORT_URL;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

const cardNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const cardDate = [/\d/, /\d/, "/", /\d/, /\d/];

const securityCode = [/\d/, /\d/, /\d/, /\d/];

export default class LeadPagesComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      paymentLogin: false,
      stripeError: {},
      subscriptions: [],
      plan: 0,
      isAgentSelectedProduct: {},
      selectedPlan: "year",
      activeTab: "listing-active",
      sortKey: "udatedAt",
      sortType: "desc",
      limit: 10,
      page: 1,
      SiteUrl1: "",
      leadPageArary: [],
      nextStep: 0,
      previousStep: 0,
      promoData: {},
      selectedata: {},
      shortSlugUrl: "",
      errorMessage: "",
      isLoading: false,
      shortLinkModal: false,
      isLoadingData: true,
      copiedIndex: -1,
      isSaveButtonEnable: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChangeSlugValue = this.handleChangeSlugValue.bind(this);
  }

  async submitCard(value) {
    const { subscriptions, plan, promoData } = this.state;
    this.setState({ isLoading: true, paymentLogin: true });
    let self = this;
    this.setState({ stripeError: {} });
    let cardDate = value.cardDate.split("/");
    await load(stripeKey).then((stripe) => {
      stripe.card
        .createToken({
          number: value.cardNumber,
          cvc: value.securityCode,
          exp_month: cardDate[0],
          exp_year: cardDate[1],
        })
        .then(async (token) => {
          if (token) {
            let obj = {};
            obj.token = token.id;
            obj.planId = subscriptions[0].plan[plan].id;
            obj.productId = subscriptions[0].id;
            obj.coupon = !isEmpty(promoData)
              ? get(promoData, "data.id", "")
              : "";
            self.setState({ isLoading: true, paymentLogin: true });
            this.props.addCard(obj);
          }
        })
        .catch((err) => {
          let stripeError = {};
          switch (err.type) {
            case "card_error":
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "rate_limit_error":
              // Too many requests made to the API too quickly
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "incorrect_number":
              // Too many requests made to the API too quickly
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(
                err,
                "message",
                "Your card number is incorrect."
              );
              break;
            case "invalid_request_error":
              // Invalid parameters were supplied to Stripe's API
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "api_error":
              // An error occurred internally with Stripe's API
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "api_connection_error":
              // Some kind of error occurred during the HTTPS communication
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "authentication_error":
              // You probably used an incorrect API key
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            default:
              // Handle any other types of unexpected errors
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
          }
          this.setState({ stripeError, isLoading: false, paymentLogin: false });
        });
    });
  }

  applyCoupon(value) {
    this.props.applyPromoCode({ code: value });
  }

  removeCoupon(value, setFieldValue) {
    setFieldValue("CouponCode", "");
    this.props.initPromoPhase();
  }

  logoutImpersonate() {
    localStorage.removeItem("Authorization");
    this.props.resetImpersonatedUser();
    localStorage.removeItem("userId");
    this.props.history.push("/admin/active");
    cookies.remove("Authorization", { path: "/", domain: "yardpost.com" });
  }

  openModal() {
    this.setState({ modalIsOpen: true, isSaveButtonEnable: true });
  }

  getData = () => {
    let tabNew = this.props.location.pathname;

    if (tabNew === "/social-links") {
      this.props.getLeadPages({
        activeTab: "listing-active",
        sortKey: this.state.sortKey,
        sortType: this.state.sortType,
        limit: this.state.limit,
        page: this.state.page,
      });
      this.setState({ activeTab: "listing-active" }, () => {
        this.forceUpdate();
      });
    } else {
      this.props.getLeadPages({
        activeTab: "listing-active",
        sortKey: this.state.sortKey,
        sortType: this.state.sortType,
        limit: this.state.limit,
        page: this.state.page,
      });
      this.setState({ activeTab: "listing-active" }, () => {
        this.forceUpdate();
      });
    }
  };

  componentDidMount() {
    if (true) {
      // if (isEmpty(this.props.user.user)) {
      this.props.getUser();
    }
    let tabNew = this.props.location.pathname;
    if (tabNew === "/social-links") {
      this.props.getLeadPages({
        activeTab: "listing-active",
        sortKey: this.state.sortKey,
        sortType: this.state.sortType,
        limit: this.state.limit,
        page: this.state.page,
      });
      this.setState({ activeTab: "listing-active", isLoadingData: true }, () => {
        this.forceUpdate();
      });
    } else {
      this.props.getLeadPages({
        activeTab: "listing-active",
        sortKey: this.state.sortKey,
        sortType: this.state.sortType,
        limit: this.state.limit,
        page: this.state.page,
      });
      this.setState(
        { activeTab: "listing-active", isLoadingData: true },
        () => {
          this.forceUpdate();
        }
      );
    }
    this.setState({
      SiteUrl1: `https://${get(this.props.user, "user.domainInfo.domain", "")}`,
    });
    this.props.getSubscriptions();
    let tab = this.props.location.pathname;
    if (tab === "/") {
      if (localStorage.getItem("Authorization")) {
        this.props.history.push("/social-links");
        if (get(this.props.user, "user.isLead", false)) {
          this.props.getLeadPages({
            activeTab: "listing-active",
            sortKey: this.state.sortKey,
            sortType: this.state.sortType,
            limit: this.state.limit,
            page: this.state.page,
          });
          this.setState(
            { activeTab: "listing-active", isLoadingData: true },
            () => {
              this.forceUpdate();
            }
          );
        }
      } else {
        this.props.history.push("/login");
      }
    } else if (tab === "/landing-pages") {
      if (get(this.props.user, "user.isLead", false)) {
        this.props.getLeadPages({
          activeTab: "listing-active",
          sortKey: this.state.sortKey,
          sortType: this.state.sortType,
          limit: this.state.limit,
          page: this.state.page,
        });
        this.setState(
          { activeTab: "listing-active", isLoadingData: true },
          () => {
            this.props.history.push("/social-links");
            this.forceUpdate();
          }
        );
      } else {
        this.props.history.push("/social-links");
      }
    } else if (tab === "/social-links") {
      this.props.getLeadPages({
        activeTab: "listing-active",
        sortKey: this.state.sortKey,
        sortType: this.state.sortType,
        limit: this.state.limit,
        page: this.state.page,
      });
      this.setState(
        { activeTab: "listing-active", isLoadingData: true },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  onSelectedPlan(e) {
    if (e.target.value === "month") {
      this.setState({ plan: 1, selectedPlan: "month" });
    } else if (e.target.value === "year") {
      this.setState({ plan: 0, selectedPlan: "year" });
    }
  }

  componentDidUpdate() {
    document.title = "Social Links | Yardpost";
    let tab = this.props.location.pathname;
    if (tab === "/") {
      if (localStorage.getItem("Authorization")) {
        this.props.history.push("/landing-pages");
      } else {
        this.props.history.push("/login");
      }
    }
    if (tab === "/landing-pages") {
      if (get(this.props.user, "user.isLead", false)) {
        this.props.getLeadPages({
          activeTab: "listing-active",
          sortKey: this.state.sortKey,
          sortType: this.state.sortType,
          limit: this.state.limit,
          page: this.state.page,
        });
        this.setState(
          { activeTab: "listing-active", isLoadingData: true },
          () => {
            this.props.history.push("/social-links");
            this.forceUpdate();
          }
        );
      }
    }
    if (this.props.promoPhase === "success") {
      this.setState({ promoData: this.props.promoData });
    }
    if (this.props.promoPhase === "error") {
      this.setState({ promoData: this.props.promoData });
    }
    if (this.props.subscriptionPhase === "success") {
      this.props.resetLoginUser();
      this.setState({ subscriptions: this.props.subscriptions });
    }
    if (this.props.addCardPhase === "success") {
      this.props.resetLoginUser();
      this.props.getUser();
      this.setState({ paymentLogin: false, modalIsOpen: false });
      this.openNotificationWithIcon(
        "success",
        "Subcription added successfully."
      );
    }
    if (this.props.addCardPhase === "error") {
      this.props.resetLoginUser();
      this.setState({ paymentLogin: false, modalIsOpen: false });
      this.openNotificationWithIcon("error", "User already Subscribed");
    }
    if (this.props.getLeadPagesPhase === "success") {
      this.props.initLeadPage();
      if (tab === "/social-links") {
        this.setState({ activeTab: "listing-active" });
      }
      this.setState(
        {
          leadPageArary: this.props.getLeadPagesData.reverse(),
          count: this.props.count,
          isLoadingData: false,
          copiedIndex: [],
        },
        () => {
          this.forceUpdate();
          let nextPageCount = parseInt(Math.ceil(this.props.count / 10));
          this.setState({ nextStep: nextPageCount }, () => {
            this.forceUpdate();
          });
        }
      );
    }
    if (this.props.userPhase === "success") {
      this.props.resetLoginUser();
      if (get(this.props.user, "user.isLead", false)) {
        let tab = this.props.location.pathname;
        if (tab === "/social-links") {
          this.setState(
            {
              activeTab: "listing-active",
              SiteUrl1: `https://${get(
                this.props.user,
                "user.domainInfo.domain",
                ""
              )}`,
            },
            () => {}
          );
          this.props.history.push("/social-links");
        } 
      } else {
        this.setState({
          SiteUrl1: `https://${get(
            this.props.user,
            "user.domainInfo.domain",
            ""
          )}`,
        });
      }
    }
    if (this.props.createLeadPagePhase === "success") {
      this.props.initLeadPage();
      this.props.getLeadPages({
        activeTab:"listing-active",
        sortKey: this.state.sortKey,
        sortType: this.state.sortType,
        limit: this.state.limit,
        page: 1,
      });
      if (this.props.createLeadPageData?.status === "active") {
        this.openNotificationWithIcon(
          "success",
          "Landing page active successfully."
        );
      } else {
        this.openNotificationWithIcon(
          "success",
          "Landing page draft successfully."
        );
      }
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    return { promoData: props.promoData };
  };

  moveAsDraft = (data) => {
    data.status = "draft";
    this.props.createLeadPage(data);
  };

  openNotificationWithIcon = (type, msg) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: msg,
    });
  };

  handleChangeSlugValue(e) {
    //   this.setState({ shortSlugUrl: e.target.value, errorMessage: "" ,isSaveButtonEnable: false }, () =>
    //     this.forceUpdate()
    //   );
    // }
    // }
    const { value } = e.target;
    const isBlank = value.trim().length === 0;
    this.setState(
      { shortSlugUrl: value, errorMessage: "", isSaveButtonEnable: isBlank },
      () => this.forceUpdate()
    );
  }

  closeModal() {
    this.setState({
      isLoading: false,
      modalIsOpen: false,
      shortLinkModal: false,
      selectedData: {},
      shortSlugUrl: "",
      errorMessage: "",
    });
  }

  pageChange(value) {
    const { nextStep, previousStep, page } = this.state;
    if (value === "next") {
      let next = nextStep - 1;
      this.setState({ page: this.state.page + 1 }, () => {
        this.forceUpdate();
        this.props.getLeadPages({
          activeTab: this.state.activeTab,
          sortKey: this.state.sortKey,
          sortType: this.state.sortType,
          page: this.state.page,
          limit: this.state.limit,
        });
      });
    } else if (value === "previous") {
      this.setState(
        { page: this.state.page - 1 === 0 ? 1 : this.state.page - 1 },
        () => {
          this.forceUpdate();
          this.props.getLeadPages({
            activeTab: this.state.activeTab,
            sortKey: this.state.sortKey,
            sortType: this.state.sortType,
            page: this.state.page,
            limit: this.state.limit,
          });
        }
      );
    }
  }

  onTabChange(tab) {
    this.setState({ activeTab: tab, checkMarkData: [] }, () => {
      if (tab === "listing-active") {
        this.props.history.push("/social-links");
        this.setState({
          page: 1,
          previousStep: 0,
          nextStep: 0,
          isLoadingData: true,
        });
      } else if (tab === "listing-active") {
        this.setState({
          page: 1,
          previousStep: 0,
          isLoadingData: true,
          nextStep: 0,
        });
        this.props.history.push("/social-links");
      }
      this.props.getLeadPages({
        activeTab: tab,
        sortKey: this.state.sortKey,
        sortType: this.state.sortType,
        limit: this.state.limit,
        page: 1,
      });
    });
  }

  duration = (t0, t1) => {
    let d = t1 - new Date(t0);
    let weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
    let days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
    let hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
    let minutes = Math.floor(
      d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60
    );
    let seconds = Math.floor(
      d / 1000 -
        weekdays * 7 * 24 * 60 * 60 -
        days * 24 * 60 * 60 -
        hours * 60 * 60 -
        minutes * 60
    );
    let milliseconds = Math.floor(
      d -
        weekdays * 7 * 24 * 60 * 60 * 1000 -
        days * 24 * 60 * 60 * 1000 -
        hours * 60 * 60 * 1000 -
        minutes * 60 * 1000 -
        seconds * 1000
    );
    let t = {};
    ["weekdays", "days", "hours", "minutes", "seconds", "milliseconds"].forEach(
      (q) => {
        if (eval(q) > 0) {
          t[q] = eval(q);
        }
      }
    );
    return t;
  };

  addSlugUrl = async () => {
    const { selectedata, shortSlugUrl } = this.state;
    this.setState({ isLoading: true });
    const payload = {
      shortSlugUrl: shortSlugUrl,
      _id: selectedata._id,
    };
    const { value } = await this.props.updateShortUrl(payload);
    if (value.success) {
      this.getData();
      let timeOut = setInterval(() => {
        this.setState({
          shortLinkModal: false,
          errorMessage: "",
          shortSlugUrl: "",
          isLoading: false,
        });
        clearInterval(timeOut);
      }, 1000);
    } else {
      this.setState({ errorMessage: value.message, isLoading: false });
    }
  };

  setOpenModal = (data) => {
    this.setState({
      isLoading: false,
      shortLinkModal: true,
      selectedata: data,
      errorMessage: "",
      shortSlugUrl: data.shortSlugUrl,
      isSaveButtonEnable: true,
    });
  };

  render() {
    const {
      leadPageArary,
      stripeError,
      subscriptions,
      activeTab,
      errorMessage,
      SiteUrl1,
      selectedata,
      shortSlugUrl,
      promoData,
      isLoading,
      plan,
      isSaveButtonEnable,
    } = this.state;
    let siteShareUrl = SiteUrl1;
    if(window !== undefined){
      if (window.location.href.includes("stag")) {
        if(!siteShareUrl.includes(".stag")){
          siteShareUrl = siteShareUrl.replace("yardpost.com", "stag.yardpost.com");
        }
      }
    }
    const shareUrl = `${siteShareUrl}`;
    const emailBody = shareUrl;
    const smsBody = shareUrl;
    const TwitterShareUrl = shareUrl;
    const FacebookShareUrl = shareUrl;
    console.log("shareUrl ==>", shareUrl)
    return (
      <>
        <main
          className="main"
          role="header-with-tabs"
          impersonate={
            localStorage.getItem("AdminAuthorization") &&
            localStorage.getItem("Authorization")
              ? "impersonate"
              : ""
          }
        >
          <div className="main__header">
            {localStorage.getItem("AdminAuthorization") &&
              localStorage.getItem("Authorization") && (
                <Impersonate
                  data={get(this.props.user, "user", "")}
                  {...this.props}
                />
              )}
            <TopNavigationComponent {...this.props} />
            <div className="tabs__links">
              <Tabs attr="leadPages" {...this.props} />
            </div>
          </div>

          {get(this.props.user, "user.isLead", false) && (
            <>
              <div className="page__header">
                <div className="page__header--container">
                  <div className="page__header--row">
                    <div className="page__header--left">
                      <h1 className="page__header--title">Social Links</h1>
                    </div>
                    <div className="page__header--right">
                      <Link
                        to="/link-page-settings"
                        className="btn btn__default"
                      >
                        <Setting className="btn__icon" /> Settings
                      </Link>
                      <Link
                        // to="/create-new-landing-page/sell"
                        to="/create-social-link"
                        className="btn btn__purple"
                      >
                        <Plus className="btn__icon" /> Create New Link
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="tabs__links tabs__links--space">
                <button
                  className={
                    activeTab === "listing-active"
                      ? "tabs__link active"
                      : "tabs__link"
                  }
                  onClick={this.onTabChange.bind(this, "listing-active")}
                >
                  Active
                </button>
                <button
                  className={
                    activeTab === "listing-draft"
                      ? "tabs__link active"
                      : "tabs__link"
                  }
                  onClick={this.onTabChange.bind(this, "listing-draft")}
                >
                  Draft
                </button>
              </div> */}
              {this.state.isLoadingData ? (
                <Spin
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 30, color: "#000", align: "center" }}
                      spin
                    />
                  }
                  className="loader__full"
                />
              ) : (
                <div className="custom__container">
                  <div className="table-responsive table__responsive--custom">
                    {leadPageArary.length > 0 ? (
                      <table className="table table__custom">
                        <thead>
                          <tr>
                            <th className="table__selection--column"></th>
                            <th>Link Name</th>
                            {/* <th>Listing Address</th> */}
                            <th>Short Link </th>
                            {/* <th>Days Active</th> */}
                            <th>Visitors</th>
                            <th>Leads</th>
                            <th></th>
                            <th>Share</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {leadPageArary.map((lead, i) => {
                            let createdTime = "";
                            let time = this.duration(
                              lead.createdAt,
                              new Date()
                            );
                            if (get(time, "days", 0)) {
                              if (get(time, "days", 0) === 1) {
                                createdTime = get(time, "days", 0) + " day";
                              } else {
                                createdTime = get(time, "days", 0) + " days";
                              }
                            } else if (get(time, "hours", 0)) {
                              if (get(time, "hours", 0) === 1) {
                                createdTime = get(time, "hours", 0) + " hour";
                              } else {
                                createdTime = get(time, "hours", 0) + " hours";
                              }
                            } else if (get(time, "minutes", 0)) {
                              if (get(time, "minutes", 0) === 1) {
                                createdTime =
                                  get(time, "minutes", 0) + " minute";
                              } else {
                                createdTime =
                                  get(time, "minutes", 0) + " minutes";
                              }
                            } else if (get(time, "seconds", 0)) {
                              if (get(time, "seconds", 0) === 1) {
                                createdTime =
                                  get(time, "seconds", 0) + " second";
                              } else {
                                createdTime =
                                  get(time, "seconds", 0) + " seconds";
                              }
                            } else if (get(time, "milliseconds", 0)) {
                              if (get(time, "milliseconds", 0) === 1) {
                                createdTime =
                                  get(time, "milliseconds", 0) + " millisecond";
                              } else {
                                createdTime =
                                  get(time, "milliseconds", 0) +
                                  " milliseconds";
                              }
                            }
                            return (
                              <tr key={i}>
                                <td className="table__selection--column">
                                  {lead.campaignType === "sell" ? (
                                    <button
                                      className="btn"
                                      onClick={() =>
                                        window.open(
                                          `/preview/sell/${lead.slugUrl}/${lead._id}`
                                        )
                                      }
                                    >
                                      <Eye />
                                    </button>
                                  ) : lead.campaignType === "social-link" ? (
                                    <button
                                      className="btn"
                                      onClick={() =>
                                        window.open(`${shareUrl}/social/${lead?._id}`)
                                      }
                                    >
                                      <Eye />
                                    </button>
                                  ) : (
                                    <button
                                      className="btn"
                                      onClick={() =>
                                        window.open(
                                          `/preview/listing-website/${lead.slugUrl}/${lead._id}`
                                        )
                                      }
                                    >
                                      <Eye />
                                    </button>
                                  )}
                                </td>
                                <td>
                                <span
                                      onClick={() =>
                                        this.props.history.push(
                                          `/edit-social-link/site-editor/${get(
                                            lead,
                                            "_id",
                                            ""
                                          )}`
                                        )
                                      }
                                      className="link_name"
                                    >
                                     {lead.campaignName}
                                    </span>
                                  </td>
                                {/* <td>
                                  {lead.campaignType === "sell"
                                    ? "-"
                                    : get(lead, "propertyId.address", "-")}
                                  {get(lead, "propertyId.units", "") !== ""
                                    ? `${","} ${get(
                                        lead,
                                        "propertyId.units",
                                        ""
                                      )}`
                                    : ""}
                                </td> */}
                                <td>
                                  <div className="short-link">
                                    {this.state.copiedIndex === i && (
                                      <span className="copied-clipboard">
                                        copied
                                      </span>
                                    )}
                                    <LinkCopyIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.setOpenModal(lead)}
                                      shortSlugUrl={lead.shortSlugUrl}
                                    />
                                    {lead.shortSlugUrl ? (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          this.setState(
                                            { copiedIndex: i },
                                            () => {
                                              navigator.clipboard.writeText(
                                                `${ShortUrl}/${lead.shortSlugUrl}`
                                              );
                                            }
                                          );
                                          let timeEnd = setInterval(() => {
                                            this.setState({
                                              copiedIndex: -1,
                                            });
                                            clearInterval(timeEnd);
                                          }, 1000);
                                        }}
                                        className="short-link-text"
                                      >
                                        Copy
                                      </span>
                                    ) : (
                                      <span
                                        className="shortlink_add"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this.setOpenModal(lead)}
                                      >
                                        ADD
                                      </span>
                                    )}
                                  </div>
                                </td>
                                {/* <td>{createdTime}</td> */}
                                <td>{lead.visitors}</td>
                                <td>{lead.leads}</td>
                                <td>
                                  {/* {lead.campaignType === "listing-website" ? (
                                    <button
                                      onClick={() => {
                                        localStorage.setItem(
                                          "isSelectDropDown",
                                          false
                                        );
                                        this.props.history.push(
                                          `/edit-landing-page/${get(
                                            lead,
                                            "campaignType",
                                            ""
                                          )}/listing-details/${get(
                                            lead,
                                            "_id",
                                            ""
                                          )}`
                                        );
                                      }}
                                      className="btn"
                                    >
                                      <Edit />
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        this.props.history.push(
                                          `/edit-landing-page/${get(
                                            lead,
                                            "campaignType",
                                            ""
                                          )}/site-editor/${get(
                                            lead,
                                            "_id",
                                            ""
                                          )}`
                                        )
                                      }
                                      className="btn"
                                    >
                                      <Edit />
                                    </button>
                                  )} */}
                                  <button
                                       onClick={() =>
                                        this.props.history.push(
                                          `/edit-social-link/site-editor/${get(
                                            lead,
                                            "_id",
                                            ""
                                          )}`
                                        )
                                      }
                                      className="btn"
                                    >
                                      <Edit />
                                    </button>
                                </td>
                                <td>
                                  <Dropdown
                                    overlay={
                                      <Menu>
                                        <Menu.Item key="1">
                                          <FacebookShareButton
                                            url={
                                              // lead.campaignType === "sell"
                                              //   ? `${shareUrl}/sell/${lead.slugUrl}/${lead._id}`
                                              //   : `${shareUrl}/listing-website/${lead.slugUrl}/${lead._id}`
                                              `${shareUrl}/social/${lead?._id}`
                                            }
                                            quote={
                                              // lead.campaignType === "sell"
                                              //   ? `${shareUrl}/sell/${lead.slugUrl}/${lead._id}`
                                              //   : `${shareUrl}/listing-website/${lead.slugUrl}/${lead._id}`
                                              `${shareUrl}/social/${lead?._id}`
                                            }
                                          >
                                            <FacebookIcon size={22} /> Facebook
                                          </FacebookShareButton>
                                        </Menu.Item>
                                        <Menu.Item key="2">
                                          <TwitterShareButton
                                            url={
                                              // lead.campaignType === "sell"
                                              //   ? `${shareUrl}/sell/${lead.slugUrl}/${lead._id}`
                                              //   : `${shareUrl}/listing-website/${lead.slugUrl}/${lead._id}`
                                              `${shareUrl}/social/${lead?._id}`
                                            }
                                            // title={TwitterShareUrl}
                                          >
                                            <img
                                              src={twitterX}
                                              alt=""
                                              width="22"  
                                            />{" "}
                                            {/* <TwitterIcon size={22} />  */}
                                            Twitter
                                          </TwitterShareButton>
                                        </Menu.Item>
                                        <Menu.Item key="3">
                                          <EmailShareButton
                                            subject=""
                                            url=""
                                            body={
                                              // lead.campaignType === "sell"
                                              //   ? `${shareUrl}/sell/${lead.slugUrl}/${lead._id}`
                                              //   : `${shareUrl}/listing-website/${lead.slugUrl}/${lead._id}`
                                              `${shareUrl}/social/${lead?._id}`
                                            }
                                            title=""
                                          >
                                            <EmailIcon size={22} /> Email
                                          </EmailShareButton>
                                        </Menu.Item>
                                        <Menu.Item key="4">
                                          <a
                                            href={`sms:?&body=${shareUrl}/social/${lead?._id}`}
                                            className="sms-icon"
                                          >
                                            <img
                                              src={SmsIcon}
                                              alt=""
                                              width="22"
                                            />{" "}
                                            SMS
                                          </a>
                                        </Menu.Item>
                                        <Menu.Item key="5">
                                          <a
                                            className="copy-link"
                                            onClick={() => {
                                              lead.campaignType === "sell"
                                                ? navigator.clipboard.writeText(
                                                    `${shareUrl}/sell/${lead.slugUrl}/${lead._id}`
                                                  )
                                                : lead.campaignType ===
                                                  "social-link"
                                                ? navigator.clipboard.writeText(
                                                    `${shareUrl}/social/${lead?._id}`
                                                  )
                                                : navigator.clipboard.writeText(
                                                    `${shareUrl}/listing-website/${lead.slugUrl}/${lead._id}`
                                                  );
                                            }}
                                          >
                                            <LinkIcon />
                                            Copy Link
                                          </a>
                                        </Menu.Item>
                                      </Menu>
                                    }
                                    trigger={["click"]}
                                  >
                                    <button className="btn ant-dropdown-link">
                                      <Export />
                                    </button>
                                  </Dropdown>
                                </td>
                                <td>
                                  <ActionMoreComponent
                                    class="landing-page"
                                    className="landing-page"
                                    data={lead}
                                    activeTab={activeTab}
                                    moveAsDraft={this.moveAsDraft.bind(this)}
                                    {...this.props}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <>
                        <div className="table__blank">
                          <div className="table__blank--text">
                            {activeTab === "listing-active"
                              ? "No Landing page available"
                              : "No drafts"}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {leadPageArary.length > 0 && (
                    <div className="table__pagination">
                      <div className="table__pagination--results">
                        {size(leadPageArary)} results
                      </div>
                      <div className="table__pagination--nav">
                        <>
                          {this.state.previousStep + (this.state.page - 1) ? (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--prev"
                              onClick={this.pageChange.bind(this, "previous")}
                            >
                              Previous
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--prev disabled"
                            >
                              Previous
                            </button>
                          )}
                        </>
                        <>
                          {this.state.nextStep - this.state.page > 0 ? (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--next"
                              onClick={this.pageChange.bind(this, "next")}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--next disabled"
                            >
                              Next
                            </button>
                          )}
                        </>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {isEmpty(this.props.user.user) && (
            <Spin
              size="large"
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 30, color: "#000", align: "center" }}
                  spin
                />
              }
              className="loader__full"
            />
          )}
          {!isEmpty(this.props.user.user) &&
            !get(this.props.user, "user.isLead", false) && (
              <StarterPageComponent {...this.props} />
            )}
        </main>

        <ReactModal
          isOpen={
            get(this.props.user, "user.isTrialEnd", false) === null
              ? false
              : get(this.props.user, "user.isTrialEnd", false)
          }
          onRequestClose={this.closeModal}
          contentLabel="Otp"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <Formik
            initialValues={{
              cardNumber: "",
              nameOnCard: "",
              cardDate: "",
              securityCode: "",
              CouponCode: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.cardNumber) {
                errors.cardNumber = "Please enter card number";
              } else if (values.cardNumber.length <= 16) {
                errors.cardNumber = "Please enter valid card number";
              }
              if (!values.nameOnCard) {
                errors.nameOnCard = "Please enter name";
              }
              if (!values.cardDate) {
                errors.cardDate = "Please enter expiration date";
              } else if (values.cardDate.includes("_")) {
                errors.cardDate = "Please enter valid expiration date";
              }
              if (!values.securityCode) {
                errors.securityCode = "Please enter security code";
              } else if (
                values.securityCode.length < 3 ||
                values.securityCode.length > 4
              ) {
                errors.securityCode = "Please enter valid security code";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                this.submitCard(values);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="modal-content">
                  <div className="react-modal-header">
                    <h5 className="react-modal-title">Subscribe to Yardpost</h5>
                  </div>
                  <div className="react-modal-body">
                    <div className="pricing__module">
                      <div className="pricing__module--inner ">
                        {get(this.props, "subscriptions", "") && (
                          <RadioButtonGroup
                            className="tesss"
                            onSelectedPlan={(e) =>
                              this.onSelectedPlan.bind(this)
                            }
                            selectedPlan={this.state.selectedPlan}
                            attribute="select-plan"
                            subscriptions={get(this.props, "subscriptions", {})}
                            {...this.props}
                          />
                        )}
                        <div className="pricing__module--save-money">
                          Save 25%
                        </div>
                      </div>
                      <div className="pricing__module--text">
                        We use Stripe to securely store and process your payment
                        info. All transactions are secure and encrypted
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="label-primary">Name on card</label>
                      <input
                        type="text"
                        name="nameOnCard"
                        className="form-control material-textfield-input"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nameOnCard}
                        required
                      />
                      {errors.nameOnCard && (
                        <div className="invalid-feedback">
                          {errors.nameOnCard}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="label-primary">Card Number</label>
                      <InputMask
                        guide={false}
                        mask={cardNumberMask}
                        name="cardNumber"
                        className="form-control material-textfield-input"
                        onChange={(e) => {
                          handleChange(e);
                          this.setState({ stripeError: {} });
                        }}
                        onBlur={handleBlur}
                        value={values.cardNumber}
                        required
                      />
                      {errors.cardNumber && (
                        <div className="invalid-feedback">
                          {errors.cardNumber}
                        </div>
                      )}
                      {!isEmpty(stripeError) &&
                        stripeError.code === "incorrect_number" && (
                          <div className="invalid-feedback">
                            {stripeError.message}
                          </div>
                        )}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">
                            Expiration Date (MM/YY)
                          </label>
                          <InputMask
                            guide={false}
                            mask={cardDate}
                            name="cardDate"
                            className="form-control material-textfield-input"
                            onChange={(e) => {
                              handleChange(e);
                              this.setState({ stripeError: {} });
                            }}
                            onBlur={handleBlur}
                            value={values.cardDate}
                            required
                          />
                          {errors.cardDate && (
                            <div className="invalid-feedback">
                              {errors.cardDate}
                            </div>
                          )}
                          {!isEmpty(stripeError) &&
                            (stripeError.code === "invalid_expiry_year" ||
                              stripeError.code === "invalid_expiry_month") && (
                              <div className="invalid-feedback">
                                {stripeError.message}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">
                            Security Code (CVV)
                          </label>
                          <InputMask
                            guide={false}
                            mask={securityCode}
                            type="text"
                            name="securityCode"
                            className="form-control material-textfield-input"
                            onChange={(e) => {
                              handleChange(e);
                              this.setState({ stripeError: {} });
                            }}
                            onBlur={handleBlur}
                            value={values.securityCode}
                            required
                          />
                          {errors.securityCode && (
                            <div className="invalid-feedback">
                              {errors.securityCode}
                            </div>
                          )}
                          {!isEmpty(stripeError) &&
                            stripeError.code === "invalid_cvc" && (
                              <div className="invalid-feedback">
                                {stripeError.message}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <label className="label-primary">Coupon Code</label>
                      <div className="coupon__code">
                        <input
                          type="text"
                          name="CouponCode"
                          className="form-control material-textfield-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.CouponCode}
                          required
                        />
                        {!isEmpty(promoData) ? (
                          <button
                            type="button"
                            onClick={this.removeCoupon.bind(
                              this,
                              values.CouponCode,
                              setFieldValue
                            )}
                            className="btn btn__purple coupon__code--btn"
                          >
                            Remove
                          </button>
                        ) : (
                          <button
                            disabled={values.CouponCode.length === 0}
                            type="button"
                            onClick={this.applyCoupon.bind(
                              this,
                              values.CouponCode
                            )}
                            className="btn btn__purple coupon__code--btn"
                          >
                            Apply
                          </button>
                        )}
                      </div>
                      {get(promoData, "message", false) && (
                        <div
                          className={
                            get(promoData, "success", false)
                              ? ""
                              : "invalid-feedback"
                          }
                        >
                          {get(promoData, "message", "")}
                        </div>
                      )}
                      <div className="powered__by__stripe">
                        <PoweredByStripe />
                      </div>
                    </div>
                  </div>
                  <div className="react-modal-footer flex-column">
                    <button
                      type="submit"
                      className="btn btn-lg btn__purple w-100"
                      disabled={this.state.paymentLogin || isSubmitting}
                    >
                      {this.state.paymentLogin ? (
                        <Spin indicator={antIcon} />
                      ) : !isEmpty(promoData) &&
                        get(promoData, "success", false) ? (
                        get(promoData, "data.amount_off", null) !== null ? (
                          plan === 1 ? (
                            "Pay & Start Using Yardpost | $" +
                            (!isEmpty(subscriptions) &&
                              subscriptions[0].plan[1].amount / 100 -
                                promoData.data.amount_off / 100) +
                            "/mo"
                          ) : (
                            "Pay & Start Using Yardpost | $" +
                            (!isEmpty(subscriptions) &&
                              subscriptions[0].plan[0].amount / 100 -
                                promoData.data.amount_off / 100) +
                            "/yr"
                          )
                        ) : plan === 1 ? (
                          "Pay & Start Using Yardpost | $" +
                          (
                            !isEmpty(subscriptions[0]) &&
                            subscriptions[0].plan[1].amount / 100 -
                              ((subscriptions[0].plan[1].amount / 100) *
                                promoData.data.percent_off) /
                                100
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) +
                          "/mo"
                        ) : (
                          "Pay & Start Using Yardpost | $" +
                          (!isEmpty(subscriptions[0]) &&
                            (
                              subscriptions[0].plan[0].amount / 100 -
                              ((subscriptions[0].plan[0].amount / 100) *
                                promoData.data.percent_off) /
                                100
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })) +
                          "/yr"
                        )
                      ) : plan === 1 ? (
                        "Pay & Start Using Yardpost | $" +
                        (
                          !isEmpty(subscriptions[0]) &&
                          subscriptions[0].plan[1].amount / 100
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) +
                        "/mo"
                      ) : (
                        "Pay & Start Using Yardpost | $" +
                        (!isEmpty(subscriptions[0]) &&
                          (
                            subscriptions[0].plan[0].amount / 100
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })) +
                        "/yr"
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-lg btn__link--deafult"
                      onClick={this.logoutImpersonate.bind(this)}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </ReactModal>
        <ReactModal
          isOpen={this.state.shortLinkModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Short URL"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Short URL</h5>
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label class="label-primary">Slug</label>
                <input
                  className="form-control"
                  type="text"
                  name="shortSlugUrl"
                  value={shortSlugUrl}
                  onChange={(e) => this.handleChangeSlugValue(e)}
                ></input>
                {errorMessage !== "" && (
                  <div className="invalid-feedback invalid-feedback-sm">
                    {errorMessage}
                  </div>
                )}
              </div>

              <div className="form-group mb-0">
                <label class="label-primary">URL:</label>
                <span>
                  {" "}
                  {ShortUrl}/
                  {shortSlugUrl !== "" ? (
                    <span className="theme-text">{shortSlugUrl}</span>
                  ) : (
                    <span className="theme-text">your_slug</span>
                  )}
                </span>
              </div>
            </div>
            <div className="react-modal-footer">
              <button onClick={this.closeModal} className="btn btn__default">
                Cancel
              </button>
              <button
                disabled={this.state.isSaveButtonEnable}
                onClick={() => {
                  this.addSlugUrl();
                }}
                className="btn btn__purple ms-3"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Save"}
              </button>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}
