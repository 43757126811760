import { connect } from "react-redux";

import SocialLinkEmptyComponent from "./component";

const SocialLinkEmptyContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {}
)(SocialLinkEmptyComponent);

export default SocialLinkEmptyContainer;
