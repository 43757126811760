import { connect } from "react-redux";
import {
  editUser,
  getUser,
  resetLogin,
  resetLoginUser,
  uploadImages,
} from "../../../../store/user/duck";
import ProfileComponent from "./component";

const ProfileContainer = connect(
  // Map state to props
  (state) => ({
    userPhase: state.user.userPhase,
    user: state.user.user,
    editUserPhase: state.user.editUserPhase,
    editUserData: state.user.editUserData,
  }),

  // Map actions to dispatch and props
  {
    getUser,
    editUser,
    resetLoginUser,
    resetLogin,
    uploadImages,
  }
)(ProfileComponent);

export default ProfileContainer;
