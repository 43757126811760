import { connect } from "react-redux";
import {
  getLeadPageById,
  getAddressDetails,
  addInquiry,
  initLeadPage,
} from "../../../store/leadPage/duck";
import SellDetailComponent from "./component";

const SellDetailContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    leadPageByIdPhase: state.leadPage.leadPageByIdPhase,
    leadPageByIdData: state.leadPage.leadPageByIdData,
    leadPageSetting: state.leadPage.leadPageSetting,
    leadPageUserData: state.leadPage.leadPageUserData,
    getAddressDetailsPhase: state.leadPage.getAddressDetailsPhase,
    getAddressDetailsData: state.leadPage.getAddressDetailsData,
    addInquiryPhase: state.leadPage.addInquiryPhase,
    addInquiryMessage: state.leadPage.addInquiryMessage,
  }),
  // Map actions to dispatch and props
  {
    addInquiry,
    getLeadPageById,
    initLeadPage,
    getAddressDetails,
  }
)(SellDetailComponent);

export default SellDetailContainer;
