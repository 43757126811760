import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Logo, CheckMarkIcon } from "../../../components/icons";

import "./styles.scss";

export default class SuccessfulSignUpComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  componentDidMount() {
    const id = this.props.match.params.customer_id;
    if (window && window.gtag) {
      window.gtag("event", "signup", {
        event_label: "signup",
        value: id,
      });
    }
  }

  render() {
    return (
      <>
        <div className="success__app">
          <div className="success__app--header">
            <Logo />
          </div>
          <div className="success__container">
            <div className="success__inner">
              <div className="success__wrap">
                <CheckMarkIcon className="info__icon" />
                <h1 className="success__title">Success</h1>
                <p className="success__subtitle">
                  Thanks for joining Yardpost. Let’s get you started building
                  your website!{" "}
                </p>
                <Link className="btn btn__purple" to={"/social-links"}>
                  Go to Dashboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
