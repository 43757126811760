import "rxjs";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { createEpicMiddleware, combineEpics } from "redux-observable";
import app from "./app";
import user, { userEpic } from "./user/duck";
import admin, { adminEpic } from "./admin/duck";
import mylisting, { mylistingEpic } from "./mylisting/duck";
import leadPage, { leadPageEpic } from "./leadPage/duck";

// Bundling Epics
const rootEpic = combineEpics(userEpic, adminEpic, leadPageEpic, mylistingEpic);

// Creating Bundled Epic
const epicMiddleware = createEpicMiddleware();

// Define Middleware
const middleware = [thunk, promise, epicMiddleware];

// Define Reducers
const reducers = combineReducers({
  user,
  admin,
  mylisting,
  leadPage,
  form: formReducer,
});

// Create Store
const store = createStore(
  reducers,
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(...middleware)
);
epicMiddleware.run(rootEpic);
export default store;
