import { connect } from "react-redux";
import {
  getUser,
  updateUserStatus,
  resetLogin,
  signupUser,
} from "../../../store/user/duck";

import AppSumoComponent from "./component";

const AppSumoContainer = connect(
  // Map state to props
  (state) => ({
    signupPhase: state.user.signupPhase,
    user: state.user.user,
    message: state.user.message,
  }),
  // Map actions to dispatch and props
  {
    updateUserStatus,
    getUser,
    signupUser,
    resetLogin,
  }
)(AppSumoComponent);

export default AppSumoContainer;
