import React, { PureComponent } from "react";
import Dropzone from "react-dropzone";
import { Plus } from "../../icons";
import { get } from "lodash";
import "./styles.scss";
import { Slider, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Cropper from "../../../components/react-cropper";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default class ImagePickerComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const {
      listingWebsiteTemplate,
      imageLoading,
      imageLoadingKey,
      rotateLoading,
      preImage,
    } = this.props;
    return (
      <>
        {get(listingWebsiteTemplate, `${this.props.name}`, "") &&
        imageLoadingKey !== this.props.name ? (
          <>
            <div className="image__picker">
              <div className="image__picker--thumb">
                <img
                  src={
                    this.props.name === "image"
                      ? get(listingWebsiteTemplate, "image", "")
                      : get(listingWebsiteTemplate, "logo", "")
                  }
                  alt=""
                />
              </div>
              <div className="image__picker--controls">
                <button
                  type="button"
                  className="btn btn__change"
                  onClick={() =>
                    this.props.onChangeCropImage(
                      this.props.name,
                      get(listingWebsiteTemplate, `${this.props.name}`, "")
                    )
                  }
                >
                  Change
                </button>
                <button
                  type="button"
                  className="btn btn__rotate"
                  onClick={() =>
                    this.props.imageRotate(
                      this.props.name,
                      get(listingWebsiteTemplate, `${this.props.name}`, "")
                    )
                  }
                >
                  {rotateLoading === this.props.name ? <Spin /> : "Rotate"}
                </button>
                <button
                  type="button"
                  className="btn btn__link--danger btn__remove"
                  onClick={() => this.props.removeImage(this.props.name)}
                >
                  Remove
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="dropzone-main mb-0">
              <Dropzone
                accept="image/*"
                multiple={false}
                onDrop={(acceptedFiles) =>
                  this.props.onDropImage(acceptedFiles, this.props.name)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div className="dropzone__area" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button type="button" className="btn btn__purple">
                        {this.props.name === "logo" &&
                        this.props.logoLoading ? (
                          <Spin indicator={antIcon} />
                        ) : this.props.name === "image" &&
                          this.props.imageLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            {" "}
                            <Plus className="btn__icon" /> Upload image{" "}
                          </>
                        )}
                      </button>
                    </div>
                  </section>
                )}
              </Dropzone>
              {this.props.imageError && (
                <div className="invalid-feedback">
                  Please use jpeg or png format.
                </div>
              )}
              {this.props.logoError && (
                <div className="invalid-feedback">
                  Please use jpeg or png format.
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

// imageLoadingKey === this.props.name ? (
//             <>
//               {/* {preImage} */}
//               <Cropper
//                 ref={(e) => {

//                   // this.props.referenceFromchildToParent(e)
//                   (this.cropper = e)
//                 }}
//                 src={preImage}
//                 checkCrossOrigin={false}
//                 zoomOnWheel={false}
//                 autoCrop={true}
//                 movable={true}
//                 center={false}
//                 dragMode="move"
//                 style={{
//                   maxWidth: "100%",
//                   maxHeight: "250px",
//                   minHeight: "250px",
//                 }}
//                 crop={(e) => this.props._crop(e,this.cropper)}
//               />
//               <div className="editor-preview-crop-cta">
//                 <Slider
//                   className="react-crop-slider"
//                   step={0.01}
//                   defaultValue={1}
//                   min={0.1}
//                   max={10}
//                   onChange={(e) => this.cropper.zoomTo(e)}
//                   disabled={imageLoading == this.props.name}
//                 />
//                 <button
//                   type="button"
//                   className="btn btn-sm btn-dark w-120 ml-3"
//                   onClick={() =>
//                     this.props.cancelCropImage()
//                   }
//                   disabled={imageLoading == this.props.name}
//                 >
//                   {"Cancel"}
//                 </button>
//                 <button
//                   type="button"
//                   className="btn"
//                   onClick={() => this.props.uploadCropImage(this.props.name)}
//                   disabled={imageLoading == this.props.name}
//                 >
//                   {imageLoading == this.props.name ? <Spin /> : "Done"}
//                 </button>
//               </div>
//             </>
//           ) :
