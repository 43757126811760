import React, { PureComponent } from "react";

import "./styles.scss";

export default class DashboardComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    return <></>;
  }
}
