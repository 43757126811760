import React, { PureComponent } from "react";
// import PropTypes from 'prop-types'

import "./styles.scss";

export default class TablePaginationComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    return (
      <>
        <div className="table__pagination">
          <div className="table__pagination--results">3 results</div>
          <div className="table__pagination--nav">
            <button
              type="button"
              className="btn btn__default table__pagination--prev disabled"
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn__default table__pagination--next disabled"
            >
              Next
            </button>
          </div>
        </div>
      </>
    );
  }
}
