import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../../components/icons";
export default class SocialLinkEmptyComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  componentDidMount() {
    document.getElementById("favicon").href =
      "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png";
  }
  render() {
    return (
      <>
        <div className="social-link-empty-header">
          <Logo />
        </div>
        <main className="social-link-empty-main">
          <div className="social-link-empty">
            <div className="social-link-empty-wrapper">
              <h1>No Link Available</h1>
              <p>
                Something went wrong, were not able to find the link you’re
                looking for.
              </p>
              <a className="btn btn__purple" href="https://yardpost.com">
                Go to Yardpost.com
              </a>
            </div>
          </div>
        </main>
      </>
    );
  }
}
