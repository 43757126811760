import React, { PureComponent } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Slogo from "./../../../images/sterea-agency.png";
import { get } from "lodash";
import "./styles.scss";
import { isMobile } from "react-device-detect";
import {
  Facebook,
  Instagram,
  Twitter,
  Whatsapp,
  Youtube,
} from "../../../components/icons";
import { ArrowLeftOutlined } from "@ant-design/icons";
export default class HeaderComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      redirectTab: "",
    };
  }

  componentDidMount() {
    let tab = this.props.location.pathname;
    let id = get(this.props.match, "params.id", "");
    if (tab === `/preview/sell-detail/${id}`) {
      this.setState({ redirectTab: `/preview/sell/${id}` });
    } else if (tab === `/sell-detail/${id}`) {
      this.setState({ redirectTab: `/sell/${id}` });
    } else {
      this.setState({ redirectTab: tab });
    }
  }

  onBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { leadData, leadPageSetting } = this.props;

    return (
      <>
        <header className="header">
          <div className="header__left d-flex gap-2">
            {this.props.showBackBtn ? <button onClick={this.onBack} className="backBtn">
              <ArrowLeftOutlined style={{fontSize:20}} />
            </button>:""}
            <div
              className="header__left--logo"
              onClick={() => this.props.history.push(this.state.redirectTab)}
            >
              <img
                src={
                  get(leadData, "logo", "")
                    ? get(leadData, "logo", "")
                    : get(leadPageSetting, "logo", "")
                }
                alt=""
                width={
                  isMobile || localStorage.getItem("width")
                    ? get(leadData, "mobileLogoSize", "40")
                    : get(leadData, "desktopLogoSize", "40")
                }
              />
            </div>
          </div>
          <div className="header__right">
            {get(leadData, "campaignType", "") === "sell" && (
              <div className="social__links--list">
                <ul className="social__links--list">
                  {get(leadPageSetting, "youtubeUrl", "") && (
                    <li>
                      <a href={get(leadPageSetting, "youtubeUrl", "")}>
                        <Youtube />
                      </a>
                    </li>
                  )}
                  {get(leadPageSetting, "facebookUrl", "") && (
                    <li>
                      <a href={get(leadPageSetting, "facebookUrl", "")}>
                        <Facebook />
                      </a>
                    </li>
                  )}
                  {get(leadPageSetting, "instagramUrl", "") && (
                    <li>
                      <a href={get(leadPageSetting, "instagramUrl", "")}>
                        <Instagram />
                      </a>
                    </li>
                  )}
                  {get(leadPageSetting, "twitterUrl", "") && (
                    <li>
                      <a href={get(leadPageSetting, "twitterUrl", "")}>
                        <Twitter />
                      </a>
                    </li>
                  )}
                  {get(leadPageSetting, "whatsupUrl", "") && (
                    <li>
                      <a href={get(leadPageSetting, "whatsupUrl", "")}>
                        <Whatsapp />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}

            {get(leadData, "campaignType", "") === "listing-website" && (
              <Link
                to="contact_us"
                className="btn btn__flat btn__flat--dark btn__interested"
                activeClass="contact"
                spy={true}
                hashSpy={true}
                smooth={true}
                offset={-80}
                duration={500}
              >
                I’m interested
              </Link>
            )}
          </div>
        </header>
      </>
    );
  }
}
