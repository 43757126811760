import { connect } from "react-redux";
import {
  getLeadPageById,
  addInquiry,
  getAddressDetails,
  initLeadPage,
} from "../../../store/leadPage/duck";
import SellComponent from "./component";

const SellContainer = connect(
  // Map state to props
  (state) => {
    return {
      leadPageByIdPhase: state.leadPage.leadPageByIdPhase,
      leadPageByIdData: state.leadPage.leadPageByIdData,
      leadPageSetting: state.leadPage.leadPageSetting,
      leadPageUserData: state.leadPage.leadPageUserData,
      getAddressDetailsPhase: state.leadPage.getAddressDetailsPhase,
      getAddressDetailsData: state.leadPage.getAddressDetailsData,
      addInquiryPhase: state.leadPage.addInquiryPhase,
      addInquiryMessage: state.leadPage.addInquiryMessage,
    };
  },
  // Map actions to dispatch and props
  {
    addInquiry,
    getLeadPageById,
    initLeadPage,
    getAddressDetails,
  }
)(SellComponent);

export default SellContainer;
