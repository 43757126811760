import { connect } from "react-redux";
import {
  getLeadPages,
  createLeadPage,
  initLeadPage,
  updateShortUrl,
} from "../../../store/leadPage/duck";
import {
  getUser,
  resetImpersonatedUser,
  resetLoginUser,
  addCard,
  getSubscriptions,
  applyPromoCode,
  initPromoPhase,
  editUser,
} from "../../../store/user/duck";
import LeadPagesComponent from "./component";

const LeadPagesContainer = connect(
  // Map state to props
  (state) => ({
    userPhase: state.user.userPhase,
    user: state.user.user,
    loginPhase: state.user.phase,
    count: state.leadPage.count,
    addCardPhase: state.user.addCardPhase,
    addCardMessage: state.user.addCardMessage,
    subscriptionPhase: state.user.subscriptionPhase,
    subscriptions: state.user.subscriptions,
    getLeadPagesPhase: state.leadPage.getLeadPagesPhase,
    createLeadPagePhase: state.leadPage.createLeadPagePhase,
    createLeadPageData: state.leadPage.createLeadPageData,
    getLeadPagesData: state.leadPage.getLeadPagesData,
    promoData: state.user.promoData,
    promoPhase: state.user.promoPhase,
  }),
  // Map actions to dispatch and props
  {
    createLeadPage,
    initLeadPage,
    getLeadPages,
    getSubscriptions,
    resetLoginUser,
    resetImpersonatedUser,
    getUser,
    updateShortUrl,
    addCard,
    applyPromoCode,
    initPromoPhase,
    editUser,
  }
)(LeadPagesComponent);

export default LeadPagesContainer;
