import React, { PureComponent } from "react";
import { EqualHO, Realtor } from "../../../../components/icons";
import JohnMatais from "../../../../images/john-matais.png";
import Placeholder from "../../../../images/placeholder.svg";
import { get } from "lodash";
import NumberFormat from "react-number-format";
import "./styles.scss";
import moment from "moment";

export default class PropertyCalculatorComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const { leadPageUserData, leadPageSetting } = this.props;
    return (
      <>
        <div className="sale__calculator">
          <div className="sale__calculator--inner">
            <div className="sale__calculator--wrapper">
              <div className="sale__calculator--header">
                <h1 className="sale__calculator--header-title">
                  Home sale calculator
                </h1>
                <p className="sale__calculator--header-sub">
                  Lets determine how much will I make selling my house?
                </p>
              </div>

              <div className="form-group">
                <label className="label-secondary">target selling price</label>
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  prefix={"$"}
                  allowNegative={false}
                  className="form-control form-control-flat"
                  name="targetSellPrice"
                  onChange={(e) =>
                    this.props.handleChangeValue(
                      e,
                      "targetSellPrice",
                      "targetSellPriceValue"
                    )
                  }
                  value={this.props.targetSellPrice}
                  required
                />
                <div className="sale-info-text">
                  We recommend beginning with the home valuation number above.
                </div>
              </div>
              <div className="form-group">
                <label className="label-secondary">
                  remaining mortgage owed
                </label>
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  prefix={"$"}
                  allowNegative={false}
                  className="form-control form-control-flat"
                  name="remainingMortgage"
                  onChange={(e) =>
                    this.props.handleChangeValue(
                      e,
                      "remainingMortgage",
                      "remainingMortgageValue"
                    )
                  }
                  value={this.props.remainingMortgage}
                  required
                />
                <div className="sale-info-text">
                  The balance you still owe on your home loan.
                </div>
              </div>
              <div className="form-group">
                <label className="label-secondary">
                  Real estate agent fees
                </label>
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  suffix={"%"}
                  allowNegative={false}
                  className="form-control form-control-flat"
                  name="realEstateAgentFees"
                  onChange={(e) =>
                    this.props.handleChangeValue(
                      e,
                      "realEstateAgentFees",
                      "realEstateAgentFeesValue"
                    )
                  }
                  value={this.props.realEstateAgentFees}
                  required
                />
                <div className="sale__calculator--percentage-calc">
                  $
                  {parseFloat(
                    get(this.props, "realEstateAgentFeesValue", 0)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>

                <div className="sale-info-text">
                  The seller pays fees to both their agent and the buyer’s
                  agent. It’s common for the total commission to be around 5-6%
                  of the sale price.
                </div>
              </div>
              <div className="form-group">
                <label className="label-secondary">
                  Staging, cleaning and prep work
                </label>
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  suffix={"%"}
                  allowNegative={false}
                  className="form-control form-control-flat"
                  name="preWork"
                  onChange={(e) =>
                    this.props.handleChangeValue(e, "preWork", "preWorkValue")
                  }
                  value={this.props.preWork}
                  required
                />
                <div className="sale__calculator--percentage-calc">
                  $
                  {parseFloat(
                    get(this.props, "preWorkValue", 0)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>

                <div className="sale-info-text">
                  This is the total cost of getting your home ready to show to
                  potential buyers. This varies depending on whether you pay for
                  staging, and cosmetic improvements.
                </div>
              </div>
              <div className="form-group">
                <label className="label-secondary">
                  Title, escrow, notary, and transfer tax
                </label>
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  suffix={"%"}
                  allowNegative={false}
                  className="form-control form-control-flat"
                  name="transferTax"
                  onChange={(e) =>
                    this.props.handleChangeValue(
                      e,
                      "transferTax",
                      "transferTaxValue"
                    )
                  }
                  value={this.props.transferTax}
                  required
                />
                <div className="sale__calculator--percentage-calc">
                  $
                  {parseFloat(
                    get(this.props, "transferTaxValue", 0)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>

                <div className="sale-info-text">
                  This is referred to as your closing costs, these fees can
                  range from 1% to 3% of the sale price depending on where you
                  live.
                </div>
              </div>
              <div className="form-group">
                <label className="label-secondary">
                  Home ownership and overlap costs
                </label>
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  suffix={"%"}
                  allowNegative={false}
                  className="form-control form-control-flat"
                  name="ownershipCost"
                  onChange={(e) =>
                    this.props.handleChangeValue(
                      e,
                      "ownershipCost",
                      "ownershipCostValue"
                    )
                  }
                  value={this.props.ownershipCost}
                  required
                />
                <div className="sale__calculator--percentage-calc">
                  $
                  {parseFloat(
                    get(this.props, "ownershipCostValue", 0)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>

                <div className="sale-info-text">
                  These are costs you incur transitioning from one home to the
                  next. Examples include paying for a short-term rental, paying
                  two mortgage payments, or leasing your home back from the
                  buyer before you move.
                </div>
              </div>
              <div className="form-group">
                <label className="label-secondary">Selling concessions</label>
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  suffix={"%"}
                  allowNegative={false}
                  className="form-control form-control-flat"
                  name="sellingConcession"
                  onChange={(e) =>
                    this.props.handleChangeValue(
                      e,
                      "sellingConcession",
                      "sellingConcessionValue"
                    )
                  }
                  value={this.props.sellingConcession}
                  required
                />
                <div className="sale__calculator--percentage-calc">
                  $
                  {parseFloat(
                    get(this.props, "sellingConcessionValue", 0)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>

                <div className="sale-info-text">
                  It’s typical for buyers to ask sellers to pay costs on their
                  behalf. Depending on the size of the buyer’s down payment and
                  where you live, these can range from 1.5% to 2% of the sale
                  price.
                </div>
              </div>
              <div className="form-group">
                <label className="label-secondary">
                  Repair & Home Improvement
                </label>
                <NumberFormat
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  allowNegative={false}
                  className="form-control form-control-flat"
                  name="homeImprovement"
                  onChange={(e) =>
                    this.props.handleChangeValue(
                      e,
                      "homeImprovement",
                      "homeImprovementValue"
                    )
                  }
                  value={this.props.homeImprovement}
                  required
                />
                <div className="sale-info-text">
                  Once you’ve agreed to a buyer’s offer, they’ll inspect your
                  home for defects. The buyer will usually ask you to make
                  repairs or request a credit equal to the expected repair cost.
                </div>
              </div>
            </div>
          </div>

          <div className="net__valuation--wrapper">
            <div className="net__valuation--block">
              <div className="net__valuation--block--dark">
                <div className="net__valuation--block-row">
                  <label className="net__valuation--label">
                    Target SALE PRICE
                  </label>
                  <div className="net__valuation--amount">
                    $
                    {parseFloat(
                      get(this.props, "targetSellPrice", 0)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="net__valuation--block-row">
                  <label className="net__valuation--label">
                    REMAINING MORTGAGE OWED
                  </label>
                  <div className="net__valuation--amount">
                    $
                    {parseFloat(
                      get(this.props, "remainingMortgage", 0)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="net__valuation--block-row">
                  <label className="net__valuation--label">
                    Est. sELLING COSTS
                  </label>
                  <div className="net__valuation--amount">
                    $
                    {parseFloat(
                      get(this.props, "estimationSellingCost", 0)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              </div>
              <div className="net__valuation--block--light">
                <label className="net__valuation--label">NET PROCEEDS</label>
                <div className="net__valuation--amount">
                  $
                  {parseFloat(get(this.props, "netProcced", 0)).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </div>
              </div>
            </div>

            <div className="broker__block--wrapper">
              <h2 className="broker__block--block-title">
                Reach out to learn more!
              </h2>
              <div className="broker__block">
                <div className="broker__block--thumb">
                  <img
                    src={
                      get(this.props.user.user, "profileImage", "") !== ""
                        ? get(this.props.user.user, "profileImage", "")
                        : Placeholder
                    }
                    alt=""
                  />
                </div>
                <div className="broker__block--info">
                  <div className="broker__block--info-header">
                    <h4 className="broker__block--title">{`${get(
                      this.props.user.user,
                      "firstName",
                      ""
                    )} ${" "} ${get(
                      this.props.user.user,
                      "lastName",
                      ""
                    )}`}</h4>
                    {get(this.props.user.user, "companyName", "") !== "" ? (
                      <p className="broker__block--subtitle">
                        {get(this.props.user.user, "title", "")} at{" "}
                        {get(this.props.user.user, "companyName", "")}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="broker__block--more-info">
                    <div className="broker__block--label">
                      <span>EM:</span>{" "}
                      <a
                        className="link"
                        href={`mailto:${get(
                          this.props.user.user,
                          "email",
                          ""
                        )}?Subject=Hello`}
                        target="_top"
                      >
                        {get(this.props.user.user, "email", "")}
                      </a>
                    </div>
                    {get(this.props.user.user, "phone", "") && (
                      <div className="broker__block--label">
                        <span>PH:</span>{" "}
                        <a
                          className="link"
                          href={`tel:+${get(
                            this.props.user.user,
                            "phone",
                            ""
                          )}`}
                        >
                          {get(this.props.user.user, "phone", "")}
                        </a>
                      </div>
                    )}
                    {get(this.props.user.user, "mlsMemberId", "") && (
                      <div className="broker__block--label">
                        <span>MLS Member ID:</span>{" "}
                        {get(this.props.user.user, "mlsMemberId", "")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="sell__footer">
              <div className="sell__footer--copyrights">
                © {moment().year()}{" "}
                {get(this.props.user.user, "companyName", "")}
              </div>
            </div>
            <div className="sell__footer mt-2">
            <ul className="sell__mls--list">
                {get(leadPageSetting, "showHousingLogo", false) && (
                  <li>
                    <EqualHO />
                  </li>
                )}
                {get(leadPageSetting, "showMlsLogo", false) && (
                  <li>
                    <Realtor />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
