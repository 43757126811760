import * as React from "react";

export const PDFImage = ({ props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="51" viewBox="0 0 44 51" fill="none">
            <path d="M3.14283 0H29.8461L44 14.0989V47.143C44 48.8794 42.5921 50.2858 40.8572 50.2858H3.14283C1.40793 50.2858 0 48.8794 0 47.143V3.14283C0 1.40646 1.40809 0 3.14283 0Z" fill="#E2574C" />
            <path d="M43.9523 14.1427H32.9978C31.2629 14.1427 29.855 12.7348 29.855 10.9999V0.03125L43.9523 14.1427Z" fill="#B53629" />
            <path d="M32.2091 23.8266C32.7356 23.8266 32.9933 23.3677 32.9933 22.923C32.9933 22.4625 32.7246 22.0178 32.2091 22.0178H29.2109C28.6247 22.0178 28.2979 22.5033 28.2979 23.0393V30.4077C28.2979 31.0645 28.6718 31.4291 29.1778 31.4291C29.6808 31.4291 30.0564 31.0645 30.0564 30.4077V28.3853H31.8698C32.4324 28.3853 32.7137 27.9248 32.7137 27.4676C32.7137 27.0198 32.4324 26.5749 31.8698 26.5749H30.0564V23.8266H32.2091ZM22.075 22.0178H19.8812C19.2856 22.0178 18.8629 22.4264 18.8629 23.0329V30.414C18.8629 31.1667 19.471 31.4024 19.9063 31.4024H22.2085C24.9333 31.4024 26.7326 29.6095 26.7326 26.8421C26.7311 23.9161 25.0372 22.0178 22.075 22.0178ZM22.1803 29.5828H20.843V23.8376H22.0483C23.8728 23.8376 24.6663 25.0618 24.6663 26.751C24.6663 28.3319 23.8868 29.5828 22.1803 29.5828ZM14.144 22.0178H11.9707C11.3563 22.0178 11.0137 22.4232 11.0137 23.0393V30.4077C11.0137 31.0645 11.4065 31.4291 11.9345 31.4291C12.4624 31.4291 12.8552 31.0645 12.8552 30.4077V28.2563H14.2176C15.8991 28.2563 17.2867 27.0652 17.2867 25.1496C17.2868 23.275 15.948 22.0178 14.144 22.0178ZM14.1079 26.5279H12.8554V23.748H14.1079C14.881 23.748 15.3729 24.3514 15.3729 25.1387C15.3713 25.9245 14.881 26.5279 14.1079 26.5279Z" fill="white" />
        </svg>
    )
}