import React, { PureComponent } from "react";
import { Menu, Dropdown, message } from "antd";
import { More, Close } from "../../icons";
import { get } from "lodash";
import ReactModal from "react-modal";

import "./styles.scss";

const menu = (
  <Menu>
    <Menu.Item key="1">Archive</Menu.Item>
    <Menu.Item key="2" danger>
      Delete
    </Menu.Item>
  </Menu>
);

export default class ActionMoreComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      deleteModalForProperty: false,
      propertyId: "",
    };
  }

  closeModal = () => {
    this.setState({ deleteModal: false, deleteModalForProperty: false });
  };

  onMore = (id) => {
    this.props.deleteAgent({ id });
    this.setState({ deleteModal: false });
  };

  render() {
    const AdminDashboardMenu = (
      <Menu>
        {get(this.props.data, "subscription.status", "") === "active" &&
          !get(this.props.data, "subscription.cancel_at_period_end", true) && (
            <Menu.Item key="1">
              <div
                onClick={() =>
                  this.props.cancelSubscriptions(
                    get(this.props.data, "_id", "")
                  )
                }
              >
                Cancel Subscription
              </div>
            </Menu.Item>
          )}
        <Menu.Item key="2" danger>
          <div
            onClick={() =>
              this.setState({
                deleteModal: true,
                deleteId: get(this.props.data, "_id", ""),
              })
            }
          >
            Delete Account
          </div>
        </Menu.Item>
      </Menu>
    );
    const LeadPageMenu = (
      <Menu>
        <Menu.Item key="1">
          <div
           onClick={() =>
            this.props.history.push(
              `/edit-social-link/site-editor/${get(
                this.props.data,
                "_id",
                ""
              )}`
            )
          }
          >
            Edit
          </div>
        </Menu.Item>
        {/* {this.props.activeTab !== "listing-draft" ? (
          <Menu.Item key="2">
            <div onClick={() => this.props.moveAsDraft(this.props.data)}>
              Move to draft
            </div>
          </Menu.Item>
        ) : (
          ""
        )} */}
      </Menu>
    );
    const ListingMenu = (
      <Menu>
        <Menu.Item key="1">
          <div
            onClick={() =>
              this.props.history.push(
                `/listings/edit-listing/general/${get(
                  this.props.data,
                  "_id",
                  ""
                )}`
              )
            }
          >
            Edit
          </div>
        </Menu.Item>
        <Menu.Item key="2" danger>
          <div
            onClick={() =>
              this.setState({
                deleteModalForProperty: true,
                propertyId: get(this.props.data, "_id", ""),
              })
            }
          >
            Delete
          </div>
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        {this.props.class === "admin-dash" ? (
          <>
            <Dropdown overlay={AdminDashboardMenu} placement="bottomRight">
              <a
                className="btn ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <More />
              </a>
            </Dropdown>
          </>
        ) : this.props.class === "my-listing" ? (
          <>
            <Dropdown overlay={ListingMenu} placement="bottomRight">
              <a
                className="btn ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <More />
              </a>
            </Dropdown>
          </>
        ) : this.props.class === "landing-page" ? (
          <>
            <Dropdown overlay={LeadPageMenu} placement="bottomRight">
              <a
                className="btn ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <More />
              </a>
            </Dropdown>
          </>
        ) : (
          <>
            <Dropdown overlay={menu} placement="bottomRight">
              <a
                className="btn ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <More />
              </a>
            </Dropdown>
          </>
        )}
        <ReactModal
          isOpen={this.state.deleteModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Delete</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to delete this? This cannot be undone.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={this.closeModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => this.onMore(this.state.deleteId)}
                className="btn btn__purple ms-2"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.deleteModalForProperty}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Delete</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to delete your listing? This will also
                delete any lead pages associated with this listing. This action
                cannot be undone.
                <br /> <br />
                If you don't want to lose any of your lead page data about this
                listing then move this to drafts.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={this.closeModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => {
                  this.props.deletePropertyById(this.state.propertyId);
                  this.setState({ deleteModalForProperty: false });
                }}
                className="btn btn__purple ms-2"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}
