import React, { PureComponent } from "react";
import Header from "../Header/component";
import Banner from "./Banner/component";
import Slider from "./Slider/component";
import EmiCalculator from "./EmiCalculator/component";
import PropertyInfo from "./PropertyInfo/component";
import ListingContact from "./ListingContact/component";
import ListingSlider from "./ListingSlider/component";
import SocialAtLeft from "../SocialAtLeft/component";
import { get, _, isEmpty } from "lodash";
import { Spin, message, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import $ from "jquery";
import "./styles.scss";
const success = (msg) => {
  message.success({ key: "updatable", content: msg });
};

const error = (err) => {
  message.error({ key: "updatable", content: err });
};
export default class ListingWebsiteComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneError: "",
      message: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      leadData: {},
      isDisabled: false,
      isLoading: false,
      leadSettingData: {},
      leadPageUserData: {},
      leadPageProperty: {},
      userData: {},
      term: "30",
      salesPrice: 0.0,
      downPaymentPercent: 20.0,
      downPaymentAmount: 0.0,
      interestRate: 4.0,
      downPaymentAmountError: "",
      mortageAmount: 0.0,
      mortagePayment: 0.0,
      homePrice: 0.0,
      totalAmount: 0,
      propertyFeature: false,
      buildingFeature: false,
      userData: {},
      allLeadPage: [],
      activePropertyTab: "sale",
      filterLeadPage: [],
      isSell: false,
      imagesSet: false,
      isRent: false,
      isCalled: false,
      countryCode: "+1",
    };
    this.showBuildingFeature = this.showBuildingFeature.bind(this);
    this.showPropertyFeature = this.showPropertyFeature.bind(this);
  }

  showBuildingFeature(value) {
    this.setState({
      buildingFeature: value,
    });
  }

  showPropertyFeature(value) {
    this.setState({
      propertyFeature: value,
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "firstName") {
      this.setState({ firstNameError: "" });
    }
    if (e.target.name === "lastName") {
      this.setState({ lastNameError: "" });
    }
    if (e.target.name === "email") {
      this.setState({ emailError: "" });
    }
    if (e.target.name === "phone") {
      this.setState({ phoneError: "" });
    }
    if (e.target.name === "salesPrice") {
      this.setState({ salesPrice: "" });
    }
  };

  propertyTabChange = (value) => {
    const { allLeadPage } = this.state;
    let propertyData = [];
    this.setState({ activePropertyTab: value }, () => {
      this.props.getAllLeadPage({
        type: value,
        id: get(this.props, "user.user._id", ""),
        listingId: get(this.props.match, "params.id", ""),
      });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, phone, message } = this.state;
    let error = true;
    let validateEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    this.setState({
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneError: "",
    });
    if (firstName === "") {
      error = false;
      this.setState({ firstNameError: "Please enter the first name" });
    }
    if (lastName === "") {
      error = false;
      this.setState({ lastNameError: "Please enter the last name" });
    }
    if (email === "") {
      error = false;
      this.setState({ emailError: "Please enter the email" });
    } else if (!validateEmail.test(email)) {
      error = false;
      this.setState({ emailError: "Please enter the valid email" });
    }
    if (phone === "") {
      error = false;
      this.setState({ phoneError: "Please enter the phone number" });
    } else if (phone.length <= 9) {
      error = false;
      this.setState({ phoneError: "Please enter the valid phone number" });
    }
    if (error) {
      this.setState({ isLoading: true, isDisabled: true });
      let singleAddress = get(this.state.leadPageProperty, "address", "");
      let units =
        get(this.state.leadPageProperty, "units", "") !== ""
          ? ", " + get(this.state.leadPageProperty, "units", "")
          : "";
      let newAddress = singleAddress + units;
      let code =
        this.state.countryCode === "+1"
          ? this.state.countryCode
          : "+" + this.state.countryCode;
      let phonNumber = code + " " + phone;
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phonNumber,
        message: message,
        userId: get(this.state.leadPageUserData, "_id", ""),
        campaignId: get(this.state.leadData, "_id", "")
          ? get(this.state.leadData, "_id", "")
          : get(this.state.leadData, "_id", ""),
        address: newAddress,
        campaignType: get(this.state.leadData, "campaignType", ""),
        campaignName: get(this.state.leadData, "campaignName", ""),
        websiteUrl: `/listing-website/${get(
          this.state.leadData,
          "slugUrl",
          ""
        )}/${get(this.state.leadData, "_id", "")}`,
        isFrom: "contact_us",
      };
      this.props.addInquiry(data);
    }
  };

  componentDidMount() {
    if (isEmpty(this.props.leadPageByIdData)) {
      this.props.getLeadPageById({
        id: get(this.props.match, "params.id", ""),
      });
    } else {
      this.setState({
        leadData: this.props.leadPageByIdData,
        leadSettingData: this.props.leadPageSetting,
        leadPageUserData: this.props.leadPageUserData,
        leadPageProperty: this.props.leadPageProperty,
      });
    }
    if (localStorage.getItem("Authorization")) {
      this.props.getUser();
    }
    let timeInterval = setInterval(() => {
      const id = get(this.props, "user.user._id", "");
      if (id === "") {
        this.props.getAllLeadPage({
          type: "sale",
          id: id,
          listingId: get(this.props.match, "params.id", ""),
        });
      } else {
        this.props.getAllLeadPage({
          type: "sale",
          id: id,
          listingId: get(this.props.match, "params.id", ""),
        });
      }
      clearInterval(timeInterval);
    }, 5000);
  }

  changeListing = (e, slugUrl) => {
    if (
      get(this.props, "location.pathname", "").split("/").indexOf("preview") !==
      -1
    ) {
      window.open(`/preview/listing-website/${slugUrl}/${e}`);
    } else {
      window.open(`/listing-website/${slugUrl}/${e}`);
    }
  };

  handleChangeCountryCode = (value, phone) => {
    this.setState(
      { countryCode: value, phone: phone.replace(value, ""), phoneError: "" },
      () => {
        this.forceUpdate();
      }
    );
  };

  openNotificationWithIcon = (type) => {
    notification[type]({
      key: "updatable",
      className: "cm-notification",
      description:
        "Thank you for your interest in this property. Someone from our team will reach out to you shortly.",
    });
  };

  componentDidUpdate(prev) {
    // document.title = 'Listing Website | Yardpost'
    let tab = this.props.location.pathname;
    if (!this.state.imagesSet) {
      let self = this;
      $(".checkImageWidth").each(function () {
        self.setState({ imagesSet: true }, () => {
          var $this = $(this);
          if ($this.prop("naturalWidth") > $this.prop("naturalHeight")) {
            // if ($this.width() > 767) {
            $this.removeClass("vertical");
            $this.addClass("horizontal");
            // console.log("calss is horizontal")
          } else {
            $this.addClass("vertical");
            $this.removeClass("horizontal");
            // console.log("calss is vertical")
          }
        });
      });
    }
    const tabArr = tab.split("/").indexOf("preview");
    if (this.props.leadPageByIdPhase === "success") {
      this.props.initLeadPage();
      this.calulateDownpayment(this.props.leadPageProperty);
      this.setState(
        {
          leadData: this.props.leadPageByIdData,
          leadSettingData: this.props.leadPageSetting,
          leadPageUserData: this.props.leadPageUserData,
          leadPageProperty: this.props.leadPageProperty,
        },
        () => {
          let companyName =
            get(this.props.leadPageUserData, "companyName", "Yardpost") !== ""
              ? get(this.props.leadPageUserData, "companyName", "Yardpost")
              : "Yardpost";
          let title =
            get(
              this.props.leadPageByIdData,
              "seo.metaTitle",
              "Listing Website"
            ) !== ""
              ? get(
                  this.props.leadPageByIdData,
                  "seo.metaTitle",
                  "Listing Website"
                )
              : "Listing Website";
          document.title = title + " | " + companyName;
          document.getElementById("favicon").href = get(
            this.props.leadPageSetting,
            "favicon",
            "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png"
          );
          if (
            get(this.state, "leadData.status", "") === "active" &&
            get(this.state, "leadPageProperty.status", "") === "active"
          ) {
          } else {
            if (
              !tabArr === 1 ||
              get(this.state, "leadPageProperty.status", "") !== "active"
            ) {
              document.getElementById("favicon").href = get(
                this.props.leadPageSetting,
                "favicon",
                "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png"
              );
              this.props.history.push("/404");
            }
          }
        }
      );
    } else if (this.props.leadPageByIdPhase === "error") {
      this.props.history.push("/404");
    }
    if (this.props.userPhase === "success") {
      this.props.resetLogin();
      this.props.resetLoginUser();
      this.setState(
        { userData: this.props.user.user, information: true },
        () => {
          this.forceUpdate();
        }
      );
    }
    if (this.props.getAllLeadPagePhase === "success") {
      let propertyData = [];
      this.props.initLeadPage();
      let listingPromise = this.props.getAllLeadPageData.map((listing, i) => {
        if (get(this.props.match, "params.id", "") !== listing._id) {
          propertyData.push(listing);
        }
      });
      Promise.all(listingPromise);
      if (!this.props.isSell && !this.state.isCalled) {
        this.setState({ isCalled: true });
        this.props.getAllLeadPage({
          type: "rent",
          id: get(this.props, "user.user._id", ""),
          listingId: get(this.props.match, "params.id", ""),
        });
      }
      this.setState(
        {
          allLeadPage: propertyData,
          isSell: this.props.isSell,
          isRent: this.props.isRent,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
    if (this.props.addInquiryPhase == true) {
      this.props.initLeadPage();
      this.openNotificationWithIcon("success");
      this.setState({
        isDisabled: false,
        disabled: false,
        isLoading: false,
        message: "",
        phone: "",
        email: "",
        firstName: "",
        lastName: "",
        countryCode: "",
      });
    }
  }

  handleBlur = (e) => {};

  mortageAmount = () => {
    const {
      getPropertyDetailData,
      downPaymentAmount,
      interestRate,
      term,
      salesPrice,
    } = this.state;
    let downPayment = downPaymentAmount;
    let sales = salesPrice;
    let totalAmount =
      parseFloat(get(getPropertyDetailData, "propertyTaxes", 0)) +
      parseFloat(
        get(getPropertyDetailData, "hoaFee", "") !== ""
          ? getPropertyDetailData.hoaFee
          : 0
      );
    //if(downPayment.indexOf(',') !== -1){
    if (downPayment != "") {
      downPayment = downPayment.replace(/,/g, "");
    } else {
      downPayment = "0";
    }
    //}
    if (salesPrice != "") {
      sales = salesPrice.replace(/,/g, "");
    }
    const finalPrice = parseFloat(sales) - parseFloat(downPayment);
    const percentageRate = parseFloat(interestRate) / 1200;
    const lengthOfLoan = 12 * parseInt(term);
    let monthlyPayment = "";
    if (interestRate != "" || interestRate != 0) {
      monthlyPayment =
        (finalPrice * percentageRate) /
        (1 - Math.pow(1 + percentageRate, lengthOfLoan * -1));
    } else {
      monthlyPayment = finalPrice / lengthOfLoan;
    }
    monthlyPayment = monthlyPayment;
    this.setState({
      mortageAmount: monthlyPayment * lengthOfLoan,
      mortagePayment: monthlyPayment.toFixed(2),
      totalAmount: totalAmount + monthlyPayment,
    });
  };

  handleInputChange = (e) => {
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value }, () => {
      this.mortageAmount();
    });
    if (name === "term") {
      if (value === "30") {
        let newInterest = 4;
        this.setState({ interestRate: newInterest });
      }
      if (value === "20") {
        let newInterest = 4;
        this.setState({ interestRate: newInterest });
      } else if (value === "15") {
        let newInterest = 4;
        this.setState({ interestRate: newInterest });
      } else if (value === "10") {
        let newInterest = 4;
        this.setState({ interestRate: newInterest });
      }
      this.mortageAmount();
    }
    if (name === "salesPrice") {
      if (value === "") {
        this.setState({ downPaymentPercent: 0 });
      } else {
        const amount = parseFloat(value.replace(/,/g, ""));
        var downpayment = this.state.downPaymentAmount
          ? parseFloat(this.state.downPaymentAmount.replace(/,/g, ""))
          : 0;
        const newPercentage = (downpayment * 100) / amount;
        this.setState({
          downPaymentPercent: newPercentage ? newPercentage : 0,
        });
      }
    }
    if (name === "downPaymentAmount") {
      if (value === "") {
        this.setState({ downPaymentPercent: 0 });
      } else {
        const downPayment = parseFloat(value.replace(/,/g, ""));
        var amount = this.state.salesPrice
          ? parseFloat(this.state.salesPrice.replace(/,/g, ""))
          : 0;
        const newPercentage = (downPayment * 100) / amount;
        this.setState({
          downPaymentPercent: newPercentage ? newPercentage : 0,
        });
      }
    }
    if (name === "downPaymentPercent") {
      if (value === "") {
        this.setState({ downPaymentAmount: "0" });
      } else {
        const downPercentage = parseFloat(value.replace(/,/g, ""));
        var amount = this.state.salesPrice
          ? parseFloat(this.state.salesPrice.replace(/,/g, ""))
          : 0;
        const newdownPayAmount = (downPercentage / 100) * amount;
        this.setState({ downPaymentAmount: newdownPayAmount.toLocaleString() });
      }
    }
  };

  calulateDownpayment = (data) => {
    let defaultDownPAyment = (get(data, "askingPrice", 0) * 20) / 100;
    // let minDownPayment = get(data,'minDownPayment', defaultDownPAyment)
    let minDownPayment = defaultDownPAyment;
    let minDownPaymentPercent =
      (minDownPayment * 100) / get(data, "askingPrice", 0);
    let askingPrice = get(data, "askingPrice", 0);
    //let homePrice = get(data, 'askingPrice', 0)
    let newminDownPayment = minDownPayment
      ? parseInt(minDownPayment).toLocaleString()
      : "0";
    this.setState(
      {
        downPaymentAmount: newminDownPayment,
        downPaymentPercent: minDownPaymentPercent,
        salesPrice: askingPrice,
      },
      () => {
        this.mortageAmount();
      }
    );
  };

  mortageAmt = () => {
    let term = this.state.term ? parseFloat(this.state.term) : 0;
    let apr =
      this.state.interestRate != "" ? parseFloat(this.state.interestRate) : 0;

    let downPayment = this.state.downPaymentAmount
      ? parseFloat(this.state.downPaymentAmount.replace(/,/g, ""))
      : 0;
    let amt = this.state.salesPrice
      ? parseFloat(this.state.salesPrice.replace(/,/g, "")) -
        parseFloat(downPayment)
      : 0;
    apr /= 1200;
    term *= 12;
    let payment = 0;
    if (apr == 0) {
      payment = amt;
    } else {
      payment = amt;
      //  payment = (amt*(apr * Math.pow((1 + apr), term))/(Math.pow((1 + apr), term) - 1))*term
    }
    return payment.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  perMonth = () => {
    let term = this.state.term ? parseFloat(this.state.term) : 0;
    let apr =
      this.state.interestRate != "" ? parseFloat(this.state.interestRate) : 0;

    let downPayment = this.state.downPaymentAmount
      ? parseFloat(this.state.downPaymentAmount.replace(/,/g, ""))
      : 0;
    let amt = this.state.salesPrice
      ? parseFloat(this.state.salesPrice.replace(/,/g, "")) -
        parseFloat(downPayment)
      : 0;
    apr /= 1200;
    term *= 12;

    const getPropertyDetailData = this.state.getPropertyDetailData;
    let hoaCharges = !get(getPropertyDetailData, "hoaFee", 0)
      ? 0
      : parseFloat(getPropertyDetailData.hoaFee.replace(/,/g, ""));
    let taxes = !get(getPropertyDetailData, "propertyTaxes", 0)
      ? 0
      : parseFloat(getPropertyDetailData.propertyTaxes.replace(/,/g, ""));
    let payment = 0;
    if (apr == 0) {
      payment = amt / term + hoaCharges + taxes;
    } else {
      payment =
        (amt * (apr * Math.pow(1 + apr, term))) /
          (Math.pow(1 + apr, term) - 1) +
        hoaCharges +
        taxes;
    }
    return payment.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  render() {
    const { leadData, leadPageProperty, leadPageUserData } = this.state;
    return isEmpty(leadData) ? (
      <Spin
        size="large"
        indicator={
          <LoadingOutlined style={{ fontSize: 30, color: "#000" }} spin />
        }
        className="loader__full"
      />
    ) : (
      <>
        <main className="listing__website--main">
          <Header {...this.props} {...this.state} />
          <Banner {...this.props} {...this.state} />
          <Slider {...this.props} {...this.state} />
          <PropertyInfo
            showBuildingFeature={this.showBuildingFeature}
            showPropertyFeature={this.showPropertyFeature}
            {...this.props}
            {...this.state}
          />
          {get(leadPageProperty, "type", "") !== "rental" && (
            <>
              <EmiCalculator
                handleBlur={this.handleBlur}
                mortageAmt={this.mortageAmt}
                handleInputChange={this.handleInputChange}
                perMonth={this.perMonth}
                {...this.props}
                {...this.state}
              />
            </>
          )}
          <ListingContact
            handleChangeCountryCode={this.handleChangeCountryCode}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            {...this.props}
            {...this.state}
          />
          {/*(this.state.isSell || this.state.isRent) &&
            <ListingSlider changeListing={this.changeListing} paramId={get(this.props.match, "params.id", "")} propertyTabChange={this.propertyTabChange} {...this.props} {...this.state} />
          */}
          <SocialAtLeft {...this.props} {...this.state} />
        </main>
      </>
    );
  }
}
