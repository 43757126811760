import { adminFetch as fetch } from "../../utils";
import { get } from "lodash";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const updateGrandfatherStatus = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/updateGrandfatherStatus`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAdminAgent = (credentials) => {
  // console.log(credentials,'credentials')
  return fetch(
    `${HOSTNAME}/api/v1/admin/agents?sortKey=${get(
      credentials,
      "sortKey",
      "createdAt"
    )}&sortType=${get(credentials, "sortType", "desc")}&activeTab=${get(
      credentials,
      "activeTab",
      "active"
    )}&page=${get(credentials, "page", 1)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(id)
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addPlivoNumber = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/addPilvoNumber`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const cancelSub = (credentials) => {
  return fetch(
    `${HOSTNAME}/api/v1/admin/cancel-subscription?id=${credentials}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteAgent = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/admin/user`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const userImpersonate = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/admin/user-impersonate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
