import React, { PureComponent } from "react";
import Dropzone from "react-dropzone";
import { Close, Plus, Remove } from "../../../components/icons";
import RadioButtonGroup from "../../../components/ui/RadioButtonGroup/component";
import imageCompression from "browser-image-compression";
import { Formik } from "formik";
import { get, size, isEmpty } from "lodash";
import { Spin, notification, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./styles.scss";
import RadioGroupInput from "../../../components/ui/RadioGroupInput/component";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default class LeadPageSettingsComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      imageError: "",
      previewArr: [],
      data: "",
      loader: false,
      dataError: false,
      imageErrorFavicon: "",
      previewArrFavicon: [],
      loaderFavicon: false,
      showMlsLogo: true,
      showHousingLogo: true,
      leadPageSettingData: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    const userId = get(user, "user._id", "");
    this.props.getLeadPageSettingId(userId);
  }

  componentDidUpdate(prev) {
    const { previewArr, previewArrFavicon } = this.state;
    document.title = "Settings | Yardpost";
    if (this.props.addLeadPageSettingPhase === "success") {
      this.props.resetLeadPageSetting();
      this.setState({ isSpinLoading: false });
      this.props.history.push("/landing-pages");
      this.openNotificationWithIcon(
        "success",
        "Settings updated successfully."
      );
    } else if (this.props.addLeadPageSettingPhase === "error") {
      this.props.resetLeadPageSetting();
      this.setState({ isSpinLoading: false });
      this.openNotificationWithIcon("error", "Something Went Wrong!");
    }
    if (this.props.getLeadPageSettingIdPhase === "success") {
      this.props.resetLeadPageSetting();
      console.log("reset lead page setting", this.props.resetLeadPageSetting());
      this.setState(
        {
          leadPageSettingData: this.props.getLeadPageSettingIdData,
          showMlsLogo: get(
            this.props.getLeadPageSettingIdData,
            "showMlsLogo",
            true
          ),
          showHousingLogo: get(
            this.props.getLeadPageSettingIdData,
            "showHousingLogo",
            true
          ),
        },
        () => {
          previewArr.push({
            preview: get(this.state.leadPageSettingData, "logo", ""),
          });
          previewArrFavicon.push({
            preview: get(this.state.leadPageSettingData, "favicon", ""),
          });
          this.forceUpdate();
        }
      );
    } else if (this.props.getLeadPageSettingIdPhase === "error") {
      this.props.resetLeadPageSetting();
      this.openNotificationWithIcon("error", "Something Went Wrong!");
    }
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: message,
    });
  };

  addFavicon = async (acceptedFiles, images, setFieldValue, errors) => {
    const { previewArrFavicon } = this.state;
    console.log(previewArrFavicon, "ss");
    this.setState({ imageErrorFavicon: "", loaderFavicon: true });
    acceptedFiles.map(async (data, key) => {
      if (data.type.split("/")[0] === "image") {
        if (this.bytesToSize(data.size) <= 10) {
          acceptedFiles[key].preview = URL.createObjectURL(data);
          const fileSize = (this.bytesToSize(data.size) * 80) / 100;
          const newFle = await imageCompression(data, { maxSizeMB: fileSize });
          const file = new File([newFle], "favicon");
          previewArrFavicon.push(data);
          this.setState({ previewArrFavicon, loaderFavicon: false });
          this.forceUpdate();
        } else {
          const imageErrorFavicon = "imageize";
          this.setState({ imageErrorFavicon, loaderFavicon: false });
          this.forceUpdate();
        }
      } else {
        const imageErrorFavicon = "imageType";
        errors.imageFavicon = "";
        this.setState({ imageErrorFavicon, errors, loaderFavicon: false });
        this.forceUpdate();
      }
    });
    setFieldValue("favicon", previewArrFavicon);
    this.forceUpdate();
    return errors;
  };

  bytesToSize = (bytes) => bytes / (1024 * 1024);

  removeImage = (index, setFieldValue) => {
    console.log(this.state.previewArr, "re");
    const files = this.state.previewArr;
    files.splice(index, 1);
    setFieldValue("logo", "");
    this.setState({ previewArr: [] }, () => {
      this.forceUpdate();
    });
  };

  removeFavicon = (index, setFieldValue) => {
    console.log(this.state.previewArrFavicon, "previewArrFavicon");
    const files = this.state.previewArrFavicon;
    files.splice(index, 1);
    setFieldValue("favicon", "");
    this.setState({ previewArrFavicon: [] }, () => {
      this.forceUpdate();
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  handelShowMlsLogo = (value) => {
    this.setState({ showMlsLogo: value });
  };

  handelShowHousingLogo = (value) => {
    this.setState({ showHousingLogo: value });
  };

  validateForm = (values) => {
    console.log("ooooooooo", values);
    const errors = {};
    const regex = new RegExp(
      "^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$"
    );
    if (!regex.test(values.facebookUrl) && values.facebookUrl !== "") {
      errors.facebookUrl = "Please enter valid Facebook URL";
    }
    if (!regex.test(values.instagramUrl) && values.instagramUrl !== "") {
      errors.instagramUrl = "Please enter valid Instagram URL";
    }
    if (!regex.test(values.youtubeUrl) && values.youtubeUrl !== "") {
      errors.youtubeUrl = "Please enter valid Youtube URL";
    }
    if (!regex.test(values.twitterUrl) && values.twitterUrl !== "") {
      errors.twitterUrl = "Please enter valid Twitter URL";
    }
    if (!regex.test(values.linkdinUrl) && values.linkdinUrl !== "") {
      errors.linkdinUrl = "Please enter valid Linkdin URL";
    }
    if (!regex.test(values.whatsupUrl) && values.whatsupUrl !== "") {
      errors.whatsupUrl = "Please enter valid Whatsapp URL";
    }
    if (!regex.test(values.tiktokUrl) && values.tiktokUrl !== "") {
      errors.tiktokUrl = "Please enter valid Tiktok URL";
    }
    return errors;
  };

  render() {
    const { imageError, imageErrorFavicon, leadPageSettingData } = this.state;
    const initialFormValues = {
      facebookUrl: get(leadPageSettingData, "facebookUrl", ""),
      instagramUrl: get(leadPageSettingData, "instagramUrl", ""),
      youtubeUrl: get(leadPageSettingData, "youtubeUrl", ""),
      twitterUrl: get(leadPageSettingData, "twitterUrl", ""),
      linkdinUrl: get(leadPageSettingData, "linkdinUrl", ""),
      whatsupUrl: get(leadPageSettingData, "whatsupUrl", ""),
      tiktokUrl: get(leadPageSettingData, "tiktokUrl", ""),
      // logo: get(leadPageSettingData, "logo", ""),
      favicon:
        get(leadPageSettingData, "favicon", "") !== null &&
        get(leadPageSettingData, "favicon", "") !== undefined &&
        get(leadPageSettingData, "favicon", "") !== ""
          ? get(leadPageSettingData, "favicon", "")
          : "",
      showMlsLogo: get(leadPageSettingData, "showMlsLogo", true),
      showHousingLogo: get(leadPageSettingData, "showHousingLogo", true),
    };
    const showMlsLogo = [
      { key: "Show", value: true },
      { key: "Hide", value: false },
    ];
    const showHousingLogo = [
      { key: "show", value: true },
      { key: "hide", value: false },
    ];
    const initialValues = initialFormValues;
    return (
      <>
        <main className="main" role="head">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validate={this.validateForm.bind(this)}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(async() => {
                setSubmitting(false);
                this.setState({ errors: {} });
                // values.userId = get(this.props.user, "user._id", "");
                values.showMlsLogo = this.state.showMlsLogo;
                values.showHousingLogo = this.state.showHousingLogo;
                // values.logo= this.
                let form_data = new FormData();
                for (let key in values) {
                  // if (key == "logo") {
                  //   const file = this.state.previewArr[0];
                  //   // this.state.previewArr.map(async(file, key) => {
                  //     if (get(file, "preview", "").includes("amazon") || get(file, "preview", "").includes("cloudinary")) {
                  //       form_data.append("logo", file.preview);
                  //     } else if(file) {
                  //       const {value} = await this.props.uploadImages(file)
                  //       form_data.append("logo", value);
                  //     }else{
                  //       form_data.append("logo", "");
                  //     }
                  //   // });
                  // } else 
                  if (key == "favicon") {
                    const file = this.state.previewArrFavicon[0];
                    // this.state.previewArrFavicon.map(async(file, key) => {
                      if (get(file, "preview", "").includes("amazon") || get(file, "preview", "").includes("cloudinary")) {
                        form_data.append("favicon", file.preview);
                      } else if(file) {
                        const {value} = await this.props.uploadImages(file)
                        form_data.append("favicon", value);
                      }else{
                        form_data.append("favicon", "");
                      }
                    // });
                  } else {
                    console.log(values[key], key);
                    form_data.append(key, values[key]);
                  }
                }
                if (!this.state.dataError) {
                  this.setState({ isSpinLoading: true });
                  this.props.addLeadPageSetting(form_data);
                  this.props.editUser(form_data);
                }
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              getValues,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="main__header">
                  <header className="head">
                    <div className="head__container">
                      <div className="head__row">
                        <div className="head__left">
                          <div className="head__close">
                            <button
                              type="button"
                              onClick={() =>
                                this.props.history.push("/landing-pages")
                              }
                              className="btn btn__close"
                            >
                              <Close />
                            </button>
                          </div>
                          <div className="head__divider"></div>
                          <div className="head__title">
                            Settings
                          </div>
                        </div>
                        <div className="head__right">
                          <button
                            type="submit"
                            disabled={this.state.isSpinLoading}
                            className="btn btn__purple btn__head"
                          >
                            {this.state.isSpinLoading ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              <>Save & Exit</>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </header>
                </div>

                <div className="custom__container">
                  <div className="custom__wrap">
                    <div className="form-group">
                      <label className="label-primary">
                        Favicon<span className="required">*</span>
                      </label>
                      <Dropzone
                        disabled={this.state.previewArrFavicon.length >= 1}
                        onDrop={(acceptedFiles) =>
                          this.addFavicon(
                            acceptedFiles,
                            values.favicon,
                            setFieldValue,
                            errors
                          )
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone">
                            <div className="dropzone__area" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <button
                                type="button"
                                className="btn btn__purple"
                                disabled={
                                  size(values.favicon) === 0 ? false : true
                                }
                              >
                                {this.state.loaderFavicon ? (
                                  <Spin indicator={antIcon} />
                                ) : (
                                  <>
                                    <Plus className="btn__icon" /> Upload Photo
                                  </>
                                )}
                              </button>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      {errors.imageFavicon && imageErrorFavicon === "" && (
                        <div className="invalid-feedback">
                          {size(values.favicon) == 0 ? errors.imageFavicon : ""}
                        </div>
                      )}
                      {imageErrorFavicon == "imageize" && (
                        <>
                          <div className="invalid-feedback">
                            Please upload an image that in 10mb or smaller. You
                            can use the following compressors to make shrink
                            your images:
                          </div>
                          <div className="invalid-feedback">
                            JPEG or JPG: https://compressjpeg.com/
                          </div>
                          <div className="invalid-feedback">
                            PNG: https://compresspng.com/"
                          </div>
                        </>
                      )}
                      {imageErrorFavicon == "imageType" && (
                        <>
                          <div className="invalid-feedback">
                            File type should be image.
                          </div>
                        </>
                      )}
                      <div className="dropzone__grid">
                        {this.state.previewArrFavicon.length > 0 &&
                          this.state.previewArrFavicon.map((value, i) => {
                            return (
                              <div className="dropzone__grid--item">
                                <div className="dropzone__grid--preview">
                                  <img
                                    className="preview__thumb"
                                    src={value.preview}
                                    alt=""
                                  />
                                  <div className="dropzone__grid--remove">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.removeFavicon(i, setFieldValue)
                                      }
                                      className="btn btn__remove"
                                    >
                                      <Remove />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="label-primary">Facebook</label>
                      <input
                        type="text"
                        name="facebookUrl"
                        value={values.facebookUrl}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.facebookUrl && touched.facebookUrl && (
                        <div className="invalid-feedback">
                          {errors.facebookUrl}
                        </div>
                      )}
                      <p className="form-control-note">
                        Example: https://www.facebook.com/yardpost/
                      </p>
                    </div>
                    <div className="form-group">
                      <label className="label-primary">Instagram</label>
                      <input
                        type="text"
                        name="instagramUrl"
                        value={values.instagramUrl}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.instagramUrl && touched.instagramUrl && (
                        <div className="invalid-feedback">
                          {errors.instagramUrl}
                        </div>
                      )}
                      <p className="form-control-note">
                        Example: https://www.instagram.com/yardpost/
                      </p>
                    </div>
                    <div className="form-group">
                      <label className="label-primary">Youtube</label>
                      <input
                        type="text"
                        name="youtubeUrl"
                        value={values.youtubeUrl}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.youtubeUrl && touched.youtubeUrl && (
                        <div className="invalid-feedback">
                          {errors.youtubeUrl}
                        </div>
                      )}
                      <p className="form-control-note">
                        Example: https://www.youtube.com/yardpost/
                      </p>
                    </div>
                    <div className="form-group">
                      <label className="label-primary">Twitter</label>
                      <input
                        type="text"
                        name="twitterUrl"
                        value={values.twitterUrl}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.twitterUrl && touched.twitterUrl && (
                        <div className="invalid-feedback">
                          {errors.twitterUrl}
                        </div>
                      )}
                      <p className="form-control-note">
                        Example: https://www.twitter.com/yardpost/
                      </p>
                    </div>
                    <div className="form-group">
                      <label className="label-primary">Linkedin</label>
                      <input
                        type="text"
                        name="linkdinUrl"
                        value={values.linkdinUrl}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.linkdinUrl && touched.linkdinUrl && (
                        <div className="invalid-feedback">
                          {errors.linkdinUrl}
                        </div>
                      )}
                      <p className="form-control-note">
                        Example: https://www.linkedin.com/yardpost/
                      </p>
                    </div>
                    <div className="form-group">
                      <label className="label-primary">Whatsapp</label>
                      <input
                        type="text"
                        name="whatsupUrl"
                        value={values.whatsupUrl}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.whatsupUrl && touched.whatsupUrl && (
                        <div className="invalid-feedback">
                          {errors.whatsupUrl}
                        </div>
                      )}
                      <p className="form-control-note">
                        Example: https://www.whatsapp.com/yardpost/
                      </p>
                    </div>
                    <div className="form-group">
                      <label className="label-primary">TikTok</label>
                      <input
                        type="text"
                        name="tiktokUrl"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tiktokUrl}
                        className="form-control"
                        required
                      />
                      {errors.tiktokUrl && touched.tiktokUrl && (
                        <div className="invalid-feedback">
                          {errors.tiktokUrl}
                        </div>
                      )}
                      <p className="form-control-note">
                        Example: https://www.tiktok.com/yardpost/
                      </p>
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <label className="label-primary">
                          Show MLS logo in footer
                        </label>
                        <div className="ms-auto">
                          {showMlsLogo.map((data, i) => {
                            return (
                              <>
                                <RadioGroupInput
                                  value={data.value}
                                  setter={this.handelShowMlsLogo}
                                  checked={this.state.showMlsLogo}
                                  label={data.key}
                                />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <label className="label-primary">
                          Show equal housing logo in footer
                        </label>
                        <div className="ms-auto">
                          {showHousingLogo.map((data, i) => {
                            return (
                              <>
                                <RadioGroupInput
                                  value={data.value}
                                  setter={this.handelShowHousingLogo}
                                  checked={this.state.showHousingLogo}
                                  label={data.key}
                                />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      disabled={this.state.isSpinLoading}
                      className="btn btn-lg btn__purple w-100"
                    >
                      {this.state.isSpinLoading ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <>Save & Exit</>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </main>
      </>
    );
  }
}
