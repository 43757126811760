import React, { PureComponent } from "react";
import { Range } from "react-range";
import { get } from "lodash";

import "./styles.scss";
const rtl = false;
export default class RangePickerComponent extends PureComponent {
  state = { values: [0] };
  static propTypes = {
    // PropTypes go here
  };

  handleChangeSize = (val, name) => {
    this.props.handleChangeSize(val, this.props.name);
  };

  getTrackBackground = ({
    values,
    colors,
    min,
    max,
    direction = "to right",
    rtl = false,
  }) => {
    if (rtl && direction === "to left") {
      direction = "to right";
    } else if (rtl && direction === "to right") {
      direction = "to left";
    }
    if (values === undefined) {
      values =
        this.props.name === "mobileLogoSize"
          ? get(this.props.listingWebsiteTemplate, "mobileLogoSize", 50) !== ""
            ? [
                parseInt(
                  get(this.props.listingWebsiteTemplate, "mobileLogoSize", 50)
                ),
              ]
            : [50]
          : get(this.props.listingWebsiteTemplate, "desktopLogoSize", 50) !== ""
          ? [
              parseInt(
                get(this.props.listingWebsiteTemplate, "desktopLogoSize", 50)
              ),
            ]
          : [50];
    }
    // sort values ascending
    const progress = values
      .slice(0)
      .sort((a, b) => a - b)
      .map((value) => ((value - min) / (max - min)) * 100);
    const middle = progress.reduce(
      (acc, point, index) =>
        `${acc}, ${colors[index]} ${point}%, ${colors[index + 1]} ${point}%`,
      ""
    );
    return `linear-gradient(${direction}, ${colors[0]} 0%${middle}, ${
      colors[colors.length - 1]
    } 100%)`;
  };

  render() {
    const { listingWebsiteTemplate, values } = this.props;

    return (
      <>
        <div className="range__picker">
          <Range
            step={1}
            min={1}
            max={300}
            values={
              this.props.name === "mobileLogoSize"
                ? get(listingWebsiteTemplate, "mobileLogoSize", 50) !== ""
                  ? [
                      parseInt(
                        get(listingWebsiteTemplate, "mobileLogoSize", 50)
                      ),
                    ]
                  : this.state.values
                : get(listingWebsiteTemplate, "desktopLogoSize", 50) !== ""
                ? [parseInt(get(listingWebsiteTemplate, "desktopLogoSize", 50))]
                : [50]
            }
            onChange={(values) =>
              this.setState({ values }, () => {
                this.props.handleChangeSize(values[0], this.props.name);
              })
            }
            rtl={rtl}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "25px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "5px",
                    width: "100%",
                    borderRadius: "4px",
                    background: this.getTrackBackground({
                      values,
                      colors: ["#C800FF", "#D9DCE1"],
                      min: 1,
                      max: 300,
                      rtl,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "18px",
                  width: "18px",
                  borderRadius: "100px",
                  backgroundColor: "#C800FF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <div
                  style={{
                    height: "16px",
                    width: "5px",
                    backgroundColor: isDragged ? "#548BF4" : "#CCC",
                  }}
                /> */}
              </div>
            )}
          />
          <div className="range__picker--value">
            {this.props.name === "desktopLogoSize"
              ? get(listingWebsiteTemplate, "desktopLogoSize", 0) !== ""
                ? get(listingWebsiteTemplate, "desktopLogoSize", 0)
                : 0
              : get(listingWebsiteTemplate, "mobileLogoSize", 0) !== ""
              ? get(listingWebsiteTemplate, "mobileLogoSize", 0)
              : 0}
            px
          </div>
        </div>
      </>
    );
  }
}
