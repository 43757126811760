import id from "date-fns/locale/id";
import { userFetch as fetch } from "../../utils";
import publicIp from "public-ip";
import { get } from "lodash";
import request from "./request";
import ip from "ip";
const ipAddress = ip.address();
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
const CLOUD_NAME = process.env.REACT_APP_CLOUD_NAME;
const CLOUDINARY_API = process.env.REACT_APP_CLOUDINARY_API;

export const publishLeadPageById = (credentials) => {
  // console.log(credentials.id, "api call");

  return fetch(
    `${HOSTNAME}/api/v1/user/publish-lead-page-by-id?id=${credentials.id}&ip=${ipAddress}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(credentials)
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllLeadPage = (credentials) => {
  return fetch(
    `${HOSTNAME}/api/v1/user/lead-page-by-type?type=${get(
      credentials,
      "type",
      ""
    )}&id=${get(credentials, "id", "")}&listingId=${get(
      credentials,
      "listingId",
      ""
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLeads = (credentials) => {
  return fetch(
    `${HOSTNAME}/api/v1/user/lead?page=${parseInt(
      credentials.page
    )}&limit=${parseInt(get(credentials, "limit", "1"))}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLeadPages = (credentials) => {
  return fetch(
    `${HOSTNAME}/api/v1/user/leadPage?page=${parseInt(
      credentials.page
    )}&limit=${parseInt(get(credentials, "limit", "1"))}&activeTab=${get(
      credentials,
      "activeTab",
      "listing-active"
    )}&sortKey=${get(credentials, "sortKey", "updatedAt")}&sortType=${get(
      credentials,
      "sortType",
      "desc"
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// Upload Image by Form Data
export const uploadImages = async(file) => {
  // return fetch(`${HOSTNAME}/api/v1/upload-image`, {
  //   method: "POST",
  //   // headers: {
  //   //   'Content-Type': 'application/json'
  //   // },
  //   body: credentials,
  // })
  //   .then((res) => {
  //     return res.json();
  //   })
  //   .then((payload) => {
  //     return payload;
  //   })
  //   .catch((error) => {
  //     throw error;
  //   });
  const apiUrl = CLOUDINARY_API;
  const form = new FormData();
  form.append("file", file);
  form.append("upload_preset", "images");
  form.append("cloud_name", CLOUD_NAME);

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      body: form
    });

    if (response.ok) {
      const data = await response.json();
      return data.secure_url;
    } else {
      throw new Error("Image upload failed");
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

export const getLeadPageById = async (credentials) => {
  return fetch(
    `${HOSTNAME}/api/v1/user/lead-page-by-id?id=${credentials.id}&ip=${ipAddress}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const createLeadPage = (credentials) => {
  return request(
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(credentials),
      url: `${HOSTNAME}/api/v1/user/create-lead-page`,
    },
    get(credentials, "token", "")
  )
    .then((data) => data)
    .catch((error) => {
      throw error;
    });
};

export const addInquiry = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/create-lead`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addSocialInquiry = (credentials) => {
  // console.log(credentials, "credentials");
  return fetch(`${HOSTNAME}/api/v1/user/create-social-lead`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addNewsletterLead = (credentials) => {
  // console.log(credentials, "credentials");
  return fetch(`${HOSTNAME}/api/v1/user/create-newsletter-lead`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      // console.log("=====", payload);

      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const publishLeadPage = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/publish-lead-page`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAddressDetails = (payload) => {
  return fetch(`${HOSTNAME}/api/v1/home-address-details`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => {
      // console.log("then((res)....", res)
      return res.json();
    })
    .then((payload) => {
      // console.log("then((payload)....", payload)
      return payload;
    })
    .catch((error) => {
      // console.log("then((error)....", error)
      throw error;
    });
};

export const getListings = async (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/listings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSocialLeadPageExist = async (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/is-social-lead-page-exist`, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateShortUrl = async (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/update-short-url`, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
