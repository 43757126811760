import React, { PureComponent } from "react";
import ReactModal from "react-modal";
import { AmericanExpress, VisaCard } from "../../../../components/icons";
import { get, isEmpty } from "lodash";
import { Spin, notification, message } from "antd";
import InputMask from "react-text-mask";
import load from "load-stripe";
import { Formik } from "formik";
import Cookies from "universal-cookie";
import "./styles.scss";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
const cookies = new Cookies();
const stripeKey = process.env.REACT_APP_STRIPE_CLIENT_TEST_KEY;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

const cardNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const cardDate = [/\d/, /\d/, "/", /\d/, /\d/];

const securityCode = [/\d/, /\d/, /\d/, /\d/];

export default class BillingComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      isDataLoading: true,
      isLoading: false,
      modalIsOpenForCancel: false,
      modalIsOpenForUpgradePlan: false,
      paymentHistoryData: {},
      isReactiveModalOpen: false,
      isUpgradeDisable: false,
    };
    this.openModal = this.openModal.bind(this);
    this.openUpgradeModal = this.openUpgradeModal.bind(this);
    this.openCancelModal = this.openCancelModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openCancelModal() {
    this.setState({ modalIsOpenForCancel: true });
  }

  openUpgradeModal() {
    this.setState({ modalIsOpenForUpgradePlan: true });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      modalIsOpenForCancel: false,
      modalIsOpenForUpgradePlan: false,
      isReactiveLoading: false,
      isReactiveModalOpen: false,
    });
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: message,
    });
  };

  componentDidMount() {
    this.props.getPaymentHistory();
  }

  componentDidUpdate(prev) {
    if (this.props.updateCardPhase === "success") {
      this.props.resetLoginUser();
      this.props.getPaymentHistory();
      this.setState({ isLoading: false, modalIsOpen: false });
      this.openNotificationWithIcon("success", "Card updated successfully.");
    } else if (this.props.updateCardPhase === "error") {
      this.props.resetLoginUser();
      this.setState({ isLoading: false, modalIsOpen: false });
      this.openNotificationWithIcon("error", "Oop's, Something went wrong.");
    }
    if (this.props.cancelSubscriptionPhase === "success") {
      this.props.resetLoginUser();
      this.props.getPaymentHistory();
      this.setState({ isLoading: false, modalIsOpenForCancel: false });
      this.openNotificationWithIcon(
        "success",
        "Cancelled subscription successfully."
      );
    } else if (this.props.cancelSubscriptionPhase === "error") {
      this.props.resetLoginUser();
      this.setState({ isLoading: false, modalIsOpenForCancel: false });
      this.openNotificationWithIcon("error", "Oop's, Something went wrong.");
    }
    if (this.props.getPaymentHistoryPhase === "success") {
      this.props.resetLoginUser();
      this.setState(
        {
          paymentHistoryData: this.props.getPaymentHistoryData,
          isDataLoading: false,
        },
        () => {
          let data = get(
            this.state.paymentHistoryData,
            "paymentHistory",
            []
          ).find((p) => {
            return (
              p.amount === 99 ||
              p.amount === 349 ||
              p.realAmount === 99 ||
              p.realAmount === 349
            );
          });
          if (data) {
            this.setState({ isUpgradeDisable: true }, () => {
              this.forceUpdate();
            });
          }
        }
      );
    }
    if (this.props.updateSubscriptionPlanPhase === "success") {
      this.props.resetLoginUser();
      this.props.getPaymentHistory();
      this.setState({
        isLoading: false,
        modalIsOpenForUpgradePlan: false,
        upgradeSubscriptionLoader: false,
      });
      this.openNotificationWithIcon(
        "success",
        "Upgrade your plan successfully."
      );
    } else if (this.props.updateSubscriptionPlanPhase === "error") {
      this.props.resetLoginUser();
      this.setState({ isLoading: false, modalIsOpenForUpgradePlan: false });
      this.openNotificationWithIcon("error", "Oop's, Something went wrong.");
    }
    if (this.props.reactiveSubscriptionPhase === "success") {
      this.props.resetLoginUser();
      this.props.getPaymentHistory();
      this.setState({ isReactiveLoading: false });
      this.openNotificationWithIcon(
        "success",
        "Re-active your plan successfully."
      );
    }
  }

  updateSubscriptionPlan = (e) => {
    this.setState({ upgradeSubscriptionLoader: true });
    this.props.updateSubscriptionPlan({
      id: get(this.props.user, "user._id", ""),
    });
  };

  reactivePlan = () => {
    this.setState({ isReactiveLoading: true });
    this.props.reactiveSubscription({
      id: get(this.props.user, "user._id", ""),
    });
  };

  openReactiveModal = () => {
    this.setState({ isReactiveModalOpen: true });
  };

  async submitCard(value) {
    this.setState({ stripeError: {}, isLoading: true });
    let cardDate = value.cardDate.split("/");
    await load(stripeKey).then((stripe) => {
      stripe.card
        .createToken({
          number: value.cardNumber,
          cvc: value.securityCode,
          exp_month: cardDate[0],
          exp_year: cardDate[1],
        })
        .then(async (token) => {
          if (token) {
            let obj = {};
            obj.token = token.id;
            this.setState({ isLoading: true });
            this.props.updateCard(obj);
          }
        })
        .catch((err) => {
          let stripeError = {};
          switch (err.type) {
            case "card_error":
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "rate_limit_error":
              // Too many requests made to the API too quickly
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "invalid_request_error":
              // Invalid parameters were supplied to Stripe's API
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "api_error":
              // An error occurred internally with Stripe's API
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "api_connection_error":
              // Some kind of error occurred during the HTTPS communication
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            case "authentication_error":
              // You probably used an incorrect API key
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
            default:
              // Handle any other types of unexpected errors
              stripeError.type = get(err, "type", "ERROR");
              stripeError.code = get(err, "code", "ERROR");
              stripeError.message = get(err, "message", "Something Went Wrong");
              break;
          }
          this.setState({ stripeError, isLoading: false });
        });
    });
  }

  render() {
    const { stripeError, isDataLoading, paymentHistoryData } = this.state;
    return (
      <>
        {isDataLoading ? (
          <Spin
            spinning={this.state.searchLoading}
            className="loader__full"
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: "#000" }} spin />
            }
          />
        ) : (
          <>
            <div className="billing__header">
              <h4 className="billing__header--title">Payment method</h4>
            </div>
            <div className="payment__card">
              {!isEmpty(get(paymentHistoryData, "cardDetails", {})) ? (
                <>
                  <div className="payment__card--info">
                    {/*get(paymentHistoryData, "cardDetails.logoUrl", "") !==
                      "" ? (
                      <img
                        className="payment__card--card"
                        src={get(paymentHistoryData, "cardDetails.logoUrl", "")}
                        width={40}
                        height={24}
                      />
                    ) : (
                      <VisaCard className="payment__card--card" />
                    )*/}
                    <span className="payment__card--number">
                      {`${get(paymentHistoryData, "cardDetails.brand", "")}`}****
                      {`${get(paymentHistoryData, "cardDetails.last4", "")}`}
                    </span>
                  </div>
                  <div className="payment__card--update">
                    <button className="btn  btn__link" onClick={this.openModal}>
                      Change
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="blank__state">
                    <div className="blank__state--text"> No Payment method</div>
                  </div>
                  {/*<button className="btn  btn__link" onClick={this.openModal}>
                    Add Card
                  </button>*/}
                </>
              )}
            </div>
            {get(paymentHistoryData, "paymentHistory", []).length > 0 ? (
              <div className="billing__header">
                <div>
                  <h4 className="billing__header--title">Payments</h4>
                </div>
                <div>
                  {this.state.isUpgradeDisable &&
                  get(paymentHistoryData, "cancelled", false) ? (
                    ""
                  ) : (
                    <>
                      {!get(paymentHistoryData, "cancelled", false) && (
                        <button
                          className="btn  btn__link"
                          onClick={this.openUpgradeModal}
                        >
                          Upgrade
                        </button>
                      )}
                    </>
                  )}
                  {get(paymentHistoryData, "cancelled", "") && (
                    <button
                      className="btn  btn__link"
                      onClick={this.openReactiveModal}
                    >
                      {this.state.isReactiveLoading ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        "Re-Active Plan"
                      )}
                    </button>
                  )}
                  {!get(paymentHistoryData, "cancelled", "") ? (
                    <button
                      className="btn  btn__link"
                      onClick={this.openCancelModal}
                    >
                      Cancel Subscription
                    </button>
                  ) : (
                    <button className="btn  btn__link" disabled>
                      Cancelled Subscription
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="billing__header mb-3">
                  <div>
                    <h4 className="billing__header--title">Payments</h4>
                  </div>
                </div>
                <div className="blank__state">
                  <div className="blank__state--text">No Payment History</div>
                </div>
              </>
            )}
            {get(paymentHistoryData, "paymentHistory", []).length > 0 && (
              <div className="table-responsive table__responsive--custom">
                <table className="table table__custom">
                  <thead>
                    <tr key={1}>
                      <th className="text-start">Date </th>
                      <th className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {get(paymentHistoryData, "paymentHistory", []).map(
                      (data, i) => {
                        return (
                          <tr key={i}>
                            <td className="text-start">
                              {get(data, "start_date", "")}
                            </td>
                            <td className="text-end">
                              ${get(data, "amount", "")}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <ReactModal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              contentLabel="Otp"
              ariaHideApp={false}
              shouldCloseOnOverlayClick={false}
              shouldFocusAfterRender={true}
              portalClassName="react-modal"
              overlayClassName="modal"
              className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
            >
              <div className="modal-content">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">Edit Payment Method</h5>
                </div>
                <Formik
                  initialValues={{
                    cardNumber: "",
                    nameOnCard: "",
                    cardDate: "",
                    securityCode: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.cardNumber) {
                      errors.cardNumber = "Please enter card number";
                    } else if (values.cardNumber.length <= 16) {
                      errors.cardNumber = "Please enter valid card number";
                    } else if (values.cardNumber.length <= 16) {
                      errors.cardNumber = "Please enter valid card number";
                    }
                    if (!values.nameOnCard) {
                      errors.nameOnCard = "Please enter name";
                    }
                    if (!values.cardDate) {
                      errors.cardDate = "Please enter expiration date";
                    } else if (values.cardDate.includes("_")) {
                      errors.cardDate = "Please enter valid expiration date";
                    }
                    if (!values.securityCode) {
                      errors.securityCode = "Please enter security code";
                    } else if (
                      values.securityCode.length < 3 ||
                      values.securityCode.length > 4
                    ) {
                      errors.securityCode = "Please enter valid security code";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      this.submitCard(values);
                    }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="react-modal-body">
                        <div className="form-group">
                          <label className="label-primary">Name on card</label>
                          <input
                            type="text"
                            name="nameOnCard"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nameOnCard}
                            required
                          />
                          {errors.nameOnCard && (
                            <div className="invalid-feedback">
                              {errors.nameOnCard}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="label-primary">Card Number</label>
                          <InputMask
                            guide={false}
                            mask={cardNumberMask}
                            name="cardNumber"
                            className="form-control material-textfield-input"
                            onChange={(e) => {
                              handleChange(e);
                              this.setState({ stripeError: {} });
                            }}
                            onBlur={handleBlur}
                            value={values.cardNumber}
                            required
                          />
                          {errors.cardNumber && (
                            <div className="invalid-feedback">
                              {errors.cardNumber}
                            </div>
                          )}
                          {!isEmpty(stripeError) &&
                            stripeError.code === "incorrect_number" && (
                              <div>
                                <div className="invalid-feedback">
                                  {stripeError.message}
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label-primary">
                                Expiration Date (MM/YY)
                              </label>
                              <InputMask
                                guide={false}
                                mask={cardDate}
                                name="cardDate"
                                className="form-control material-textfield-input"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({ stripeError: {} });
                                }}
                                onBlur={handleBlur}
                                value={values.cardDate}
                                required
                              />
                              {errors.cardDate && (
                                <div className="invalid-feedback">
                                  {errors.cardDate}
                                </div>
                              )}
                              {!isEmpty(stripeError) &&
                                (stripeError.code === "invalid_expiry_year" ||
                                  stripeError.code ===
                                    "invalid_expiry_month") && (
                                  <div className="invalid-feedback">
                                    {stripeError.message}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label-primary">
                                Security Code (CVV)
                              </label>
                              <InputMask
                                guide={false}
                                mask={securityCode}
                                type="text"
                                name="securityCode"
                                className="form-control material-textfield-input"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({ stripeError: {} });
                                }}
                                onBlur={handleBlur}
                                value={values.securityCode}
                                required
                              />
                              {errors.securityCode && (
                                <div className="invalid-feedback">
                                  {errors.securityCode}
                                </div>
                              )}
                              {!isEmpty(stripeError) &&
                                stripeError.code === "invalid_cvc" && (
                                  <div className="invalid-feedback">
                                    {stripeError.message}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="react-modal-footer">
                        <button
                          type="button"
                          className="btn btn__default"
                          onClick={this.closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn__purple ms-2"
                          disabled={this.state.isLoading || isSubmitting}
                        >
                          {this.state.isLoading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            <>Update</>
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </ReactModal>
            <ReactModal
              isOpen={this.state.modalIsOpenForCancel}
              onRequestClose={this.closeModal}
              contentLabel="Otp"
              ariaHideApp={false}
              shouldCloseOnOverlayClick={false}
              shouldFocusAfterRender={true}
              portalClassName="react-modal"
              overlayClassName="modal"
              className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
            >
              <div className="modal-content">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">
                    Cancel Yardpost Subscription
                  </h5>
                </div>
                <div className="react-modal-body">
                  <div className="react-modal-body-text">
                    If you decide to cancel you will have access to your
                    Yardpost account till{" "}
                    {moment(
                      get(
                        this.props.user,
                        "user.subscription.current_period_end",
                        ""
                      ) * 1000
                    ).format("MMM DD, YYYY")}
                    . After that point you will no longer have access to
                    Yardpost and will have to sign up.
                  </div>
                </div>
                <div className="react-modal-footer">
                  <button
                    type="button"
                    className="btn btn__default"
                    onClick={this.closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      this.closeModal();
                      this.props.cancelSubscriptions("");
                    }}
                    className="btn btn__purple ms-2"
                  >
                    Cancel Subscription
                  </button>
                </div>
              </div>
            </ReactModal>

            <ReactModal
              isOpen={this.state.isReactiveModalOpen}
              onRequestClose={this.closeModal}
              contentLabel="Otp"
              ariaHideApp={false}
              shouldCloseOnOverlayClick={false}
              shouldFocusAfterRender={true}
              portalClassName="react-modal"
              overlayClassName="modal"
              className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
            >
              <div className="modal-content">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">
                    Reactive Yardpost Subscription
                  </h5>
                </div>
                <div className="react-modal-body">
                  <div className="react-modal-body-text">
                    This will reactivate your{" "}
                    {get(this.props.user, "user.subscription.interval", "")}{" "}
                    subscription from{" "}
                    {moment(
                      get(
                        this.props.user,
                        "user.subscription.current_period_end",
                        ""
                      ) * 1000
                    ).format("MMM DD, YYYY")}
                    . Are you sure you want to reactivate your subscription?
                  </div>
                </div>
                <div className="react-modal-footer">
                  <button
                    type="button"
                    className="btn btn__default"
                    onClick={this.closeModal}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      this.closeModal();
                      this.reactivePlan();
                    }}
                    className="btn btn__purple ms-2"
                  >
                    {this.state.isReactiveLoading ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      "Yes"
                    )}
                  </button>
                </div>
              </div>
            </ReactModal>

            <ReactModal
              isOpen={this.state.modalIsOpenForUpgradePlan}
              onRequestClose={this.closeModal}
              contentLabel="Upgrade Plan"
              ariaHideApp={false}
              shouldCloseOnOverlayClick={false}
              shouldFocusAfterRender={true}
              portalClassName="react-modal"
              overlayClassName="modal"
              className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
            >
              <div className="modal-content">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">Upgrade Plan</h5>
                </div>
                {get(paymentHistoryData, "switch_next_interval", "") !==
                "month" ? (
                  <div className="react-modal-body">
                    <div className="react-modal-body-text">
                      You are currently on a monthly plan. Your yearly
                      subscription will be $349 a year and will start on{" "}
                      {get(paymentHistoryData, "switch_next_billing_date", "")}.
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="react-modal-body">
                      <div className="react-modal-body-text">
                        You are currently on yearly plan, you cannot update to
                        monthly plan.
                      </div>
                    </div>
                    <div className="react-modal-footer">
                      <button
                        type="button"
                        className="btn btn__default"
                        onClick={this.closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}

                {get(paymentHistoryData, "switch_next_interval", "") !==
                  "month" && (
                  <div className="react-modal-footer">
                    <button
                      type="button"
                      className="btn btn__default"
                      onClick={this.closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn__purple ms-2"
                      onClick={this.updateSubscriptionPlan}
                    >
                      {this.state.upgradeSubscriptionLoader ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <> Change Billing Frequency </>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </ReactModal>
          </>
        )}
      </>
    );
  }
}
