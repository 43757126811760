import React, { PureComponent } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-text-mask";
import Footer from "../../Footer/component";
import { Link, animateScroll as scroll } from "react-scroll";
import { get } from "lodash";
import Placeholder from "../../../../images/placeholder.svg";
import "./styles.scss";
import { Spin } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export default class ListingContactComponent extends PureComponent {
  onClose = () => {
    this.setState({
      mobileDrawer: false,
    });
  };
  render() {
    const {
      leadData,
      leadPageProperty,
      leadPageUserData,
      firstNameError,
      lastNameError,
      emailError,
      phoneError,
    } = this.props;
    return (
      <>
        <section id="contact_us" className="contact__split--section">
          <div className="contact__split--row">
            <div className="contact__split--col">
              <div className="contact__split--content">
                <div className="broker__info">
                  <div className="broker__thumb--wrap">
                    <div className="broker__thumb">
                      {get(leadPageUserData, "profileImage", "") !== "" ? (
                        <img
                          src={get(leadPageUserData, "profileImage", "")}
                          alt=""
                        />
                      ) : (
                        <img src={Placeholder} alt="" />
                      )}
                      <div className="property-agent-info">
                        <h4>
                          {get(leadPageUserData, "firstName", "")}{" "}
                          {get(leadPageUserData, "lastName", "")}
                        </h4>
                        {get(leadPageUserData, "companyName", "") !== "" ? (
                          <p>
                            {get(leadPageUserData, "title", "")} at{" "}
                            {get(leadPageUserData, "companyName", "")}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="broker__thumb--layer-1"></div>
                    <div className="broker__thumb--layer-2"></div>
                  </div>
                  <div className="broker__more--info">
                    <div className="broker__more--label">
                      <span>EM:</span>{" "}
                      <a
                        id="mail"
                        className="link"
                        href={`mailto:${get(
                          leadPageUserData,
                          "email",
                          ""
                        )}?Subject=Hello`}
                        target="_top"
                      >
                        {get(leadPageUserData, "email", "")}
                      </a>
                    </div>
                    {get(leadPageUserData, "phone", "") && (
                      <div className="broker__more--label">
                        <span>PH:</span>{" "}
                        <a
                          id="phone"
                          className="link"
                          href={`tel:+${get(leadPageUserData, "phone", "")}`}
                        >
                          {get(leadPageUserData, "phone", "")}
                        </a>
                      </div>
                    )}
                    {get(leadPageUserData, "mlsMemberId", "") && (
                      <div className="broker__more--label">
                        <span>MLS Member ID:</span>{" "}
                        {get(leadPageUserData, "mlsMemberId", "")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="contact__split--col">
              <div className="contact__split--content">
                <div className="contact__split--card">
                  <h2 className="contact__split--title">
                    Interested in {get(leadPageProperty, "address", "")}
                    {get(leadPageProperty, "units", "") !== ""
                      ? `, ${get(leadPageProperty, "units", "")}`
                      : ""}{" "}
                    ?{" "}
                  </h2>
                  <p className="contact__split--subtitle">
                    Fill out the from below:
                  </p>
                  <form onSubmit={this.props.handleSubmit} noValidate>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group material-textfield">
                          <label className="label-secondary dark">
                            First Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            value={this.props.firstName}
                            onChange={this.props.handleChange}
                            className="form-control form-control-flat"
                            required
                          />
                          <div className="invalid-feedback">
                            {firstNameError}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group material-textfield">
                          <label className="label-secondary dark">
                            Last Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            value={this.props.lastName}
                            onChange={this.props.handleChange}
                            className="form-control form-control-flat"
                            required
                          />
                          <div className="invalid-feedback">
                            {lastNameError}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group material-textfield">
                          <label className="label-secondary dark">
                            Email Address <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="email"
                            value={this.props.email}
                            onChange={this.props.handleChange}
                            className="form-control form-control-flat"
                            required
                          />
                          <div className="invalid-feedback">{emailError}</div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group material-textfield">
                          <label className="label-secondary dark">
                            Phone Number <span className="required">*</span>
                          </label>
                          <PhoneInput
                            inputProps={{
                              readOnly: false,
                              name: "phone",
                              required: true,
                              // autoFocus: true,
                              className: "form-control form-control-flat",
                            }}
                            country={"us"}
                            value={this.props.countryCode || ""}
                            onChange={(phone, val) =>
                              this.props.handleChangeCountryCode(
                                val.dialCode,
                                phone
                              )
                            }
                          />
                        </div>
                        <div className="invalid-feedback">{phoneError}</div>
                      </div>
                    </div>
                    {/*<div className="row">
                     
                      <div className="col-lg-6">
                        <div className="form-group material-textfield">
                          <label className="label-secondary dark">
                            Phone Number <span className="required">*</span>
                          </label>
                          <InputMask
                              guide={false}
                              type="text"
                              id="phone"
                              keepCharPositions={false}
                              mask={phoneNumberMask}
                              className="form-control  form-control-flat"
                              name="phone"
                              onChange={this.props.handleChange}
                              value={this.props.phone}
                              required
                            />
                          <div className="invalid-feedback">{phoneError}</div>
                        </div>
                      </div>
                    </div>*/}
                    <div className="form-group material-textfield">
                      <label className="label-secondary dark">
                        Message (optional)
                      </label>
                      <textarea
                        type="text"
                        className="form-control form-control-flat"
                        name="message"
                        value={this.props.message}
                        onChange={this.props.handleChange}
                        required
                        rows="7"
                      ></textarea>
                    </div>

                    <button
                      className="btn btn__flat btn__flat--outline w-100"
                      disabled={this.props.isDisabled}
                    >
                      {this.props.isLoading ? (
                        <Spin size="small" />
                      ) : (
                        "Lets Talk"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer className="footer-dark" {...this.props} />
        </section>
      </>
    );
  }
}
