import React, { PureComponent } from "react";
import Header from "../Header/component";
import Footer from "../Footer/component";
import SocialAtLeft from "../SocialAtLeft/component";
import { Arrow, EqualHO, Realtor } from "../../../components/icons";
import { get, isEmpty } from "lodash";
import InputMask from "react-text-mask";
import "./styles.scss";
import Placeholder from "./../../../images/placeholder.svg";
import { Spin, message, notification } from "antd";
import { mapStyle } from "../../../constants/config";
import { LoadingOutlined } from "@ant-design/icons";
import { isThisISOWeek } from "date-fns/esm";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import ReactModal from "react-modal";
import SaleCalculator from "./SaleCalculator/component";

const google = window.google;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const success = (msg) => {
  message.success({ key: "updatable", content: msg });
};

const error = (err) => {
  message.error({ key: "updatable", content: err });
};
export default class SellDetailComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      leadData: {},
      leadSettingData: {},
      leadPageUserData: {},
      homeData: {},
      userId: "",
      isLoading: false,
      message: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      image: "",
      error: {},
      onOptionSelect: {},
      existProperty: {},
      unit: "",
      map: {},
      emailError: "",
      firstNameError: "",
      lastNameError: "",
      phoneError: "",
      address: "",
      homeData: {},
      error: false,
      map: {},
      modalIsOpen: true,
      countryCode: "",
      recaptcha: false,
      sellingConcession: 2,
      sellingConcessionValue: 0,
      homeImprovement: 0,
      ownershipCost: 1,
      ownershipCostValue: 0,
      transferTax: 1,
      transferTaxValue: 0,
      preWork: 2,
      preWorkValue: 0,
      realEstateAgentFees: 6,
      realEstateAgentFeesValue: 0,
      remainingMortgage: 0,
      remainingMortgageValue: 0,
      targetSellPrice: 200000,
      targetSellPriceValue: 0,
      estimationSellingCost: 0,
      netProcced: 0,
    };
    // this.onSubmit = this.onSubmit.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
  }

  componentDidMount() {
    if (isEmpty(this.props.leadPageByIdData)) {
      this.props.getLeadPageById({
        id: get(this.props.match, "params.id", ""),
      });
    } else {
      this.setState(
        {
          leadData: this.props.leadPageByIdData,
          leadSettingData: this.props.leadPageSetting,
          leadPageUserData: this.props.leadPageUserData,
        },
        () => {
          let title =
            get(
              this.props.leadPageByIdData,
              "seo.metaTitle",
              "Sell Details Page"
            ) !== ""
              ? get(this.props.leadPageByIdData, "seo.metaTitle", "Sell Page")
              : "Sell Details";
          let companyName =
            get(this.props.leadPageUserData, "companyName", "Yardpost") !== ""
              ? get(this.props.leadPageUserData, "companyName", "Yardpost")
              : "Yardpost";
          document.title = title + " | " + companyName;
          document.getElementById("favicon").href = get(
            this.props.leadPageSetting,
            "favicon",
            "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png"
          );
          this.forceUpdate();
        }
      );
    }
    if (get(this.props, "getAddressDetailsData[0]", "")) {
      this.setState(
        {
          homeData: this.props.getAddressDetailsData[0],
          recaptcha: false,
          targetSellPrice: parseInt(
            get(this.props, "getAddressDetailsData[0].avm.amount.value", 0)
          ),
        },
        () => {
          this.handleMap();
          // let realEstateAgentFeesV = (this.state.targetSellPrice * this.state.realEstateAgentFees) / 100
          // let preWorkV = (this.state.targetSellPrice * this.state.preWork) / 100
          // let transferTaxV = (this.state.targetSellPrice * this.state.transferTax) / 100
          // let ownershipCostV = (this.state.targetSellPrice * this.state.ownershipCost) / 100
          // let sellingConcessionV = (this.state.targetSellPrice * this.state.sellingConcession) / 100
          // this.setState({realEstateAgentFeesValue: realEstateAgentFeesV, preWorkValue: preWorkV, transferTaxValue: transferTaxV, ownershipCostValue: ownershipCostV, sellingConcessionValue: sellingConcessionV, transferTaxValue: transferTaxV},()=>{

          // })
          this.valueUpdate();
          this.forceUpdate();
        }
      );
    } else {
      if (
        get(this.props, "location.pathname", "")
          .split("/")
          .indexOf("preview") !== -1
      ) {
        this.props.history.push(
          `/preview/sell/${get(this.props.match, "params.id", "")}`
        );
      } else {
        this.props.history.push(
          `/sell/${get(this.props.match, "params.id", "")}`
        );
      }
    }
  }

  openNotificationWithIcon = (type) => {
    notification[type]({
      key: "updatable",
      className: "cm-notification",
      description:
        "Thank you for your interest in this property. Someone from our team will reach out to you shortly.",
    });
  };

  valueUpdate = () => {
    let realEstateAgentFeesV = (
      (this.state.targetSellPrice * this.state.realEstateAgentFees) /
      100
    ).toFixed(2);
    let preWorkV = (
      (this.state.targetSellPrice * this.state.preWork) /
      100
    ).toFixed(2);
    let transferTaxV = (
      (this.state.targetSellPrice * this.state.transferTax) /
      100
    ).toFixed(2);
    let ownershipCostV = (
      (this.state.targetSellPrice * this.state.ownershipCost) /
      100
    ).toFixed(2);
    let sellingConcessionV = (
      (this.state.targetSellPrice * this.state.sellingConcession) /
      100
    ).toFixed(2);
    let remaining =
      this.state.remainingMortgage !== "" &&
      this.state.remainingMortgage !== null &&
      this.state.remainingMortgage !== undefined
        ? parseFloat(this.state.remainingMortgage)
        : "";
    let homeImp =
      this.state.homeImprovement !== "" &&
      this.state.homeImprovement !== null &&
      this.state.homeImprovement !== undefined
        ? parseFloat(this.state.homeImprovement)
        : "";
    let estSellCost = parseFloat(realEstateAgentFeesV);
    estSellCost += parseFloat(preWorkV);
    estSellCost += parseFloat(transferTaxV);
    estSellCost += parseFloat(ownershipCostV);
    estSellCost += parseFloat(sellingConcessionV);
    estSellCost += parseFloat(homeImp);
    let net =
      parseFloat(this.state.targetSellPrice) -
      (remaining + parseFloat(estSellCost));
    this.setState(
      {
        remainingMortgage: remaining,
        netProcced: net,
        estimationSellingCost: estSellCost,
        realEstateAgentFeesValue: realEstateAgentFeesV,
        preWorkValue: preWorkV,
        transferTaxValue: transferTaxV,
        ownershipCostValue: ownershipCostV,
        sellingConcessionValue: sellingConcessionV,
        transferTaxValue: transferTaxV,
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  componentDidUpdate(prev) {
    document.title = "Sell Details";
    if (this.props.addInquiryPhase == true) {
      this.props.initLeadPage();
      this.openNotificationWithIcon("success");
      document.body.classList.remove("ReactModal__Body--open");
      this.setState(
        {
          isLoading: false,
          message: this.props.addInquiryMessage,
          phone: "",
          email: "",
          firstName: "",
          lastName: "",
          countryCode: "",
          recaptcha: false,
          modalIsOpen: false,
        },
        () => {
          this.forceUpdate();
        }
      );
    } else if (!this.props.addInquiryPhase) {
      this.setState(
        {
          isLoading: false,
          emailError: this.props.addInquiryMessage,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  camelCase = (str) => {
    return str
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function (b) {
        return b.toLowerCase();
      });
  };

  // onSubmit(event) {
  //   event.preventDefault();
  //   this.setState({
  //     lastNameError: "",
  //     firstNameError: "",
  //     emailError: "",
  //     phoneError: "",
  //   });
  //   let multiInput = {};
  //   let singleInput = {};
  //   let error = {};
  //   let formData = {};
  //   const userId = get(this.state.leadPageUserData, "_id", "");
  //   const campaignId = get(this.props.match, "params.id", "");
  //   let target = event.target;
  //   for (let i = 0; i < target.length; i++) {
  //     var name = target.elements[i].getAttribute("name");
  //     var fieldType = target.elements[i].getAttribute("fieldtype");
  //     if (name) {
  //       formData[this.camelCase(name)] = target.elements[i].value;
  //       if (target.elements[i].value === "") {
  //         let newname;
  //         if (name === "phone") {
  //           this.setState({
  //             phoneError: "Please Enter Phone Number",
  //           });
  //         }
  //         if (name === "email") {
  //           this.setState({
  //             emailError: "Please Enter Email",
  //           });
  //         }
  //         if (name === "firstName") {
  //           this.setState({
  //             firstNameError: "Please Enter First Name",
  //           });
  //         }
  //         if (name === "lastName") {
  //           this.setState({
  //             lastNameError: "Please Enter Last Name",
  //           });
  //         }
  //         error[name] = "Please Enter " + newname;
  //         // console.log(error,'error')
  //       }
  //     }
  //   }
  //   // if (this.state.phone === "") {
  //   //   this.setState({
  //   //     phoneError: "Please Enter Phone Number",
  //   //   });
  //   //   error["phone"] = "Please enter phone number";
  //   // } else if (this.state.phone.length <= 9) {
  //   //   this.setState({
  //   //     phoneError: "Please enter valid Number",
  //   //   });
  //   //   error["phone"] = "Please enter valid phone number";
  //   // }
  //   if(!this.state.recaptcha){
  //     this.setState({recaptchaError: "Please select re-captcha"})
  //   }
  //   this.setState({ error });
  //   let price = parseInt(
  //     get(this.state.homeData, "valuations.general.EMV", 0)
  //   ).toLocaleString("en-US");
  //   let valuationPrice = "$" + price;
  //   if (isEmpty(error)) {
  //     this.setState({ isLoading: true });
  //     formData.userId = userId;
  //     formData.campaignId = campaignId;
  //     formData.isFrom = "sell";
  //     formData.campaignName = get(
  //       this.props.leadPageByIdData,
  //       "campaignName",
  //       ""
  //     );
  //     formData.valuationPrice = valuationPrice.toString();
  //     formData.address = `${get(
  //       this.state.homeData,
  //       "address.street",
  //       ""
  //     )}${", "}${get(this.state.homeData, "address.city", "")}${", "}${get(
  //       this.state.homeData,
  //       "address.state",
  //       ""
  //     )}${", "}${get(this.state.homeData, "address.zip", "")}`;
  //     formData.websiteUrl = `/sell/${get(
  //       this.props.leadPageByIdData,
  //       "slugUrl",
  //       ""
  //     )}/${get(this.props.leadPageByIdData, "_id", "")}`;
  //     formData.type = this.props.location.pathname.split("/")[2];
  //     formData.phone =
  //       this.state.countryCode === "+1"
  //         ? this.state.countryCode + " " + this.state.phone
  //         : "+" + this.state.countryCode + " " + this.state.phone;
  //     this.props.addInquiry(formData);
  //   }
  // }

  setModalIsOpen = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen }, () => {
      this.forceUpdate();
    });
  };

  cancelModal() {
    document.body.classList.remove("ReactModal__Body--open");
    this.setState({ modalIsOpen: false }, () => {
      this.forceUpdate();
    });
  }

  handleMap = () => {
    const { homeData } = this.state;
    const zoom = 18;
    if (document.getElementById("map-canvas")) {
      let map = new google.maps.Map(document.getElementById("map-canvas"), {
        zoom: zoom,
        center: new google.maps.LatLng(
          parseFloat(homeData.location.latitude),
          parseFloat(homeData.location.longitude)
        ),
        fullscreenControl: false,
        zoomControl: false,
        styles: mapStyle,
        streetViewControl: false,
        draggable: true,
        scrollwheel: false,
        scaleControl: false,
        panControl: false,
        clickableIcons: false,
        mapTypeControl: false,
      });
      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          parseFloat(homeData.location.latitude),
          parseFloat(homeData.location.longitude)
        ),
        icon: "https://yardpost-dev.s3.amazonaws.com/property_80501646920765595",
      });
      // To add the marker to the map, call setMap();
      marker.setMap(map);

      this.setState({
        map,
      });
    }
  };

  handleChangeValue = (e, keyV, keyName) => {
    console.log(e.target.value.replace("$", "").replace(/,/g, ""), "value");
    if (
      keyName === "targetSellPriceValue" ||
      keyName === "remainingMortgageValue" ||
      keyName === "homeImprovementValue"
    ) {
      let vl = e.target.value
        .replace("$", "")
        .replace(/,/g, "")
        .replace(/^0+/, "");
      vl = vl !== "" && vl !== null && vl !== undefined ? vl : 0;
      this.setState({ [`${keyV}`]: vl }, () => {
        this.valueUpdate();
        this.forceUpdate();
      });
    } else {
      let data = (
        (this.state.targetSellPrice *
          e.target.value.replace(/,/g, "").replace("%", "")) /
        100
      ).toFixed(2);
      this.setState(
        {
          [`${keyV}`]: e.target.value.replace(/,/g, "").replace("%", ""),
          [`${keyName}`]: data,
        },
        () => {
          this.valueUpdate();
          this.forceUpdate();
        }
      );
    }
  };

  handleChangeCountryCode = (value, phone) => {
    this.setState(
      { countryCode: value, phone: phone.replace(value, ""), phoneError: "" },
      () => {
        this.forceUpdate();
      }
    );
  };

  render() {
    const { leadPageSetting } = this.props;
    const { homeData, modalIsOpen, isLoading, leadPageUserData } = this.state;
    return (
      <>
        <main className="listing__website--main">
          <Header {...this.state} {...this.props} />
          <div className="sell__detail">
            <div className="sell__detail--row">
              <div className="sell__detail--left">
                <div className="sell__detail--content">
                  <h1 className="sell__detail--title">
                    👋 Right now your home is worth $
                    {parseInt(
                      get(homeData, "avm.amount.value", 0)
                    ).toLocaleString("en-US")}
                    . Talk to me to see how we can get more
                    <Arrow className="ms-3" />
                  </h1>
                  <ul className="sell__detail--list">
                    <li>
                      <span>Address</span>
                      <span>
                        {`${get(homeData, "address.line1", "")} ${" "} ${get(
                          homeData,
                          "area.countrysecsubd",
                          ""
                        )} ${" "} ${get(
                          homeData,
                          "address.countrySubd",
                          ""
                        )} ${" "} ${get(homeData, "address.postal1", "")}`}
                      </span>
                    </li>
                    <li>
                      <span>
                        Estimated Price Range in{" "}
                        {get(homeData, "address.postal1", "")}{" "}
                      </span>
                      <span>
                        $
                        {get(homeData, "avm.amount.low", "").toLocaleString(
                          "en-US"
                        )}{" "}
                        - $
                        {get(homeData, "avm.amount.high", "").toLocaleString(
                          "en-US"
                        )}
                      </span>
                    </li>
                    <li>
                      <span>
                        Avg. price per Sq. Ft. in{" "}
                        {get(homeData, "address.postal1", "")}
                      </span>
                      {homeData?.building?.size?.grosssize == undefined ? (
                        <span>
                          $
                          {get(
                            homeData,
                            "building.size.grosssizeadjusted",
                            0
                          ).toLocaleString("en-US")}{" "}
                          / Sq. Ft.
                        </span>
                      ) : (
                        <span>
                          $
                          {get(
                            homeData,
                            "building.size.grosssize",
                            0
                          ).toLocaleString("en-US")}{" "}
                          / Sq. Ft.
                        </span>
                      )}
                    </li>
                    <li>
                      <span>
                        Avg. price property price in{" "}
                        {get(homeData, "address.postal1", "")}
                      </span>
                      {homeData?.building?.size?.bldgsize == 0 ? (
                        <span>$ - Sq.Ft.</span>
                      ) : (
                        <span>
                          $
                          {(
                            homeData?.avm?.amount?.value /
                            homeData?.building?.size?.bldgsize
                          ).toLocaleString("en-US")}
                        </span>
                      )}
                    </li>
                    {/*<li>
                      <span>
                        Avg. price --agent full name>-- has sold in {get(homeData,'address.zip','')}
                      </span>
                      <span>$900,000</span>
                    </li>
                    <li>
                      <span>
                        Number of homes --agent full name-- has sold in {get(homeData,'address.zip','')}
                      </span>
                      <span>22</span>
                    </li>*/}
                  </ul>
                  {/* <div className="sell__detail__footer">
                    <div className="sell__detail__footer--copyrights">
                      © 2022 {get(leadPageUserData, "companyName", "")}
                    </div>
                    <ul className="sell__detail__mls--list">
                      {get(leadPageSetting, "showHousingLogo", false) && (
                        <li>
                          <EqualHO />
                        </li>
                      )}
                      {get(leadPageSetting, "showMlsLogo", false) && (
                        <li>
                          <Realtor />
                        </li>
                      )}
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="sell__detail--right">
                <div id="map-canvas" className="sell__detail--map"></div>
                {/*<form onSubmit={this.onSubmit} noValidate>
                  <div className="sell__detail--form">
                    <div className="brocker__profile">
                      <img
                        src={
                          get(leadPageUserData, "profileImage", "") !== ""
                            ? get(leadPageUserData, "profileImage", "")
                            : Placeholder
                        }
                        alt=""
                      />
                    </div>
                    <div className="brocker__details">
                      <div className="brocker__name">{`${get(
                        leadPageUserData,
                        "firstName",
                        ""
                      )} ${" "} ${get(leadPageUserData, "lastName", "")}`}</div>
                      {get(leadPageUserData, "companyName", "") !== "" ? (
                        <div className="brocker__at__agency">
                          Broker at {get(leadPageUserData, "companyName", "")}
                        </div>
                      ) : (
                        ""
                      )}
                      {get(leadPageUserData, "totalExperience", "") !== "" ? (
                        <div className="brocker__experience">
                          {get(leadPageUserData, "totalExperience", 0)} years of
                          experience
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group ">
                          <label className="label-secondary dark">
                            First Name <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            value={this.state.firstName}
                            onChange={(e) =>
                              this.setState({ firstName: e.target.value })
                            }
                            className="form-control form-control-flat material-textfield-input"
                            required
                          />
                          {this.state.firstNameError !== "" && (
                            <div className="invalid-feedback">
                              {this.state.firstNameError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group ">
                          <label className="label-secondary dark">
                            Last Name <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            value={this.state.lastName}
                            onChange={(e) =>
                              this.setState({ lastName: e.target.value })
                            }
                            autoComplete="false"
                            className="form-control form-control-flat material-textfield-input"
                            required
                          />
                          {this.state.lastNameError !== "" && (
                            <div className="invalid-feedback">
                              {this.state.lastNameError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group ">
                      <label className="label-secondary dark">
                        Email Address <span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        autoComplete="false"
                        className="form-control form-control-flat material-textfield-input"
                        required
                      />
                      {this.state.emailError !== "" && (
                        <div className="invalid-feedback">
                          {this.state.emailError}
                        </div>
                      )}
                    </div>
                    <div className="form-group ">
                      <label className="label-secondary dark">
                        Phone Number <span className="required">*</span>
                      </label>
                        <PhoneInput
                          inputProps={{
                            readOnly: false,
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                            className:"form-control form-control-flat"
                          }}
                          country={'us'}
                          value={(this.state.countryCode) || ''}
                          onChange={(phone, val) => this.handleChangeCountryCode(val.dialCode,phone)}
                        />
                        {this.state.phoneError !== "" && (
                          <div className="invalid-feedback">
                            {this.state.phoneError}
                          </div>
                        )}
                    </div>

                    <button className="btn btn__flat btn__flat--dark w-100">
                      {isLoading ? <Spin indicator={antIcon} /> : "Submit"}
                    </button>
                  </div>
                </form>*/}
              </div>
            </div>
          </div>

          <SaleCalculator
            handleChangeValue={this.handleChangeValue}
            {...this.state}
            {...this.props}
          />
          {/* <Footer {...this.state} {...this.props} /> */}
          {/* <SocialAtLeft {...this.state} {...this.props} /> */}
        </main>
      </>
    );
  }
}
