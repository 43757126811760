import React, { PureComponent } from "react";
import { Desktop, Mobile } from "../../../../../components/icons";

import "./styles.scss";

export default class PreviewHeaderComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    return (
      <>
        <div className="preview__header">
          <div className="preview__header--row">
            <div className="preview__header--left">
              <div className="preview__header--title">
                Preview of your template
              </div>
              <div className="preview__header--subtitle">
                Last published on Jan 1, 2022
              </div>
            </div>
            <div className="preview__header--right">
              <div className="preview__header--action">
                <button className="btn btn__desktop active">
                  <Desktop />
                </button>
                <button className="btn btn__mobile">
                  <Mobile />
                </button>
                <button className="btn btn__default btn__fullscreen">
                  Full Screen
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
