import { connect } from "react-redux";
import { handleSignOut } from "../../../store/user/duck";
import TopNavigationComponent from "./component";

const TopNavigationContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    handleSignOut,
  }
)(TopNavigationComponent);

export default TopNavigationContainer;
