import { connect } from "react-redux";
import {
  getPropertyDetails,
  initPropertyPhase,
  addProperty,
} from "../../../../store/mylisting/duck";
import EditListingComponent from "./component";

const EditListingContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    getPropertyDetailsPhase: state.mylisting.getPropertyDetailsPhase,
    addPropertyPhase: state.mylisting.addPropertyPhase,
    getPropertyDetailsData: state.mylisting.getPropertyDetailsData,
    addPropertyMessage: state.mylisting.addPropertyMessage,
  }),
  // Map actions to dispatch and props
  {
    addProperty,
    initPropertyPhase,
    getPropertyDetails,
  }
)(EditListingComponent);

export default EditListingContainer;
