import * as React from "react";

const CheckMarkIcon = ({ ...props }) => {
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m38.322 11.981-2.903 1.275a16.843 16.843 0 1 1-7.472-8.086l1.502-2.797a20 20 0 1 0 8.873 9.608Z"
        fill="#fff"
      />
      <path
        d="M11.014 14.165 8.819 16.46l10.492 10.032 18.98-21.11-2.36-2.121-16.79 18.672-8.127-7.768Z"
        fill="#00E1E1"
      />
    </svg>
  );
};
export default CheckMarkIcon;
