import { connect } from "react-redux";

import {
  getLeadPageById,
  initLeadPage,
  getAllLeadPage,
  getAddressDetails,
  addInquiry,
} from "../../../store/leadPage/duck";
import DomainPageComponent from "./component";
import {
  getUser,
  editUser,
  resetLogin,
  resetLoginUser,
} from "../../../store/user/duck";
import {
  addSocialInquiry,
  addNewsletterLead,
} from "../../../store/leadPage/duck";
import { getLeadPageSettingId } from "../../../store/user/duck";
const DomainPageContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    userPhase: state.user.userPhase,
    leadPageByIdPhase: state.leadPage.leadPageByIdPhase,
    leadPageByIdData: state.leadPage.leadPageByIdData,
    leadPageSetting: state.leadPage.leadPageSetting,
    leadPageUserData: state.leadPage.leadPageUserData,
    leadPageProperty: state.leadPage.leadPageProperty,
    addInquiryPhase: state.leadPage.addInquiryPhase,
    addInquiryMessage: state.leadPage.addInquiryMessage,
    getAllLeadPagePhase: state.leadPage.getAllLeadPagePhase,
    getAllLeadPageData: state.leadPage.getAllLeadPageData,
    isSell: state.leadPage.isSell,
    isRent: state.leadPage.isRent,
    getAddressDetailsPhase: state.leadPage.getAddressDetailsPhase,
    getAddressDetailsData: state.leadPage.getAddressDetailsData,
    editUserPhase: state.user.editUserPhase,
    editUserData: state.user.editUserData,
    getLeadPageSettingIdPhase: state.user.getLeadPageSettingIdPhase,
    getLeadPageSettingIdData: state.user.getLeadPageSettingIdData,
  }),
  // Map actions to dispatch and props
  {
    getAddressDetails,
    getUser,
    resetLoginUser,
    resetLogin,
    getAllLeadPage,
    addInquiry,
    initLeadPage,
    getLeadPageById,
    editUser,
    getLeadPageSettingId,
    addSocialInquiry,
    addNewsletterLead,
  }
)(DomainPageComponent);

export default DomainPageContainer;
