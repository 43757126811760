import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { get, size } from "lodash";
import TopNavigation from "../../../components/common/TopNavigation/container";
import TablePagination from "../../../components/common/TablePagination/component";
import CheckboxOnly from "../../../components/ui/CheckboxOnly/component";
import { Plus } from "../../../components/icons";
import Tabs from "../../../components/common/Tabs/container";
import "./styles.scss";
import ActionMoreComponent from "../../../components/common/ActionMore/container";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Impersonate from "../../../components/common/Impersonate/container";

export default class MyListingsComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      activeTab: "active",
      isActiveListing: true,
      isSoldListing: false,
      isDraftListing: false,
      page: 1,
      limit: 50,
      status: "active",
      sortKey: "createdAt",
      sortType: "desc",
      type: "",
      propertyData: [],
      isLoading: true,
      totalCount: 0,
      currentData: 0,
      previousStep: 0,
      nextStep: 0,
    };
  }

  componentDidMount() {
    let tab = this.props.location.pathname;
    let selectedTab = "";
    let newStatus = "";
    if (tab === "/listings/active") {
      selectedTab = "active";
      newStatus = "active";
    } else if (tab === "/listings/closed-sold") {
      selectedTab = "closed-sold";
      newStatus = "sold";
    } else if (tab === "/listings/drafts") {
      selectedTab = "drafts";
      newStatus = "draft";
    }
    this.setState(
      { activeTab: selectedTab, status: newStatus, checkMarkData: [] },
      () => {
        this.forceUpdate();
        this.props.getProperty({
          activeTab: this.state.activeTab,
          page: this.state.page,
          limit: 50,
          status: this.state.status,
          type: "",
          sortType: this.state.sortType,
          sortKey: this.state.sortKey,
        });
      }
    );
  }

  componentDidUpdate(prev) {
    document.title = "My Listing | Yardpost";
    if (this.props.getPropertyPhase === "success") {
      this.props.initPropertyPhase();
      this.setState(
        { propertyData: this.props.getPropertyData, isLoading: false },
        () => {
          this.forceUpdate();
        }
      );
    }
    if (this.props.deletePropertyPhase === "success") {
      this.props.initPropertyPhase();
      this.props.getProperty({
        activeTab: this.state.activeTab,
        page: this.state.page,
        limit: 50,
        status: this.state.status,
        type: "",
        sortType: this.state.sortType,
        sortKey: this.state.sortKey,
      });
      this.openNotificationWithIcon(
        "success",
        "Deleted property successfully."
      );
    }
  }

  pageChange(value) {
    const { nextStep, previousStep, page } = this.state;
    if (value === "next") {
      let next = nextStep - 1;
      this.setState({ page: this.state.page + 1 }, () => {
        this.forceUpdate();
        this.props.getProperty({
          activeTab: this.state.activeTab,
          page: this.state.page,
          limit: 50,
          status: this.state.status,
          type: "",
          sortType: this.state.sortType,
          sortKey: this.state.sortKey,
        });
      });
    } else if (value === "previous") {
      this.setState(
        { page: this.state.page - 1 === 0 ? 1 : this.state.page - 1 },
        () => {
          this.forceUpdate();
          this.props.getProperty({
            activeTab: this.state.activeTab,
            page: this.state.page,
            limit: 50,
            status: this.state.status,
            type: "",
            sortType: this.state.sortType,
            sortKey: this.state.sortKey,
          });
        }
      );
    }
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: message,
    });
  };

  onTabChange(tab) {
    this.setState({ activeTab: tab, checkMarkData: [] }, () => {
      if (tab === "active") {
        this.props.history.push("/listings/active");
        this.setState({
          isActiveListing: true,
          isSoldListing: false,
          isDraftListing: false,
          page: this.state.page,
          status: "active",
          previousStep: 0,
          nextStep: 0,
          isLoading: true,
        });
      } else if (tab === "closed-sold") {
        this.setState({
          isActiveAgent: false,
          isSoldListing: true,
          isDraftListing: false,
          page: this.state.page,
          status: "sold",
          previousStep: 0,
          nextStep: 0,
          isLoading: true,
        });
        this.props.history.push("/listings/closed-sold");
      } else if (tab === "drafts") {
        this.setState({
          isActiveListing: false,
          isSoldListing: false,
          isDraftListing: true,
          status: "draft",
          page: this.state.page,
          previousStep: 0,
          nextStep: 0,
          isLoading: true,
        });
        this.props.history.push("/listings/drafts");
      }
      this.props.getProperty({
        activeTab: tab,
        status: this.state.status,
        sortKey: this.state.sortKey,
        sortType: this.state.sortType,
        page: this.state.page,
        limit: 50,
        type: "",
        status: this.state.status,
      });
    });
  }

  deletePropertyById(id) {
    this.props.deleteProperty({ id: id });
  }

  render() {
    const { activeTab, isLoading, propertyData } = this.state;
    return (
      <>
        <main
          className="main"
          role="header-with-tabs"
          impersonate={
            localStorage.getItem("AdminAuthorization") &&
            localStorage.getItem("Authorization")
              ? "impersonate"
              : ""
          }
        >
          <div className="main__header">
            {localStorage.getItem("AdminAuthorization") &&
              localStorage.getItem("Authorization") && (
                <Impersonate
                  data={get(this.props.user, "user", "")}
                  {...this.props}
                />
              )}
            <TopNavigation {...this.props} />
            <div className="tabs__links">
              <Tabs attr="myListing" {...this.props} />
            </div>
          </div>

          <div className="page__header">
            <div className="page__header--container">
              <div className="page__header--row">
                <div className="page__header--left">
                  <h1 className="page__header--title">My Listings</h1>
                </div>
                <div className="page__header--right">
                  <Link
                    to="/listings/create-listing"
                    type="button"
                    className="btn btn__purple"
                  >
                    <Plus className="btn__icon" /> Create New Listing
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="tabs__links tabs__links--space">
            <button
              className={
                activeTab === "active" ? "tabs__link active" : "tabs__link"
              }
              onClick={this.onTabChange.bind(this, "active")}
            >
              Active
            </button>
            <button
              className={
                activeTab === "closed-sold" ? "tabs__link active" : "tabs__link"
              }
              onClick={this.onTabChange.bind(this, "closed-sold")}
            >
              Closed/Sold
            </button>
            <button
              className={
                activeTab === "drafts" ? "tabs__link active" : "tabs__link"
              }
              onClick={this.onTabChange.bind(this, "drafts")}
            >
              Drafts
            </button>
          </div>
          {isLoading ? (
            <Spin
              size="large"
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 30, color: "#000", align: "center" }}
                  spin
                />
              }
              className="loader__full"
            />
          ) : (
            <div className="custom__container">
              <div className="table-responsive table__responsive--custom">
                {propertyData.length > 0 ? (
                  <table className="table table__custom">
                    <thead>
                      <tr>
                        <th className="table__cell--first">Address</th>
                        <th>Type</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {propertyData.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td className="table__cell--first">
                              {get(data, "address", "")}
                              {get(data, "units", "") !== ""
                                ? ", " + get(data, "units", "")
                                : ""}
                              {", "}
                              {get(data, "city", "")}
                              {", "}
                              {get(data, "state", "")}
                              {", "}
                              {get(data, "zipcode", "")}
                            </td>
                            <td>
                              {get(data, "type", "") === "for-purchase"
                                ? "Purchase"
                                : "Rental"}
                            </td>
                            <td className="table__cell--last">
                              <ActionMoreComponent
                                class="my-listing"
                                className="my-listing"
                                data={data}
                                deletePropertyById={this.deletePropertyById.bind(
                                  this
                                )}
                                {...this.props}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <>
                    <div className="table__blank">
                      <div className="table__blank--text">
                        {activeTab === "active"
                          ? "No active listings"
                          : activeTab === "closed-sold"
                          ? "No closed/sold listings"
                          : "No draft listings"}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {propertyData.length > 0 && (
                <div className="table__pagination">
                  <div className="table__pagination--results">
                    {size(propertyData)} results
                  </div>
                  <div className="table__pagination--nav">
                    <>
                      {this.state.previousStep + (this.state.page - 1) ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--prev"
                          onClick={this.pageChange.bind(this, "previous")}
                        >
                          Previous
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--prev disabled"
                        >
                          Previous
                        </button>
                      )}
                    </>
                    <>
                      {this.state.nextStep - this.state.page > 0 ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--next"
                          onClick={this.pageChange.bind(this, "next")}
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--next disabled"
                        >
                          Next
                        </button>
                      )}
                    </>
                  </div>
                </div>
              )}
            </div>
          )}
        </main>
      </>
    );
  }
}
