import * as React from "react";

const Facebook = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        fill="currentColor"
        d="M17.212 0H.788A.769.769 0 000 .787v16.538c0 .338.338.675.787.675H9v-5.625H6.75V9H9V6.75c0-2.362 1.35-3.375 3.375-3.375h2.25V6.75H13.5c-.675 0-1.125.45-1.125 1.125V9H15.3l-.675 3.375h-2.25V18h4.837c.45 0 .788-.337.788-.788V.788A.769.769 0 0017.212 0z"
      ></path>
    </svg>
  );
};

export default Facebook;
