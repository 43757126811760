import { connect } from "react-redux";
import { getUser, resetLogin, resetLoginUser } from "../../../store/user/duck";
import AccountComponent from "./component";

const AccountContainer = connect(
  // Map state to props
  (state) => ({
    userPhase: state.user.userPhase,
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    getUser,
    resetLoginUser,
    resetLogin,
  }
)(AccountComponent);

export default AccountContainer;
