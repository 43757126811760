import { connect } from "react-redux";
import { getLeads, initLeadPage } from "../../../store/leadPage/duck";
import LeadsComponent from "./component";

const LeadsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    getLeadsPhase: state.leadPage.getLeadsPhase,
    getLeadsData: state.leadPage.getLeadsData,
    getLeadsCount: state.leadPage.getLeadsCount,
  }),
  // Map actions to dispatch and props
  {
    getLeads,
    initLeadPage,
  }
)(LeadsComponent);

export default LeadsContainer;
