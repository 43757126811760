import React, { PureComponent } from "react";
// import "./styles.scss";
import { get } from "lodash";
import ListingWebsiteComponent from "../ListingWebsite/container";
import SellComponent from "../Sell/container";
import SocialLinkComponent from "../../Agent/SocialLink/container";
import PageNotFoundComponent from "../PageNotFound/container";
import SocialLinkEmptyContainer from "../../Agent/SocialLinkEmpty/container";
export default class FooterComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  componentDidMount() {
    console.log(window.location.pathname, "path");
    let id = window.location.pathname.split("/")[0];

    this.props.getLeadPageById({
      id: get(this.props.match, "params.id", ""),
    });
  }
  componentDidUpdate(prev) {
    console.log(this.props.leadPageByIdPhase, " this.props.leadPageByIdPhas");
    if (this.props.leadPageByIdPhase === "success") {
      console.log(this.props.leadPageByIdData, "leadPageByIdData");
    }
  }
  render() {
    return (
      <div>
        {this.props.leadPageByIdData &&
        this.props.leadPageByIdData?.campaignType === "listing-website" ? (
          <ListingWebsiteComponent {...this.props} />
        ) : this.props.leadPageByIdData?.campaignType === "sell" ? (
          <SellComponent {...this.props} />
        ) : this.props.leadPageByIdData?.campaignType === "social-link" ||
          window.location.pathname.includes("/social")  ? (
          <SocialLinkComponent {...this.props} />
        ) : (
          this.props.leadPageByIdPhase !== "loading" &&
          !window.location.pathname.includes("/social") && (
            <SocialLinkEmptyContainer {...this.props} />
          )
        )}
      </div>
    );
  }
}
