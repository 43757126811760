import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Facebook,
  Instagram,
  Logo,
  // TikTok,
  Twitter,
  Whatsapp,
  Youtube,
  EqualHO, Realtor
} from "../../../components/icons";
import TikTok from "../../../components/icons/TikTok";
import LinkedIn from "../../../components/icons/LinkedIn";
import Money from "./../../../images/money.png";
import { get, isEmpty } from "lodash";
import Autocomplete from "react-google-autocomplete";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import ReactModal from "react-modal";
import TestimonialCarousel from "./testimonials/Carousel";
import { PDFImage } from "../../../components/icons/PDF";
import PDFThumbnail from '../Campaign/Edit/PDF';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default class SocialLinkComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      userData: {},
      profileImage: "",
      leadData: {},
      isLoading: false,
      AddressError: false,
      dataError: false,
      unitError: false,
      userId: "",
      isLoading: false,
      message: "",
      image: "",
      error: {},
      onOptionSelect: {},
      existProperty: {},
      unit: "",
      address: "",
      AddressError: "",
      unitError: "",
      zipcode: 0,
      zipcodeError: "",
      cityError: "",
      city: "",
      state: "",
      stateValue: "",
      stateError: "",
      place: {},
      submitLoading: false,
      scriptLoaded: false,
      changeTab: "single-family",
      leadSettingData: {},
      leadPageUserData: {},
      homeData: {},
      isDataCome: false,
      formDATA: {},
      messageTab: "inbox",
      emailId: "",
      phoneNumber: "",
      countryCode: "us",
      phError: "",
      emailIdError: "",
      email: "",
      emailNew: "",
      hexColorRegex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.onSubmitAddress = this.onSubmitAddress.bind(this);
    this.handleChangeManage = this.handleChangeManage.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
  }

  handleChangeManage(value) {
    this.setState({
      changeTab: value,
      address: "",
      unit: "",
      dataError: false,
    });
  }

  handleChangeMessage(value) {
    if (value === "inbox") {
      this.setState({
        emailIdError: "",
        emailId: "",
        messageTab: value,
      });
    } else {
      this.setState({
        phError: "",
        phoneNumber: "",
        messageTab: value,
      });
    }
  }

  openNotificationWithIcon = (type, msg) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: msg,
    });
  };

  handleChangeCountryCode = (value, phone) => {
    this.setState({
      countryCode: value,
      phoneNumber: phone.replace(value, ""),
      phError: "",
    });
  };

  handleEmail = (value) => {
    this.setState({ emailId: value, emailIdError: "" });
  };

  onSignUp = async () => {
    const campaignId = get(this.props.match, "params.id", "");
    console.log(this.props.match)
    const { userData } = this.state;
    if (this.state.messageTab === "sms") {
      if (this.state.phoneNumber === "") {
        this.setState({
          phError: "Please enter phone number.",
        });
      } else if (this.state.phoneNumber.length < 9) {
        this.setState({
          phError: "Please enter a valid phone number.",
        });
      } else {
        this.setState({
          phError: "",
        });
      }
      if (this.state.phoneNumber.length >= 10) {
        let userId = get(this.state.userData, "_id", "");
        let phone =
          "+" + this.state.countryCode.concat(" ", this.state.phoneNumber);
        let payload = {
          userId: userId,
          isFrom: "newsletter",
          campaignName: userData?.campaignName,
          phoneNumber: phone,
          campaignId: this.state.leadId ?? campaignId,
        };
        console.log("this.state ==>", this.state)
        const { value } = await this.props.addNewsletterLead(payload);
        if (value.success) {
          this.openNotificationWithIcon(
            "success",
            "You're now signed up to receive my newest listings and market updates."
          );
          this.setState({
            phoneNumber: "",
          });
          // this.props.history.push("/leads");
        } else {
          this.openNotificationWithIcon(
            "error",
            "You're have already signed up."
          );
          this.setState({
            phoneNumber: "",
            countryCode: "us",
          });
        }
      }
    } else {
      // let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let mailformat =
        /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
      if (isEmpty(this.state.emailId)) {
        this.setState({ emailIdError: "Please enter an email address." });
      } else if (!this.state.emailId.match(mailformat)) {
        this.setState({
          emailIdError: "Please enter a valid email address.",
        });
      } else {
        this.setState({
          emailIdError: "",
        });
      }
      if (this.state.emailId !== "" && this.state.emailId.match(mailformat)) {
        let userId = get(this.state.userData, "_id", "");
        let payload = {
          userId: userId,
          isFrom: "newsletter",
          campaignName: userData?.campaignName,
          emailId: this.state.emailId,
          campaignId: this.state.leadId ?? campaignId,
        };
        console.log("this.state ==>", this.state)
        const { value } = await this.props.addNewsletterLead(payload);
        if (value.success) {
          this.openNotificationWithIcon(
            "success",
            "You're now signed up to receive my newest listings and market updates."
          );
          this.setState({ emailId: "" });
          // this.props.history.push("/leads");
        } else {
          this.openNotificationWithIcon(
            "error",
            "You're have already signed up."
          );
          this.setState({ emailId: "" });
          this.setState({ emailIdError: "This Email is already exist." });
        }
      }
    }
  };

  setPlace = (place) => {
    this.setState({ AddressError: false, dataError: false });
    this.setState({ address: place });
    if (get(place, "address_components[8].types[0]", "") === "postal_code") {
      this.setState({
        zipcode: get(place, "address_components[8].short_name", ""),
      });
    } else {
      if (get(place, "address_components[7].types[0]", "") === "postal_code") {
        this.setState({
          zipcode: get(place, "address_components[7].short_name", ""),
        });
      } else {
        this.setState({ zipcode: "" });
      }
    }
  };

  onSubmitAddress(e) {
    e.preventDefault();
    this.setState({ dataError: false, AddressError: false });
    const { address, place, unit, city, stateValue, zipcode } = this.state;
    let state = stateValue;
    let errors = {};
    if (address === "") {
      errors.address = "Please enter address.";
      this.setState({
        AddressError: true,
      });
    }
    if (isEmpty(errors)) {
      this.setState({ isLoading: false, modalIsOpen: true });
    }
  }

  setModalIsOpen = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen }, () => {
      this.forceUpdate();
    });
  };

  handleChange = (e) => {
    if ((e.target.name = "unit")) {
      this.setState({
        unit: e.target.value,
      });
    }
  };

  cancelModal() {
    document.body.classList.remove("ReactModal__Body--open");
    this.setState(
      {
        modalIsOpen: false,
        isLoading: false,
        submitLoading: false,
        recaptchaError: "",
        lastNameError: "",
        firstNameError: "",
        emailError: "",
        phoneError: "",
      },
      () => {
        this.forceUpdate();
      }
    );
  }

  camelCase = (str) => {
    return str
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function (b) {
        return b.toLowerCase();
      });
  };

  onSubmit(event) {
    event.preventDefault();
    this.setState({
      lastNameError: "",
      firstNameError: "",
      emailError: "",
      phoneError: "",
    });
    let multiInput = {};
    let singleInput = {};
    let error = {};
    let formData = {};
    // const userId = get(this.state.leadPageUserData, "_id", "");
    const userId = get(this.state.userData, "_id", "");
    // const campaignId = get(this.props.match, "params.id", "");
    let target = event.target;
    for (let i = 0; i < target.length; i++) {
      var name = target.elements[i].getAttribute("name");
      var fieldType = target.elements[i].getAttribute("fieldtype");
      if (name) {
        formData[this.camelCase(name)] = target.elements[i].value;
        if (name === "email") {
          if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              target.elements[i].value
            ) &&
            target.elements[i].value !== ""
          ) {
            this.setState({
              emailError: "Please enter valid Email",
            });
            error["email"] = "Please enter valid Email";
          } else if (target.elements[i].value === "") {
            this.setState({
              emailError: "Please Enter Email",
            });
          } else {
            this.setState({
              email: target.elements[i].value,
              emailNew: target.elements[i].value,
            });
          }
        }

        if (target.elements[i].value === "") {
          let newname;
          if (name === "phone") {
            this.setState({
              phoneError: "Please Enter Phone Number",
            });
          }
          if (name === "firstName") {
            this.setState({
              firstNameError: "Please Enter First Name",
            });
          }
          if (name === "lastName") {
            this.setState({
              lastNameError: "Please Enter Last Name",
            });
          }
          error[name] = "Please Enter " + newname;
          // console.log(error,'error')
        }
      }
    }
    if (!this.state.recaptcha) {
      this.setState({ recaptchaError: "Please select re-captcha" });
    }
    this.setState({ error });
    if (isEmpty(error)) {
      document.body.classList.remove("ReactModal__Body--open");
      const { address, place, unit, city, stateValue, zipcode } = this.state;
      this.setState({ submitLoading: true });
      if (!isEmpty(address)) {
        this.props.getAddressDetails({
          address: address,
          unit: unit,
          zipcode: zipcode,
        });
      } else {
        // this.props.getAddressDetails({
        //   address: address,
        //   unit: unit,
        //   zipcode: zipcode,
        // });
      }
      this.setState({ homeData: this.props.getAddressDetailsData[0] });
      let price = parseInt(
        get(this.state.homeData, "avm.amount.value", 0)
      ).toLocaleString("en-US");
      let valuationPrice = "$" + price;
      this.setState({ isLoading: false, submitLoading: true });
      formData.email = this.state.emailNew;
      formData.userId = userId;
      // formData.campaignId = campaignId;
      formData.isFrom = "socialLink";
      // formData.campaignName = get(
      //   this.props.leadPageByIdData,
      //   "campaignName",
      //   ""
      // );
      formData.campaignName = "Social Link";
      formData.valuationPrice = valuationPrice.toString();
      formData.address = `${get(
        this.state.homeData,
        "address.line1",
        ""
      )}${", "}${get(
        this.state.homeData,
        "area.countrysecsubd",
        ""
      )}${", "}${get(
        this.state.homeData,
        "address.countrySubd",
        ""
      )}${", "}${get(this.state.homeData, "address.postal1", "")}`;
      // formData.websiteUrl = `/sell/${get(
      //   this.props.leadPageByIdData,
      //   "slugUrl",
      //   ""
      // )}/${get(this.props.leadPageByIdData, "_id", "")}`;
      // formData.type = this.props.location.pathname.split("/")[2];
      formData.phone = "";
      // this.props.addInquiry(formData);
      // this.props.addSocialInquiry(formData);
      this.setState({ formDATA: formData });
    }
  }

  componentDidMount() {
    let leadId = get(this.props.match, "params.id", "");
    if(this.props.leadPageByIdData?._id !== leadId){
      leadId = this.props.leadPageByIdData?._id;
    }
    this.setState({leadId})
    window.scrollTo({ top: 0 });
    if (
      window.location.origin !== "https://app.yardpost.com" &&
      window.location.origin !== "https://stag-app.yardpost.com" &&
      window.location.origin !== "http://localhost:3000" &&
      window.location.origin !== "https://yardpost-frontend-04tp.onrender.com"
    ) {
      if (
        window.location.origin
          ?.replaceAll("https://", "")
          ?.replaceAll("http://", "") === "yrdp.st" || window.location.origin
          ?.replaceAll("https://", "")
          ?.replaceAll("http://", "") === "stag.yrdp.st"
      ) {
        this.props.getUser({
          domain:window.location.origin
            ?.replaceAll("https://", "")
            ?.replaceAll("http://", "") + window.location.pathname,
            leadId,withIp:true}
        );
      } else {
        let domain = window.location.origin;
        if(window.location.origin?.includes(".stag")){
          domain = window.location.origin?.replace("stag.yardpost.com", "yardpost.com");
        }
        console.log(domain, "window.location.origin====?");
        this.props.getUser({domain: domain?.replaceAll("https://", ""),leadId,withIp:true});
      }
    } else {
      this.props.getUser({domain:"",leadId});
    }
    if (!isEmpty(this.props.user)) {
      const userId = get(this.props.user, "user._id", "");
      this.props.getLeadPageSettingId(userId);
      this.setState(
        {
          userData: get(this.props.user, "user", {}),
          profileImage: get(this.props.user, "user.profileImage", ""),
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  getYouTubeID(url) {
    const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const matches = url.match(regex);
    return matches ? matches[1] : null;
  }

  componentDidUpdate = (prev) => {
    let leadId = get(this.props.match, "params.id", "")
    if(this.props.leadPageByIdData?._id !== leadId){
      leadId = this.props.leadPageByIdData?._id;
    }
    if (!isEmpty(this.props.user)) {
      this.props.initLeadPage();
      this.setState({
        userData: get(this.props.user, "user", {}),
        profileImage: get(this.props.user, "user.profileImage", ""),
      });
      // if (!get(this.props.user, "user.showSocialLinks", false)) {
      //   window.location.href = "/social-link-empty";
      // }
    }

    if (this.props.getLeadPageSettingIdPhase === "success") {
      this.props.initLeadPage();
      this.setState(
        {
          leadSettingData: this.props.getLeadPageSettingIdData,
        },
        () => {
          document.getElementById("favicon").href = get(
            this.props.getLeadPageSettingIdData,
            "favicon",
            "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png"
          );
        }
      );
    }
    if (this.props.userPhase === "success") {
      window.scrollTo({ top: 0 });
      let tab = window.location.pathname;
      console.log(tab.split("/").indexOf("preview"));

      if (
        tab.split("/").indexOf("preview") === -1 &&
        get(this.props.user, "user.status", {}) === "draft" &&
        get(this.props.user, "user.campaignType", {}) === "social-link"
      ) {
        this.props.history.push("/social-link-empty");
      }
      const userId = get(this.props.user, "user._id", "");
      let title =
        get(this.props.user, "user.seo.metaTitle", "Social Page") !== ""
          ? get(this.props.user, "user.seo.metaTitle", "Social Page")
          : "Social Page";
      let companyName =
        get(this.props.user, "user.companyName", "Yardpost") !== ""
          ? get(this.props.user, "user.companyName", "Yardpost")
          : "Yardpost";
      document.title = title + " | " + companyName;
      this.props.getLeadPageSettingId(userId);
      this.props.resetLoginUser();
      this.setState(
        {
          userData: get(this.props.user, "user", {}),
          profileImage: get(this.props.user, "user.profileImage", ""),
        },
        () => {
          this.forceUpdate();
        }
      );
    }
    if (this.props.editUserPhase === "success") {
      window.scrollTo({ top: 0 });
      this.props.getUser({leadId});
      this.props.resetLogin();

      if (this.props.userPhase === "success") {
        this.props.resetLoginUser();
        this.setState(
          {
            userData: get(this.props.user, "user", {}),
            profileImage: get(this.props.user, "user.profileImage", ""),
          },
          () => {
            this.forceUpdate();
          }
        );
      }
    }

    let id = get(this.props.match, "params.id", "");
    let tab = this.props.location.pathname;
    const tabArr = tab.split("/").indexOf("preview");
    if (this.props.leadPageByIdPhase === "success") {
      this.props.initLeadPage();

      this.setState(
        {
          leadData: this.props.leadPageByIdData,
          // leadSettingData: this.props.leadPageSetting,
          leadPageUserData: this.props.leadPageUserData,
        },
        () => {
          let title =
            get(this.props.leadPageByIdData, "seo.metaTitle", "Social Page") !==
              ""
              ? get(this.props.leadPageByIdData, "seo.metaTitle", "Social Page")
              : "Social Page";
          let companyName =
            get(this.props.leadPageUserData, "companyName", "Yardpost") !== ""
              ? get(this.props.leadPageUserData, "companyName", "Yardpost")
              : "Yardpost";
          document.title = title + " | " + companyName;
          document.getElementById("favicon").href = get(
            this.props.leadPageSetting,
            "favicon",
            "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png"
          );
          if (get(this.state, "leadData.status", "") === "active") {
          } else {
            if (!tabArr === 1) {
              this.props.history.push("/404");
            }
          }
          this.forceUpdate();
        }
      );
    } else if (this.props.leadPageByIdPhase === "error") {
      // if (
      //   window.location.origin === "https://app.yardpost.com" ||
      //   window.location.origin === "https://stag-app.yardpost.com" ||
      //   window.location.origin === "http://localhost:3000" ||
      //   window.location.origin === "https://yardpost-frontend-04tp.onrender.com"
      // ) {
      //   this.props.history.push("/social-link-preview");
      // } else {
      //   this.props.history.push("/404");
      // }
    }

    if (this.props.addInquiryPhase == true) {
      this.props.initLeadPage();
      this.openNotificationWithIcon("success");
      if (window.location.origin?.includes("yrdp.st")) {
        this.props.history.push({
          pathname: `/sell-detail/${id}`,
          state: { liveKey: "sellDetail" },
        });
      } else if (
        get(this.props, "location.pathname", "")
          .split("/")
          .indexOf("preview") !== -1
      ) {
        this.props.history.push({
          pathname: `/preview/sell-detail/${id}`,
          state: { key: "sellDetail" },
        });
      } else if (
        get(this.props, "location.pathname", "")
          .split("/")
          .indexOf("edit-lead-page") !== -1
      ) {
        this.props.history.push({
          pathname: `/preview/sell-detail/${id}`,
          state: { key: "sellDetail" },
        });
      } else {
        this.props.history.push({
          pathname: `/sell-detail/${id}`,
          state: { liveKey: "sellDetail" },
        });
      }
      this.setState({
        isLoading: false,
        AddressError: false,
        dataError: false,
        unitError: false,
      });
      this.setState(
        {
          submitLoading: false,
          isLoading: false,
          message: this.props.addInquiryMessage,
          phone: "",
          // email: "",
          firstName: "",
          lastName: "",
          countryCode: "",
          recaptcha: false,
          modalIsOpen: false,
        },
        () => {
          this.forceUpdate();
        }
      );
    } else if (!this.props.addInquiryPhase) {
      this.setState(
        {
          isLoading: false,
          emailError: this.props.addInquiryMessage,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
    if (this.props.getAddressDetailsPhase === "success") {
      this.props.initLeadPage();
      this.setState(
        {
          isLoading: false,
          AddressError: false,
          dataError: false,
          isDataCome: true,
          unitError: false,
          homeData: this.props.getAddressDetailsData[0],
        },
        async () => {
          // const userId = get(this.state.leadPageUserData, "_id", "");
          const userId = get(this.state.userData, "_id", "");

          console.log("data ==>", this.state.leadId)
          // const campaignId = get(this.props.match, "params.id", "");
          let { formDATA } = this.state;
          let formData = formDATA;
          let price = parseInt(
            get(this.state.homeData, "avm.amount.value", 0)
          ).toLocaleString("en-US");
          let valuationPrice = "$" + price;
          formData.email = this.state.emailNew;
          formData.userId = userId;
          formData.campaignId = this.state.leadId;
          formData.isFrom = "socialLink";
          // formData.campaignName = get(
          //   this.props.leadPageByIdData,
          //   "campaignName",
          //   ""
          // );
          formData.campaignName = "Social Link";
          formData.valuationPrice = valuationPrice.toString();
          formData.address = this.state.address?.formatted_address;
          // formData.address = `${get(
          //   this.state.homeData,
          //   "address.line1",
          //   ""
          // )}${", "}${get(
          //   this.state.homeData,
          //   "area.countrysecsubd",
          //   ""
          // )}${", "}${get(
          //   this.state.homeData,
          //   "address.countrySubd",
          //   ""
          // )}${", "}${get(this.state.homeData, "address.postal1", "")}`;
          // formData.websiteUrl = `/sell/${get(
          //   this.props.leadPageByIdData,
          //   "slugUrl",
          //   ""
          // )}/${get(this.props.leadPageByIdData, "_id", "")}`;
          // formData.type = this.props.location.pathname.split("/")[2];
          formData.phone = "";
          const { value } = await this.props.addSocialInquiry(formData);
          console.log("value ==>", value)
          if (value.success) {
            this.props.initLeadPage();
            // this.openNotificationWithIcon("success");
            this.setState({ isLoading: false });
            this.props.history.push({
              pathname: `/preview/property-detail`,
              state: { key: "PropertyDetail",id },
            });
          }
        }
      );
    }
    if (this.props.getAddressDetailsPhase === "error") {
      this.props.initLeadPage();
      this.setState({
        isLoading: false,
        modalIsOpen: false,
        firstName: "",
        lastName: "",
        phone: "",
        // email: "",
        recaptcha: false,
        submitLoading: false,
        dataError: true,
        AddressError: false,
      });
    }
  };

  render() {
    const {
      userData,
      leadData,
      isLoading,
      cityError,
      stateError,
      AddressError,
      zipcodeError,
      unitError,
      dataError,
      changeTab,
      leadPageUserData,
      leadSettingData,
      submitLoading,
      messageTab,
      hexColorRegex
    } = this.state;
    const { leadPageSetting } = this.props;

    const getBackgroundStyle = (background) => {
      if (hexColorRegex.test(background)) {
        return { backgroundColor: background };
      } else if (background) {
        return { backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' };
      }
      return {};
    };
    return (
      <>
        {isEmpty(userData) ? (
          <Spin
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: "#000" }} spin />
            }
            className="loader__full"
          />
        ) : (
          <>
            <div className="custom__container_social" style={getBackgroundStyle(userData.background)}>
              <div className="custom__wrap" style={{ maxWidth: 460 }}>
                <div className="sl__broker__block">
                  <div className="broker__block--thumb">
                    {get(userData, "profileImage", "") === "" ? (
                      <img src={require("../../../images/defaultImage.jpg")} />
                    ) : (
                      <img src={get(userData, "profileImage", "")} alt="" />
                    )}
                  </div>

                  <div className="broker__block--info">
                    <h4>
                      {get(userData, "firstName", "")}{" "}
                      {get(userData, "lastName", "")}
                    </h4>
                    {get(userData, "title", "") === "" ? (
                      <p> </p>
                    ) : (
                      <p>
                        {get(userData, "title", "")} {get(userData, "companyName", "") !== "" ? "at ":""}
                        {get(userData, "companyName", "")}
                      </p>
                    )}
                  </div>

                  <div className="broker__block--contact-details">
                    <div className="broker__block--label">
                      <span>EM:</span>{" "}
                      <a
                        id="mail"
                        className="broker__block--link"
                        href={`mailto:${get(
                          userData,
                          "email",
                          ""
                        )}?Subject=Hello`}
                        target="_top"
                      >
                        {get(userData, "email", "")}
                      </a>
                    </div>
                    {get(userData, "phone", "") === "" ? (
                      <div></div>
                    ) : (
                      <div className="broker__block--label">
                        <span>PH:</span>{" "}
                        <a
                          id="phone"
                          className="broker__block--link"
                          href={`tel:+${get(userData, "phone", "")}`}
                        >
                          {get(userData, "phone", "")}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                {
                  get(userData, "videosData", [])?.map((video , index)=>{
                    const isYouTube = video.includes("youtube.com") || video.includes("youtu.be");
                    return (
                      isYouTube ? (
                        <iframe
                          width="460"
                          height="340"
                          src={`https://www.youtube.com/embed/${this.getYouTubeID(video)}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title={`youtube-video-${index}`}
                        ></iframe>
                      ) : (
                        <video
                          controls
                          width="460"
                          height="340"
                          src={video}
                          type="video/mp4"
                        >
                        </video>
                      )
                    )
                  })
                }
                {get(userData, "propertylist", []).length > 0 &&
                  <div className="social__link--listing">
                    <ul className="social__link--listing-list">
                      {get(userData, "propertylist", []).map((item) => {
                        if (item.title === "") {
                          return <div></div>;
                        } else {
                          return (
                            <>
                              <li>
                                <a
                                  href={item.link}
                                  target="_blank"
                                // rel="noopener noreferrer"
                                >
                                  {item.title.toUpperCase()}
                                </a>
                              </li>
                            </>
                          );
                        }
                      })}
                    </ul>
                  </div>
                }
                {userData?.testimonial?.dataList?.length > 0 ? <div className="social__link--listing">
                  <h3 className="social__link--block-title mt-3">
                    {userData?.testimonial?.title ?? "My Review"}
                  </h3>
                  <TestimonialCarousel
                    testimonials={userData?.testimonial?.dataList}
                  />
                </div>:""}
                {userData?.myWork?.dataList?.length > 0 ? <div className="social__link--listing">
                  <h3 className="social__link--block-title mt-3">
                    {userData?.myWork?.title ?? "My Work"}
                  </h3>
                  <div className="social__link--listing">
                    <ul className="social__link--listing-list">
                      {get(userData, "myWork", [])?.dataList.map((item) => {
                        if (item.title === "") {
                          return <div></div>;
                        } else {
                          return (
                            <>
                              <li className="work_container">
                                {
                                item?.workImage && <div className="work__image__container"><img className="work-image" src={item?.workImage}/></div>
                                }
                                <div className={`${item?.workImage ? "has__image":""} work_name_section`}>
                                  <span className="work-name">
                                    {item?.title}
                                  </span>
                                  <span className="work-price">
                                  {item?.price && new Intl.NumberFormat('en-US', { style: 'currency',currency: 'USD'}).format(item?.price)}
                                  </span>
                                </div>
                              </li>
                            </>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>:""}
                {get(userData, "showPropertyVal", true) === true ? (
                  <div className="social__link--block py-4">
                    <div className="social__link--block-title">
                      See how much your property is worth?
                    </div>
                    <div className="tabs__block">
                      <button
                        className={
                          changeTab === "single-family"
                            ? "btn btn__tabs active "
                            : "btn btn__tabs"
                        }
                        onClick={() => this.handleChangeManage("single-family")}
                      >
                        Single Family Home
                      </button>
                      <button
                        className={
                          changeTab === "condo"
                            ? "btn btn__tabs active"
                            : "btn btn__tabs"
                        }
                        onClick={() => this.handleChangeManage("condo")}
                      >
                        ConDO
                      </button>
                    </div>
                    <div className="row">
                      {changeTab === "single-family" && (
                        <div className="form-group">
                          <label className="label-secondary">
                            STREET ADDRESS
                          </label>
                          <Autocomplete
                            className="form-control form-control-flat"
                            name="address"
                            title="Address Name, City, State, 00000"
                            placeholder="Address Name, City, State, 00000"
                            defaultValue={this.state.address}
                            onPlaceSelected={(place) =>
                              this.setPlace(place, "place")
                            }
                            onBlur={(e) =>
                              e.target.value !== ""
                                ? this.setState({ address: e.target.value })
                                : this.setState({ address: "" })
                            }
                            types={["geocode"]}
                            required
                          />
                          {AddressError && (
                            <div className="invalid-feedback">
                              Please enter address.
                            </div>
                          )}
                          {dataError && (
                            <div className="invalid-feedback">
                              Address not found.
                            </div>
                          )}
                        </div>
                      )}
                      {changeTab === "condo" && (
                        <>
                          <div className="form-group col-9">
                            <label className="label-secondary">
                              STREET ADDRESS
                            </label>
                            <Autocomplete
                              className="form-control form-control-flat"
                              name="address"
                              title="Address Name, City, State, 00000"
                              placeholder="Address Name, City, State, 00000"
                              // value={this.state.address}
                              defaultValue={this.state.address}
                              onPlaceSelected={(place) => this.setPlace(place)}
                              onBlur={(e) =>
                                e.target.value !== ""
                                  ? this.setState({
                                    address: e.target.value,
                                  })
                                  : this.setState({ address: "" })
                              }
                              types={["geocode"]}
                              required
                            />
                            {AddressError && (
                              <div className="invalid-feedback">
                                Please enter address.
                              </div>
                            )}
                            {dataError && (
                              <div className="invalid-feedback">
                                Address not found.
                              </div>
                            )}
                          </div>
                          <div className="form-group col-3">
                            <label className="label-secondary">Unit #</label>
                            <input
                              type="text"
                              name="unit"
                              value={this.state.unit}
                              placeholder="Unit"
                              className="form-control form-control-flat"
                              onChange={(e) => this.handleChange(e)}
                              required
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <button
                      className="btn btn-lg btn__flat btn-rounded btn__dark w-100"
                      onClick={this.onSubmitAddress}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <>
                          <span className="btn__icon--left">
                            <img src={Money} width={26} />
                          </span>{" "}
                          See my Properties Valuation
                        </>
                      )}
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}

                {/* PDF DATA  */}

                {
                 get(userData, "pdfData", []).length > 0 &&
                 get(userData, "pdfData", [])?.map((pdf , index) =>{
                    return (
                      <div className="social__link--block">
                        <div className="d-flex gap-3">
                          <PDFImage />
                          <div>
                            <p className="pdf_name">{pdf.name}</p>
                            <p className="pdf_label">PDF</p>
                          </div>
                        </div>
                        <div>
                          <PDFThumbnail pdfUrl={pdf?.file} preview={true} />
                        </div>
                      </div>
                    )
                  })
                }

                <div className="social__link--block">
                  {get(userData, "showNewsLetter", true) === true ? (
                    <div className="social__link--block">
                      <div className="social__link--block-title">
                        Stay up to date with my listing
                      </div>
                      <div className="tabs__block">
                        <button
                          className={
                            messageTab === "inbox"
                              ? "btn btn__tabs active "
                              : "btn btn__tabs"
                          }
                          onClick={() => this.handleChangeMessage("inbox")}
                        >
                          Inbox
                        </button>
                        <button
                          className={
                            messageTab === "sms"
                              ? "btn btn__tabs active"
                              : "btn btn__tabs"
                          }
                          onClick={() => this.handleChangeMessage("sms")}
                        >
                          SMS
                        </button>
                      </div>
                      {messageTab === "inbox" && (
                        <div className="form-group">
                          <label className="label-secondary">Email Address</label>
                          <input
                            type="text"
                            name="email"
                            className="form-control form-control-flat form-control-rounded"
                            placeholder="email@email.com"
                            onChange={(e) => this.handleEmail(e.target.value)}
                            required
                          />
                          <div className="invalid-feedback">
                            {this.state.emailIdError}
                          </div>
                        </div>
                      )}
                      {messageTab === "sms" && (
                        <div className="form-group">
                          <label className="label-secondary">Phone Number</label>
                          <PhoneInput
                            inputProps={{
                              readOnly: false,
                              name: "phone",
                              required: true,
                              className:
                                "form-control form-control-flat form-control-rounded form-control-phone",
                            }}
                            country={"us"}
                            value={
                              this.state.countryCode + this.state.phoneNumber
                            }
                            // isValid={(value, country) => {
                            //   console.log(value, country, 'country')
                            // }}
                            onChange={(phone, val) => {
                              this.handleChangeCountryCode(val.dialCode, phone);
                            }}
                          />
                          <div className="invalid-feedback">
                            {this.state.phError}
                          </div>
                        </div>
                      )}
                      <button
                        className="btn btn-lg btn__flat btn-rounded btn__dark w-100"
                        onClick={this.onSignUp}
                      >
                        Sign Up
                      </button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="social__links--social-link">
                    <ul className="social__links--list">
                      {get(userData, "youtubeUrl", "") === "" ? (
                        <div></div>
                      ) : (
                        <li>
                          <a
                            href={get(userData, "youtubeUrl", "")}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Youtube />
                          </a>
                        </li>
                      )}
                      {get(userData, "facebookUrl", "") === "" ? (
                        <div></div>
                      ) : (
                        <li>
                          <a
                            href={get(userData, "facebookUrl", "")}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Facebook />
                          </a>
                        </li>
                      )}
                      {get(userData, "instagramUrl", "") === "" ? (
                        <div></div>
                      ) : (
                        <li>
                          <a
                            href={get(userData, "instagramUrl", "")}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Instagram />
                          </a>
                        </li>
                      )}
                      {get(userData, "twitterUrl", "") === "" ? (
                        <div></div>
                      ) : (
                        <li>
                          <a
                            href={get(userData, "twitterUrl", "")}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Twitter />
                          </a>
                        </li>
                      )}
                      {get(userData, "whatsupUrl", "") === "" ? (
                        <div></div>
                      ) : (
                        <li>
                          <a
                            href={get(userData, "whatsupUrl", "")}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Whatsapp />
                          </a>
                        </li>
                      )}
                      {get(userData, "tiktokUrl", "") === "" ? (
                        <div></div>
                      ) : (
                        <li>
                          <a
                            href={get(userData, "tiktokUrl", "")}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TikTok />
                          </a>
                        </li>
                      )}
                      {get(userData, "linkdinUrl", "") === "" ? (
                        <div></div>
                      ) : (
                        <li>
                          <a
                            href={get(userData, "linkdinUrl", "")}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkedIn />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  {/* {get(userData, "yardpostLogo", true) === true ? ( */}
                  <div className="social__link--footer">
                    Powered by
                    <a target="_blank" href="https://yardpost.com/">
                      <Logo />
                    </a>
                  </div>
                  <div className="sell__footer">
                    <ul className="sell__mls--list">
                      {get(this.props.getLeadPageSettingIdData, "showHousingLogo", false) && (
                        <li>
                            <EqualHO/>
                        </li>
                      )
                      }
                      {get(this.props.getLeadPageSettingIdData, "showMlsLogo", false) && (
                        <li>
                            <Realtor/>
                        </li>
                      )
                      }
                    </ul>
                  </div>
                </div>

                {/* ) : (
              <div className="social__link--footer"></div>
            )} */}
              </div>
              <ReactModal
                isOpen={this.state.modalIsOpen}
                contentLabel="One More Step"
                ariaHideApp={false}
                onRequestClose={this.cancelModal}
                shouldCloseOnOverlayClick={false}
                shouldFocusAfterRender={true}
                portalClassName="react-modal"
                overlayClassName="modal"
                className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
              >
                <div className="modal-content modal-content-flat">
                  <div className="react-modal-header">
                    <h2 className="react-modal-title react-modal-title-style-2">
                      One More Step
                    </h2>
                    <h5 className="react-modal-subtitle react-modal-subtitle-style-2">
                      Verify that this is your address
                    </h5>
                  </div>
                  <div className="react-modal-body react-modal-body-style-2">
                    <form onSubmit={this.onSubmit.bind(this)} noValidate>
                      <div className="sell__detail--form">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label className="label-secondary">
                                First Name <span class="required">*</span>
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                value={this.state.firstName}
                                onChange={(e) =>
                                  this.setState({ firstName: e.target.value })
                                }
                                className="form-control form-control-flat material-textfield-input"
                                required
                              />
                              {this.state.firstNameError !== "" && (
                                <div className="invalid-feedback">
                                  {this.state.firstNameError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label className="label-secondary">
                                Last Name <span class="required">*</span>
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                value={this.state.lastName}
                                onChange={(e) =>
                                  this.setState({ lastName: e.target.value })
                                }
                                autoComplete="false"
                                className="form-control form-control-flat material-textfield-input"
                                required
                              />
                              {this.state.lastNameError !== "" && (
                                <div className="invalid-feedback">
                                  {this.state.lastNameError}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label className="label-secondary">
                            Email Address <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                            autoComplete="false"
                            className="form-control form-control-flat material-textfield-input"
                            required
                          />
                          {this.state.emailError !== "" && (
                            <div className="invalid-feedback">
                              {this.state.emailError}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <ReCAPTCHA
                            ref={(r) => (this.captcha = r)}
                            sitekey="6Lc2BMQZAAAAAARE4jzuMJTQSjPIZLCGX_bNOtUc"
                            onChange={() => {
                              this.setState(
                                { recaptcha: true, recaptchaError: "" },
                                () => {
                                  this.forceUpdate();
                                }
                              );
                              this.onChange();
                            }}
                          />
                          {!this.state.recaptcha && (
                            <div className="invalid-feedback">
                              {this.state.recaptchaError}
                            </div>
                          )}
                        </div>
                        <button className="btn btn__flat btn__flat--dark w-100">
                          {submitLoading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            "Confirm"
                          )}
                        </button>
                        <button
                          type="button"
                          onClick={this.cancelModal.bind(this)}
                          className="btn btn__flat btn__flat--link w-100"
                        >
                          {"Cancel"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </ReactModal>
            </div>
          </>
        )}
      </>
    );
  }
}
