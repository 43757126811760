import * as React from "react";

const Desktop = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11.827H7v2.5H4.75a.75.75 0 0 0 0 1.5h6.5a.75.75 0 1 0 0-1.5H9v-2.5Z"
        fill="#A3ACB9"
        fillOpacity={0.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.173V1.307A1.31 1.31 0 0 1 1.31 0h13.38C15.414 0 16 .579 16 1.307v9.386A1.31 1.31 0 0 1 14.69 12H1.31C.585 12 0 11.421 0 10.693v-1.52Z"
        fill="#A3ACB9"
      />
    </svg>
  );
};

export default Desktop;
