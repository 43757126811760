import React, { PureComponent } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { get, size } from "lodash";
import "./styles.scss";

export default class SliderComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const { leadPageProperty } = this.props;
    return (
      <>
        <section className="section__slider">
          <Carousel
            infiniteLoop={true}
            swipeable={true}
            emulateTouch={true}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            className="carousel__slider"
          >
            {size(get(leadPageProperty, "images", [])) > 0 &&
              leadPageProperty.images.map((img, key) => {
                return (
                  <div
                    key={key}
                    onClick={() =>
                      this.setState({
                        photoIndex: key,
                        imagePickerisOpen: false,
                      })
                    }
                    className="carousel__slider--item"
                  >
                    <img
                      className="carousel__slider--thumb checkImageWidth"
                      src={img}
                      alt=""
                    />
                  </div>
                );
              })}
          </Carousel>
          <div className="carousel__slider--info">
            <div className="carousel__slider--info-wrapper">
              {get(leadPageProperty, "type", "") !== "rental" && (
                <div className="carousel__slider--budge">For Purchase</div>
              )}
              {get(leadPageProperty, "type", "") === "rental" && (
                <div className="carousel__slider--budge">Rental</div>
              )}
              <h1 className="carousel__slider--title">
                {`${get(leadPageProperty, "address", "")}${
                  get(leadPageProperty, "units", "") !== ""
                    ? `${", "}${get(leadPageProperty, "units", "")}`
                    : ""
                }${", "}${get(leadPageProperty, "city", "")}${", "}${get(
                  leadPageProperty,
                  "state",
                  ""
                )}${", "}${get(leadPageProperty, "zipcode", "")}`}
              </h1>
              <p className="carousel__slider--desc">
                {get(leadPageProperty, "type", "") !== "rental" ? (
                  <>
                    {`$${parseInt(
                      get(leadPageProperty, "askingPrice", 0)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`}
                    {parseInt(get(leadPageProperty, "interiorSqFeet", 0)) !==
                      0 &&
                      get(leadPageProperty, "interiorSqFeet", "") !== "" &&
                      ` | ${parseInt(
                        get(leadPageProperty, "interiorSqFeet", "")
                      ).toLocaleString()} Sq.Ft`}
                    {" | "} {get(leadPageProperty, "bedrooms", 0)}{" "}
                    {get(leadPageProperty, "bedrooms", 0) > 1
                      ? " Bedrooms"
                      : " Bedroom"}{" "}
                    | {get(leadPageProperty, "bathrooms", 0)}{" "}
                    {get(leadPageProperty, "bathrooms", 0) > 1
                      ? "Baths"
                      : "Bath"}
                  </>
                ) : (
                  <>
                    $
                    {get(leadPageProperty, "monthlyRent", 0) !== "" &&
                      parseInt(get(leadPageProperty, "monthlyRent", 0)) !== 0 &&
                      get(leadPageProperty, "monthlyRent", "") !== "" &&
                      `${parseInt(
                        get(leadPageProperty, "monthlyRent", "")
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}/mo`}
                    {get(leadPageProperty, "squareFootage", 0) !== "" &&
                      parseInt(get(leadPageProperty, "squareFootage", 0)) !==
                        0 &&
                      parseInt(get(leadPageProperty, "squareFootage", "")) !==
                        "" &&
                      ` | ${parseInt(
                        get(leadPageProperty, "squareFootage", "")
                      ).toLocaleString()} Sq.Ft`}{" "}
                    {" | "}
                    {get(leadPageProperty, "bedrooms", 0)}{" "}
                    {get(leadPageProperty, "bedrooms", 0) > 1
                      ? " Bedrooms"
                      : " Bedroom"}{" "}
                    | {get(leadPageProperty, "bathrooms", 0)}{" "}
                    {get(leadPageProperty, "bathrooms", 0) > 1
                      ? "Baths"
                      : "Bath"}
                  </>
                )}
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}
