import { Carousel } from 'react-responsive-carousel'
import React, { useEffect } from 'react'
import { Rating } from 'react-simple-star-rating'
import './style.scss'

function TestimonialCarousel({ testimonials }) {

    const [activeIndex, setActiveIndex] = React.useState(0);

    const CarouselCard = ({ item }) => {

        const [seeMoreEnabled, setSeeMoreEnabled] = React.useState(false);
        const [lengthyDescription] = React.useState(item?.description?.length > 400);

        const seeMore = () => {
            setSeeMoreEnabled(true)
        }

        useEffect(() => {
            setSeeMoreEnabled(false)
        },[activeIndex])

        return <div className={`carousel__testimonial__card ${testimonials?.length > 1 ? "pBottom" : ""}`}>
            <div>
                <Rating
                    initialValue={item?.stars}
                    allowFraction
                    readonly
                    emptyColor="#b3afaf"
                    size={14}
                    fillColor='#000'
                />
            </div>
            <div>
                <p className="testomonial__description">
                    {lengthyDescription ?
                        seeMoreEnabled ? <>
                            {item?.description}{" "}
                            <button className='see__more__btn' onClick={() => setSeeMoreEnabled(false)}>See Less</button>
                        </> :
                            <>
                                {item?.description?.slice(0, 400)} {" "} <button className='see__more__btn' onClick={seeMore}>See More</button>
                            </>
                        : item?.description}
                </p>
                <p>
                    {item?.name}
                </p>
            </div>
        </div>
    }

    return (
        <Carousel
            infiniteLoop={true}
            swipeable={true}
            emulateTouch={true}
            showIndicators={testimonials?.length > 1}
            showStatus={false}
            showThumbs={false}
            className="carousel__slider"
            onChange={setActiveIndex}
        >
            {testimonials?.map((item, key) => {
                return <CarouselCard item={item} key={key} />
            })}
        </Carousel>
    )
}

export default TestimonialCarousel
