import React, { PureComponent } from "react";
// import PropTypes from 'prop-types'

import "./styles.scss";

export default class CheckboxOnlyComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    return (
      <>
        <label className="custom__checkbox--only">
          <input type="checkbox" className="custom__checkbox--only-input" />
          <span className="custom__checkbox--only-check"></span>
        </label>
      </>
    );
  }
}
