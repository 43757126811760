import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import TopNavigation from "../../../components/common/TopNavigation/component";
import TablePagination from "../../../components/common/TablePagination/component";
import CheckboxOnly from "../../../components/ui/CheckboxOnly/component";
import { Export, Eye } from "../../../components/icons";
import Tabs from "../../../components/common/Tabs/container";
import { get, size } from "lodash";
import "./styles.scss";
import Impersonate from "../../../components/common/Impersonate/container";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, notification } from "antd";
import { CSVLink } from "react-csv";
import moment from "moment";
import RadioInput from "../../../components/ui/Radio/component";

export default class LeadsComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor() {
    super();
    this.state = {
      selectedExportType: "xls",
      exportModalIsOpen: false,
      modalIsOpen: false,
      leadArray: [],
      limit: 10,
      page: 1,
      getLeadsData: [],
      checkMarkData: [],
      totalCount: 0,
      currentData: 0,
      previousStep: 0,
      nextStep: 0,
      isLoading: true,
      data: {},
      allChecked: false,
    };
    this.openLeadMessageModal = this.openLeadMessageModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeExportModal = this.closeExportModal.bind(this);
    this.openExportModal = this.openExportModal.bind(this);
    this.changeExportType = this.changeExportType.bind(this);
  }

  componentDidMount() {
    this.props.getLeads({ page: 1, limit: this.state.limit });
  }

  componentDidUpdate() {
    document.title = "Lead Page | Yardpost";
    if (this.props.getLeadsPhase === "success") {
      this.props.initLeadPage();
      this.setState(
        { getLeadsData: this.props.getLeadsData, isLoading: false },
        () => {
          let nextPageCount = parseInt(
            Math.ceil(this.props.getLeadsCount / this.state.limit)
          );
          this.setState({ nextStep: nextPageCount, isLoading: false });
          this.forceUpdate();
        }
      );
    }
  }

  onCheck = async (item, key = "single") => {
    const { checkMarkData, allChecked } = this.state;
    if (key === "single") {
      const found = checkMarkData.findIndex((el) => el._id === item._id);
      if (found === -1) {
        item.createdAt = moment(item.createdAt).format("MMM D, YYYY LT");
        item.campaignName = get(item, "campaignId.campaignName", "");
        let units =
          get(item, "campaignId.propertyId.units", "") !== ""
            ? ", " + get(item, "campaignId.propertyId.units", "")
            : "";
        let address = get(item, "campaignId.propertyId.address", "");
        let listingAddress = address + units;
        item.listingAddress = listingAddress !== "" ? listingAddress : "";
        checkMarkData.push(item);
        this.setState({ checkMarkData }, () => {
          this.forceUpdate();
        });
      } else {
        checkMarkData.splice(found, 1);
        this.setState({ checkMarkData }, () => {
          this.forceUpdate();
        });
      }
    } else {
      if (!allChecked) {
        let usersData = [];
        let dataPromise = this.state.getLeadsData.map((data) => {
          data.createdAt = moment(data.createdAt).format("MMM D, YYYY  LT");
          data.campaignName = get(data, "campaignId.campaignName", "");
          let units =
            get(data, "campaignId.propertyId.units", "") !== ""
              ? ", " + get(data, "campaignId.propertyId.units", "")
              : "";
          let address = get(data, "campaignId.propertyId.address", "");
          let listingAddress = address + units;
          data.listingAddress = listingAddress !== "" ? listingAddress : "";
          usersData.push(data);
        });
        await Promise.all(dataPromise);
        this.setState({
          allChecked: true,
          checkMarkData: usersData,
        });
      } else {
        this.setState({ allChecked: false, checkMarkData: [] });
      }
    }
  };

  pageChange(value) {
    const { nextStep, previousStep, page } = this.state;
    if (value === "next") {
      let next = nextStep - 1;
      this.setState({ page: this.state.page + 1 }, () => {
        this.forceUpdate();
        this.props.getLeads({
          page: this.state.page,
          limit: this.state.limit,
        });
      });
    } else if (value === "previous") {
      this.setState(
        { page: this.state.page - 1 === 0 ? 1 : this.state.page - 1 },
        () => {
          this.forceUpdate();
          this.props.getLeads({
            limit: this.state.limit,
            page: this.state.page,
          });
        }
      );
    }
  }

  openLeadMessageModal(data) {
    this.setState({ modalIsOpen: true, data: data });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openExportModal(data) {
    this.setState({ exportModalIsOpen: true, data: data });
  }

  closeExportModal() {
    this.setState({ exportModalIsOpen: false, checkMarkData: [] });
  }

  changeExportType(e) {
    this.setState({ selectedExportType: e });
  }

  render() {
    const ExportType = [
      { key: "xls", value: "XLS (excel)" },
      { key: "csv", value: "CSV" },
    ];
    const headings = [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Email", key: "email" },
      { label: "Phone Number", key: "phone" },
      { label: "Link Name", key: "campaignName" },
      { label: "listing Address", key: "listingAddress" },
      { label: "Valuation Address", key: "valuationAddress" },
      { label: "Message", key: "message" },
      { label: "Created At", key: "createdAt" },
    ];
    const { getLeadsData, isLoading, data, checkMarkData, allChecked } =
      this.state;
    return (
      <>
        <main
          className="main"
          role="header-with-tabs"
          impersonate={
            localStorage.getItem("AdminAuthorization") &&
            localStorage.getItem("Authorization")
              ? "impersonate"
              : ""
          }
        >
          <div className="main__header">
            {localStorage.getItem("AdminAuthorization") &&
              localStorage.getItem("Authorization") && (
                <Impersonate
                  data={get(this.props.user, "user", "")}
                  {...this.props}
                />
              )}
            <TopNavigation {...this.props} />
            <div className="tabs__links">
              <Tabs attr="lead" {...this.props} />
            </div>
          </div>

          <div className="page__header">
            <div className="page__header--container">
              <div className="page__header--row">
                <div className="page__header--left">
                  <h1 className="page__header--title">Leads</h1>
                </div>
                {size(checkMarkData) > 0 && (
                  <div className="page__header--right">
                    {/* <CSVLink
                      className="btn btn__default"
                      data={checkMarkData}
                      headers={headings}
                      filename={"Leads.csv"}
                    > */}
                    <button
                      className="btn btn__default"
                      onClick={() => this.openExportModal(checkMarkData)}
                    >
                      <Export className="btn__icon" /> Export
                    </button>
                    {/* </CSVLink> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          {isLoading ? (
            <Spin
              size="large"
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 30, color: "#000", align: "center" }}
                  spin
                />
              }
              className="loader__full"
            />
          ) : (
            <div className="custom__container">
              {getLeadsData.length > 0 && (
                <div className="table-responsive table__responsive--custom">
                  <table className="table table__custom">
                    <thead>
                      <tr>
                        <th className="table__selection--column">
                          <label className="custom__checkbox--only">
                            <input
                              type="checkbox"
                              checked={size(checkMarkData)}
                              onChange={(e) =>
                                this.onCheck(getLeadsData, "multiple")
                              }
                              className="custom__checkbox--only-input"
                            />
                            <span className="custom__checkbox--only-check"></span>
                          </label>
                        </th>
                        <th>Message</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Link Name</th>
                        <th>Valuation Address</th>
                        <th>Listing Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getLeadsData.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td className="table__selection--column">
                              <label className="custom__checkbox--only">
                                <input
                                  type="checkbox"
                                  value={i}
                                  checked={checkMarkData.indexOf(data) !== -1}
                                  onChange={(e) => this.onCheck(data, "single")}
                                  className="custom__checkbox--only-input"
                                />
                                <span className="custom__checkbox--only-check"></span>
                              </label>
                            </td>
                            <td>
                              {get(data, "isFrom", "") === "newsletter" ? (
                                <div></div>
                              ) : (
                                <button
                                  className="btn"
                                  onClick={() =>
                                    this.openLeadMessageModal(data)
                                  }
                                >
                                  <Eye />
                                </button>
                              )}
                            </td>
                            <td>
                              {moment(data.createdAt).format("MMM D, YYYY LT")}
                            </td>
                            <td>
                              {get(data, "isFrom", "") === "newsletter"
                                ? "-"
                                : get(data, "firstName", "")}{" "}
                              {get(data, "lastName", "")}
                            </td>
                            <td>
                              {get(data, "email", "") === ""
                                ? "-"
                                : get(data, "email", "")}
                            </td>
                            <td>
                              {get(data, "phone", "") === ""
                                ? "-"
                                : get(data, "phone", "")}
                            </td>
                            <td>
                              {/* {get(data, "isFrom", "") === "newsletter" ||
                              get(data, "isFrom", "") === "socialLink"
                                ? get(data, "campaignName", "")
                                : get(data, "campaignId.campaignName", "")} */}
                                {get(data, "campaignId.campaignName", "")}
                            </td>
                            <td>
                              {get(data, "valuationAddress", "") !== ""
                                ? get(data, "valuationAddress", "")
                                : "-"}
                            </td>
                            <td>
                              {get(data, "campaignId.propertyId", "") !== ""
                                ? get(
                                    data,
                                    "campaignId.propertyId.address",
                                    ""
                                  ) +
                                  `${
                                    get(
                                      data,
                                      "campaignId.propertyId.units",
                                      ""
                                    ) !== ""
                                      ? ", " +
                                        get(
                                          data,
                                          "campaignId.propertyId.units",
                                          ""
                                        )
                                      : ""
                                  }`
                                : "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {getLeadsData.length === 0 && (
                <div className="table__blank">
                  <div className="table__blank--text">No Leads available.</div>
                </div>
              )}
              {getLeadsData.length > 0 && (
                <div className="table__pagination">
                  <div className="table__pagination--results">
                    {size(getLeadsData)} results
                  </div>
                  <div className="table__pagination--nav">
                    <>
                      {this.state.previousStep + (this.state.page - 1) ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--prev"
                          onClick={this.pageChange.bind(this, "previous")}
                        >
                          Previous
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--prev disabled"
                        >
                          Previous
                        </button>
                      )}
                    </>
                    <>
                      {this.state.nextStep - this.state.page > 0 ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--next"
                          onClick={this.pageChange.bind(this, "next")}
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--next disabled"
                        >
                          Next
                        </button>
                      )}
                    </>
                  </div>
                </div>
              )}
            </div>
          )}
        </main>
        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Lead Message"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Lead Message</h5>
            </div>

            <div className="react-modal-body">
              <div className="lead__message">
                <div className="form-group">
                  <label className="label-primary">Full Name</label>
                  <div className="react-modal-body-text">
                    {get(data, "firstName", "")} {get(data, "lastName", "")}
                  </div>
                </div>
                {get(data, "campaignId.campaignName", "") !== "" && (
                  <div className="form-group">
                    <label className="label-primary">Campaign Name</label>
                    <div className="react-modal-body-text">
                      {get(data, "campaignId.campaignName", "")}
                    </div>
                  </div>
                )}
                {get(data, "message", "") !== "" && (
                  <div className="form-group">
                    <label className="label-primary">Message</label>
                    <div className="react-modal-body-text">
                      {get(data, "message", "")}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                className="btn btn__default"
                onClick={this.closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.exportModalIsOpen}
          onRequestClose={this.closeExportModal}
          contentLabel="Export Lead"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Export Leads</h5>
            </div>

            <div className="react-modal-body">
              <ul className="property__type--list">
                {ExportType.map((data, i) => {
                  return (
                    <li key={i} style={{ border: 0 }}>
                      <RadioInput
                        label={data.value}
                        value={data.key}
                        type="exportType"
                        checked={this.state.selectedExportType}
                        setter={this.changeExportType}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                className="btn btn__default"
                onClick={this.closeExportModal}
              >
                Cancel
              </button>

              <CSVLink
                className="btn btn__purple ms-3"
                data={this.state.checkMarkData}
                headers={headings}
                filename={
                  this.state.selectedExportType === "xls"
                    ? "Leads.xls"
                    : "Leads.csv"
                }
              >
                Export Leads
              </CSVLink>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}
