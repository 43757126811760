import React, { PureComponent } from "react";
import NumberFormat from "react-number-format";
import { get } from "lodash";
import "./styles.scss";

export default class EmiCalculatorComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const { leadData, leadPageProperty } = this.props;
    // console.log(this.props.perMonth(),'this.props.perMonth()')
    return (
      <>
        <section className="emi__calculation">
          <div className="emi__calculation--row">
            <div className="emi__calculation--left">
              <div className="emi__calculation--wrap">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-secondary">Term</label>
                      <select
                        value={this.props.term}
                        name="term"
                        onChange={this.props.handleInputChange}
                        className="form-control custom-select form-control-flat"
                        required
                      >
                        <option value="0">Select</option>
                        <option value="30">30 Years Fixed</option>
                        <option value="20">20 Years Fixed</option>
                        <option value="15">15 Years Fixed</option>
                        <option value="10">10 Years Fixed</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-secondary">Price</label>
                      <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        allowNegative={false}
                        className="form-control form-control-flat"
                        name="salesPrice"
                        value={this.props.salesPrice}
                        onChange={this.props.handleInputChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label-secondary">Down Payment</label>
                      <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        allowNegative={false}
                        value={this.props.downPaymentAmount}
                        onChange={this.props.handleInputChange}
                        onBlur={this.props.handleBlur}
                        name="downPaymentAmount"
                        className="form-control form-control-flat"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label-secondary">Down Payment %</label>
                      <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        allowNegative={false}
                        className="form-control form-control-flat"
                        name="downPaymentPercent"
                        value={this.props.downPaymentPercent}
                        onChange={this.props.handleInputChange}
                        onBlur={this.props.handleBlur}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label-secondary">Interest</label>
                      <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        allowNegative={false}
                        className="form-control form-control-flat"
                        name="interestRate"
                        value={this.props.interestRate}
                        onChange={this.props.handleInputChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="emi__calculation--right">
              <div className="emi__calculation--output">
                <div className="emi__calculation--title">
                  Estimated Monthly Payment
                </div>
                <div className="emi__calculation--amount">
                  <span className="emi__calculation--doller">$</span>
                  {this.props.perMonth() === "NaN" ? 0 : this.props.perMonth()}
                </div>
                <div className="emi__calculation--full-amount">
                  <b>Mortage Amount:</b> $
                  {this.props.mortageAmt() === "NaN"
                    ? 0
                    : this.props.mortageAmt()}
                  .
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
