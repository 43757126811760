import { connect } from "react-redux";

import PaymentCardComponent from "./component";

const PaymentCardContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(PaymentCardComponent);

export default PaymentCardContainer;
