import React, { PureComponent } from "react";
import { get } from "lodash";

import "./styles.scss";

export default class SocialAtLeftComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const { leadPageUserData, leadSettingData, leadPageSetting } = this.props;
    return (
      <>
        <div className="social__links--at-left">
          <ul className="social__links">
            {(get(leadSettingData, "facebookUrl", "") ||
              get(leadPageSetting, "facebookUrl", "")) && (
              <li>
                <a
                  href={
                    get(leadSettingData, "facebookUrl", "")
                      ? get(leadSettingData, "facebookUrl", "")
                      : get(leadPageSetting, "facebookUrl", "")
                  }
                  target="_blank"
                  rel="noopener"
                >
                  FACEBOOK
                </a>
              </li>
            )}
            {(get(leadSettingData, "instagramUrl", "") ||
              get(leadPageSetting, "instagramUrl", "")) && (
              <li>
                <a
                  href={
                    get(leadSettingData, "instagramUrl", "")
                      ? get(leadSettingData, "instagramUrl", "")
                      : get(leadPageSetting, "instagramUrl", "")
                  }
                  target="_blank"
                  rel="noopener"
                >
                  Instagram
                </a>
              </li>
            )}
            {(get(leadSettingData, "twitterUrl", "") ||
              get(leadPageSetting, "twitterUrl", "")) && (
              <li>
                <a
                  href={
                    get(leadSettingData, "twitterUrl", "")
                      ? get(leadSettingData, "twitterUrl", "")
                      : get(leadPageSetting, "twitterUrl", "")
                  }
                  target="_blank"
                  rel="noopener"
                >
                  Twitter
                </a>
              </li>
            )}
          </ul>
        </div>
      </>
    );
  }
}
