import { connect } from "react-redux";
import EditorComponent from "./component";
import {
  editUser,
  getUser,
  resetLogin,
  resetLoginUser,
  uploadImages,
} from "../../../../../store/user/duck";

const EditorContainer = connect(
  // Map state to props
  (state) => ({
    userPhase: state.user.userPhase,
    user: state.user.user,
    editUserPhase: state.user.editUserPhase,
    editUserData: state.user.editUserData,
  }),
  // Map actions to dispatch and props
  {
    uploadImages,
    editUser,
    getUser,
    resetLogin,
    resetLoginUser,
  }
)(EditorComponent);

export default EditorContainer;
