import React, { PureComponent } from "react";
import { get } from "lodash";
import "./styles.scss";

export default class BannerComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const { leadData } = this.props;
    return (
      <>
        {get(leadData, "image", "") !== "" &&
        get(leadData, "heading", "") !== "" &&
        get(leadData, "showHeading", false) &&
        get(leadData, "showSubHeading", false) &&
        get(leadData, "subHeading", "") !== "" ? (
          <div
            className="banner__listing"
            style={{ background: get(leadData, "backgroundColor.hex", "") }}
          >
            <div className="banner__listing--row">
              <div className="banner__listing--col">
                <div className="banner__listing--content">
                  {get(leadData, "showHeading", false) && (
                    <h1
                      style={{ color: get(leadData, "textColor.hex", "") }}
                      className="banner__listing--title"
                    >
                      <pre>{get(leadData, "heading", "")}</pre>
                    </h1>
                  )}
                  {get(leadData, "showSubHeading", false) && (
                    <p
                      style={{ color: get(leadData, "textColor.hex", "") }}
                      className="banner__listing--subtitle"
                    >
                      <pre>{get(leadData, "subHeading", "")} </pre>
                    </p>
                  )}
                </div>
              </div>
              <div className="banner__listing--col">
                <div className="banner__listing--thumb">
                  <img src={get(leadData, "image", "")} alt="" />
                </div>
              </div>
            </div>
          </div>
        ) : get(leadData, "image", "") !== "" &&
          get(leadData, "heading", "") !== "" &&
          get(leadData, "showHeading", false) ? (
          <div
            className="banner__listing"
            style={{ background: get(leadData, "backgroundColor.hex", "") }}
          >
            <div className="banner__listing--row">
              <div className="banner__listing--col">
                <div className="banner__listing--content">
                  {get(leadData, "showHeading", false) && (
                    <h1
                      style={{ color: get(leadData, "textColor.hex", "") }}
                      className="banner__listing--title"
                    >
                      <pre>{get(leadData, "heading", "")}</pre>
                    </h1>
                  )}
                  {get(leadData, "showSubHeading", false) && (
                    <p
                      style={{ color: get(leadData, "textColor.hex", "") }}
                      className="banner__listing--subtitle"
                    >
                      <pre>{get(leadData, "subHeading", "")} </pre>
                    </p>
                  )}
                </div>
              </div>
              <div className="banner__listing--col">
                <div className="banner__listing--thumb">
                  <img src={get(leadData, "image", "")} alt="" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          get(leadData, "image", "") !== "" &&
          get(leadData, "showSubHeading", false) &&
          get(leadData, "subHeading", "") !== "" && (
            <div
              className="banner__listing"
              style={{ background: get(leadData, "backgroundColor.hex", "") }}
            >
              <div className="banner__listing--row">
                <div className="banner__listing--col">
                  <div className="banner__listing--content">
                    {get(leadData, "showHeading", false) && (
                      <h1
                        style={{ color: get(leadData, "textColor.hex", "") }}
                        className="banner__listing--title"
                      >
                        <pre>{get(leadData, "heading", "")}</pre>
                      </h1>
                    )}
                    {get(leadData, "showSubHeading", false) && (
                      <p
                        style={{ color: get(leadData, "textColor.hex", "") }}
                        className="banner__listing--subtitle"
                      >
                        <pre>{get(leadData, "subHeading", "")} </pre>
                      </p>
                    )}
                  </div>
                </div>
                <div className="banner__listing--col">
                  <div className="banner__listing--thumb">
                    <img src={get(leadData, "image", "")} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </>
    );
  }
}
