import React, { PureComponent } from "react";
import Dropzone from "react-dropzone";
import { Close, Plus, Remove } from "../../../../components/icons";
import RadioGroupInput from "../../../../components/ui/RadioGroupInput/component";
import CheckboxComponent from "../../../../components/ui/Checkbox/component";
import RadioInput from "../../../../components/ui/Radio/component";
import { Formik } from "formik";
import { getAllUsStates } from "../../../../constants/config";
import { get, size } from "lodash";
import Autocomplete from "react-google-autocomplete";
import NumberFormat from "react-number-format";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import imageCompression from "browser-image-compression";
import "./styles.scss";

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
const jwtToken = localStorage.getItem("Authorization");

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

const config = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "blockQuote",
  ],
};

const initialFormValues = {
  type: "rental",
  status: "",
  subPropertyType: "singleFamilyHouse",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  monthlyRent: "",
  bedrooms: "",
  bathrooms: "",
  squareFootage: "",
  interiorSqFeet: "",
  minMonths: "",
  maxMonths: "",
  availability: "",
  description: "",
  propertyFeature: [],
  buildingFeature: [],
  images: [],
  numOfImage: 0,
  askingPrice: "",
};

const SortableItem = SortableElement(
  ({ value, indexKey, index, onRemove, data, onSortEnd, onReset }) =>
    get(value, "preview", "") !== "" ? (
      <div
        className={`dropzone__grid--item index_${indexKey}`}
        key={`input-${indexKey}`}
      >
        <div className="dropzone__grid--preview">
          <img
            id={`imgId${indexKey}`}
            className="preview__thumb"
            src={value.preview}
            alt=""
          />
        </div>
        <div className="dropzone__grid--remove">
          <button
            type="button"
            key={`input-${value}`}
            onClick={() => onRemove(indexKey, onReset)}
            className="btn btn__remove"
          >
            <Remove />
          </button>
        </div>
      </div>
    ) : (
      <div
        className={`dropzone__grid--item index_${indexKey}`}
        key={`input-${indexKey}`}
      >
        <div className="dropzone__grid--preview">
          <img
            id={`imgId${indexKey}`}
            className="preview__thumb"
            src={value}
            alt=""
          />
        </div>
        <div className="dropzone__grid--remove">
          <button
            type="button"
            key={`input-${value}`}
            onClick={() => onRemove(indexKey, onReset)}
            className="btn btn__remove"
          >
            <Remove />
          </button>
        </div>
      </div>
    )
);

const SortableList = SortableContainer(
  ({ onRemove, data, onSortEnd, onReset }) => {
    return (
      <div className="dropzone__grid--sorter">
        {data.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            indexKey={index}
            index={index}
            value={value}
            onRemove={onRemove}
            onSortEnd={onSortEnd}
            onReset={onReset}
          />
        ))}
      </div>
    );
  }
);

export default class CreateListingComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      selectedPropertyType: "rental",
      selectedPropertySubType: "singleFamilyHouse",
      selectedStatus: "active",
      imageError: "",
      previewArr: [],
      buildingFeatureArray: [],
      propertyFeatureArray: [],
      data: "",
      dataError: false,
      loader: false,
    };
    this.changePropertyType = this.changePropertyType.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.changePropertySubType = this.changePropertySubType.bind(this);
    this.onSelectedStatus = this.onSelectedStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onEditorChange(value) {
    if (value == "") {
      this.setState({ dataError: true });
    } else {
      this.setState({ data: value, dataError: false });
    }
  }

  changePropertyType(e, type) {
    this.setState({ selectedPropertyType: e });
  }

  changePropertySubType(e, type) {
    this.setState({ selectedPropertySubType: e });
  }

  onSelectedStatus(value) {
    this.setState({ selectedStatus: value });
  }

  handleChange = (name) => (event) => {
    if (event.target.value === "None") {
      let error = {};
      error[name] = "required*";
      this.setState({ error });
    } else {
      this.setState({ error: {} });
    }

    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  handlePropertyFeature(e) {
    let { propertyFeatureArray } = this.state;
    if (propertyFeatureArray.includes(e.target.value)) {
      const index = propertyFeatureArray.indexOf(e.target.value);
      if (index > -1) {
        propertyFeatureArray.splice(index, 1);
      }
    } else {
      propertyFeatureArray.push(e.target.value);
    }
    this.setState({ propertyFeatureArray }, () => {
      this.forceUpdate();
    });
  }

  handleBuildingFeature(e) {
    let { buildingFeatureArray } = this.state;
    if (buildingFeatureArray.includes(e.target.value)) {
      const index = buildingFeatureArray.indexOf(e.target.value);
      if (index > -1) {
        buildingFeatureArray.splice(index, 1);
      }
    } else {
      buildingFeatureArray.push(e.target.value);
    }
    this.setState({ buildingFeatureArray }, () => {
      this.forceUpdate();
    });
  }

  bytesToSize = (bytes) => bytes / (1024 * 1024);

  addImages = async (acceptedFiles, images, setFieldValue) => {
    if (acceptedFiles.length > 0) {
      const { previewArr } = this.state;
      this.setState({ imageError: "", loader: true });
      if (acceptedFiles.length > 50) {
        const imageError = "imageLength";
        this.setState({ imageError });
        this.forceUpdate();
      } else {
        acceptedFiles.map(async (data, key) => {
          if (data.type.split("/")[0] === "image") {
            if (this.bytesToSize(data.size) <= 10) {
              acceptedFiles[key].preview = URL.createObjectURL(data);
              const fileSize = (this.bytesToSize(data.size) * 80) / 100;
              const newFle = await imageCompression(data, {
                maxSizeMB: fileSize,
              });
              const file = new File([newFle], "image");
              previewArr.push(data);
              this.setState({ previewArr, loader: false });
              this.forceUpdate();
            } else {
              const imageError = "imageize";
              this.setState({ imageError, loader: false });
              this.forceUpdate();
            }
          } else {
            const imageError = "imageType";
            this.setState({ imageError, loader: false });
            this.forceUpdate();
          }
        });
      }
      setFieldValue("images", previewArr);
    } else {
      const imageError = "imageType";
      this.setState({ imageError, loader: false });
      this.forceUpdate();
    }
    this.forceUpdate();
  };

  removeImage = (index) => {
    const files = this.state.previewArr;
    files.splice(index, 1);
    this.setState({ previewArr: files });
    this.forceUpdate();
  };

  resetImages = (setFieldValue) => {
    setFieldValue("images", this.state.previewArr);
  };

  componentDidUpdate(prev) {
    document.title = "Create Listing | Yardpost";
    if (this.props.addPropertyPhase === "success") {
      this.setState({ isSpinLoading: false });
      this.props.history.push("/listings/active");
      this.props.initPropertyPhase();
      this.openNotificationWithIcon("success", "Listing Created successfully.");
    } else if (this.props.addPropertyPhase === "error") {
      this.props.initPropertyPhase();
      this.setState({
        isSpinLoading: false,
        AddressError: this.props.addPropertyMessage,
      });
      let addError =
        this.props.addPropertyMessage !== ""
          ? this.props.addPropertyMessage
          : "Something Went Wrong!";
      this.openNotificationWithIcon("error", addError);
    }
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Yardpost",
      description: message,
    });
  };

  orderList({ oldIndex, newIndex }) {
    this.setState({
      previewArr: arrayMoveImmutable(this.state.previewArr, oldIndex, newIndex),
    });
  }

  resetImages(setFieldValue) {
    setFieldValue("images", this.state.previewArr);
  }

  selectedPlace = (place, values, setFieldValue) => {
    setFieldValue("area", "");
    let streetNumber = "";
    let streetName = "";
    let buildingName = "";
    let cityArea = "";
    if (get(place, "formatted_address", "")) {
      const address = place.formatted_address;
    }

    if (get(place, "address_components", "")) {
      let neighborhood = place.address_components.filter((obj) => {
        return obj.types.indexOf("neighborhood") !== -1;
      });

      if (neighborhood.length > 0) {
        cityArea = neighborhood[0].long_name;
        setFieldValue("area", neighborhood[0].long_name);
      }

      console.log(place.address_components, "place.address_components[i]");
      for (let i = 0; i < place.address_components.length; i++) {
        if (place.address_components[i].types[0] === "postal_code") {
          const zipcode = place.address_components[i].long_name;
          setFieldValue("zipcode", zipcode);
        }
        if (place.address_components[i].types[0] === "locality") {
          const city = place.address_components[i].long_name;
          setFieldValue("city", city);
        } else if (place.address_components[i].types[1] === "sublocality") {
          const city = place.address_components[i].long_name;
          setFieldValue("city", city);
        }

        if (place.address_components[i].types[0] === "route") {
          streetName = place.address_components[i].long_name;
        }

        if (place.address_components[i].types[0] === "street_number") {
          streetNumber = place.address_components[i].long_name;
        }

        let address = streetNumber + " " + streetName;
        setFieldValue("address", address);

        if (
          place.address_components[i].types[0] === "administrative_area_level_1"
        ) {
          const state = place.address_components[i].short_name;
          if (size(getAllUsStates) > 0) {
            let index = getAllUsStates.findIndex(
              (x) => x.abbreviation === state
            );
            if (index != -1) {
              setFieldValue("state", state);
            } else {
              setFieldValue("state", "");
            }
          }
        }
      }
    }
  };

  renderStates = (allUsStates) => {
    const states = size(allUsStates) > 0 ? allUsStates : [];
    return states.map((item, index) => {
      return (
        <option key={index} value={item.abbreviation}>
          {item.name}
        </option>
      );
    });
  };

  validateForm = (values) => {
    const errors = {};
    if (!this.state.selectedPropertyType) {
      errors.type = "Please select type";
    } else if (values.type === "0") {
      errors.type = "Please select primary agent";
    }
    if (!values.address) {
      errors.address = "Please enter address";
    }
    if (!values.city) {
      errors.city = "Please enter city name";
    }
    if (!values.state) {
      errors.state = "Please enter state";
    } else if (values.state === "0") {
      errors.state = "Please select primary agent";
    }
    if (!values.zipcode) {
      errors.zipcode = "Please enter zipcode";
    }
    if (this.state.selectedStatus !== "draft") {
      if (!values.bedrooms) {
        errors.bedrooms = "Please enter bedrooms";
      }
      if (!values.bathrooms) {
        errors.bathrooms = "Please enter bathrooms";
      }
      if (size(values.images) == 0) {
        errors.images = "Please upload at least one property image";
      }
    }
    if (values.description === "") {
      if (this.state.selectedStatus !== "draft") {
        errors.description = "Please enter description";
      } else {
        errors.description = "";
      }
    }
    if (this.state.selectedPropertyType === "rental") {
      if (this.state.selectedStatus !== "draft") {
        if (!values.monthlyRent) {
          errors.monthlyRent = "Please enter monthly rent";
        }
        if (!values.squareFootage) {
          errors.squareFootage = "Please enter square footage";
        }
        if (!values.minMonths) {
          errors.minMonths = "Please enter Min Lease";
        }
        if (!values.maxMonths) {
          errors.maxMonths = "Please enter Max Lease";
        }
        if (
          parseFloat(values.minMonths.replace(/,/g, "")) >
          parseFloat(values.maxMonths.replace(/,/g, ""))
        ) {
          errors.maxMonths = "Max Lease must be grater than Min Lease";
        }
        if (values.availability === "" || values.availability === "Custom") {
          errors.availability = "Please select availability";
        }
      }
    } else if (this.state.selectedPropertyType === "for-purchase") {
      if (!values.askingPrice) {
        errors.askingPrice = "Please enter asking price";
      }
    }
    return errors;
  };

  render() {
    const fieldA = {
      form: {
        message: "hey",
      },
    };
    const fieldB = undefined;
    const res = fieldB?.form;
    const propertySubType = [
      { key: "singleFamilyHouse", value: "Single Family House" },
      { key: "townhouse", value: "Townhouse" },
      { key: "condo", value: "Condo" },
      { key: "co-op", value: "Co-op" },
      { key: "apartment", value: "Apartment" },
      { key: "land", value: "Land" },
      { key: "office", value: "Office" },
    ];
    const propertyStatus = [
      { key: "active", value: "Active" },
      { key: "sold", value: "Closed/Sold" },
      { key: "draft", value: "Draft" },
    ];
    const propertyType = [
      { key: "rental", value: "Rental" },
      { key: "for-purchase", value: "For Purchase" },
    ];
    const propertyFeature = [
      "Dishwasher",
      "Laundry in building",
      "Balcony",
      "Laundry in unit",
      "Fireplace",
      "Central AC",
      "Garage",
      "Central heat",
      "Pool",
      "Elevator",
      "Hardwood floors",
      "Exposed brick",
    ];
    const buildingFeature = [
      "Pool",
      "Bike room",
      "Gym",
      "Mail room",
      "Fireplace",
      "Children's playroom",
      "Garage",
      "Roofdeck",
      "Outdoor grills",
      "Elevator",
      "Media room",
      "Live in super",
      "Wheelchair accessible",
      "Doorman",
      "Laundry room",
    ];
    const from = get(this.props, "match.params.list_id", ""); //get(this.props,'location.state.from','')
    const initialValues = from
      ? get(this.props, "getPropertyDetailsData", "")
      : initialFormValues;
    const { imageError } = this.state;
    const custom_config = {
      extraPlugins: [MyCustomUploadAdapterPlugin],
      // toolbar: {
      //   shouldNotGroupWhenFull: true,
      // },
      toolbar: {
        items: [
          "bold",
          "italic",
          "link",
          "|",
          "undo",
          "redo",
          "|",
          "numberedList",
          "bulletedList",
        ],
      },
      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
      },
    };

    function MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader, this.props);
      };
    }

    class MyUploadAdapter {
      constructor(props) {
        // CKEditor 5's FileLoader instance.
        this.loader = props;
        // URL where to send files.
        this.url = `${HOSTNAME}/api/v1/uploadImage`;
      }

      // Starts the upload process.
      upload() {
        return new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject);
          this._sendRequest();
        });
      }

      // Aborts the upload process.
      abort() {
        if (this.xhr) {
          this.xhr.abort();
        }
      }

      // Example implementation using XMLHttpRequest.
      _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());
        xhr.open("POST", this.url, true);
        xhr.responseType = "json";
        //xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
        xhr.setRequestHeader("Authorization", jwtToken);
      }

      // Initializes XMLHttpRequest listeners.
      _initListeners(resolve, reject) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText =
          "Couldn't upload file:" + ` ${loader.file.name}.`;

        xhr.addEventListener("error", () => reject(genericErrorText));
        xhr.addEventListener("abort", () => reject());
        xhr.addEventListener("load", () => {
          const response = xhr.response;
          if (!response || response.error) {
            return reject(
              response && response.error
                ? response.error.message
                : genericErrorText
            );
          }

          // If the upload is successful, resolve the upload promise with an object containing
          // at least the "default" URL, pointing to the image on the server.
          resolve({
            default: response.data.images[0],
          });
        });

        if (xhr.upload) {
          xhr.upload.addEventListener("progress", (evt) => {
            if (evt.lengthComputable) {
              loader.uploadTotal = evt.total;
              loader.uploaded = evt.loaded;
            }
          });
        }
      }

      _sendRequest() {
        const data = new FormData();

        this.loader.file.then((result) => {
          data.append("file", result);
          this.xhr.send(data);
        });
      }
    }
    const { data } = this.state;
    return (
      <>
        <main className="main" role="head">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validate={this.validateForm.bind(this)}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                this.setState({ errors: {} });
                values.userId = get(this.props.user, "user._id", "");
                values.status = this.state.selectedStatus;
                if (values.status === "") {
                  values.propertyStatus = "active";
                } else {
                  values.propertyStatus = values.status.toLowerCase();
                }
                values.type = this.state.selectedPropertyType;
                values.subPropertyType = this.state.selectedPropertySubType;
                values.propertyFeature = this.state.propertyFeatureArray;
                values.buildingFeature = this.state.buildingFeatureArray;
                let form_data = new FormData();
                // form_data.append(`numOfImage`, this.state.previewArr.length);
                values.numOfImage = this.state.previewArr.length;
                for (let key in values) {
                  if (key == "images") {
                    this.state.previewArr.map((file, key) => {
                      if (get(file, "preview", "").includes("amazon") || get(file, "preview", "").includes("cloudinary")) {
                        form_data.append("images", file.preview);
                      } else {
                        form_data.append("images", file);
                      }
                    });
                  } else if (key == "availability") {
                    if (values[key] === "1") {
                      form_data.append(key, this.state.availabilityDate);
                    } else {
                      form_data.append(key, values[key]);
                    }
                  } else {
                    form_data.append(key, values[key]);
                  }
                }
                if (true) {
                  this.setState({ isSpinLoading: true });
                  this.props.addProperty(form_data);
                }
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              getValues,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="main__header">
                  <header className="head">
                    <div className="head__container">
                      <div className="head__row">
                        <div className="head__left">
                          <div className="head__close">
                            <button
                              onClick={() =>
                                this.props.history.push("/listings/active")
                              }
                              className="btn btn__close"
                            >
                              <Close />
                            </button>
                          </div>
                          <div className="head__divider"></div>
                          <div className="head__title">Create Listing</div>
                        </div>
                        <div className="head__right">
                          <button
                            type="submit"
                            disabled={this.state.isSpinLoading}
                            className="btn btn__purple btn__head"
                          >
                            {this.state.isSpinLoading ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              <>Save & Exit</>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </header>
                </div>
                <div className="custom__container">
                  <div className="custom__wrap">
                    <div className="form-group">
                      <label className="label-primary">
                        Type<span className="required">*</span>
                      </label>
                      <ul className="property__type--list">
                        {propertyType.map((data, i) => {
                          return (
                            <li key={i}>
                              <RadioInput
                                label={data.value}
                                value={data.key}
                                type="pro-type"
                                checked={this.state.selectedPropertyType}
                                setter={this.changePropertyType}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="form-group">
                      <label className="label-primary">
                        Property Type<span className="required">*</span>
                      </label>
                      <ul className="property__type--list">
                        {propertySubType.map((data, i) => {
                          return (
                            <li key={i}>
                              <RadioInput
                                label={data.value}
                                value={data.key}
                                type="pro-sub-type"
                                checked={this.state.selectedPropertySubType}
                                setter={this.changePropertySubType}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="form-group">
                      <label className="label-primary">
                        Status<span className="required">*</span>
                      </label>
                      <div className="d-flex">
                        {propertyStatus.map((data, i) => {
                          return (
                            <>
                              <RadioGroupInput
                                value={data.key}
                                setter={this.onSelectedStatus}
                                checked={this.state.selectedStatus}
                                label={data.value}
                              />
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="label-primary">
                            Street Address<span className="required">*</span>
                          </label>
                          <Autocomplete
                            className="form-control material-textfield-input"
                            name="address"
                            value={values.address}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onPlaceSelected={(place) =>
                              this.selectedPlace(place, values, setFieldValue)
                            }
                            types={["geocode"]}
                            required
                          />

                          {((errors.address && touched.address) ||
                            get(this.state, "errors.address", false)) && (
                            <div className="invalid-feedback">
                              {errors.address ||
                                get(this.state, "errors.city", "")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="label-primary">Unit or Apt #</label>
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="units"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.units}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="label-primary">
                            City<span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="city"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            required
                          />
                          {((errors.city && touched.city) ||
                            get(this.state, "errors.city", false)) && (
                            <div className="invalid-feedback">
                              {errors.city ||
                                get(this.state, "errors.city", "")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="label-primary">
                            State<span className="required">*</span>
                          </label>
                          <select
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control custom-select material-textfield-input"
                            required
                          >
                            <option
                              value="0"
                              disabled={values.state ? true : false}
                            >
                              Select
                            </option>
                            {size(getAllUsStates) > 0 &&
                              this.renderStates(getAllUsStates)}
                          </select>
                          {((errors.state && touched.state) ||
                            get(this.state, "errors.state", false)) && (
                            <div className="invalid-feedback">
                              {errors.state ||
                                get(this.state, "errors.state", "")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="label-primary">
                            Zip<span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="zipcode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.zipcode}
                            required
                          />
                          {((errors.zipcode && touched.zipcode) ||
                            get(this.state, "errors.zipcode", false)) && (
                            <div className="invalid-feedback">
                              {errors.zipcode ||
                                get(this.state, "errors.zipcode", "")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {this.state.selectedPropertyType === "rental" ? (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="label-primary">
                              Price
                              {this.state.selectedStatus !== "draft" ? (
                                <span className="required">*</span>
                              ) : (
                                ""
                              )}
                            </label>
                            <NumberFormat
                              thousandSeparator={true}
                              decimalScale={2}
                              allowNegative={false}
                              className="form-control material-textfield-input"
                              name="monthlyRent"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.monthlyRent}
                              required
                            />
                            {errors.monthlyRent && touched.monthlyRent && (
                              <div className="invalid-feedback">
                                {this.state.selectedStatus !== "draft"
                                  ? errors.monthlyRent
                                  : ""}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="label-primary">
                              Price
                              {this.state.selectedStatus !== "draft" ? (
                                <span className="required">*</span>
                              ) : (
                                ""
                              )}
                            </label>
                            <NumberFormat
                              thousandSeparator={true}
                              decimalScale={2}
                              allowNegative={false}
                              className="form-control material-textfield-input"
                              name="askingPrice"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.askingPrice}
                              required
                            />
                            {errors.askingPrice && touched.askingPrice && (
                              <div className="invalid-feedback">
                                {this.state.selectedStatus !== "draft"
                                  ? errors.askingPrice
                                  : ""}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-md-6">
                        {this.state.selectedPropertyType === "rental" ? (
                          <div className="form-group">
                            <label className="label-primary">
                              Square Footage
                              {this.state.selectedStatus !== "draft" ? (
                                <span className="required">*</span>
                              ) : (
                                ""
                              )}
                            </label>
                            <NumberFormat
                              thousandSeparator={true}
                              decimalScale={2}
                              allowNegative={false}
                              type="text"
                              className="form-control material-textfield-input"
                              name="squareFootage"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.squareFootage}
                              required
                            />
                            {errors.squareFootage && touched.squareFootage && (
                              <div className="invalid-feedback">
                                {this.state.selectedStatus !== "draft"
                                  ? errors.squareFootage
                                  : ""}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="form-group">
                            <label className="label-primary">
                              Sq.Ft
                              {this.state.selectedStatus !== "draft" ? (
                                <span className="required">*</span>
                              ) : (
                                ""
                              )}
                            </label>
                            <NumberFormat
                              thousandSeparator={true}
                              decimalScale={2}
                              allowNegative={false}
                              type="text"
                              className="form-control material-textfield-input"
                              name="interiorSqFeet"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.interiorSqFeet}
                              required
                            />
                            {errors.interiorSqFeet &&
                              touched.interiorSqFeet && (
                                <div className="invalid-feedback">
                                  {this.state.selectedStatus !== "draft"
                                    ? errors.interiorSqFeet
                                    : ""}
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">
                            Bedrooms
                            {this.state.selectedStatus !== "draft" ? (
                              <span className="required">*</span>
                            ) : (
                              ""
                            )}
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            // prefix={'$'}
                            type="text"
                            className="form-control material-textfield-input"
                            name="bedrooms"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bedrooms}
                            required
                          />
                          {errors.bedrooms && touched.bedrooms && (
                            <div className="invalid-feedback">
                              {this.state.selectedStatus !== "draft"
                                ? errors.bedrooms
                                : ""}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label-primary">
                            Bathrooms
                            {this.state.selectedStatus !== "draft" ? (
                              <span className="required">*</span>
                            ) : (
                              ""
                            )}
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            className="form-control material-textfield-input"
                            name="bathrooms"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bathrooms}
                            required
                          />
                          {errors.bathrooms && touched.bathrooms && (
                            <div className="invalid-feedback">
                              {this.state.selectedStatus !== "draft"
                                ? errors.bathrooms
                                : ""}
                            </div>
                          )}
                        </div>
                      </div>
                      {this.state.selectedPropertyType === "rental" && (
                        <>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label-primary">
                                Min Lease
                                {this.state.selectedStatus !== "draft" ? (
                                  <span className="required">*</span>
                                ) : (
                                  ""
                                )}
                              </label>
                              <NumberFormat
                                thousandSeparator={true}
                                decimalScale={2}
                                allowNegative={false}
                                className="form-control material-textfield-input"
                                name="minMonths"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.minMonths}
                                required
                              />
                              {errors.minMonths && touched.minMonths && (
                                <div className="invalid-feedback">
                                  {this.state.selectedStatus !== "draft"
                                    ? errors.minMonths
                                    : ""}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label-primary">
                                Max Lease
                                {this.state.selectedStatus !== "draft" ? (
                                  <span className="required">*</span>
                                ) : (
                                  ""
                                )}
                              </label>
                              <NumberFormat
                                thousandSeparator={true}
                                decimalScale={2}
                                allowNegative={false}
                                className="form-control material-textfield-input"
                                name="maxMonths"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.maxMonths}
                                required
                              />
                              {errors.maxMonths && touched.maxMonths && (
                                <div className="invalid-feedback">
                                  {this.state.selectedStatus !== "draft"
                                    ? errors.maxMonths
                                    : ""}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {this.state.selectedPropertyType === "rental" && (
                      <div className="form-group">
                        <label className="label-primary">
                          Availability
                          {this.state.selectedStatus !== "draft" ? (
                            <span className="required">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          type="text"
                          className="form-control material-textfield-input"
                          name="availability"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.availability}
                          required
                        />
                        {errors.availability && touched.maxMonths && (
                          <div className="invalid-feedback">
                            {this.state.selectedStatus !== "draft"
                              ? errors.availability
                              : ""}
                          </div>
                        )}
                      </div>
                    )}
                    <div className="form-group">
                      <label className="label-primary">
                        Property Description
                        {this.state.selectedStatus !== "draft" ? (
                          <span className="required">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      {/*
                        <div className="ck__editor--customized">
                        <CKEditor
                          editor={DecoupledEditor}
                          data={data}
                          config={custom_config}
                          onInit={(editor) => {
                            editor.execute("fontSize", { value: 20 });
                            editor.ui
                              .getEditableElement()
                              .parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                              );
                          }}
                          onChange={(event, editor) =>
                            this.onEditorChange(editor.getData())
                          }
                        />*/}
                      <div className="form-group material-textfield">
                        <textarea
                          type="text"
                          className="form-control material-textfield-input"
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          required
                          cols={20}
                          rows={10}
                          wrap="description"
                        ></textarea>
                        {errors.description && (
                          <div className="invalid-feedback">
                            {this.state.selectedStatus !== "draft"
                              ? "Please enter description."
                              : ""}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="label-primary">
                        Property Photos
                        {this.state.selectedStatus !== "draft" ? (
                          <span className="required">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <Dropzone
                        accept="image/*"
                        multiple={true}
                        disabled={this.state.previewArr.length >= 50}
                        onDrop={(acceptedFiles) =>
                          this.addImages(
                            acceptedFiles,
                            values.images,
                            setFieldValue
                          )
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone">
                            <div className="dropzone__area" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <button type="button" className="btn btn__purple">
                                {this.state.loader ? (
                                  <Spin indicator={antIcon} />
                                ) : (
                                  <>
                                    <Plus className="btn__icon" /> Upload Photo
                                  </>
                                )}
                              </button>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      {errors.images && touched.images && (
                        <div className="invalid-feedback">
                          {this.state.selectedStatus !== "draft"
                            ? errors.images
                            : ""}
                        </div>
                      )}
                      {imageError == "imageLength" && (
                        <>
                          <div className="invalid-feedback">
                            Maximum 50 photos
                          </div>
                        </>
                      )}
                      {imageError === "imageType" && (
                        <>
                          <div className="invalid-feedback">
                            Please use jpeg or png format.
                          </div>
                        </>
                      )}
                      {imageError == "imageize" && (
                        <>
                          <div className="invalid-feedback">
                            Please upload an image that in 10mb or smaller. You
                            can use the following compressors to make shrink
                            your images:
                          </div>
                          <div className="invalid-feedback">
                            JPEG or JPG: https://compressjpeg.com/
                          </div>
                          <div className="invalid-feedback">
                            PNG: https://compresspng.com/"
                          </div>
                        </>
                      )}
                      {this.state.previewArr.length == 50 && (
                        <div className="invalid-feedback">
                          Maximum 50 photos
                        </div>
                      )}
                      <div className="dropzone__grid">
                        {/*this.state.previewArr.length > 0 &&
                          this.state.previewArr.map((value, i) => {
                            return (
                              <div className="dropzone__grid--item">
                                <div className="dropzone__grid--preview">
                                  <img
                                    className="preview__thumb"
                                    src={value.preview}
                                    alt=""
                                  />
                                  <div className="dropzone__grid--remove">
                                    <button className="btn btn__remove">
                                      <Remove
                                        onClick={(e) => this.removeImage(i)}
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })*/}
                        <SortableList
                          axis={"xy"}
                          onRemove={(index, onReset) =>
                            this.removeImage(index, onReset)
                          }
                          // imageRotate={(index) => this.imageRotate(index)}
                          data={this.state.previewArr}
                          onSortEnd={this.orderList.bind(this)}
                          onReset={() => this.resetImages(setFieldValue)}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="label-primary">Property Features</label>
                      <ul className="property__features--list">
                        {propertyFeature.map((option, index) => {
                          return (
                            <li key={index}>
                              <CheckboxComponent
                                index={index}
                                value={option}
                                handleFeature={this.handlePropertyFeature.bind(
                                  this
                                )}
                                featureArray={this.state.propertyFeatureArray}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="form-group">
                      <label className="label-primary">Building Features</label>
                      <ul className="building__features--list">
                        {buildingFeature.map((option, index) => {
                          return (
                            <li key={index}>
                              <CheckboxComponent
                                index={index}
                                value={option}
                                handleFeature={this.handleBuildingFeature.bind(
                                  this
                                )}
                                featureArray={this.state.buildingFeatureArray}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <button
                      type="submit"
                      disabled={this.state.isSpinLoading}
                      className="btn btn-lg btn__purple w-100"
                    >
                      {this.state.isSpinLoading ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <>Create a listing</>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </main>
      </>
    );
  }
}
