import { fetch } from "../../utils";
import { get } from "lodash";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const addProperty = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/property/create-property`, {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json'
    // },
    body: credentials, //JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendSMS = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/sharePropertySMS`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendMail = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/shareProperty`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addInquiry = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/create_inquiry`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCountryAndState = (credentials) => {
  let url = HOSTNAME + `/stateAndCountry?country_id=${credentials.country_id}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    //body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// get all property by me & my team member
export const getAllProperty = (credentials) => {
  let url =
    HOSTNAME +
    `/api/v1/userAllproperty?page=${credentials.page}&limit=${credentials.limit}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    //body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getAllPropertyAddress = (credentials) => {
  let url = HOSTNAME + `/api/v1/user/getAllListingAddress`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    //body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
// get all property by me & my team member
export const getFeaturedProperty = (credentials) => {
  let url = HOSTNAME + "/api/v1/getFeatured?id=" + credentials;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    //body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAmenitiesProperty = (credentials) => {
  let url = HOSTNAME + "/api/v1/getAmenities?id=" + credentials;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    //body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// Get all my property created by me
export const getProperty = (credentials) => {
  return fetch(
    `${HOSTNAME}/api/v1/user/property?page=${credentials.page}&activeTab=${
      credentials.activeTab
    }&sortKey=${credentials.sortKey}&sortType=${
      credentials.sortType
    }&search=${get(credentials, "search", "")}&limit=${get(
      credentials,
      "limit",
      ""
    )}&type=${get(credentials, "type", "")}&status=${get(
      credentials,
      "status",
      ""
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      //body: JSON.stringify(credentials)
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPropertyOptions = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/propertyOptions`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    //body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteProperty = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/delete_property`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const copyProperty = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/propertyCopy`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPropertyDetail = (credentials) => {
  // console.log(credentials,'credentials')
  return fetch(
    `${HOSTNAME}/api/v1/getProperty?slugUrl=` +
      get(credentials, "slugUrl", "") +
      `&mls=${get(credentials, "mls", "")}&userId=${get(
        credentials,
        "userId",
        ""
      )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      //body: JSON.stringify(credentials)
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPropertyDetails = (id) => {
  return fetch(`${HOSTNAME}/api/v1/user/property-id?id=` + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    //body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPropertyLeadsCount = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/propertyLeadCount`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPropertyLeads = (id) => {
  return fetch(`${HOSTNAME}/api/v1/propertyLeads?id=` + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getClientDetails = (id) => {
  return fetch(`${HOSTNAME}/api/v1/getClientDetails?id=` + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getExistProperty = (id) => {
  return fetch(`${HOSTNAME}/api/v1/exist_property?id=` + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadListings = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/uploadListings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
