import React, { PureComponent } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { get } from "lodash";
import "./styles.scss";

export default class ColorPickerComponent extends PureComponent {
  state = {
    displayColorPicker: false,
    color: {
      hex: "#ffffff",
      rgb: {
        r: "255",
        g: "255",
        b: "255",
        a: "1",
      },
    },
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (c) => {
    const { color } = this.state;
    color.rgb = c.rgb;
    color.hex = c.hex;
    this.setState({ color });
    this.props.handleColorChange(color, this.props.name);
  };

  handleChangeColour = (e) => {
    const { color } = this.state;
    const rgbCode = this.hexToRgb(e.target.value);
    const obj = {
      hex: e.target.value,
      rgb: rgbCode,
    };
    this.setState({ color: obj });
    if (get(obj, "rgb", "") === null) {
      this.handleChange(color);
    } else {
      this.handleChange(obj);
    }
  };

  hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: 1,
        }
      : {
          r: "255",
          g: "255",
          b: "255",
          a: "1",
        };
  };

  render() {
    const { listingWebsiteTemplate } = this.props;
    let background = "";
    if (this.props.name === "textColor") {
      background = `rgba(${get(
        listingWebsiteTemplate,
        "textColor.rgb.r",
        "241"
      )}, ${get(listingWebsiteTemplate, "textColor.rgb.g", "112")}, ${get(
        listingWebsiteTemplate,
        "textColor.rgb.b",
        ""
      )}, ${get(listingWebsiteTemplate, "textColor.rgb.a", "")})`;
    } else {
      background = `rgba(${get(
        listingWebsiteTemplate,
        "backgroundColor.rgb.r",
        "241"
      )}, ${get(listingWebsiteTemplate, "backgroundColor.rgb.g", "112")}, ${get(
        listingWebsiteTemplate,
        "backgroundColor.rgb.b",
        ""
      )}, ${get(listingWebsiteTemplate, "backgroundColor.rgb.a", "")})`;
    }
    const styles = reactCSS({
      default: {
        main: {
          position: "relative",
        },
        wrap: {
          display: "inline-flex",
        },
        hexInput: {
          marginLeft: "15px",
          width: 110,
        },
        color: {
          width: "44px",
          height: "44px",
          borderRadius: "4px",
          background: background,
        },
        swatch: {
          background: "#fff",
          borderRadius: "4px",
          display: "inline-block",
          cursor: "pointer",
          border: "1px solid #D9DCE1",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });
    return (
      <div style={styles.main}>
        <div style={styles.wrap}>
          <div style={styles.swatch} onClick={this.handleClick}>
            <div style={styles.color} />
          </div>
          <div style={styles.hexInput}>
            <input
              name={this.props.name}
              type="text"
              value={
                this.props.name === "textColor"
                  ? get(listingWebsiteTemplate, "textColor.hex", "")
                  : get(listingWebsiteTemplate, "backgroundColor.hex", "")
              }
              onChange={(e) => this.handleChangeColour(e)}
              className="form-control"
              // value={this.state.color.hex}
            />
          </div>
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={
                this.props.name === "textColor"
                  ? get(listingWebsiteTemplate, "textColor", "")
                  : get(listingWebsiteTemplate, "backgroundColor", "")
              }
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
