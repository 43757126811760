import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { get } from "lodash";
import { Desktop, Mobile } from "../../../../../components/icons";
import "./styles.scss";
import moment from "moment";

// const REACT_APP_URL = 'http://localhost:3000'
const REACT_APP_URL = "https://app.yardpost.com";

export default class PreviewComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile: "preview",
      width: "40%",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
    // this.props.getGoogleAnalyticAccNo()
  }
  componentDidUpdate() {
    if (this.props.isUpdate) {
      this.updateDimensions();
      this.setState({ isUpdate: false });
    }
  }
  updateDimensions = () => {
    // let terminalHeader = $("#mac__window--header").height()
    let maxWidth = $("#editor-webisite-preview").width(),
      maxHeight = $("#editor-webisite-preview").height();
    let basePage = {
      width: this.state.width === "100%" ? 1340 : 375,
      height: this.state.width === "100%" ? 857 : 667,
      scale: 1,
      scaleX: 1,
      scaleY: 1,
    };
    let scaleX = 1,
      scaleY = 1;
    scaleX = maxWidth / basePage.width;
    scaleY = maxHeight / basePage.height;
    basePage.scaleX = scaleX;
    basePage.scaleY = scaleY;
    basePage.scale = scaleX > scaleY ? scaleY : scaleX;
    if (basePage.scale > 1) {
      basePage.scale = 1;
    }
    let newLeftPos = Math.abs(
      Math.floor((basePage.width * basePage.scale - maxWidth) / 2)
    );
    let newTopPos = Math.abs(
      Math.floor((basePage.height * basePage.scale - maxHeight) / 2)
    );
    // document.getElementById('responsive-scale-wrapper').setAttribute('style', 'transform:scale(' + basePage.scale.toFixed(6) + ');left:' + newLeftPos + 'px;top:' + newTopPos + 'px;')
    document
      .getElementById("responsive-scale-wrapper")
      .setAttribute(
        "style",
        "transform:scale(" +
          basePage.scale.toFixed(6) +
          ") translate(-50%, -50%);"
      );
    // document.getElementById('ifram_preview') && document.getElementById('ifram_preview').contentDocument.location.reload(true);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  onMobileView = () => {
    localStorage.removeItem("width");
    this.setState({ width: "40%" }, () => {
      setTimeout(() => {
        this.updateDimensions();
      }, 500);
    });
  };

  onDesView = () => {
    localStorage.setItem("width", "100");
    this.setState({ width: "100%" }, () => {
      setTimeout(() => {
        this.updateDimensions();
      }, 500);
    });
  };

  render() {
    const { width } = this.state;
    const { campaignType, campaignId, leadPageByIdData, publishLeadPageData } =
      this.props;
      const currentDomain = 
      process.env.REACT_APP_DASHBOARD_URL
      // "http://localhost:3000"
      // "https://yardpost-frontend-04tp.onrender.com"
    // console.log(this.props.campaignType, "rajat");
    return (
      <>
        <div className="preview__header">
          <div className="preview__header--row">
            <div className="preview__header--left">
              <div className="preview__header--title">
                Preview of your {leadPageByIdData?.campaignName}
              </div>
              {this.props.campaignType === "social-link"
                ? get(this.props.userData, "updatedAt", "") && (
                    <div className="preview__header--subtitle">
                      Last updated on{" "}
                      {moment(get(this.props.userData, "updatedAt", "")).format(
                        "MMM D, YYYY LT"
                      )}
                    </div>
                  )
                : get(this.props, "publishDate", "") && (
                    <div className="preview__header--subtitle">
                      Last published on{" "}
                      {moment(this.props.publishDate).format("MMM D, YYYY LT")}
                    </div>
                  )}
            </div>
            <div className="preview__header--right">
              <div className="preview__header--action">
                <button
                  onClick={() => this.onDesView()}
                  className={
                    width === "100%"
                      ? "btn btn__desktop active"
                      : "btn btn__desktop"
                  }
                >
                  <Desktop />
                </button>
                <button
                  onClick={() => this.onMobileView()}
                  className={
                    width === "40%"
                      ? "btn btn__mobile active"
                      : "btn btn__desktop"
                  }
                >
                  <Mobile />
                </button>
                {campaignType === "sell" && (
                  <Link
                    to={`/preview/sell/${get(
                      leadPageByIdData,
                      "slugUrl",
                      ""
                    )}/${campaignId}`}
                    target="_blank"
                    className="btn btn__default btn__fullscreen"
                  >
                    Full Screen
                  </Link>
                )}
                {campaignType === "listing-website" && (
                  <Link
                    to={`/preview/listing-website/${get(
                      leadPageByIdData,
                      "slugUrl",
                      ""
                    )}/${campaignId}`}
                    target="_blank"
                    className="btn btn__default btn__fullscreen"
                  >
                    Full Screen
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div id="editor-webisite-preview" className="editor__webisite--preview">
          <div
            id="responsive-scale-wrapper"
            className={
              width === "100%"
                ? "responsive__scale--wrapper is__desktop"
                : "responsive__scale--wrapper is__mobile"
            }
          >
            <div className="mac__window">
              <div id="mac__window--header" className="mac__window--header">
                <div className="mac__window--dot dot__1"></div>
                <div className="mac__window--dot dot__2"></div>
                <div className="mac__window--dot dot__3"></div>
              </div>
              <div className="mac__window--body">
                {campaignType === "sell" && (
                  <iframe
                    className="preview__website--iframe"
                    id="ifram_preview"
                    //src={`http://localhost:3000/preview/sell/${campaignId}`}
                    // src={`https://stag-app.yardpost.com/preview/sell/${campaignId}`}
                    src={`${currentDomain}/preview/sell/${campaignId}`}
                    allowFullScreen={true}
                    width="100%"
                    height="100%"
                    sandbox="allow-scripts allow-forms allow-pointer-lock  allow-popups allow-same-origin allow-top-navigation"
                  ></iframe>
                )}
                {campaignType === "listing-website" && (
                  <iframe
                    id="ifram_preview"
                    className="preview__website--iframe"
                    //src={`http://localhost:3000/preview/listing-website/${campaignId}`}
                    src={`${currentDomain}/preview/listing-website/${campaignId}`}
                    allowFullScreen={true}
                    width="100%"
                    height="100%"
                    sandbox="allow-scripts allow-forms allow-pointer-lock  allow-popups allow-same-origin allow-top-navigation"
                  ></iframe>
                )}
                {campaignType === "social-link" && (
                  <iframe
                    id="ifram_preview"
                    className="preview__website--iframe"
                    src={`${currentDomain}/preview/social-link-preview/${campaignId}`}
                    allowFullScreen={true}
                    width="100%"
                    height="100%"
                    sandbox="allow-scripts allow-forms allow-pointer-lock  allow-popups allow-same-origin allow-top-navigation"
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
