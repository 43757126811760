import { connect } from "react-redux";

import TrialPeriodComponent from "./component";

import {
  getUser,
  addCard,
  getSubscriptions,
  resetLoginUser,
  applyPromoCode,
  initPromoPhase,
} from "../../../store/user/duck";

const TrialPeriodContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    addCardPhase: state.user.addCardPhase,
    promoPhase: state.user.promoPhase,
    addCardMessage: state.user.addCardMessage,
    subscriptionPhase: state.user.subscriptionPhase,
    subscriptions: state.user.subscriptions,
    promoData: state.user.promoData,
    // Map actions to dispatch and props
  }),
  {
    getUser,
    applyPromoCode,
    initPromoPhase,
    resetLoginUser,
    getSubscriptions,
    addCard,
  }
)(TrialPeriodComponent);

export default TrialPeriodContainer;
