// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from "immutable";
import { assign, isEmpty } from "lodash";
import Cookies from "universal-cookie";
import { INIT, LOADING, SUCCESS, ERROR } from "../../constants/phase";
import { get } from "lodash";

import { fromPromise } from "rxjs/observable/fromPromise";
import { of } from "rxjs";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
const cookies = new Cookies();

export const GET_LEAD_PAGE_BY_ID = "yardpost/leadPage/GET_LEAD_PAGE_BY_ID";
export const GET_LEAD_PAGE_BY_ID_SUCCESS =
  "yardpost/leadPage/GET_LEAD_PAGE_BY_ID_SUCCESS";
export const GET_LEAD_PAGE_BY_ID_ERROR =
  "yardpost/leadPage/GET_LEAD_PAGE_BY_ID_ERROR";

export const GET_LISTINGS = "yardpost/leadPage/GET_LISTINGS";
export const GET_LISTINGS_SUCCESS = "yardpost/leadPage/GET_LISTINGS_SUCCESS";
export const GET_LISTINGS_ERROR = "yardpost/leadPage/GET_LISTINGS_ERROR";

export const GET_LEAD_PAGE = "yardpost/leadPage/GET_LEAD_PAGE";
export const GET_LEAD_PAGE_SUCCESS = "yardpost/leadPage/GET_LEAD_PAGE_SUCCESS";
export const GET_LEAD_PAGE_ERROR = "yardpost/leadPage/GET_LEAD_PAGE_ERROR";

export const GET_LEADS = "yardpost/leadPage/GET_LEADS";
export const GET_LEADS_SUCCESS = "yardpost/leadPage/GET_LEADS_SUCCESS";
export const GET_LEADS_ERROR = "yardpost/leadPage/GET_LEADS_ERROR";

export const GET_LEAD_PAGE_BY_TYPE = "yardpost/leadPage/GET_LEAD_PAGE_BY_TYPE";
export const GET_LEAD_PAGE_BY_TYPE_SUCCESS =
  "yardpost/leadPage/GET_LEAD_PAGE_BY_TYPE_SUCCESS";
export const GET_LEAD_PAGE_BY_TYPE_ERROR =
  "yardpost/leadPage/GET_LEAD_PAGE_BY_TYPE_ERROR";

export const CREATE_LEAD_PAGE = "yardpost/leadPage/CREATE_LEAD_PAGE";
export const CREATE_LEAD_PAGE_SUCCESS =
  "yardpost/leadPage/CREATE_LEAD_PAGE_SUCCESS";
export const CREATE_LEAD_PAGE_ERROR =
  "yardpost/leadPage/CREATE_LEAD_PAGE_ERROR";

export const PUBLISH_LEAD_PAGE = "yardpost/leadPage/PUBLISH_LEAD_PAGE";
export const PUBLISH_LEAD_PAGE_SUCCESS =
  "yardpost/leadPage/PUBLISH_LEAD_PAGE_SUCCESS";
export const PUBLISH_LEAD_PAGE_ERROR =
  "yardpost/leadPage/PUBLISH_LEAD_PAGE_ERROR";

export const PUBLISH_LEAD_PAGE_BY_ID =
  "yardpost/leadPage/PUBLISH_LEAD_PAGE_BY_ID";
export const PUBLISH_LEAD_PAGE_BY_ID_SUCCESS =
  "yardpost/leadPage/PUBLISH_LEAD_PAGE_BY_ID_SUCCESS";
export const PUBLISH_LEAD_PAGE_BY_ID_ERROR =
  "yardpost/leadPage/PUBLISH_LEAD_PAGE_BY_ID_ERROR";

export const UPLOAD_IMAGE = "yardpost/leadPage/UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "yardpost/leadPage/UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_ERROR = "yardpost/leadPage/UPLOAD_IMAGE_ERROR";

export const ADD_INQUIRY = "yardpost/leadPage/ADD_INQUIRY";
export const ADD_INQUIRY_SUCCESS = "yardpost/leadPage/ADD_INQUIRY_SUCCESS";
export const ADD_INQUIRY_ERROR = "yardpost/leadPage/ADD_INQUIRY_ERROR";

export const GET_ADDRESS_DETAILS = "yardpost/leadPage/GET_ADDRESS_DETAILS";
export const GET_ADDRESS_DETAILS_SUCCESS =
  "yardpost/leadPage/GET_ADDRESS_DETAILS_SUCCESS";
export const GET_ADDRESS_DETAILS_ERROR =
  "yardpost/leadPage/GET_ADDRESS_DETAILS_ERROR";

export const INIT_LEAD_PAGE_PHASE = "yardpost/leadPage/INIT_LEAD_PAGE_PHASE";

export const ADD_SOCIAL_INQUIRY = "yardpost/leadPage/ADD_SOCIAL_INQUIRY";
export const GET_SOCIAL_LEAD_PAGE_EXIST = "GET_SOCIAL_LEAD_PAGE_EXIST";
export const ADD_NEWSLETTER_LEAD = "yardpost/leadPage/ADD_NEWSLETTER_LEAD";
export const UPDATE_SHORT_URL = "yardpost/leadPage/UPDATE_SHORT_URL";

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  leadPageByIdPhase: INIT,
  leadPageByIdData: {},
  leadPageByIdMessage: "",
  createLeadPagePhase: INIT,
  createLeadPageMessage: "",
  createLeadPageData: {},
  uploadImagePhase: INIT,
  uploadImageData: {},
  getListingsPhase: INIT,
  getListingsData: [],
  getAddressDetailsData: {},
  getAddressDetailsPhase: INIT,
  leadSettingData: {},
  leadPageUserData: {},
  addInquiryPhase: INIT,
  addInquiryMessage: "",
  publishLeadPagePhase: INIT,
  publishLeadPageData: {},
  leadPageProperty: {},
  getLeadPagesPhase: INIT,
  getLeadPagesData: [],
  count: 0,
  getLeadsPhase: INIT,
  getLeadsData: [],
  getLeadsCount: 0,
  getAllLeadPagePhase: INIT,
  getAllLeadPageData: [],
  getAllLeadPageCount: 0,
  leadPageSetting: {},
  isRent: false,
  isSell: false,
  publishLeadPageByIdPhase: INIT,
  publishLeadPageByIdData: {},
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  // console.log("Actions payload:-> ", JSON.stringify(action));
  switch (action.type) {
    case GET_LEAD_PAGE_BY_ID: {
      return state
        .set("leadPageByIdPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_LEAD_PAGE_BY_ID_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("leadPageByIdPhase", SUCCESS)
          .set("leadPageByIdData", payload.data)
          .set("leadPageSetting", payload.leadPageSetting)
          .set("leadPageUserData", payload.userData)
          .set("leadPageProperty", payload.property);
      } else {
        return state
          .set("leadPageByIdPhase", ERROR)
          .set("leadPageByIdMessage", payload.message);
      }
      break;
    }

    case GET_LEAD_PAGE_BY_ID_ERROR: {
      const { payload } = action;
      return state
        .set("leadPageByIdPhase", ERROR)
        .set("leadPageByIdMessage", payload.message)
        .set("isSubmitting", true);
    }

    case ADD_INQUIRY: {
      return state.set("addInquiryPhase", LOADING);
    }

    case ADD_INQUIRY_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("addInquiryPhase", payload.success)
          .set("addInquiryMessage", payload.message);
      } else {
        return state
          .set("addInquiryPhase", payload.success)
          .set("addInquiryMessage", payload.message);
      }
    }

    case ADD_INQUIRY_ERROR: {
      const { payload } = action;
      return state.set("addInquiryPhase", ERROR).set("error", payload.message);
    }

    case GET_LISTINGS: {
      return state.set("getListingsPhase", LOADING);
    }

    case GET_LISTINGS_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("getListingsPhase", SUCCESS)
          .set("getListingsData", payload.data);
      } else {
        return state.set("getListingsPhase", ERROR);
      }
      break;
    }

    case GET_LISTINGS_ERROR: {
      const { payload } = action;
      return state.set("getListingsPhase", ERROR);
    }

    case GET_LEADS: {
      return state.set("getLeadsPhase", LOADING);
    }

    case GET_LEADS_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("getLeadsPhase", SUCCESS)
          .set("getLeadsData", payload.data)
          .set("getLeadsCount", payload.count);
      } else {
        return state.set("getLeadsPhase", ERROR);
      }
      break;
    }

    case GET_LEADS_ERROR: {
      const { payload } = action;
      return state.set("getLeadsPhase", ERROR);
    }

    case GET_LEAD_PAGE: {
      return state.set("getLeadPagesPhase", LOADING);
    }

    case GET_LEAD_PAGE_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("getLeadPagesPhase", SUCCESS)
          .set("getLeadPagesData", payload.data)
          .set("count", payload.count);
      } else {
        return state.set("getLeadPagesPhase", ERROR);
      }
      break;
    }

    case GET_LEAD_PAGE_ERROR: {
      const { payload } = action;
      return state.set("getLeadPagesPhase", ERROR);
    }

    case GET_ADDRESS_DETAILS: {
      // console.log("getAddressDetailsPhase loading")
      return state
        .set("getAddressDetailsPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_ADDRESS_DETAILS_SUCCESS: {
      const { payload } = action;
      // console.log("getAddressDetailsPhase data -> payload", payload.success)
      // const updatedData = { ...state, isSubmitting: true, getAddressDetailsPhase: payload.success, getAddressDetailsData: payload.data }
      // state = updatedData
      // console.log("After updating Address details =======> ", state)
      // return state
      if (isEmpty(payload.data)) {
        return state
          .set("getAddressDetailsPhase", "error")
          .set("getAddressDetailsData", payload.data)
          .set("isSubmitting", true);
      } else {
        return state
          .set("getAddressDetailsPhase", "success")
          .set("getAddressDetailsData", payload.data)
          .set("isSubmitting", true);
      }
    }

    case GET_ADDRESS_DETAILS_ERROR: {
      const { payload } = action;
      // console.log("get address detail error phase in duck", payload);
      return state
        .set("getAddressDetailsPhase", "error")
        .set("getAddressDetailsData", {})
        .set("error", payload.message)
        .set("isSubmitting", true);
    }

    case UPLOAD_IMAGE: {
      return state
        .set("uploadImagePhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case UPLOAD_IMAGE_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("uploadImagePhase", SUCCESS)
          .set("uploadImageData", payload.data);
      }
    }

    case UPLOAD_IMAGE_ERROR: {
      const { payload } = action;
      return state
        .set("uploadImagePhase", ERROR)
        .set("uploadImageData", {})
        .set("error", payload.message);
    }

    case GET_LEAD_PAGE_BY_TYPE: {
      return state.set("getAllLeadPagePhase", LOADING);
    }

    case GET_LEAD_PAGE_BY_TYPE_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("getAllLeadPagePhase", SUCCESS)
          .set("getAllLeadPageCount", payload.count)
          .set("getAllLeadPageData", payload.data)
          .set("isSell", payload.isSell)
          .set("isRent", payload.isRent);
      }
    }

    case GET_LEAD_PAGE_BY_TYPE_ERROR: {
      const { payload } = action;
      return state
        .set("getAllLeadPagePhase", ERROR)
        .set("getAllLeadPageData", {})
        .set("error", payload.message);
    }

    case CREATE_LEAD_PAGE: {
      return state.set("createLeadPagePhase", LOADING);
    }

    case CREATE_LEAD_PAGE_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("createLeadPagePhase", SUCCESS)
          .set("createLeadPageData", payload.data)
          .set("createLeadPageMessage", payload.message);
      } else if (payload.success === false) {
        return state
          .set("createLeadPagePhase", "new_error")
          .set("createLeadPageData", {})
          .set("createLeadPageMessage", payload.message);
      }
      return state
        .set("createLeadPagePhase", ERROR)
        .set("createLeadPageData", {})
        .set("createLeadPageMessage", payload.message);
      break;
    }

    case CREATE_LEAD_PAGE_ERROR: {
      const { payload } = action;
      return state
        .set("createLeadPagePhase", ERROR)
        .set("createLeadPageMessage", payload.message);
    }

    case PUBLISH_LEAD_PAGE: {
      return state.set("publishLeadPagePhase", LOADING);
    }

    case PUBLISH_LEAD_PAGE_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("publishLeadPagePhase", SUCCESS)
          .set("publishLeadPageData", payload.data);
      } else {
        return state
          .set("publishLeadPagePhase", ERROR)
          .set("publishLeadPageData", {});
      }
      break;
    }

    case PUBLISH_LEAD_PAGE_ERROR: {
      const { payload } = action;
      return state.set("publishLeadPagePhase", ERROR);
    }

    case PUBLISH_LEAD_PAGE_BY_ID: {
      return state.set("publishLeadPageByIdPhase", LOADING);
    }

    case PUBLISH_LEAD_PAGE_BY_ID_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("publishLeadPageByIdPhase", SUCCESS)
          .set("publishLeadPageByIdData", payload.data);
      } else {
        return state
          .set("publishLeadPageByIdPhase", ERROR)
          .set("publishLeadPageData", {});
      }
      break;
    }

    case PUBLISH_LEAD_PAGE_BY_ID_ERROR: {
      const { payload } = action;
      return state.set("publishLeadPagePhase", ERROR);
    }

    case INIT_LEAD_PAGE_PHASE: {
      return state
        .set("leadPageByIdMessage", "")
        .set("leadPageByIdPhase", INIT)
        .set("createLeadPagePhase", INIT)
        .set("uploadImagePhase", INIT)
        .set("getListingsPhase", INIT)
        .set("addInquiryPhase", INIT)
        .set("getAddressDetailsPhase", INIT)
        .set("publishLeadPagePhase", INIT)
        .set("getLeadPagesPhase", INIT)
        .set("getLeadsPhase", INIT)
        .set("getAllLeadPagePhase", INIT)
        .set("publishLeadPageByIdPhase", INIT);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getLeadPageById = (credentials) => {
  return {
    type: GET_LEAD_PAGE_BY_ID,
    payload: credentials,
  };
};

export const uploadImages = (credentials) => {
  return {
    type: UPLOAD_IMAGE,
    payload: credentials,
  };
};

export const createLeadPage = (credentials) => {
  return {
    type: CREATE_LEAD_PAGE,
    payload: credentials,
  };
};

export const getLeads = (credentials) => {
  return {
    type: GET_LEADS,
    payload: credentials,
  };
};

export const getLeadPages = (credentials) => {
  return {
    type: GET_LEAD_PAGE,
    payload: credentials,
  };
};

export const addInquiry = (credentials) => {
  return {
    type: ADD_INQUIRY,
    payload: credentials,
  };
};

export const addSocialInquiry = (credentials) => {
  return {
    type: ADD_SOCIAL_INQUIRY,
    payload: api.addSocialInquiry(credentials),
  };
};

export const getSocialLeadPageExist = (credentials) => {
  return {
    type: GET_SOCIAL_LEAD_PAGE_EXIST,
    payload: api.getSocialLeadPageExist(credentials),
  };
};

export const addNewsletterLead = (credentials) => {
  return {
    type: ADD_NEWSLETTER_LEAD,
    payload: api.addNewsletterLead(credentials),
  };
};

export const publishLeadPageById = (credentials) => {
  return {
    type: PUBLISH_LEAD_PAGE_BY_ID,
    payload: credentials,
  };
};

export const getAllLeadPage = (credentials) => {
  return {
    type: GET_LEAD_PAGE_BY_TYPE,
    payload: credentials,
  };
};

export const getListings = (credentials) => {
  return {
    type: GET_LISTINGS,
    payload: credentials,
  };
};

export const publishLeadPage = (credentials) => {
  return {
    type: PUBLISH_LEAD_PAGE,
    payload: credentials,
  };
};

export const updateShortUrl = (credentials) => {
  return {
    type: UPDATE_SHORT_URL,
    payload: api.updateShortUrl(credentials),
  };
};

export const initLeadPage = (credentials) => {
  return {
    type: INIT_LEAD_PAGE_PHASE,
  };
};

export const getAddressDetails = (credentials) => {
  return {
    type: GET_ADDRESS_DETAILS,
    payload: credentials,
  };
};

/***********************************
 * Epics
 ***********************************/

const getLeadPageByIdEpic = (action$) =>
  action$.pipe(
    ofType(GET_LEAD_PAGE_BY_ID),
    mergeMap((action) => {
      return fromPromise(api.getLeadPageById(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_LEAD_PAGE_BY_ID_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_LEAD_PAGE_BY_ID_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const publishLeadPageEpic = (action$) =>
  action$.pipe(
    ofType(PUBLISH_LEAD_PAGE),
    mergeMap((action) => {
      return fromPromise(api.publishLeadPage(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: PUBLISH_LEAD_PAGE_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: PUBLISH_LEAD_PAGE_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const uploadImagesEpic = (action$) =>
  action$.pipe(
    ofType(UPLOAD_IMAGE),
    mergeMap((action) => {
      return fromPromise(api.uploadImages(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPLOAD_IMAGE_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPLOAD_IMAGE_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const addInquiryEpic = (action$) =>
  action$.pipe(
    ofType(ADD_INQUIRY),
    mergeMap((action) => {
      return fromPromise(api.addInquiry(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: ADD_INQUIRY_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: ADD_INQUIRY_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const createLeadPageEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_LEAD_PAGE),
    mergeMap((action) => {
      return fromPromise(api.createLeadPage(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: CREATE_LEAD_PAGE_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: CREATE_LEAD_PAGE_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getAddressDetailsEpic = (action$) =>
  action$.pipe(
    ofType(GET_ADDRESS_DETAILS),
    mergeMap((action) => {
      return fromPromise(api.getAddressDetails(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_ADDRESS_DETAILS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_ADDRESS_DETAILS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getLeadPagesEpic = (action$) =>
  action$.pipe(
    ofType(GET_LEAD_PAGE),
    mergeMap((action) => {
      return fromPromise(api.getLeadPages(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_LEAD_PAGE_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_LEAD_PAGE_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getListingsEpic = (action$) =>
  action$.pipe(
    ofType(GET_LISTINGS),
    mergeMap((action) => {
      return fromPromise(api.getListings(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_LISTINGS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_LISTINGS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getLeadsEpic = (action$) =>
  action$.pipe(
    ofType(GET_LEADS),
    mergeMap((action) => {
      return fromPromise(api.getLeads(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_LEADS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_LEADS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getAllLeadPageEpic = (action$) =>
  action$.pipe(
    ofType(GET_LEAD_PAGE_BY_TYPE),
    mergeMap((action) => {
      return fromPromise(api.getAllLeadPage(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_LEAD_PAGE_BY_TYPE_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_LEAD_PAGE_BY_TYPE_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const publishLeadPageByIdEpic = (action$) =>
  action$.pipe(
    ofType(PUBLISH_LEAD_PAGE_BY_ID),
    mergeMap((action) => {
      return fromPromise(api.publishLeadPageById(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: PUBLISH_LEAD_PAGE_BY_ID_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: PUBLISH_LEAD_PAGE_BY_ID_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

export const leadPageEpic = combineEpics(
  getLeadPageByIdEpic,
  createLeadPageEpic,
  uploadImagesEpic,
  publishLeadPageByIdEpic,
  addInquiryEpic,
  getListingsEpic,
  getAddressDetailsEpic,
  publishLeadPageEpic,
  getLeadPagesEpic,
  getLeadsEpic,
  getAllLeadPageEpic
);
