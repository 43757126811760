import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
// Set the workerSrc for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfToImage = ({ pdfUrl, preview }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const canvasRef = useRef(null);

  const onLoadSuccess = async (pdf) => {
    try {
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      const imgData = canvas.toDataURL('image/png');
      setImageSrc(imgData);
    } catch (error) {
      console.error('Error during PDF rendering:', error);
    }
  };

  const onLoadError = (error) => {
    console.error('Error while loading the PDF document:', error);
  };

  return (
    <div>
      {!imageSrc && (
        <Document
          file={pdfUrl}
          onLoadSuccess={onLoadSuccess}
          onLoadError={onLoadError}
          style={{ display: 'none' }}
        >
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </Document>
      )}
      {imageSrc ? (
        <a href={pdfUrl} target='_blank'>
          <img
            src={imageSrc}
            className={preview ? 'pdf_image_preview2' : 'pdf_image_preview'}
          />
        </a>
      ) : (
        <Spin
          size="large"
          indicator={
            <LoadingOutlined
              style={{ fontSize: 30, color: "#000", align: "center" }}
              spin
            />
          }
          className="loader__full"
        />
      )}
    </div>
  );
};

export default PdfToImage;
