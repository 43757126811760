import { connect } from "react-redux";
import {
  updateCard,
  resetLoginUser,
  reactiveSubscription,
  updateSubscriptionPlan,
  cancelSubscriptions,
  getPaymentHistory,
} from "../../../../store/user/duck";
import BillingComponent from "./component";

const BillingContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    updateCardPhase: state.user.updateCardPhase,
    updateCardData: state.user.updateCardData,
    cancelSubscriptionPhase: state.user.cancelSubscriptionPhase,
    cancelSubscriptionsMessage: state.user.cancelSubscriptionsMessage,
    getPaymentHistoryPhase: state.user.getPaymentHistoryPhase,
    getPaymentHistoryData: state.user.getPaymentHistoryData,
    updateSubscriptionPlanPhase: state.user.updateSubscriptionPlanPhase,
    updateSubscriptionPlanData: state.user.updateSubscriptionPlanData,
    reactiveSubscriptionPhase: state.user.reactiveSubscriptionPhase,
    reactiveSubscriptionMessage: state.user.reactiveSubscriptionMessage,
  }),
  // Map actions to dispatch and props
  {
    reactiveSubscription,
    updateSubscriptionPlan,
    getPaymentHistory,
    cancelSubscriptions,
    resetLoginUser,
    updateCard,
  }
)(BillingComponent);

export default BillingContainer;
