import * as React from "react";

const Instagram = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      fill="none"
      viewBox="0 0 19 18"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.455 5.46a1.08 1.08 0 100-2.16 1.08 1.08 0 000 2.16z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.292 9.001c0 2.482 2.018 4.5 4.5 4.5s4.5-2.018 4.5-4.5-2.018-4.5-4.5-4.5a4.505 4.505 0 00-4.5 4.5zm2.25-.002c0-1.24 1.01-2.25 2.25-2.25s2.25 1.01 2.25 2.25c0 1.241-1.01 2.25-2.25 2.25S7.542 10.24 7.542 9z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.292 18h9c2.313 0 4.5-2.187 4.5-4.5v-9c0-2.313-2.187-4.5-4.5-4.5h-9c-2.313 0-4.5 2.187-4.5 4.5v9c0 2.313 2.187 4.5 4.5 4.5zM3.042 4.498c0-1.052 1.198-2.25 2.25-2.25h9c1.052 0 2.25 1.198 2.25 2.25v9c0 1.052-1.198 2.25-2.25 2.25h-9c-1.072 0-2.25-1.178-2.25-2.25v-9z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Instagram;
