import * as React from "react";

const Info = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16ZM7 8.089V8.5a1 1 0 0 0 1.926.377 3.5 3.5 0 1 0-3.401-5.852A3.437 3.437 0 0 0 4.5 5.51a1.005 1.005 0 1 0 2.009-.02 1.43 1.43 0 0 1 .436-1.045 1.5 1.5 0 0 1 1.058-.437 1.492 1.492 0 0 1 0 2.983A1.004 1.004 0 0 0 7 8.09v-.001ZM8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        fill="#A3ACB9"
      />
    </svg>
  );
};

export default Info;
