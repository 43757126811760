import React, { PureComponent } from "react";
import TopNavigationComponent from "../TopNavigation/container";
import Impersonate from "../Impersonate/container";
import { get } from "lodash";
import "./styles.scss";

export default class PaymentCardComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    return (
      <>
        {localStorage.getItem("AdminAuthorization") &&
          localStorage.getItem("Authorization") && (
            <Impersonate
              data={get(this.props.user, "user", "")}
              {...this.props}
            />
          )}
        <TopNavigationComponent {...this.props} />
        <div className="form-group">
          <label className="label-primary">Name on card </label>
          <input type="text" name="" className="form-control" required />
        </div>
        <div className="form-group">
          <label className="label-primary">Card Number</label>
          <input
            type="number"
            name=""
            autoComplete="off"
            className="form-control"
            required
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-primary">Expiration Date (MM/YY)</label>
              <input
                type="text"
                name=""
                autoComplete="off"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-primary">Security Code (CVV)</label>
              <input
                type="text"
                name=""
                autoComplete="off"
                className="form-control"
                required
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
