import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Close } from "../../../components/icons";
import Profile from "./Profile/container";
import Billing from "./Billing/container";
// import DomainSetup from "./DomainSetup/container";
import { get, isEmpty } from "lodash";

import "./styles.scss";

export default class AccountComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activeTab: "profile",
      user: {},
    };
  }

  componentDidMount() {
    document.title = "Account Information | Yardpost";
    let clear = setInterval(() => {
      if (isEmpty(this.props.user)) {
        this.props.getUser();
      }
      clearInterval(clear);
    }, 2000);
    let tab = this.props.location.pathname;
    let selectedTab = "";
    if (tab === "/account/profile") {
      selectedTab = "profile";
    }
    // else if (tab === "/account/domain-setup") {
    //   selectedTab = "domainSetup";
    // }
    else if (tab === "/account/billing") {
      selectedTab = "billing";
    }
    this.setState({ activeTab: selectedTab });
  }

  closeModal() {
    this.props.history.push("/social-links");
  }

  onTabChange(tab) {
    this.setState({ activeTab: tab }, () => {
      if (tab === "profile") {
        this.props.history.push("/account/profile");
      }
      // else if (tab === "domainSetup") {
      //   this.props.history.push("/account/domain-setup");
      // }
      else if (tab === "billing") {
        this.props.history.push("/account/billing");
      }
    });
  }

  componentDidUpdate(prev) {
    document.title = "Account Information | Yardpost";
    if (get(prev.user, "user", {}) !== get(this.props.user, "user", {})) {
      if (this.props.userPhase === "success") {
        this.props.resetLoginUser();
        console.log(document.getElementById("favicon").href);
        document.getElementById("favicon").href = get(
          this.props.user,
          "user.favicon",
          "https://res.cloudinary.com/duatl7ntm/image/upload/v1710754846/favicon-32x32_x2keke.png"
        );
        console.log(document.getElementById("favicon").href);
        this.setState({ user: get(this.props.user, "user", {}) });
      }
    }
  }

  render() {
    const { activeTab } = this.state;
    return (
      <>
        <main className="main" role="tabs">
          <div className="main__header">
            <header className="head">
              <div className="head__container">
                <div className="head__row">
                  <div className="head__left">
                    <div className="head__close">
                      <button
                        onClick={this.closeModal.bind(this)}
                        className="btn btn__close"
                      >
                        <Close />
                      </button>
                    </div>
                    <div className="head__divider"></div>
                    <div className="head__title">Account Information</div>
                  </div>
                </div>
              </div>
            </header>

            <div className="tabs__links">
              <button
                className={
                  activeTab === "profile" ? "tabs__link active" : "tabs__link"
                }
                onClick={this.onTabChange.bind(this, "profile")}
              >
                Profile
              </button>
              {/* <button
                className={
                  activeTab === "domainSetup"
                    ? "tabs__link active"
                    : "tabs__link"
                }
                onClick={this.onTabChange.bind(this, "domainSetup")}
              >
                Domain Setup
              </button> */}
              <button
                className={
                  activeTab === "billing" ? "tabs__link active" : "tabs__link"
                }
                onClick={this.onTabChange.bind(this, "billing")}
              >
                Billing
              </button>
            </div>
          </div>

          <div className="custom__container">
            <div className="custom__wrap">
              {activeTab === "profile" ? (
                <Profile userData={get(this.props.user, "user", {})} />
              ) : (
                // : activeTab === "domainSetup" ? (
                //   <DomainSetup />
                // )
                <Billing />
              )}
            </div>
          </div>
        </main>
      </>
    );
  }
}
