import { fetch, fetchWithoutAuth } from "../../utils";
import { get } from "lodash";
import request from "./request";

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
const CLOUD_NAME = process.env.REACT_APP_CLOUD_NAME;
const CLOUDINARY_API = process.env.REACT_APP_CLOUDINARY_API;

export const loginUser = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const reactiveSubscription = (credentials) => {
  return fetch(
    `${HOSTNAME}/api/v1/admin/re-active-subscription?id=${credentials.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const loginUserTemplate = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/loginUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateTeamStatus = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/updateTeamStatus`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: credentials,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const syncContacts = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/googleContact`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const verifyOtp = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/verify-otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const resendOtp = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/resend-otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateUserStatus = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/updateUserStatus`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials), //credentials
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const signupUser = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/signup`, {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json'
    // },
    body: credentials, //JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUser = async(credentials) => {
  const {withIp} = credentials ?? {};
  let ipAdd = ""
  if(withIp){
    const response = await fetchWithoutAuth('https://api.ipify.org?format=json');
    if (response.ok) {
    const data = await response.json();
    ipAdd = data?.ip ?? "";
    }
  }
  return fetch(
    `${HOSTNAME}/api/v1/user/me?domain=${credentials?.domain ??  ""}&leadId=${credentials?.leadId ?? ""}${withIp ? `&ip=${ipAdd}` : ""}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      //body: JSON.stringify(credentials)
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPropertyLog = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/propertyCount`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    //body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const editUser = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/profile`, {
    method: "PUT",
    // headers: {
    //   'Content-Type': 'application/json'
    // },
    body: credentials, //JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSubscriptions = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/subscription-plan`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addCard = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/add-card`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const cancelSub = (credentials) => {
  return fetch(
    `${HOSTNAME}/api/v1/admin/cancel-subscription?id=${credentials}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// Team Member API

export const getTeam = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/userTeamById?id=` + credentials, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLead = (credentials) => {
  return fetch(
    `${HOSTNAME}/api/v1/user_inquiry?type=${credentials.type}&page=${
      credentials.page
    }&search=${get(credentials, "search", "")}&limit=${get(
      credentials,
      "limit",
      ""
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLeadSearch = (credentials) => {
  return fetch(
    `${HOSTNAME}/api/v1/user_inquiry?type=${credentials.type}&page=${
      credentials.page
    }&search=${get(credentials, "search", "")}&limit=${get(
      credentials,
      "limit",
      ""
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addMember = (credentials) => {
  // console.log('addMember',credentials)
  return fetch(`${HOSTNAME}/api/v1/registration`, {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json'
    // },
    // body: JSON.stringify(credentials)
    body: credentials,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getMember = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/userteam?userId=${credentials}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteMember = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/delete_user`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const editMember = (credentials) => {
  // console.log(credentials,'credentials1')
  return fetch(`${HOSTNAME}/api/v1/edit_user`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
    // body: credentials
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

//PAYMENT HISTORY API
export const getPaymentHistory = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/payment-history`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateCard = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/edit_card`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// Search API

export const searchDash = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/search/dashboard`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// export const searchMyList = credentials => {
//   return fetch(`${HOSTNAME}/api/v1/search/user/listing`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(credentials)
//   })
//     .then(res => {
//       return res.json()
//     })
//     .then(payload => {
//       return payload
//     })
//     .catch(error => {
//       throw error
//     })
// }

export const searchMyList = (credentials) => {
  return request(
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(credentials),
      url: `${HOSTNAME}/api/v1/search/user/listing`,
    },
    credentials.token
  )
    .then((data) => data)
    .catch((error) => {
      throw error;
    });
};

export const getAgent = (id) => {
  return fetch(`${HOSTNAME}/api/v1/endAgent?id=` + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(id)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAdminAgent = (id) => {
  return fetch(`${HOSTNAME}/api/v1/getAgent`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(id)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateDomain = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/update_domain`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const postGoogleAnalyticAccNo = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/postGoogleAnalyticAccNo`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGoogleAnalyticAccNo = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/getGoogleAnalyticAccNo`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(credentials)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSubscriptionPlan = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/update-subscription-plan`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadLeads = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/uploadLeads`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLeadPageById = async (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/lead-page-by-id?id=${credentials.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// export const createLeadPage = credentials => {
//   return fetch(`${HOSTNAME}/api/v1/user/create-lead-page`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(credentials)
//   })
//     .then(res => {
//       return res.json()
//     })
//     .then(payload => {
//       return payload
//     })
//     .catch(error => {
//       throw error
//     })
// }

export const addSubscription = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/add_subscription`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addLeadPageSetting = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/create-lead-page-setting`, {
    method: "POST",
    body: credentials,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLeadPageSettingId = (credentials) => {
  return fetch(
    `${HOSTNAME}/api/v1/user/lead-page-setting-by-id?userId=${credentials}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const applyCoupon = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/apply_coupon`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadImages = async(file) => {
  const apiUrl = CLOUDINARY_API;

  const form = new FormData();
  form.append("file", file);
  form.append("upload_preset", "images");
  form.append("cloud_name", CLOUD_NAME);

  try {
    const response = await fetchWithoutAuth(apiUrl, {
      method: "POST",
      body: form
    });

    if (response.ok) {
      const data = await response.json();
      return data.secure_url;
    } else {
      throw new Error("Image upload failed");
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
  // return fetch(`${HOSTNAME}/api/v1/upload-image`, {
  //   method: "POST",
  //   // headers: {
  //   //   'Content-Type': 'application/json'
  //   // },
  //   body: credentials,
  // })
  //   .then((res) => {
  //     return res.json();
  //   })
  //   .then((payload) => {
  //     return payload;
  //   })
  //   .catch((error) => {
  //     throw error;
  //   });
};
